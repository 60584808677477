import * as React from 'react';

import { useGetUserQuery } from '@local/auth';
import { useInView, useRouting } from '@local/hooks';
import { Reservation, ReservationVenueInfo, Scope } from '@local/types';

import { useFetchFutureReservations } from './useFetchFutureReservations';
import { useFetchPastReservations } from './useFetchPastReservations';

export function useReservations() {
  const { isUpcomingReservationsPage } = useRouting();
  const scope: Scope = isUpcomingReservationsPage ? 'future' : 'past';
  const [reservations, setReservations] = React.useState<Reservation[]>([]);
  const [shops, setShops] = React.useState<ReservationVenueInfo[]>([]);

  const { ref: pageScrollEndRef, isInView } = useInView();

  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const {
    data: reservationsFutureData,
    isLoading: isLoadingFutureReservations,
    fetchNextPage: fetchMoreFutureReservations,
    hasNextPage: hasNextFuturePage,
    isFetchingNextPage: isFetchingNextFuturePage,
  } = useFetchFutureReservations(user, scope);
  const {
    data: reservationsPastData,
    isLoading: isLoadingPastReservations,
    fetchNextPage: fetchMorePastReservations,
    hasNextPage: hasNextPastPage,
    isFetchingNextPage: isFetchingNextPastPage,
  } = useFetchPastReservations(user, scope);

  const isInitialPageLoading =
    isUserLoading || (user && !reservationsFutureData && !reservationsPastData);

  const isLoadingReservations =
    isLoadingPastReservations || isLoadingFutureReservations;

  const hasMorePagesToFetch =
    (hasNextFuturePage && scope === 'future') ||
    (hasNextPastPage && scope === 'past');

  const isFetchingMoreReservations =
    isFetchingNextFuturePage || isFetchingNextPastPage;

  React.useEffect(() => {
    setReservations(
      scope === 'future'
        ? reservationsFutureData?.reservations ?? []
        : reservationsPastData?.reservations ?? [],
    );
    setShops(
      scope === 'future'
        ? reservationsFutureData?.shops ?? []
        : reservationsPastData?.shops ?? [],
    );
  }, [
    isUpcomingReservationsPage,
    reservationsFutureData,
    reservationsPastData,
    scope,
  ]);

  React.useEffect(() => {
    if (isInView) {
      void (scope === 'future'
        ? fetchMoreFutureReservations()
        : fetchMorePastReservations());
    }
  }, [fetchMoreFutureReservations, fetchMorePastReservations, isInView, scope]);

  return {
    isInitialPageLoading,
    isLoadingReservations,
    isFetchingMoreReservations,
    reservations,
    shops,
    scope,
    hasMorePagesToFetch,
    pageScrollEndRef,
    isLoggedIn: !!user,
  };
}
