import pino from 'pino';

import { CONFIG } from '@local/configs';
import { SENSITIVE_HEADERS } from '@local/constants';

const REDACTED_HEADERS = SENSITIVE_HEADERS.map(
  (header) => `req.headers["${header}"]`,
);

export const logger = pino({
  redact: [...REDACTED_HEADERS], // remove sensitive headers from logs
  transport:
    CONFIG.VITE_APP_ENVIRONMENT === 'development'
      ? {
          target: 'pino-pretty',
          options: {
            colorize: true,
          },
        }
      : undefined,
  enabled: CONFIG.VITE_APP_ENVIRONMENT === 'development' || CONFIG.IS_SSR, // only log in development or SSR (this works both on browser and server)
});
