import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { SEARCH_EMPTY_VIEW_IMG } from '@local/assets';
import { EmptyView } from '@local/components';

import { useSearchFilters } from '../../hooks/useSearchFilters';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--surface);
`;

interface Props {
  onResetSearch: () => void;
}

export function NotFound({ onResetSearch }: Props) {
  const { updateSearchFilters } = useSearchFilters();
  const handleReset = () => {
    updateSearchFilters({}, { reset: true });
    onResetSearch();
  };

  return (
    <Container data-testid="Results Not Found">
      <EmptyView
        title={t('search.not_found.title')}
        description={t('search.not_found.description')}
        imgSrc={SEARCH_EMPTY_VIEW_IMG}
        isSmall
        imgAlt={t('search.not_found.description')}
      />
      <Button data-size="medium" onClick={handleReset}>
        {t('search.action_btns.reset')}
      </Button>
    </Container>
  );
}
