import { Bar, Book, Favorite } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';

import { ICON_SIZE_24 } from '@local/constants';

const FeaturesWrapper = styled.section`
  display: grid;
  gap: var(--spacing-l3);
`;

const FeatWrapper = styled.div`
  display: flex;
`;

const FeatIcon = styled.div`
  padding-right: var(--spacing-l2);
`;

const FeatDescription = styled.div`
  color: grey;
  font-size: 12px;
  line-height: 18px;
`;

function getFeatureContent(feat: string) {
  switch (feat) {
    case 'bar':
      return (
        <>
          <FeatIcon>
            <Bar size={ICON_SIZE_24} />
          </FeatIcon>
          <div>
            {t('venue_feat.feature.title.bar')}
            <FeatDescription>
              {t('venue_feat.feature.description.bar')}
            </FeatDescription>
          </div>
        </>
      );
    case 'NYT':
      return (
        <>
          <FeatIcon>
            <Book size={ICON_SIZE_24} />
          </FeatIcon>
          <div>
            {t('venue_feat.feature.title.fav')}
            <FeatDescription>
              {t('venue_feat.feature.description.fav')}
            </FeatDescription>
          </div>
        </>
      );
    default:
      return (
        <>
          <FeatIcon>
            <Favorite size={ICON_SIZE_24} />
          </FeatIcon>
          <div>
            {t('venue_feat.feature.title.nyt')}
            <FeatDescription>
              {t('venue_feat.feature.description.nyt')}
            </FeatDescription>
          </div>
        </>
      );
  }
}

export function Features({ features }: { features: string[] }): JSX.Element {
  return (
    <FeaturesWrapper>
      {features.map((feat) => (
        <FeatWrapper key={feat}>{getFeatureContent(feat)}</FeatWrapper>
      ))}
    </FeaturesWrapper>
  );
}
