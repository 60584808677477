import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { CONFIG } from '@local/configs';
import { VenueInfo } from '@local/types';
import { CurrencyFormat, getPrice, translate } from '@local/utils';

interface VenueHeadProps {
  venueData: VenueInfo;
}

const constructUrl = (path: string): string =>
  `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${path}`;

interface SchemaAddress {
  '@type': 'PostalAddress';
  streetAddress: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;
}

interface SchemaGeo {
  '@type': 'GeoCoordinates';
  latitude: number;
  longitude: number;
}

interface SchemaRestaurant {
  '@context': 'http://schema.org';
  '@type': 'Restaurant';
  '@id': string;
  name: string;
  description?: string;
  image?: string | null;
  acceptsReservations: string;
  address?: SchemaAddress;
  telephone?: string;
  priceRange?: string;
  servesCuisine?: string;
  menu?: string;
  geo?: SchemaGeo;
}

const buildLinkedData = (
  venueData: VenueInfo,
  language: string,
  description: string,
  venueImage?: string | null,
): SchemaRestaurant => {
  const address: SchemaAddress | undefined = venueData.address
    ? {
        '@type': 'PostalAddress',
        streetAddress: `${venueData.address.street}${venueData.address.street2 ? ` ${venueData.address.street2}` : ''}`,
        addressLocality: venueData.address.city,
        addressRegion: venueData.address.region,
        postalCode: venueData.address.postal_code,
        addressCountry: venueData.address.country,
      }
    : undefined;

  const geo: SchemaGeo | undefined = venueData.geocode
    ? {
        '@type': 'GeoCoordinates',
        latitude: venueData.geocode.lat,
        longitude: venueData.geocode.lon,
      }
    : undefined;
  const bookingFormURL =
    venueData.booking_page_mode === 'v1'
      ? `${language}/${venueData.slug}/reserve/landing`
      : `${language}/shops/${venueData.slug}/reserve`;
  return {
    '@context': 'http://schema.org',
    '@type': 'Restaurant',
    '@id': constructUrl(`${language}/${venueData.slug}`),
    name: translate(venueData.name_translations, language),
    description,
    image: venueImage,
    acceptsReservations: constructUrl(bookingFormURL),
    address,
    telephone: venueData.phone,
    priceRange: getPrice({
      amount: venueData.price,
      showZero: false,
      currencyFormat: venueData.currency as CurrencyFormat,
    }),
    servesCuisine: venueData.cuisines?.join(', '),
    menu: venueData.url,
    geo,
  };
};

export function VenueHead({ venueData }: VenueHeadProps): JSX.Element | null {
  const [t, { language }] = useTranslation();

  if (!venueData) return null;

  const name = translate(venueData.name_translations, language);
  const locationName = translate(
    venueData.location_name_translations,
    language,
  );
  const description =
    translate(venueData.tagline_translations, language) ||
    translate(venueData.content_body_translations, language) ||
    translate(venueData.content_title_translations, language);

  const title = [name, locationName].filter(Boolean).join(' - ');
  const venueImage = venueData.banner_image || venueData.search_image;

  const linkedData = buildLinkedData(
    venueData,
    language,
    description,
    venueImage,
  );

  return (
    <Helmet>
      <title itemProp="name" lang={language}>
        {t('meta.shop_title', { title })}
      </title>

      <meta name="description" content={description} />
      <meta property="og:title" content={t('meta.shop_title', { title })} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="restaurant.restaurant" />
      <meta
        property="og:url"
        content={constructUrl(`${language}/${venueData.slug}`)}
      />
      {venueImage && <meta property="og:image" content={venueImage} />}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@tablecheck_jp" />

      {!!venueData.geocode && [
        <meta
          key="latitude"
          property="place:location:latitude"
          content={String(venueData.geocode.lat)}
        />,
        <meta
          key="longitude"
          property="place:location:longitude"
          content={String(venueData.geocode.lon)}
        />,
      ]}

      {venueData.phone && (
        <meta
          property="restaurant:contact_info:phone_number"
          content={venueData.phone}
        />
      )}
      {venueData.url && (
        <meta
          property="restaurant:contact_info:website"
          content={venueData.url}
        />
      )}

      {venueData.cuisines?.map((cuisine) => (
        <meta
          key={cuisine}
          property="restaurant:category"
          content={t(`cuisine.${cuisine.toLowerCase()}`)}
        />
      ))}

      {/* Structured Data */}
      <script type="application/ld+json">{JSON.stringify(linkedData)}</script>
    </Helmet>
  );
}
