import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button, IconButton } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';

import { DEFAULT_ICON_SIZE } from '@local/constants';
import { useDeviceType } from '@local/hooks';
import type { CardCarouselProps } from '@local/types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const CarouselTitle = styled.div`
  margin-bottom: var(--spacing-l2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  & button {
    font-size: 14px !important;
    line-height: 16px !important;
  }
`;

const StyledSwiper = styled(Swiper)`
  width: -webkit-fill-available;
`;

const Carousel = styled.div`
  position: relative;
  display: flex;
  &[data-is-mobile='true'] {
    margin: 0 -17px;
    & .swiper-slide:first-child {
      margin: 0 17px;
    }
  }
  & .swiper-slide {
    width: auto;
    max-width: min-content;
  }
`;

const SeeMoreArrow = styled(IconButton)`
  background-color: white;
  border: 1px solid var(--lighter-grey-border);
  border-radius: 50%;
  margin-bottom: var(--spacing-l3);
`;

const Arrow = styled(SeeMoreArrow)`
  position: absolute;
  margin: 0 var(--spacing-l4);
  z-index: var(--z-index-1);
  top: 50%;
  transform: translateY(-50%);

  &[class*='arrow-right'] {
    right: calc(-1 * var(--spacing-l4));
    margin: 0;
  }
  &[class*='arrow-left'] {
    left: calc(-1 * var(--spacing-l4));
    margin: 0;
  }
  &.swiper-button-disabled {
    display: none;
  }
  &.swiper-button-disabled + div {
    display: none;
  }
`;

const GradientLeft = styled.div`
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 32px;
  z-index: 2;
`;

const GradientRight = styled.div`
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 32px;
  z-index: 2;
`;

const SLIDE_GAP = 16;
const MOMENTUM_RATIO = 0.5;
const MOMENTUM_VELOCITY_RATIO = 0.7;

export function CardCarousel({
  id,
  children,
  showViewMore,
  title,
  showMoreAction,
}: CardCarouselProps): JSX.Element {
  const { isDesktop } = useDeviceType();
  const [swiperIndex, setSwiperIndex] = React.useState(0);

  const sliderSetting: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: SLIDE_GAP,
    pagination: { clickable: true },
    modules: [FreeMode],
    freeMode: {
      enabled: true,
      momentum: true,
      momentumRatio: MOMENTUM_RATIO,
      momentumVelocityRatio: MOMENTUM_VELOCITY_RATIO,
    },
    ...(isDesktop && {
      modules: [FreeMode, Navigation],
      navigation: {
        nextEl: `.arrow-right-${id}`,
        prevEl: `.arrow-left-${id}`,
      },
    }),
  };

  return (
    <section data-testid="Card Carousel">
      {!!title && (
        <CarouselTitle>
          <h3>{title}</h3>
          {showViewMore && (
            <Button
              data-hidden={swiperIndex}
              data-variant="ghost"
              type="button"
              onClick={showMoreAction}
            >
              {t('general.view_all')}
            </Button>
          )}
        </CarouselTitle>
      )}
      <Carousel data-is-mobile={!isDesktop}>
        {isDesktop && (
          <span>
            <Arrow
              type="button"
              data-variant="bare"
              className={`arrow-left-${id} arrow`}
            >
              <ChevronLeft size={DEFAULT_ICON_SIZE} />
            </Arrow>
            <GradientLeft />
          </span>
        )}
        <StyledSwiper
          {...sliderSetting}
          onSlideChange={(swiper) => {
            setSwiperIndex(swiper.activeIndex);
          }}
        >
          {children}
        </StyledSwiper>
        {isDesktop && (
          <span>
            <Arrow
              type="button"
              data-variant="bare"
              data-hidden={swiperIndex}
              className={`arrow-right-${id} arrow`}
            >
              <ChevronRight size={DEFAULT_ICON_SIZE} />
            </Arrow>
            <GradientRight className={`arrow-right-${id} arrow`} />
          </span>
        )}
      </Carousel>
    </section>
  );
}
