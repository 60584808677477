import { ChevronLeft } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import * as React from 'react';

import { ICON_SIZE_16 } from '@local/constants';

const SubHeader = styled.div`
  padding: var(--spacing-l2);
  box-shadow: 0px 0px 6px 0px var(--lighter-grey-border);
  display: grid;
  grid-template-columns: 32px auto 32px;
  justify-items: center;
  align-content: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: var(--z-index-1);

  &[data-variant='default'] {
    box-shadow: none;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
  &[data-variant='no-gradient'] {
    box-shadow: none;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  border-radius: 100%;
  height: 32px;
  padding: var(--spacing-l2);
  background-color: white;

  & svg {
    margin-bottom: 20px;
  }

  &[data-variant='default'] {
    box-shadow: 0px 1px 3px 0px var(--lighter-grey-border);
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  max-width: 95%;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;

  &[data-variant='default'] {
    color: transparent;
  }
`;

const SCROLL_Y_THRESHOLD = 280;

interface Props {
  title?: string;
  icon?: JSX.Element;
  withGradient?: boolean;
  onBackBtnClick?: () => void;
  onRightIconClick?: () => void;
}

export function MobileSubHeader({
  title,
  icon,
  withGradient = true,
  onBackBtnClick,
  onRightIconClick,
}: Props) {
  const [dataVariant, setDataVariant] = React.useState(
    withGradient ? 'default' : 'no-gradient',
  );

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > SCROLL_Y_THRESHOLD) {
        setDataVariant('fixed');
      } else {
        setDataVariant(withGradient ? 'default' : 'no-gradient');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [withGradient]);

  return (
    <SubHeader data-variant={dataVariant} data-testid="Mobile Sub Header">
      <IconWrapper
        data-variant={dataVariant}
        onClick={onBackBtnClick}
        data-testid="Mobile Header Left Button"
      >
        <ChevronLeft size={ICON_SIZE_16} />
      </IconWrapper>
      <Title data-testid="Mobile Header Title" data-variant={dataVariant}>
        {title}
      </Title>
      {onRightIconClick && icon && (
        <IconWrapper
          data-variant={dataVariant}
          onClick={onRightIconClick}
          data-testid="Mobile Header Right Button"
        >
          {icon}
        </IconWrapper>
      )}
    </SubHeader>
  );
}
