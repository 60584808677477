import { useAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ChangePasswordContent,
  DeleteAccountContent,
  DeleteMembershipContent,
  DeleteSocialAccountContent,
  TermsConditionsContent,
} from '@local/account';
import { useGetUserQuery } from '@local/auth';
import { Panel, panelStateAtom, Spinner, useToast } from '@local/components';
import { useDeviceType, useUniverse } from '@local/hooks';
import type { MembershipInfo, SocialAuthInfo } from '@local/types';

import { DesktopContentWrapper } from '../DesktopContentWrapper';
import { MobileContentWrapper } from '../MobileContentWrapper';

import { ProfileContent } from './ProfileContent';

export function EditAccountRoot() {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const { universeName } = useUniverse();
  const [t, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const [selectedMembership, setSelectedMembership] =
    React.useState<MembershipInfo | null>(null);
  const [selectedSocialAuth, setSelectedSocialAuth] =
    React.useState<SocialAuthInfo | null>(null);

  const { successToastContent, setIsToastOpen, setToastContent } = useToast();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();

  const handleOnSuccess = (content: string) => {
    setToastContent(successToastContent(content));
    setIsToastOpen(true);
  };

  React.useEffect(() => {
    if (!user && !isUserLoading) {
      navigate(`/${language}/${universeName}`);
    }
  }, [user, isUserLoading, navigate, language, universeName]);

  if (!user || isUserLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <div data-testid="Edit Account Page Root">
        {isDesktop ? (
          <DesktopContentWrapper header={<h1>{t('account.profile')}</h1>}>
            <ProfileContent
              onSuccess={handleOnSuccess}
              setSelectedMembership={setSelectedMembership}
              setSelectedSocialAuth={setSelectedSocialAuth}
            />
          </DesktopContentWrapper>
        ) : (
          <MobileContentWrapper title={t('account.profile')}>
            <ProfileContent
              onSuccess={handleOnSuccess}
              setSelectedMembership={setSelectedMembership}
              setSelectedSocialAuth={setSelectedSocialAuth}
            />
          </MobileContentWrapper>
        )}
      </div>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'terms_conditions' && (
          <TermsConditionsContent onClose={() => setPanelState(null)} />
        )}
        {panelState === 'change_pwd' && (
          <ChangePasswordContent
            email={user?.customer_user.email || ''}
            onSuccess={() => {
              handleOnSuccess(t('toast.updated_password'));
              setPanelState(null);
            }}
            onCancel={() => setPanelState(null)}
          />
        )}
        {panelState === 'delete_account' && (
          <DeleteAccountContent onCancel={() => setPanelState(null)} />
        )}
        {panelState === 'disconnect_social' && selectedSocialAuth && (
          <DeleteSocialAccountContent
            selectedSocialAuth={selectedSocialAuth}
            onSuccess={() => {
              handleOnSuccess(t('toast.deleted_social'));
              setPanelState(null);
            }}
            onCancel={() => {
              setPanelState(null);
              setSelectedSocialAuth(null);
            }}
          />
        )}
        {panelState === 'delete_membership' && selectedMembership && (
          <DeleteMembershipContent
            selectedMembership={selectedMembership}
            onSuccess={() => {
              handleOnSuccess(t('toast.deleted_membership'));
              setPanelState(null);
            }}
            onCancel={() => {
              setPanelState(null);
              setSelectedMembership(null);
            }}
          />
        )}
      </Panel>
    </>
  );
}
