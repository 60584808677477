import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { CONFIG } from '@local/configs';

import { Spinner } from '../Spinner/Spinner';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-l4);
  margin-block: var(--spacing-l8);
  text-align: center;
  & > p {
    color: var(--text-subtle);
  }
  & > h3 {
    color: var(--black);
  }
`;

const Img = styled.img`
  max-width: 240px;
  width: 100%;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  image: string;
  title: string;
  subtitle?: string;
  onClick: () => void;
}

export function LoginPrompt({
  image,
  title,
  subtitle,
  onClick,
}: Props): JSX.Element {
  if (CONFIG.IS_SSR) {
    return (
      <SpinnerWrapper>
        <Spinner isFullPage />
      </SpinnerWrapper>
    );
  }
  return (
    <Wrapper>
      <Img src={image} />
      <h3>{title}</h3>
      {subtitle && <p>{subtitle}</p>}
      <Button data-testid="Login CTA Btn" data-size="medium" onClick={onClick}>
        {t('general.log_in')}
      </Button>
    </Wrapper>
  );
}
