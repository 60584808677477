import { useQuery, useQueryClient } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import { AvailabilityResponse } from '@local/types';

import { fetchVenueAvailability } from '../venueApi';

const formatAvailabilitySlots = (
  data: AvailabilityResponse,
  selectedDateTime: Date,
) => {
  let hasAvailability = false;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedTimeUTC = zonedTimeToUtc(selectedDateTime, userTimezone);
  const selectedDateData =
    data.availability_calendar.data[format(selectedDateTime, 'yyyy-MM-dd')];

  if (!selectedDateData) return [];

  // Sort slots chronologically
  const sortedSlots = Object.entries(selectedDateData)
    .map(([time, available]) => ({
      time: parseISO(time),
      available,
    }))
    .sort((a, b) => a.time.getTime() - b.time.getTime());

  // Get the index of the selected time
  const selectedIndex = sortedSlots.findIndex(
    (slot) => slot.time >= selectedTimeUTC,
  );

  // Return 9 slots, 4 before and 4 after the selected time
  let startIndex;
  let endIndex;
  if (selectedIndex === -1) {
    startIndex = sortedSlots.length - 9;
    endIndex = sortedSlots.length;
  } else {
    startIndex = Math.max(0, selectedIndex - 4);
    endIndex = Math.min(sortedSlots.length, startIndex + 9);
  }

  // Format the slots to be displayed
  const finalArray = sortedSlots.slice(startIndex, endIndex).map((slot) => {
    if (slot.available) hasAvailability = true;
    return {
      [format(utcToZonedTime(slot.time, userTimezone), 'HH:mm')]:
        slot.available,
    };
  });

  // if no availability, return empty array
  if (!hasAvailability || data.availability_calendar.type === 'error')
    return [];
  return finalArray;
};

export const useFetchVenueAvailQuery = (
  venueSlug: string,
  pax: string,
  date: string,
) => {
  const [, { language }] = useTranslation();
  const queryClient = useQueryClient();
  const formattedDate = format(new Date(date), 'yyyy-MM-dd');
  const accumulatedCacheKey = ['availabilities-accumulated', venueSlug, pax];
  const accumulatedData =
    queryClient.getQueryData<AvailabilityResponse>(accumulatedCacheKey);

  return useQuery({
    queryKey: ['availabilities', venueSlug, date, pax],
    queryFn: async () => {
      if (accumulatedData?.availability_calendar.data[formattedDate])
        return accumulatedData;

      const newData = await fetchVenueAvailability(
        venueSlug,
        language,
        pax,
        date,
      );
      return newData;
    },
    select: (newData: AvailabilityResponse) => {
      const mergedData: AvailabilityResponse = {
        availability_calendar: {
          type: 'success',
          data: {
            ...(accumulatedData?.availability_calendar.data ?? {}),
            ...(newData.availability_calendar.data || {}),
          },
        },
      };
      queryClient.setQueryData(accumulatedCacheKey, mergedData);
      return formatAvailabilitySlots(mergedData, new Date(date));
    },
  });
};
