import { Edit } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import {
  panelStateAtom,
  VenueCard,
  ViewSwitcherBtn,
  Spinner,
} from '@local/components';
import {
  DEFAULT_ASIA_BOUNDS,
  DEFAULT_ASIA_CENTER,
  DEFAULT_ICON_SIZE,
  LISTS_DEFAULT_ZOOM,
} from '@local/constants';
import { useTypedRouteParams } from '@local/hooks';
import { MobileSubHeader } from '@local/layouts';
import { listFiltersAtom, useListView } from '@local/list';
import { selectedVenueAtom, useMapService, Map } from '@local/map-system';
import { buildUrl, translate } from '@local/utils';

import { ListViewContent } from './ListViewContent';

const MobilePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-bottom: var(--spacing-l7);
`;

const MapSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;

  &[data-hidden='true'] {
    visibility: hidden;
  }
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MapVenueCard = styled(VenueCard)`
  position: absolute;
  width: 95svw;
  max-width: var(--map-card-width);
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--border-radius-large);
  padding: var(--spacing-l2);
  box-shadow: var(--elevation-large);
`;

interface ListRouteParams {
  listId: string;
}

export function ListViewMobile() {
  const { listId } = useTypedRouteParams<ListRouteParams>();
  const [, { language }] = useTranslation();
  const [searchParams] = useSearchParams();
  const setPanelState = useSetAtom(panelStateAtom);
  const listFilters = useAtomValue(listFiltersAtom);
  const selectedVenue = useAtomValue(selectedVenueAtom);
  const [shouldShowMap, setShouldShowMap] = React.useState(false);
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery();
  const {
    listDetail: { data: listData },
    mapVenues,
    availabilityToShow,
  } = useListView();
  const { venue, shouldShowMapVenueCard } = selectedVenue;
  const shouldShowViewSwitch =
    user && !shouldShowMap && listData && listData.list?.shops.length > 0;
  const listName = shouldShowMap ? listData?.list?.name : '';

  const { isLoading: mapIsLoading } = useMapService({
    venues: mapVenues,
    containerId: listId,
    language,
    center: DEFAULT_ASIA_CENTER as [number, number],
    zoom: LISTS_DEFAULT_ZOOM,
    cameraTransition: 'jumpTo',
    bounds: DEFAULT_ASIA_BOUNDS,
  });

  return (
    <>
      {user && (
        <MobileSubHeader
          title={listName}
          onBackBtnClick={() => {
            if (shouldShowMap) {
              setShouldShowMap(false);
            } else navigate(-1);
          }}
          onRightIconClick={() => setPanelState('list_options')}
          icon={<Edit size={DEFAULT_ICON_SIZE} />}
          withGradient={false}
        />
      )}
      <MobilePageWrapper data-testid="List View Page Root">
        <ListViewContent />
      </MobilePageWrapper>

      {shouldShowViewSwitch && (
        <ViewSwitcherBtn onClick={() => setShouldShowMap(true)} />
      )}

      <MapSection data-testid="List View Page Map" data-hidden={!shouldShowMap}>
        {mapIsLoading && <StyledSpinner isFullPage />}
        <Map
          id={listId}
          style={
            { '--geolocate-control-bottom': '80px' } as React.CSSProperties
          }
          data-is-loading={mapIsLoading}
        >
          {venue && shouldShowMapVenueCard && shouldShowMap && (
            <MapVenueCard
              data-testid="Map Venue Card"
              venue={{
                slug: venue.slug,
                title: translate(venue.name_translations, language),
                cuisines: venue.cuisines,
                lunchAvg: venue.budget_lunch_avg,
                dinnerAvg: venue.budget_dinner_avg,
                imageUrls: [venue.search_image],
                availability: venue.availability,
              }}
              href={buildUrl(`/${language}/${venue.slug}`, searchParams)}
            >
              <VenueCard.Header
                icon="bookmarkFilled"
                onIconClick={() => setPanelState(user ? 'save_list' : 'login')}
                buttonTestId="Map Venue Card Bookmark Btn"
              />
              <VenueCard.Info />
              <VenueCard.Budget />
              <VenueCard.SidePicture />
              {availabilityToShow() === 'date' && (
                <VenueCard.DateTable
                  query={{
                    pax: listFilters.num_people,
                    time: listFilters.time,
                    dateMin: listFilters.date_min,
                  }}
                />
              )}
              {availabilityToShow() === 'time' && (
                <VenueCard.TimeSlots
                  query={{
                    date: listFilters.date,
                    pax: listFilters.num_people,
                    time: listFilters.time,
                  }}
                />
              )}{' '}
            </MapVenueCard>
          )}
        </Map>
      </MapSection>
    </>
  );
}
