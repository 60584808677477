import { STALETIME_1_DAY as ONE_DAY_DURATION } from '@local/constants';
import { apiService } from '@local/services';
import type {
  GalleryResponse,
  MenuItemsResponse,
  VenueResponse,
} from '@local/types';
import { AvailabilityResponse } from '@local/types';

export const fetchVenueData = async (
  venueId: string,
  locale: string,
): Promise<VenueResponse> =>
  apiService.get(`v2/shops/${venueId}?locale=${locale}`).json();

export const fetchGalleryQuery = async (
  venueId: string,
  locale: string,
): Promise<GalleryResponse> =>
  apiService
    .get(`v2/gallery?locale=${locale}&page=0&shop_slug=${venueId}`)
    .json();

const DATE_MAX_LOOKAHEAD = 50;

export const fetchMenuItemsQuery = async (
  venueId: string,
  locale: string,
): Promise<MenuItemsResponse> => {
  const today = new Date();
  return apiService
    .post(`v2/hub/menu_items`, {
      json: {
        locale,
        shop_id: venueId,
        date_min: today.toISOString(),
        date_max: new Date(
          today.getTime() + ONE_DAY_DURATION * DATE_MAX_LOOKAHEAD,
        ).toISOString(),
        has_price: true,
      },
    })
    .json();
};

export const fetchExperiencesQuery = async (
  venueId: string,
  locale: string,
): Promise<MenuItemsResponse> => {
  const today = new Date();
  return apiService
    .post(`v2/hub/menu_items`, {
      json: {
        locale,
        shop_id: venueId,
        date_min: today.toISOString(),
        date_max: new Date(
          today.getTime() + ONE_DAY_DURATION * DATE_MAX_LOOKAHEAD,
        ).toISOString(),
        use_experience_page: true,
      },
    })
    .json();
};

export const fetchVenueAvailability = async (
  venueId: string,
  locale: string,
  pax: string,
  date: string,
): Promise<AvailabilityResponse> =>
  apiService
    .post(`v2/hub/availability_calendar`, {
      json: {
        locale,
        start_at: date,
        shop_id: venueId,
        num_people: pax,
      },
    })
    .json();
