import { Store } from '@carbon/icons-react';
import { styled } from '@linaria/react';

import { ICON_SIZE_40 } from '@local/constants';

import { useVenueCardContext } from '../VenueCardContext';

const StyledImage = styled.img`
  height: var(--card-side-icon);
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: var(--border-radius-small);
  order: -1;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--card-side-icon);
  width: var(--card-side-icon);
  min-width: var(--card-side-icon);
  max-width: var(--card-side-icon);
  border-radius: var(--border-radius-small);
  background-color: var(--surface-raised-hover);
  order: -1;
`;

export function SidePicture() {
  const { venue } = useVenueCardContext();
  const { imageUrls } = venue;

  return imageUrls?.[0] ? (
    <StyledImage src={imageUrls[0]} alt={venue.title} loading="lazy" />
  ) : (
    <IconWrapper>
      <Store fill="var(--text-placeholder)" size={ICON_SIZE_40} />
    </IconWrapper>
  );
}
