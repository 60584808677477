import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { cookieService, logger, sentryService } from '@local/services';

import { deleteAccount } from '../accountApi';

export const useDeleteAccountQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAccount,
    onSuccess: async () => {
      cookieService.removeAuthToken();
      queryClient.setQueriesData({ queryKey: [USER_QUERY_KEY] }, null);
      await queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
    },
    onError: (error) => {
      logger.error(error);
      sentryService.captureException({ error });
    },
  });
};
