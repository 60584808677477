import { useQuery } from '@tanstack/react-query';

import { STALETIME_10_SECONDS, USER_QUERY_KEY } from '@local/constants';

import { getCreditCards } from '../accountApi';

export const useFetchCreditCards = () =>
  useQuery({
    queryKey: [USER_QUERY_KEY, 'credit_cards'],
    queryFn: () => getCreditCards(),
    staleTime: STALETIME_10_SECONDS,
  });
