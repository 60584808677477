import {
  Bookmark,
  Bullhorn,
  Calendar,
  Help,
  Logout,
  Purchase,
  Receipt,
  UserAvatar,
} from '@carbon/icons-react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLogoutQuery } from '@local/auth';
import { CONFIG } from '@local/configs';
import { BASE_SUPPORT_SITE_URL, ICON_SIZE_24 } from '@local/constants';
import { useRouting } from '@local/hooks';
import { isHomepageSearchVisibleAtom } from '@local/search';
import { openUrl } from '@local/utils';

export type DropdownContentItem =
  | {
      label: string;
      onClick: () => void;
      icon: React.ReactElement;
      'data-testid'?: string;
    }
  | 'separator';

export function useHeader() {
  const navigate = useNavigate();
  const [t, { language }] = useTranslation();
  const { mutate: logout } = useLogoutQuery();
  const isHomepageSearchVisible = useAtomValue(isHomepageSearchVisibleAtom);

  const shouldShowSearchInput =
    !useRouting().isHomepage || !isHomepageSearchVisible;

  return {
    shouldShowSearchInput,
    profileDropdown: [
      {
        label: t('account.profile'),
        onClick: () => navigate(`/${language}/account/edit`),
        icon: <UserAvatar size={ICON_SIZE_24} />,
      },
      {
        label: t('layout.bookings'),
        onClick: () => navigate(`/${language}/account/reservations/upcoming`),
        icon: <Calendar size={ICON_SIZE_24} />,
      },
      {
        label: t('layout.lists'),
        onClick: () => navigate(`/${language}/lists`),
        icon: <Bookmark size={ICON_SIZE_24} />,
      },
      {
        label: t('account.transactions'),
        onClick: () => navigate(`/${language}/account/payments`),
        icon: <Receipt size={ICON_SIZE_24} />,
      },
      {
        label: t('account.saved_cards'),
        onClick: () => navigate(`/${language}/account/credit-cards`),
        icon: <Purchase size={ICON_SIZE_24} />,
      },
      {
        label: t('account.communications.title'),
        onClick: () => navigate(`/${language}/account/favorites`),
        icon: <Bullhorn size={ICON_SIZE_24} />,
      },
      'separator',
      {
        label: t('account.help'),
        onClick: () => {
          openUrl(`${BASE_SUPPORT_SITE_URL}hc/${language}`, '_blank');
        },
        icon: <Help size={ICON_SIZE_24} />,
      },
      {
        label: t('auth.logout'),
        onClick: () => logout(),
        icon: <Logout size={ICON_SIZE_24} />,
        'data-testid': 'Header Account Logout Btn',
      },
    ] as DropdownContentItem[],
    utilityHeaderOptions: [
      {
        label: t('account.for_restaurateurs'),
        href: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/join/`,
      },
      {
        label: t('account.help'),
        href: `${BASE_SUPPORT_SITE_URL}hc/${language}`,
      },
    ],
  };
}
