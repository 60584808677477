import { View, ViewOff } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import {
  IconButton,
  InputStructure,
  InputWithIcons as InputWithIconsTK,
} from '@tablecheck/tablekit-react-css';
import * as React from 'react';

import { DEFAULT_ICON_SIZE } from '@local/constants';

export const InputWithIcons = styled(InputWithIconsTK)`
  background-color: var(--surface-transparent);
  grid-template-columns: var(--spacing-l2) var(--spacing-l4) auto var(
      --spacing-l8
    );
  grid-auto-rows: max(var(--spacing-l7));
  grid-gap: 0;
  border-radius: var(--border-radius-small);

  & > input,
  & > select {
    grid-area: 1/1/1/4;
    color: var(--text);

    &:first-child:not(:last-child),
    &:not(:first-child):last-child {
      padding-left: calc(var(--spacing-l4));
      padding-right: calc(var(--spacing-l4));
    }
    &:not(:first-child):not(:last-child) {
      padding: var(--spacing-l3) var(--spacing-l4);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  & > button {
    border-top-left-radius: var(--border-radius-small);
    border-bottom-left-radius: var(--border-radius-small);

    &:focus:not(:focus-visible),
    &:focus-visible {
      outline: none;
      &:after {
        content: none;
        position: absolute;
      }
    }
  }
`;

export const InputWrapper = styled(InputStructure)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l1);
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  onChangeValue: React.InputHTMLAttributes<HTMLInputElement>['onChange'];
  'data-variant'?: 'error' | 'disabled' | undefined;
}

export function PasswordField({
  'data-variant': variant,
  value,
  onChangeValue,
  name,
  ...inputProps
}: Props): JSX.Element {
  const [shouldShowPassword, setShowPassword] = React.useState(false);

  return (
    <InputWrapper
      name={name}
      label=""
      input={
        <InputWithIcons data-stretch data-variant={variant}>
          <input
            {...inputProps}
            type={shouldShowPassword ? 'text' : 'password'}
            name={name}
            value={value}
            onChange={onChangeValue}
          />
          <IconButton
            data-variant="bare"
            data-size="large"
            type="button"
            onClick={() => setShowPassword(!shouldShowPassword)}
          >
            {shouldShowPassword ? (
              <View size={DEFAULT_ICON_SIZE} />
            ) : (
              <ViewOff size={DEFAULT_ICON_SIZE} />
            )}
          </IconButton>
        </InputWithIcons>
      }
    />
  );
}
