import { debounce } from 'lodash-es';
import * as React from 'react';

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);
  React.useEffect(() => {
    debounce(setDebouncedValue, delay)(value);
  }, [value, setDebouncedValue, delay]);

  return debouncedValue;
}
