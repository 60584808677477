import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { logger, sentryService } from '@local/services';
import { EreceiptResponse } from '@local/types';

import { generateEreceipt } from '../accountApi';

export const useGenerateEreceipt = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      paymentId,
      locale,
      customerName,
    }: {
      paymentId: string;
      locale: string;
      customerName?: string;
    }) => generateEreceipt(paymentId, locale, customerName),
    onSuccess: async (data: EreceiptResponse, variables) => {
      const { paymentId, locale } = variables;
      await queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY, locale, 'payments', paymentId],
      });
      return data;
    },
    onError: (error) => {
      logger.error(error);
      sentryService.captureException({ error });
    },
  });
};
