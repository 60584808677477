import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useSetAtom } from 'jotai';
import * as React from 'react';

import {
  ChangePassword,
  EditProfileForm,
  Memberships,
  SocialAccounts,
} from '@local/account';
import { panelStateAtom } from '@local/components';
import type { MembershipInfo, SocialAuthInfo } from '@local/types';

interface Props {
  onSuccess: (content: string) => void;
  setSelectedMembership: React.Dispatch<
    React.SetStateAction<MembershipInfo | null>
  >;
  setSelectedSocialAuth: React.Dispatch<
    React.SetStateAction<SocialAuthInfo | null>
  >;
}

export function ProfileContent({
  onSuccess,
  setSelectedMembership,
  setSelectedSocialAuth,
}: Props) {
  const setPanelState = useSetAtom(panelStateAtom);

  return (
    <>
      <EditProfileForm
        onSuccess={() => onSuccess(t('toast.updated_profile'))}
      />
      <ChangePassword onClick={() => setPanelState('change_pwd')} />
      <SocialAccounts
        onDisconnectClick={({ id, provider }) => {
          setSelectedSocialAuth({ id, provider });
          setPanelState('disconnect_social');
        }}
      />
      <Memberships
        onRemoveClick={(membership) => {
          setSelectedMembership(membership);
          setPanelState('delete_membership');
        }}
      />
      <Button
        data-testid="Account Delete Btn"
        data-variant="danger"
        onClick={() => setPanelState('delete_account')}
      >
        {t('account.delete_tc_account')}
      </Button>
    </>
  );
}
