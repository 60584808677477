import {
  Bar,
  Bookmark,
  Fish,
  Location,
  LocationCurrent,
  PedestrianFamily,
  PiggyBank,
  Portfolio,
  Restaurant,
  Search,
  Sprout,
  Star,
  Store,
  Strawberry,
  User,
} from '@carbon/icons-react';
import { CarbonIconProps } from '@carbon/icons-react/lib/CarbonIcon';

const iconMap = {
  store: Store,
  leaf: Sprout,
  bar: Bar,
  fish: Fish,
  portfolio: Portfolio,
  sprout: Sprout,
  pedestrian: PedestrianFamily,
  strawberry: Strawberry,
  user: User,
  piggyBank: PiggyBank,
  star: Star,
  cuisine: Restaurant,
  location: Location,
  prompt: Search,
  list: Bookmark,
  occasion: Star,
  locationCurrent: LocationCurrent,
};
export type IconNameType = keyof typeof iconMap;
type IconProps = {
  iconName: IconNameType;
} & CarbonIconProps;

export function DynamicIcon({ iconName, ...props }: IconProps) {
  const IconComponent = iconMap[iconName];
  if (!IconComponent) {
    return null;
  }
  return <IconComponent {...props} />;
}
