/// <reference types="../../../definitions/window.d.ts" />
/// <reference types="vite/client" />

import ky, {
  AfterResponseHook,
  BeforeRequestHook,
  KyInstance,
  Options,
} from 'ky';

import { CONFIG } from '@local/configs';
import { AuthToken } from '@local/types';

import { cookieService } from './cookieService';
import { logger } from './logger';
import * as sentryService from './sentryService';

// NOTE: https://github.com/tablecheck/diner-frontend/blob/cb6e2ca0c447969c3229b30cbda31b4a519c9a94/src/utils/fetch.js#L8-L12
const cordovaRequestHandler: BeforeRequestHook = async (
  request: Request,
  options: Options,
) => {
  const cordovaOptions: {
    headers: Record<string, string>;
    method: string;
    data?: Record<string, unknown> | unknown[];
    serializer?: 'json';
  } = {
    method: request.method,
    headers: {},
  };
  if (request.headers) {
    request.headers.forEach((value, key) => {
      cordovaOptions.headers[key] = value;
    });
  }
  if (request.method) {
    cordovaOptions.method = request.method;
  }

  if (options.json) {
    cordovaOptions.data = options.json as Record<string, unknown> | unknown[];
  }
  if (
    request.method === 'POST' ||
    request.method === 'PUT' ||
    request.method === 'PATCH'
  ) {
    cordovaOptions.serializer = 'json';
  }

  return new Promise<Response>((resolve, reject) => {
    window.cordova.plugin.http.sendRequest(
      request.url,
      cordovaOptions,
      (response) => {
        let data;
        try {
          data = JSON.parse(response.data);
        } catch (error) {
          logger.error(error, 'Error: Parse JSON');
          sentryService.captureException({ error });
        }
        return resolve(new Response(JSON.stringify(data), response));
      },
      (errResponse) => {
        sentryService.captureException({ error: errResponse.error });
        reject(new Error(errResponse.error));
      },
    );
  });
};

const handleNonCordovaErrors: AfterResponseHook = async (
  request: Request,
  options: Options,
  response: Response,
) => {
  if (!CONFIG.IS_CORDOVA && !response.ok) {
    let errorDetails;
    try {
      errorDetails = await response.json();
    } catch {
      errorDetails = `Failed to parse error response. Response status: ${response.status}. Response status text: ${response.statusText}`;
    }
    throw new Error(JSON.stringify(errorDetails));
  }

  return response;
};

const reactivateMsw = async () => {
  // why we need to reactivate MSW? see this: https://github.com/mswjs/msw/issues/2115, msw stops intercepting the request after some idle time
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage('MOCK_ACTIVATE');
    // Small wait for changes to propagate
    await new Promise((resolve) => {
      setTimeout(resolve, 10);
    });
  }
};

const refreshToken = async (data: AuthToken): Promise<AuthToken> =>
  ky
    .post(`v2/user/token`, {
      prefixUrl: CONFIG.VITE_BASE_AUTH_API_URL,
      json: {
        grant_type: 'refresh_token',
        refresh_token: data.refresh_token,
      },
    })
    .json<AuthToken>();

const isValidReqUrl = (url: string): boolean => {
  const VALID_REQUEST_ORIGINS = [
    CONFIG.VITE_BASE_API_URL,
    CONFIG.VITE_BASE_INTERNAL_API_URL,
    CONFIG.VITE_BASE_AUTH_API_URL,
  ];
  const urlOrigin = new URL(url).origin;
  return VALID_REQUEST_ORIGINS.some(
    (origin) => urlOrigin === new URL(origin).origin,
  );
};

const addAuthHeader = async (request: Request): Promise<void> => {
  if (!isValidReqUrl(request.url)) {
    return;
  }
  let authToken = cookieService.getAuthToken();

  if (!authToken) {
    return;
  }
  if (cookieService.isTokenExpired(authToken)) {
    try {
      authToken = await refreshToken(authToken);
      cookieService.setAuthToken(authToken);
    } catch (error) {
      logger.error(error, 'Failed to refresh token');
      sentryService.captureException({ error });
      cookieService.removeAuthToken();
      return;
    }
  }
  request.headers.set('Authorization', `Bearer ${authToken.access_token}`);
};

// for logging all external api request made from SSR
const ssrExternalApiLoggingHook: AfterResponseHook = async (
  request,
  _,
  response,
) => {
  if (!CONFIG.IS_SSR) return;
  const { pathname: url, searchParams: query } = new URL(request.url);
  const { status: statusCode, type } = response;

  const serializeHeaders = (headers: Headers): Record<string, string> => {
    const obj: Record<string, string> = {};
    headers.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  };

  const logData = {
    req: {
      url,
      method: request.method,
      headers: serializeHeaders(request.headers),
      query: Object.fromEntries(query),
    },
    res: {
      statusCode,
      headers: serializeHeaders(response.headers),
      type,
    },
  };
  if (!response.ok) {
    const err = await response.json();
    logger.error({ ...logData, err }, 'request errored');
    sentryService.captureException({ error: { ...logData, err } });
  } else {
    logger.info(logData, 'request completed');
  }
};

const create = (defaultOptions: Options): KyInstance => {
  let beforeRequestHooks = defaultOptions?.hooks?.beforeRequest ?? [];
  const afterResponseHooks = defaultOptions?.hooks?.afterResponse ?? [];

  beforeRequestHooks = [addAuthHeader, ...beforeRequestHooks];
  if (CONFIG.IS_SSR) {
    afterResponseHooks.push(ssrExternalApiLoggingHook);
  }
  if (CONFIG.VITE_IS_MOCKING_ENABLED) {
    beforeRequestHooks.push(reactivateMsw);
  }
  if (CONFIG.IS_CORDOVA) {
    beforeRequestHooks.push(cordovaRequestHandler);
  }
  afterResponseHooks.push(handleNonCordovaErrors);

  return ky.create({
    ...defaultOptions,
    retry: CONFIG.VITE_APP_ENVIRONMENT === 'testing' ? 0 : 3,
    hooks: {
      ...(defaultOptions?.hooks ?? {}),
      beforeRequest: beforeRequestHooks,
      afterResponse: afterResponseHooks,
    },
  });
};

const shouldUseInternalApi =
  CONFIG.IS_SSR && CONFIG.VITE_APP_ENVIRONMENT !== 'development';

const apiService = create({
  prefixUrl: shouldUseInternalApi
    ? CONFIG.VITE_BASE_INTERNAL_API_URL
    : CONFIG.VITE_BASE_API_URL,
});

// override the create method to allow for custom options
export { apiService };
