import { styled } from '@linaria/react';
import { Button, Input, TextArea } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';

import { Footer } from '@local/components';
import {
  INPUT_MAX_CHAR_LENGTH,
  LIST_NAME_MAX_CHAR_LENGTH,
} from '@local/constants';
import type { CreateListData } from '@local/types';

const Form = styled.form`
  display: grid;
  gap: var(--spacing-l4);
  font: var(--body-1);
  padding: 0 var(--spacing-l4);
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);

  & > label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font: var(--small);
    }
  }
  & div > textarea {
    resize: none;
    min-height: 100px;
  }
`;

interface CreateNewListContentProps {
  onSubmit: (data: CreateListData) => void;
  onCancel: () => void;
  venueId?: string;
  imageUrl?: string;
}

export function CreateNewListContent({
  onSubmit,
  onCancel,
  venueId,
  imageUrl,
}: CreateNewListContentProps) {
  const form = useForm<CreateListData>({
    defaultValues: {
      name: '',
      description: '',
      shop_ids: venueId ? [venueId] : [],
      image_url: imageUrl,
    },
  });

  const handleSubmit = form.handleSubmit((fields) => {
    onSubmit(fields);
  });

  return (
    <Form
      id="create-list-form"
      onSubmit={(e) => {
        e.preventDefault();
        void handleSubmit();
      }}
      data-testid="Create List Modal"
    >
      <ItemWrapper>
        <label htmlFor="name">
          {t('list_feat.list_name')}
          <span>
            {form.watch('name').length} / {LIST_NAME_MAX_CHAR_LENGTH}
          </span>
        </label>
        <Input
          {...form.register('name')}
          aria-label="List name"
          data-stretch
          maxLength={LIST_NAME_MAX_CHAR_LENGTH}
          placeholder={t('list_feat.add_list_name')}
          data-testid="List Name Input"
        />
      </ItemWrapper>
      <ItemWrapper>
        <label htmlFor="description">
          {t('list_feat.description')}
          <span>
            {form.watch('description').length} / {INPUT_MAX_CHAR_LENGTH}
          </span>
        </label>
        <TextArea
          {...form.register('description')}
          aria-label="List description"
          data-stretch
          maxLength={INPUT_MAX_CHAR_LENGTH}
          placeholder={t('list_feat.add_optional_description')}
          data-testid="List Description Input"
        />
      </ItemWrapper>
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-variant="primary"
          type="submit"
          form="create-list-form"
          disabled={form.watch('name').length === 0}
          data-testid="Create List Save Button"
        >
          {t('general.save')}
        </Button>
      </Footer>
    </Form>
  );
}
