import { useAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  DeleteSubscriptionContent,
  TermsConditionsContent,
} from '@local/account';
import { useGetUserQuery } from '@local/auth';
import { Panel, Spinner, panelStateAtom, useToast } from '@local/components';
import { useDeviceType, useUniverse } from '@local/hooks';
import type { Subscription } from '@local/types';

import { DesktopContentWrapper } from '../DesktopContentWrapper';
import { MobileContentWrapper } from '../MobileContentWrapper';

import { CommunicationsContent } from './CommunicationsContent';

export function CommunicationsRoot() {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const [t, { language }] = useTranslation();
  // TODO: There's a lot of duplicated code in these Root files (CreditCardsRoot, PaymentsRoot, etc.). DRY up. REF: https://tablecheck.atlassian.net/browse/DPOR-744
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const { universeName } = useUniverse();
  const [selectedSubscription, setSelectedSubscription] =
    React.useState<Subscription | null>(null);
  const { successToastContent, setIsToastOpen, setToastContent } = useToast();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();

  const handleOnSuccess = (content: string) => {
    setToastContent(successToastContent(content));
    setPanelState(null);
    setIsToastOpen(true);
  };

  React.useEffect(() => {
    if (!user && !isUserLoading) {
      navigate(`/${language}/${universeName}`);
    }
  }, [user, isUserLoading, navigate, language, universeName]);

  if (isUserLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <div data-testid="Communications Page Root">
        {isDesktop ? (
          <DesktopContentWrapper
            header={<h1>{t('account.communications.title')}</h1>}
          >
            <CommunicationsContent
              setSelectedSubscription={setSelectedSubscription}
            />
          </DesktopContentWrapper>
        ) : (
          <MobileContentWrapper title={t('account.communications.title')}>
            <CommunicationsContent
              setSelectedSubscription={setSelectedSubscription}
            />
          </MobileContentWrapper>
        )}
      </div>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'terms_conditions' && (
          <TermsConditionsContent onClose={() => setPanelState(null)} />
        )}
        {panelState === 'delete_subscription' && selectedSubscription && (
          <DeleteSubscriptionContent
            selectedSubscription={selectedSubscription}
            onSuccess={() => handleOnSuccess(t('toast.updated_preferences'))}
            onCancel={() => {
              setPanelState(null);
              setSelectedSubscription(null);
            }}
          />
        )}
      </Panel>
    </>
  );
}
