/* eslint-disable max-lines */
import {
  Information,
  RestaurantFine,
  Search,
  ShoppingBag,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import {
  Button,
  Checkbox,
  CheckboxLabel,
  Input,
  Toggle,
} from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { useGetUserQuery } from '@local/auth';
import {
  Collapsible,
  Footer,
  Panel,
  RcSlider,
  Spinner,
} from '@local/components';
import { BUDGET_INFINITY, ICON_SIZE_24 } from '@local/constants';
import { useDeviceType, useOutsideClick, useUniverse } from '@local/hooks';
// TODO: Can `ListCard` and `useFetchAllListsQuery` be imported in `ExploreMobile` and passed to this component in order to prevent future cyclic dependencies?
// REF: https://tablecheck.atlassian.net/browse/DPOR-698
import { ListCard, useFetchAllListsQuery } from '@local/list';
import type { SearchFilterViewType, ServiceMode } from '@local/types';
import { formatCurrency } from '@local/utils';

import { useCuisineSearch } from '../../hooks/useCuisineSearch';
import { useSearchFilters } from '../../hooks/useSearchFilters';

const BUDGET_JAPAN_VIRTUAL_MAX = 20500;
const BUDGET_SINGAPORE_VIRTUAL_MAX = 210;
const BUDGET_JAPAN_STEPS = 500;
const BUDGET_SINGAPORE_STEPS = 10;

/* This might be coded nicer, I needed to add this to prevent formatting errors */
const serviceIconsMap: Record<ServiceMode, React.ReactElement> = {
  dining: <RestaurantFine size={ICON_SIZE_24} />,
  pickup: <ShoppingBag size={ICON_SIZE_24} />,
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l4);
  &[data-is-all-filter='true'] {
    gap: var(--spacing-l4);
  }
`;

const CuisineSection = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-l2);
  flex-wrap: wrap;
`;

const CuisineContent = styled.div`
  display: grid;
  gap: var(--spacing-l3);
  grid-template-rows: auto 1fr;
`;

const ServiceModesSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l3);
  button {
    flex: 1;
    transition: none;

    &[data-has-value='true'] {
      background-color: var(--surface-raised-active);
      color: var(--text);
      border: 1px solid var(--brand-primary);
    }
  }
`;
const ServiceTitle = styled.h5`
  font: var(--body-2);
`;

const ModeButton = styled(Button)`
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  border-radius: var(--border-radius-small);
  gap: var(--spacing-l3);
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  small {
    color: var(--text-subtle);
  }
`;

const BudgetSection = styled.section`
  display: flex;
  gap: var(--spacing-l4);
  margin-top: var(--spacing-l3);
  flex-wrap: wrap;
  & > * {
    width: 100%;
  }
`;

const SmartpaySection = styled.section`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    justify-content: space-between;
  }
  input {
    min-width: 50px;
  }
  [data-all-filter-view='true'] {
    flex-direction: column;
  }
  [data-learn-more='true'] {
    align-items: center;
    color: var(--primary);
    gap: var(--spacing-l1);
    justify-content: flex-start;
    cursor: pointer;
    button {
      cursor: pointer;
      position: relative;
      color: var(--primary);
      text-decoration: underline;
    }
  }
`;

const TableCheckPayOverview = styled.span`
  max-width: 80%;
  label {
    padding-bottom: 0px;
  }
  p span {
    font: var(--body-2);
    color: var(--text-subtle);
  }
  button {
    font: var(--body-2);
  }
`;

const TableCheckPayExplanation = styled.div`
  align-self: center;
  background-color: var(--surface);
  border: 1px solid var(--border);
  border-radius: var(--border-radius-small);
  box-shadow: 0px 0px 3px 0px #0000001a;
  font: var(--small);
  padding: 6px var(--spacing-l2);
  position: absolute;
  bottom: 122px;
  width: 360px;
  z-index: var(--zindex-tooltip);
  position: absolute;
  bottom: var(--spacing-l8);
  text-align: left;
  color: var(--text);
`;

const BudgetInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-l2);
  & > * {
    color: var(--text-subtle);
  }
`;

const Title = styled.h5`
  width: 100%;
`;

const ListsSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  > div:last-child {
    border-bottom: none;
  }
`;

const InputWrapper = styled.div`
  width: -webkit-fill-available;
  padding: 0 var(--spacing-l4) var(--spacing-l4) 0;
  background-color: white;
  position: sticky;
  z-index: 1;
`;

const StyledInput = styled(Input)`
  height: fit-content;
  width: -webkit-fill-available;
  & input {
    width: auto;
  }
`;

const Label = styled.span`
  overflow: hidden;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
`;

const NoResults = styled.span`
  color: var(--text-subtle);
  width: max-content;
`;

const Spacer = styled.hr`
  width: calc(100% * var(--spacing-l4));
  border-top: 1px solid var(--grey-200);
  margin: 0 -16px;
`;

interface SearchFilterPanelProps {
  isOpen: boolean;
  onClose: () => void;
  searchFilterView: SearchFilterViewType;
}

export function SearchFilterPanel({
  isOpen,
  onClose,
  searchFilterView,
}: SearchFilterPanelProps) {
  const {
    getValues,
    setValue,
    handleReset,
    control,
    handleHubShopListSelect,
    handleSubmit,
    handleCuisineClick,
    handleServiceModeClick,
    onSubmit,
    handleSmartpayChange,
    searchFilters,
    reset,
  } = useSearchFilters();

  const {
    cuisineSearch,
    setCuisineSearch,
    selectedCuisines,
    setSelectedCuisines,
    filteredCuisines,
    isCuisineListLoaded,
  } = useCuisineSearch({ searchFilterView });

  const { isDesktop } = useDeviceType();
  const { isJapanUniverse } = useUniverse();
  const { universeBudgetLimit, universeCurrency } = useUniverse();
  const [shouldShowSmartpayExplanation, setShouldShowSmartpayExplanation] =
    React.useState(false);

  const { data: user } = useGetUserQuery();
  const { data: listsData, isLoading: isListsLoading } =
    useFetchAllListsQuery(user);

  const serviceModeList: ServiceMode[] = ['dining', 'pickup'];

  const getTitle = () => {
    switch (searchFilterView) {
      case 'cuisines':
        return t('cuisine.title');
      case 'service_mode':
        return t('service_modes.title');
      case 'smartpay':
        return t('smartpay.title');
      case 'budget':
        return t('search.budget');
      case 'lists':
        return t('list_feat.title');
      default:
        return t('general.filters');
    }
  };

  //  useWatch to trigger re-renders when these values change
  const budgetMin = Number(
    useWatch({ control, name: 'budget_dinner_avg_min' }),
  );
  const budgetMax = Number(
    useWatch({ control, name: 'budget_dinner_avg_max' }),
  );
  const budgetSliderProps = {
    min: 0,
    max: isJapanUniverse
      ? BUDGET_JAPAN_VIRTUAL_MAX
      : BUDGET_SINGAPORE_VIRTUAL_MAX,
    step: isJapanUniverse ? BUDGET_JAPAN_STEPS : BUDGET_SINGAPORE_STEPS,
    value: [budgetMin, budgetMax],
  };

  // TODO: Try using Radix Popover instead for potentially better desktop UI. Would also allow us to remove useOutsideClick
  // REF: https://tablecheck.atlassian.net/browse/DPOR-657
  const smartpayExplanationRef = useOutsideClick<HTMLDivElement>(() =>
    setShouldShowSmartpayExplanation(false),
  );

  const onCuisineChange = (cuisine: string) => {
    handleCuisineClick(cuisine);
    setSelectedCuisines((prev) =>
      prev.includes(cuisine)
        ? prev.filter((item) => item !== cuisine)
        : [...prev, cuisine],
    );
  };

  const shouldHideFooter =
    searchFilterView === 'smartpay' || searchFilterView === 'service_mode';

  const cuisineContent = (showAll: boolean): React.ReactNode => {
    if (!isCuisineListLoaded) {
      return <Spinner />;
    }
    if (filteredCuisines.length === 0) {
      return <NoResults>{t('search.cuisines_search_no_results')}</NoResults>;
    }
    return (
      /* NOTE: Below is to only show the condensed version in the all filters panel, maybe a cleaner way to do this? */
      <Collapsible isGrid={isDesktop} initialVisibleCount={showAll ? 6 : 999}>
        {filteredCuisines.map((c) => (
          <Controller
            name="cuisines"
            control={control}
            key={c.field}
            render={({ field }) => (
              <CheckboxLabel
                data-testid="Cuisine Filter Checkbox"
                data-selected={selectedCuisines.includes(c.field)}
              >
                <Checkbox
                  {...field}
                  value={c.field}
                  checked={selectedCuisines.includes(c.field)}
                  onChange={() => onCuisineChange(c.field)}
                />
                <Label>{t(`cuisine.${c.field}`)}</Label>
              </CheckboxLabel>
            )}
          />
        ))}
      </Collapsible>
    );
  };

  return (
    <Panel
      isOpen={isOpen}
      isLarge={searchFilterView === 'all' || searchFilterView === 'cuisines'}
      onClose={() => {
        onClose();
        // Ensure unsubmitted changes are not stored when a user closes the panel
        reset({ ...searchFilters });
      }}
      title={getTitle()}
    >
      <Form
        onSubmit={(e) => {
          void handleSubmit(onSubmit)(e);
          onClose();
        }}
        data-is-all-filter={searchFilterView === 'all'}
      >
        {/* TODO: Refactor the content of `Form`. For example, pull out into separate components, or use a hook for all the conditionals */}
        {/* REF: https://tablecheck.atlassian.net/browse/DPOR-695 */}
        {/* REF: https://github.com/tablecheck/diner-frontend-next/pull/195#discussion_r1749456223 */}

        {(searchFilterView === 'all' || searchFilterView === 'budget') && (
          <>
            <BudgetSection data-testid="Search Filter Budget Section">
              {searchFilterView === 'all' && (
                <Title>{t('search.budget')}</Title>
              )}
              <RcSlider
                {...budgetSliderProps}
                onChange={(values) => {
                  const [min, max] = values as number[];
                  setValue('budget_dinner_avg_min', min.toString());
                  if (max === budgetSliderProps.max) {
                    setValue('budget_dinner_avg_max', BUDGET_INFINITY);
                  } else {
                    setValue('budget_dinner_avg_max', max.toString());
                  }
                }}
              />
              <BudgetInfo>
                <span>{formatCurrency(budgetMin, universeCurrency)}</span>
                <span>
                  {budgetMax === Number(BUDGET_INFINITY)
                    ? t('general.more_than_amount', {
                        amount: formatCurrency(
                          universeBudgetLimit,
                          universeCurrency,
                        ),
                      })
                    : formatCurrency(budgetMax, universeCurrency)}
                </span>
              </BudgetInfo>
            </BudgetSection>
            {searchFilterView === 'all' && <Spacer />}
          </>
        )}

        {(searchFilterView === 'all' || searchFilterView === 'cuisines') && (
          <>
            <CuisineSection>
              {searchFilterView === 'all' && (
                <Title>{t('cuisine.title')}</Title>
              )}
              <InputWrapper>
                <StyledInput
                  iconBefore={<Search size="20px" />}
                  data-testid="Cuisine Search Input"
                  name="cuisine_search_text"
                  placeholder={t('search.cuisines_search_placeholder')}
                  value={cuisineSearch}
                  onChange={(e) => setCuisineSearch(e.target.value)}
                />
              </InputWrapper>
              {/* TODO: Relates to the comment above DPOR-695 */}
              <CuisineContent>
                {cuisineContent(searchFilterView === 'all')}
              </CuisineContent>
            </CuisineSection>
            {searchFilterView === 'all' && <Spacer />}
          </>
        )}
        {(searchFilterView === 'all' || searchFilterView === 'smartpay') && (
          <>
            <SmartpaySection data-testid="Search Filter Smartpay Section">
              {searchFilterView === 'all' && <h5>{t('smartpay.title')}</h5>}
              <div>
                <TableCheckPayOverview>
                  <p>{t('smartpay.label')}</p>
                  <p>
                    <span>{t('smartpay.description')}</span>
                  </p>

                  <div data-learn-more>
                    <Information size={14} />
                    <button
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        setShouldShowSmartpayExplanation(
                          (prevValue) => !prevValue,
                        );
                      }}
                    >
                      {t('smartpay.learn_more')}
                    </button>
                    {shouldShowSmartpayExplanation && (
                      <TableCheckPayExplanation ref={smartpayExplanationRef}>
                        {t('smartpay.explanation')}
                      </TableCheckPayExplanation>
                    )}
                  </div>
                </TableCheckPayOverview>
                <Controller
                  name="is_smartpay"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      data-testid="Smartpay Toggle"
                      data-variant="tertiary"
                      {...field}
                      value={field.value?.toString()}
                      onChange={() => {
                        if (searchFilterView === 'all') {
                          handleSmartpayChange();
                        } else {
                          handleSmartpayChange();
                          void handleSubmit(onSubmit)();
                          onClose();
                        }
                      }}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </SmartpaySection>
            {searchFilterView === 'all' && <Spacer />}
          </>
        )}
        {user &&
          (searchFilterView === 'all' || searchFilterView === 'lists') && (
            <>
              <ListsSection data-testid="Search Filter Lists Section">
                {searchFilterView === 'all' && (
                  <Title>{t('list_feat.title')}</Title>
                )}
                {isListsLoading && <Spinner />}
                {!!listsData &&
                  listsData.lists.map((list) => (
                    <Controller
                      name="hub_shop_list_ids"
                      control={control}
                      key={list.id}
                      render={({ field }) => (
                        <ListCard
                          id={list.id}
                          name={list.name}
                          shopIds={list.shop_ids}
                          isFavorite={list.is_favorite}
                          imageUrl={list.image_url}
                          isChecked={!!field.value?.includes(list.id)}
                          onSelect={() => handleHubShopListSelect(list.id)}
                          isDisabled={list.shop_ids.length === 0}
                        />
                      )}
                    />
                  ))}
              </ListsSection>
              {searchFilterView === 'all' && <Spacer />}
            </>
          )}

        {(searchFilterView === 'all' ||
          searchFilterView === 'service_mode') && (
          <ServiceModesSection data-testid="Search Filter Service Modes Section">
            {searchFilterView === 'all' && (
              <Title>{t('service_modes.title')}</Title>
            )}

            {serviceModeList.map((serviceMode) => (
              <Controller
                name="service_mode"
                control={control}
                key={serviceMode}
                render={({ field }) => (
                  <ModeButton
                    data-testid={`Service Modes ${serviceMode} Button`}
                    data-variant="ghost"
                    data-has-value={getValues().service_mode === serviceMode}
                    {...field}
                    onClick={() => {
                      if (searchFilterView === 'all') {
                        handleServiceModeClick(serviceMode);
                      } else {
                        handleServiceModeClick(serviceMode);
                        void handleSubmit(onSubmit)();
                        onClose();
                      }
                    }}
                    value={serviceMode}
                  >
                    {serviceIconsMap[serviceMode]}
                    <div>
                      <ServiceTitle>
                        {t(`service_modes.${serviceMode}`)}
                      </ServiceTitle>
                      <small>
                        {t(`service_modes.${serviceMode}_description`)}
                      </small>
                    </div>
                  </ModeButton>
                )}
              />
            ))}
          </ServiceModesSection>
        )}
        <Footer hasShadow={['all', 'cuisines'].includes(searchFilterView)}>
          {!shouldHideFooter && (
            <Button
              type="reset"
              data-testid="Reset Filters Button"
              data-size="medium"
              data-variant="tertiary"
              onClick={() => {
                handleReset(searchFilterView);
                onClose();
              }}
            >
              {t('general.reset')}
            </Button>
          )}
          {!shouldHideFooter && (
            <Button
              data-testid="Submit Filters Button"
              type="submit"
              data-size="medium"
              value="submit"
              data-variant="primary"
            >
              {t('general.update')}
            </Button>
          )}
        </Footer>
      </Form>
    </Panel>
  );
}
/* eslint-enable max-lines */
