import * as React from 'react';

interface SourceProps
  extends React.DetailedHTMLProps<
    React.SourceHTMLAttributes<HTMLSourceElement>,
    HTMLSourceElement
  > {
  srcSet: string;
  type: string;
}
interface SmartImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  sources?: SourceProps[];
}

export function SmartImage({
  src,
  alt,
  sources = [],
  ...imgProps
}: SmartImageProps) {
  return (
    <picture>
      {sources?.map(({ srcSet, type, ...sourceProps }) => (
        <source key={srcSet} srcSet={srcSet} type={type} {...sourceProps} />
      ))}
      <img {...imgProps} src={src} alt={alt} />
    </picture>
  );
}
