import { withScope } from '@sentry/core';
import type { NodeClient } from '@sentry/node';
import type {
  Client,
  ErrorEvent,
  EventHint,
  SeverityLevel,
} from '@sentry/types';

import { CONFIG } from '@local/configs';
import { withoutPII } from '@local/utils';

import { logger } from './logger';

const SENTRY_BASE_CONFIG = {
  dsn: CONFIG.VITE_SENTRY_DSN,
  environment: CONFIG.VITE_APP_ENVIRONMENT,
  release: CONFIG.VITE_APP_VERSION,
  tracesSampleRate: CONFIG.VITE_APP_ENVIRONMENT === 'production' ? 0.6 : 1.0,
  normalizeDepth: 5,
  sampleRate: 1.0,
};

let sentryInstance: NodeClient | Client;
async function initClientSentry() {
  if (!CONFIG.VITE_IS_SENTRY_ENABLED) {
    logger.info('Client: Sentry is disabled');
    return;
  }

  try {
    const [
      sentryClient,
      { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType },
      { useEffect },
    ] = await Promise.all([
      import('@sentry/react'),
      import('react-router-dom'),
      import('react'),
    ]);
    logger.info('Client: initializing Sentry');
    sentryInstance = sentryClient.init({
      ...SENTRY_BASE_CONFIG,
      beforeSend(event) {
        return withoutPII<ErrorEvent>(event);
      },
      //   replaysOnErrorSampleRate: 1.0,
      //   replaysSessionSampleRate: 0.1,
      // on top of these integrations, sentry also adds default integrations
      integrations: [
        sentryClient.browserTracingIntegration(),
        sentryClient.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
    })!;
  } catch (error) {
    logger.error('Failed to initialize client Sentry:', error);
  }
}

async function initServerSentry() {
  if (!CONFIG.VITE_IS_SENTRY_ENABLED) {
    logger.info('Server: Sentry is disabled');
    return;
  }
  const { init, expressIntegration, httpIntegration } = await import(
    '@sentry/node'
  );
  try {
    logger.info('Server: initializing Sentry');
    sentryInstance = init({
      ...SENTRY_BASE_CONFIG,
      beforeSend(event) {
        return withoutPII<ErrorEvent>(event);
      },
      // on top of these integrations, sentry also adds default integrations
      integrations: [httpIntegration(), expressIntegration()],
    })!;
  } catch (error) {
    logger.error('Failed to initialize server Sentry:', error);
  }
}

export const init = async () => {
  if (CONFIG.IS_SSR) {
    await initServerSentry();
  } else {
    await initClientSentry();
  }
};

interface SentryCaptureOptions {
  error: unknown;
  hint?: EventHint;
  level?: SeverityLevel;
}

export const captureException = ({
  error,
  level = 'error',
  hint,
}: SentryCaptureOptions) => {
  if (!CONFIG.VITE_IS_SENTRY_ENABLED || !sentryInstance) {
    logger.warn('Sentry is disabled, skipping capture');
    return;
  }

  withScope((scope) => {
    scope.setLevel(level);
    sentryInstance.captureException(error, hint);
  });
};
