import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { useSocialAuthProviders } from '@local/auth';
import { Footer } from '@local/components';
import type { SocialAuthInfo } from '@local/types';

import { useDeleteSocialAuthQuery } from '../../../hooks/useDeleteSocialAuthQuery';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4);

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: var(--spacing-l3);
    padding: var(--spacing-l2);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--border-transparent);
  }
`;

interface Props {
  selectedSocialAuth: SocialAuthInfo;
  onSuccess: () => void;
  onCancel: () => void;
}

export function DeleteSocialAccountContent({
  selectedSocialAuth,
  onSuccess,
  onCancel,
}: Props) {
  const { mutate: deleteSocialAuth, isPending } = useDeleteSocialAuthQuery();
  const { id, provider } = selectedSocialAuth;
  const { iconMap } = useSocialAuthProviders();

  return (
    <Wrapper>
      <div>
        {iconMap[provider]}
        {t(`account.providers.${provider}`)}
      </div>
      <p>{t('account.disconnect_social_msg')}</p>
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-testid="Social Auth Disconnect Btn"
          data-variant="danger"
          aria-busy={isPending}
          onClick={() =>
            deleteSocialAuth(id, {
              onSuccess,
            })
          }
        >
          {t('general.disconnect')}
        </Button>
      </Footer>
    </Wrapper>
  );
}
