import {
  Bookmark,
  Calendar,
  Home,
  LogoFacebook,
  LogoInstagram,
  LogoX,
  Search,
  UserAvatar,
} from '@carbon/icons-react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useGetUserQuery } from '@local/auth';
import { panelStateAtom } from '@local/components';
import { CONFIG } from '@local/configs';
import {
  BASE_SUPPORT_SITE_URL,
  CAREERS_URL,
  FACEBOOK_BASE_URL,
  ICON_SIZE_24,
  INSTAGRAM_URL,
  X_BASE_URL,
} from '@local/constants';
import { useRouting, useUniverse } from '@local/hooks';

export function useFooter() {
  const [t, { language }] = useTranslation();
  const { universeName } = useUniverse();
  const setPanelState = useSetAtom(panelStateAtom);
  const { data: user } = useGetUserQuery();

  return {
    shouldShowFooter: !useRouting().isVenuePage,
    tablecheckOptions: [
      {
        title: t('layout.home'),
        href: `/${language}/${universeName}`,
      },
      {
        title: t('layout.explore'),
        href: `/${language}/${universeName}/search`,
        dataTestId: 'Desktop Footer Explore Btn',
      },
      ...(user
        ? [{ title: t('navbar.account'), href: `/${language}/account/edit` }]
        : [
            {
              title: t('auth.login'),
              href: `/${language}/account/edit`,
              onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                void setPanelState('login');
              },
            },
          ]),
      {
        title: t('account.help'),
        href: `${BASE_SUPPORT_SITE_URL}hc/${language}`,
      },
    ],
    companyOptions: [
      {
        title: t('layout.about_us'),
        href: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/join/about-us/`,
      },
      {
        title: t('layout.team'),
        href: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/join/about-us/team`,
      },
      {
        title: t('layout.careers'),
        href: CAREERS_URL,
      },
      {
        title: t('layout.press'),
        href: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/join/about-us/press/press-release/`,
      },
    ],
    legalOptions: [
      {
        title: t('account.terms_conditions.diner_terms'),
        href: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/policy/terms`,
      },
      {
        title: t('account.terms_conditions.privacy_policy'),
        href: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/policy/privacy`,
      },
      {
        title: t('account.terms_conditions.payment_policy'),
        href: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/policy/payment`,
      },
    ],
    snsOptions: [
      {
        icon: LogoInstagram,
        href: `${INSTAGRAM_URL}?hl=${language}`,
      },
      {
        icon: LogoX,
        href: `${X_BASE_URL}tablecheck?lang=${language}`,
      },
      {
        icon: LogoFacebook,
        href: `${FACEBOOK_BASE_URL}TableCheckGlobal`,
      },
    ],
    mobileActionBtns: [
      {
        key: 'home',
        path: `/${language}/${universeName}`,
        icon: <Home size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Home Btn',
      },
      {
        key: 'explore',
        path: `/${language}/${universeName}/search`,
        icon: <Search size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Explore Btn',
      },
      {
        key: 'lists',
        path: `/${language}/lists`,
        icon: <Bookmark size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Lists Btn',
      },
      {
        key: 'bookings',
        path: `/${language}/account/reservations/upcoming`,
        icon: <Calendar size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Reservations Btn',
      },
      {
        key: 'account',
        path: `/${language}/account`,
        icon: <UserAvatar size={ICON_SIZE_24} />,
        testid: 'Mobile Footer Account Btn',
      },
    ],
  };
}
