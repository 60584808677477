import { Renew } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import {
  nextPanelActionAtom,
  panelStateAtom,
  Spinner,
  VenueCard,
} from '@local/components';
import { DEFAULT_ICON_SIZE, EXPLORE_MAP_ID } from '@local/constants';
import { useUniverse } from '@local/hooks';
import {
  Map,
  selectedVenueAtom,
  shouldFitBoundsToMarkersAtom,
  userMapMovementDetailFamilyAtom,
} from '@local/map-system';
import {
  SearchFilterBar,
  SearchFilterPanel,
  SearchResultsContent,
  SortBy,
  useExplore,
  useFetchGeolocation,
  useSearchFilters,
} from '@local/search';
import type { MapServiceApi, SearchFilterViewType } from '@local/types';
import { buildUrl, translate } from '@local/utils';

const Container = styled.div`
  display: grid;
  position: fixed;
  width: 100svw;
  grid-template-columns: 450px 1fr;
  top: var(--desktop-header-full-height);

  & > div:first-child {
    grid-column: 1 / -1;
    grid-row: 1;
  }
`;

const MapSection = styled.div`
  height: calc(100% - var(--desktop-header-full-height-plus-filter-bar));
  width: 100%;
  position: relative;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MapSearchBtn = styled(Button)`
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--border-radius-full);
  top: var(--spacing-l6);
  box-shadow: var(--elevation-medium-raised);
  border-color: var(--surface);
  color: var(--primary);
`;

const MapVenueCard = styled(VenueCard)`
  left: 50%;
  bottom: 50px;
  position: absolute;
  padding: var(--spacing-l2);
  transform: translateX(-50%);
  width: var(--map-card-width);
  border-radius: var(--border-radius-large);
  z-index: 1;
  box-shadow: var(--elevation-large-raised);
`;

const LeftSection = styled.section`
  z-index: 1;
  grid-row: 2;
  grid-column: 1;
  display: flex;
  height: 100svh !important;
  flex-direction: column;
  background-color: white;
  height: -webkit-fill-available;
  gap: var(--spacing-l4);
  padding: var(--spacing-l4);
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > span {
    font: var(--label);
    text-align: center;
  }
`;

interface Props {
  map: MapServiceApi;
}

export function ExploreDesktop({ map }: Props): JSX.Element {
  const [searchParams] = useSearchParams();
  const [, { language }] = useTranslation();
  const { data: user } = useGetUserQuery();
  const { data: geolocation } = useFetchGeolocation();
  const { updateSearchFilters, searchFilters } = useSearchFilters();
  const setPanelState = useSetAtom(panelStateAtom);
  const setNextPanelAction = useSetAtom(nextPanelActionAtom);
  const userMapMovementDetail = useAtomValue(
    userMapMovementDetailFamilyAtom(EXPLORE_MAP_ID),
  );
  const [selectedVenue, setSelectedVenue] = useAtom(selectedVenueAtom);
  const { venue, shouldShowMapVenueCard } = selectedVenue;
  const { universeSlug } = useUniverse();
  const setShouldFitBoundsToMarkers = useSetAtom(shouldFitBoundsToMarkersAtom);

  const {
    panelTitle,
    shopSearchQuery,
    allSavedShopsSet,
    searchCount,
    availabilityToShow,
  } = useExplore();

  const [searchFilterView, setSearchFilterView] =
    React.useState<SearchFilterViewType>('none');

  return (
    <>
      <Container id="search" data-testid="Explore Page Root">
        <SearchFilterBar setSearchFilterView={setSearchFilterView} isDesktop />

        <LeftSection>
          {searchCount > 0 && (
            <HeaderWrapper>
              <span>{panelTitle}</span>
              <SortBy />
            </HeaderWrapper>
          )}
          <SearchResultsContent
            onResetSearch={() => {
              if (map.instance && geolocation) {
                map.instance.flyTo({
                  center: [geolocation.longitude, geolocation.latitude],
                });
              }
            }}
          />
        </LeftSection>

        <MapSection data-testid="Explore Page Map" data-universe={universeSlug}>
          {map.isLoading && <StyledSpinner isFullPage />}
          <Map id={EXPLORE_MAP_ID} data-is-loading={map.isLoading}>
            {venue && shouldShowMapVenueCard && (
              <MapVenueCard
                data-testid="Map Venue Card"
                venue={{
                  title: translate(venue.name_translations, language),
                  slug: venue.slug,
                  cuisines: venue.cuisines,
                  lunchAvg: venue.budget_lunch_avg,
                  dinnerAvg: venue.budget_dinner_avg,
                  imageUrls: [venue.search_image],
                  availability: venue.availability,
                }}
                href={buildUrl(`/${language}/${venue.slug}`, searchParams)}
              >
                <VenueCard.Header
                  icon={
                    allSavedShopsSet.has(venue.id)
                      ? 'bookmarkFilled'
                      : 'bookmarkAdd'
                  }
                  onIconClick={() => {
                    if (user) {
                      setPanelState('save_list');
                    } else {
                      setPanelState('login');
                      setNextPanelAction(
                        () => () => setPanelState('save_list'),
                      );
                    }
                  }}
                  buttonTestId="Map Venue Card Bookmark Btn"
                />
                <VenueCard.Info />
                <VenueCard.Budget />
                <VenueCard.SidePicture />
                {availabilityToShow === 'time' && (
                  <VenueCard.TimeSlots
                    query={{
                      time: searchFilters.time,
                      pax: searchFilters.num_people,
                      date: searchFilters.date,
                    }}
                  />
                )}
                {availabilityToShow === 'date' && (
                  <VenueCard.DateTable
                    query={{
                      time: searchFilters.time,
                      pax: searchFilters.num_people,
                      dateMin: searchFilters.date_min,
                    }}
                  />
                )}
              </MapVenueCard>
            )}
            {userMapMovementDetail?.canPerformSearch && (
              <MapSearchBtn
                data-size="small"
                data-variant="tertiary"
                aria-busy={
                  shopSearchQuery.status === 'pending' ||
                  shopSearchQuery.fetchStatus === 'fetching'
                }
                onClick={() => {
                  setShouldFitBoundsToMarkers(false);
                  setSelectedVenue({
                    venue: null,
                    shouldShowMapVenueCard: false,
                  });
                  updateSearchFilters({
                    geo_latitude: userMapMovementDetail?.center.lat,
                    geo_longitude: userMapMovementDetail?.center.lng,
                    geo_distance: `${userMapMovementDetail?.radiusInKm}km`,
                    auto_geolocate: false,
                  });
                }}
              >
                <Renew size={DEFAULT_ICON_SIZE} />
                {t('search.action_btns.search_area')}
              </MapSearchBtn>
            )}
          </Map>
        </MapSection>
      </Container>

      <SearchFilterPanel
        isOpen={searchFilterView !== 'none'}
        onClose={() => setSearchFilterView('none')}
        searchFilterView={searchFilterView}
      />
    </>
  );
}
