import { styled } from '@linaria/react';

import { useUniverse } from '@local/hooks';
import { CurrencyFormat, getPrice } from '@local/utils';

const ForceLeftDirection = styled.span`
  direction: ltr;
  unicode-bidi: bidi-override;
`;

export function Price({
  amount,
  currency,
  showZero = false,
  withAmountLimit = false,
  ...props
}: {
  amount: string;
  currency?: CurrencyFormat;
  showZero?: boolean;
  withAmountLimit?: boolean;
}): JSX.Element {
  const { universeCurrency, universeBudgetLimit } = useUniverse();

  const price = getPrice({
    amount,
    showZero,
    currencyFormat: currency ?? universeCurrency,
    amountLimit: withAmountLimit ? universeBudgetLimit : undefined,
  });
  return (
    <ForceLeftDirection {...props}>
      <span>{price}</span>
    </ForceLeftDirection>
  );
}
