import { RestaurantFine } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ICON_SIZE } from '@local/constants';
import { useUniverse } from '@local/hooks';
import { Restrictions } from '@local/restrictions';
import type { MenuItem, Restriction } from '@local/types';
import { getServiceFeeAndTaxTypeKey, translateForMarkup } from '@local/utils';

import { Price } from '../Price/Price';
import { ProcessedMarkup } from '../ProcessedMarkup/ProcessedMarkup';

const Wrapper = styled.div`
  width: var(--experience-card-width);
  max-height: -webkit-fill-available;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-large);
  border: 1px solid var(--border-transparent);
  transition: background 300ms ease-in-out;
  &:hover {
    background: var(--surface-raised);
  }
`;

const ImgPlaceholder = styled.div`
  background-color: #eeeeee;
  height: var(--experience-card-image-height);
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--text-placeholder);
  align-items: center;
`;

const Image = styled.img`
  height: var(--experience-card-image-height);
  width: 100%;
  object-fit: cover;
`;

const Title = styled.span`
  font: var(--label);
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font: var(--body-2);
`;

const ExperienceInfo = styled.div`
  padding: var(--spacing-l3);
`;

const LineDetails = styled.div`
  font-size: 14px;
  strong {
    font-weight: 600;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  gap: var(--spacing-l1);
  text-align: left;
  align-items: center;
  font: var(--body-2);
  color: var(--text-subtle);
  span {
    color: var(--text);
    font: var(--label);
  }
`;

export const CardRestriction = styled.span`
  display: flex;
  gap: var(--spacing-l1);
  overflow: hidden;
`;

export function ExperienceCard({
  cardData,
  experienceTitle,
  onClickAction,
}: {
  cardData: MenuItem;
  experienceTitle: string;
  onClickAction: () => void;
}): JSX.Element {
  const [, { language }] = useTranslation();

  const {
    description_translations,
    price,
    valid_date_ranges,
    valid_start_time,
    valid_end_time,
    days,
    is_holiday: isHoliday,
    image_urls,
    tax_type,
    service_fee_type,
  } = cardData;

  const restrictions = new Restrictions({
    validDateRanges: valid_date_ranges,
    days: days ?? [],
    validStartTime: valid_start_time,
    validEndTime: valid_end_time,
    isHoliday,
    language,
  }).build(({ times, datesDays }) => [times, datesDays]);
  const { universeCurrency } = useUniverse();
  const [descriptionMarkup, descriptionLanguage] = translateForMarkup(
    description_translations,
    language,
  );
  return (
    <Wrapper data-testid="Experience Cards" onClick={onClickAction}>
      {image_urls?.original ? (
        <Image src={image_urls.original} alt={experienceTitle} />
      ) : (
        <ImgPlaceholder>
          <RestaurantFine size={DEFAULT_ICON_SIZE} />
        </ImgPlaceholder>
      )}
      <ExperienceInfo>
        <Title>{experienceTitle}</Title>
        <Description>
          <ProcessedMarkup
            markup={descriptionMarkup}
            language={descriptionLanguage}
          />
        </Description>
        {restrictions.length > 0 && (
          <LineDetails>
            {restrictions.map((restriction: Restriction) => (
              <CardRestriction
                data-testid={restriction['data-testid']}
                key={restriction.id}
              >
                {restriction.getText(t)}
              </CardRestriction>
            ))}
          </LineDetails>
        )}
        <PriceContainer>
          <Price amount={price} currency={universeCurrency} />
          {t(getServiceFeeAndTaxTypeKey(tax_type, service_fee_type))}
        </PriceContainer>
      </ExperienceInfo>
    </Wrapper>
  );
}
