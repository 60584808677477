import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { Footer } from '@local/components';
import { PaymentProfile } from '@local/types';

import { useDeleteCreditCardQuery } from '../../hooks/useDeleteCreditCardQuery';

import { CreditCard } from './CreditCard';

const Container = styled.div`
  padding: 0 var(--spacing-l4);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
`;

interface Props {
  selectedCreditCard: PaymentProfile;
  onSuccess: () => void;
  onCancel: () => void;
}

export function DeleteCreditCardContent({
  selectedCreditCard,
  onSuccess,
  onCancel,
}: Props) {
  const { mutate: deleteCreditCard, isPending } = useDeleteCreditCardQuery();

  return (
    <Container>
      <InfoWrapper>
        <CreditCard creditCard={selectedCreditCard} />
        <p>{t('account.credit_cards.remove_msg')} </p>
      </InfoWrapper>
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-testid="Credit Card Remove Submit Btn"
          data-variant="danger"
          aria-busy={isPending}
          onClick={() => {
            deleteCreditCard(selectedCreditCard.id, {
              onSuccess,
            });
          }}
        >
          {t('account.action_btns.remove')}
        </Button>
      </Footer>
    </Container>
  );
}
