import { locales } from '@tablecheck/locales';
import { ThemeProvider } from '@tablecheck/tablekit-react-css';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// TODO: add support for dark theme, currently its broken
export function TcThemeProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [, { language }] = useTranslation();
  return (
    <ThemeProvider
      lang={language || 'en'}
      theme="light"
      isRtl={language === locales.ar.code}
    >
      {children}
    </ThemeProvider>
  );
}
