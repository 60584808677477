import { useLocation } from 'react-router-dom';

import {
  JAPAN_UNIVERSE,
  SINGAPORE_UNIVERSE,
  VALID_UNIVERSES,
} from '@local/constants';
import { cookieService } from '@local/services';
import { Universe, UniverseSlug } from '@local/types';

const getUniverseFromPath = (pathname: string) => {
  const pathParts = pathname.split('/');
  const universePathPart = pathParts[2] as Universe['name'];
  if (!universePathPart || !VALID_UNIVERSES.includes(universePathPart)) {
    return null;
  }
  return universePathPart === VALID_UNIVERSES[0]
    ? JAPAN_UNIVERSE.slug
    : SINGAPORE_UNIVERSE.slug;
};

export const useUniverse = () => {
  const { pathname } = useLocation();
  const universe =
    (cookieService.getUniverse() ?? getUniverseFromPath(pathname)) ===
    UniverseSlug.SG
      ? SINGAPORE_UNIVERSE
      : JAPAN_UNIVERSE;

  return {
    isJapanUniverse: universe.slug === UniverseSlug.JP,
    isSingaporeUniverse: universe.slug === UniverseSlug.SG,
    universeId: universe.id,
    universeName: universe.name,
    universeSlug: universe.slug,
    universeCurrency: universe.currency,
    universeZoomLevel: universe.zoomLevel,
    universeBoundaries: universe.boundaries,
    universeBudgetLimit: universe.budgetLimit,
    universeDefaultLocation: universe.defaultLocation,
  };
};
