import { Close } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { IconButton } from '@tablecheck/tablekit-react-css';

import { ICON_SIZE_24 } from '@local/constants';

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  inset: 0;
  z-index: var(--zindex-dialog);
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: var(--spacing-l2);
  background-color: white;
  margin: var(--spacing-l1) var(--spacing-l2);
  z-index: var(--zindex-spotlight) !important;
  &:hover {
    background-color: var(--grey-200);
  }
`;

const DialogContent = styled(Dialog.Content)`
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: var(--border-radius-large);
  overflow: hidden;
  transform: translate(-50%, -50%);
  max-width: var(--default-dialog-width);
  &[data-is-large='true'] {
    max-width: var(--large-dialog-width);
  }
  z-index: var(--zindex-blanket) !important;
`;

const Content = styled.div`
  background-color: white;
  width: 100svw;
  max-width: -webkit-fill-available;
  border-radius: var(--border-radius-large);
  height: min-content;
  max-height: calc(100svh - var(--spacing-l8));
  overflow-x: hidden;
`;

const DialogTitle = styled(Dialog.Title)`
  margin: var(--spacing-l4);
`;

const Header = styled.div`
  position: fixed;
  z-index: var(--z-index-1);
  background-color: var(--surface);
  width: -webkit-fill-available;
  border-radius: var(--border-radius-large) var(--border-radius-large) 0px 0px;
`;

const ChildrenContent = styled.div`
  &[data-has-title='true'] {
    margin-top: 66px;
  }
`;

type PanelProps = {
  title?: string | null;
  isOpen: boolean;
  isLarge?: boolean;
  onClose: () => void;
  children: React.ReactNode;
} & Dialog.DialogProps;

export function PanelDesktop({
  isOpen,
  title,
  children,
  onClose,
  isLarge,
}: PanelProps): JSX.Element {
  return (
    <Dialog.Root open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <Dialog.Portal>
        <Overlay />
        <DialogContent
          aria-describedby="" // Fixes the "Missing `Description` or `aria-describedby={undefined}` for {DialogContent}" warning
          data-is-large={isLarge}
        >
          <Dialog.Close asChild>
            <CloseButton
              type="button"
              data-variant="bare"
              data-testid="Close Panel Btn"
            >
              <Close size={ICON_SIZE_24} />
            </CloseButton>
          </Dialog.Close>
          <Content>
            {title ? (
              <Header>
                <DialogTitle>{title}</DialogTitle>
              </Header>
            ) : (
              <VisuallyHidden asChild>
                <Dialog.Title />
              </VisuallyHidden>
            )}
            <ChildrenContent data-has-title={!!title}>
              {children}
            </ChildrenContent>
          </Content>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
