import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import type { Scope, UserResponseDataType } from '@local/types';

import { fetchReservations } from '../ReservationsApi';

export const useFetchPastReservations = (
  user: UserResponseDataType | undefined,
  scope: Scope,
) => {
  const [, { language }] = useTranslation();

  return useInfiniteQuery({
    queryKey: ['reservations', 'past'],
    queryFn: ({ pageParam }) => fetchReservations(language, 'past', pageParam),
    staleTime: Infinity,
    enabled: !!user && scope === 'past',
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.page >= lastPage.meta.total_pages) return;
      return lastPage.meta.page + 1;
    },
    initialPageParam: 0,
    select: (data) => {
      // TODO: DPOR-758
      // api is returning duplicate reservations in different pages
      // this is a temporary fix to remove duplicates
      const reservationsData = data.pages.flatMap((page) => page.reservations);

      const uniqueReservations = Array.from(
        reservationsData
          .reduce((acc, reservation) => {
            acc.set(reservation.slug, reservation);
            return acc;
          }, new Map())
          .values(),
      );

      return {
        pages: data.pages,
        shops: data.pages.at(-1)?.shops,
        reservations: uniqueReservations,
      };
    },
  });
};
