import { styled } from '@linaria/react';
import { Button, Input } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Payment } from '@local/types';
import { isSafari, openUrl } from '@local/utils';

import { useGenerateEreceipt } from '../../../hooks/useGenerateEreceiptQuery';

const Wrapper = styled.section`
  margin-top: var(--spacing-l5);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);

  & > p {
    font: var(--label);
  }

  & > form {
    display: flex;
    gap: var(--spacing-l2);
  }
`;
interface Props {
  paymentId: Payment['id'];
  status: Payment['ereceipt_status'];
}

export function GenerateReceiptSection({ paymentId, status }: Props) {
  const [, { language }] = useTranslation();
  const { mutate: generateEreceipt, isPending } = useGenerateEreceipt();
  const { register, handleSubmit, watch } = useForm<{
    customer_name: string;
  }>({
    defaultValues: {
      customer_name: '',
    },
  });
  const customerName = watch('customer_name');
  const isSafariBrowser = isSafari();
  const [receiptUrl, setReceiptUrl] = React.useState<string | null>(null);

  switch (status) {
    case 'none':
      return null;

    case 'created':
      return (
        <Wrapper>
          {receiptUrl || !isSafariBrowser ? (
            <Button
              data-testid="Ereceipt Show Receipt Btn"
              aria-busy={isPending}
              onClick={() => {
                if (receiptUrl) {
                  openUrl(receiptUrl, '_blank', 'noreferrer');
                } else {
                  generateEreceipt(
                    { paymentId, locale: language },
                    {
                      onSuccess: (data) => {
                        openUrl(data.url, '_blank', 'noreferrer');
                        setReceiptUrl(data.url);
                      },
                    },
                  );
                }
              }}
            >
              {t('account.action_btns.show_receipt')}
            </Button>
          ) : (
            <Button
              aria-busy={isPending}
              onClick={() => {
                generateEreceipt(
                  { paymentId, locale: language },
                  {
                    onSuccess: (data) => {
                      setReceiptUrl(data.url);
                    },
                  },
                );
              }}
            >
              {t('account.action_btns.download_receipt')}
            </Button>
          )}
        </Wrapper>
      );

    case 'ready':
      return (
        <Wrapper>
          <p>{t('account.generate_receipt')}</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              void handleSubmit((fields) => {
                generateEreceipt(
                  {
                    paymentId,
                    locale: language,
                    customerName: fields.customer_name,
                  },
                  {
                    onSuccess: (data) => {
                      openUrl(data.url, '_blank', 'noreferrer');
                      setReceiptUrl(data.url);
                    },
                  },
                );
              })();
            }}
          >
            <Input
              data-testid="Ereceipt Name Input"
              type="text"
              data-stretch
              placeholder={t('account.enter_name')}
              {...register('customer_name', { required: true })}
            />
            <Button
              data-testid="Ereceipt Issue Receipt Btn"
              type="submit"
              aria-busy={isPending}
              disabled={!customerName}
            >
              {t('account.action_btns.issue_receipt')}
            </Button>
          </form>
        </Wrapper>
      );

    default:
      return null;
  }
}
