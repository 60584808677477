import { styled } from '@linaria/react';
import { useAtomValue } from 'jotai';

import { DynamicIcon, IconNameType } from '@local/components';
import { DEFAULT_ICON_SIZE } from '@local/constants';
import { useDeviceType } from '@local/hooks';
import { escapeRegexChars } from '@local/utils';

import { searchValAtom } from '../../searchStore';

const ItemContainer = styled.div`
  display: flex;

  gap: var(--spacing-l2);
  padding: var(--spacing-l2) var(--spacing-l2);
  &[data-is-mobile='true'] {
    padding: var(--spacing-l2) var(--spacing-l4);
  }
  cursor: pointer;

  width: 100%;
  &:hover {
    background-color: var(--surface-raised-hover);
  }
  &:last-child:hover {
    border-bottom-left-radius: var(--border-radius-large);
    border-bottom-right-radius: var(--border-radius-large);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--spacing-l7);
  height: var(--spacing-l7);
  border-radius: var(--border-radius-small);
  background-color: var(--surface-raised);
  padding: 0px !important;
`;

const Title = styled.dt`
  font: var(--body-2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  scrollbar-width: none;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;
const Description = styled.dd`
  font: var(--small);
  color: var(--text-subtle);
  width: min-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const InfoWrapper = styled.dl`
  width: calc(100% - 56px);
  display: flex;
  flex: 1 1 1;
  flex-wrap: wrap;
  align-items: center;
  & * {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-small);
`;

const highlightMatches = (text: string, match: string) => {
  if (!match.trim() || !text) return <span>{text}</span>;
  const escapedMatch = escapeRegexChars(match.trim());
  const regex = new RegExp(`(${escapedMatch})`, 'gi');
  return (
    <span>
      {text
        .split(regex)
        .map((part) =>
          regex.test(part) ? <strong key={part}>{part}</strong> : part,
        )}
    </span>
  );
};

interface SuggestionItemProps {
  type?: 'Cuisines' | 'Shops' | 'Locations' | 'Plain Text' | 'Fallback';
  title: string;
  description?: string;
  imgUrl?: string;
  iconName: IconNameType;
  onClick: () => void;
}
export function SuggestionItem({
  type = 'Plain Text',
  onClick,
  imgUrl,
  title,
  iconName,
  description,
}: SuggestionItemProps) {
  const { isDesktop } = useDeviceType();
  const searchVal = useAtomValue(searchValAtom);
  return (
    <ItemContainer
      data-is-mobile={!isDesktop}
      data-testid={`${type} Suggestion Item`}
      onClick={onClick}
    >
      <IconWrapper>
        {imgUrl ? (
          <Image src={imgUrl} alt={title} />
        ) : (
          <DynamicIcon
            iconName={iconName}
            fill="var(--text-subtle)"
            size={DEFAULT_ICON_SIZE}
          />
        )}
      </IconWrapper>
      <InfoWrapper>
        {description ? <Description>{description}</Description> : null}
        <Title>{highlightMatches(title, searchVal)}</Title>
      </InfoWrapper>
    </ItemContainer>
  );
}
