import {
  addDays,
  differenceInMinutes,
  format,
  isBefore,
  isEqual,
  set,
} from 'date-fns';

import { DEFAULT_TIME, DINNER_LIMIT_TIME } from '@local/constants';

import { roundTimeTo30Min } from './datetime';

export const getDefaultDate = (today: Date) => {
  const [limitHours, limitMinutes] = DINNER_LIMIT_TIME.split(':').map(Number);
  // Until 9pm should use today, otherwise use tomorrow
  const isBefore9PM =
    today <=
    set(today, {
      hours: limitHours,
      minutes: limitMinutes,
      seconds: 0,
    });
  return format(isBefore9PM ? today : addDays(today, 1), 'yyyy-MM-dd');
};

export const getDefaultTime = (today: Date): string => {
  const [limitHours, limitMinutes] = DINNER_LIMIT_TIME.split(':').map(Number);
  const [dinnerHours, dinnerMinutes] = DEFAULT_TIME.split(':').map(Number);
  const limitTime = set(today, {
    hours: limitHours,
    minutes: limitMinutes,
    seconds: 0,
  });
  const eveningTime = set(today, {
    hours: dinnerHours,
    minutes: dinnerMinutes,
    seconds: 0,
  });

  if (isBefore(today, eveningTime)) {
    // Before 7pm, should return DEFAULT_TIME
    return DEFAULT_TIME;
  }
  if (isBefore(today, limitTime) || isEqual(today, limitTime)) {
    // From 7pm to 9pm, should return the next 30min timeslot
    return roundTimeTo30Min(today);
  }
  // After 9pm, should return DEFAULT_TIME
  return DEFAULT_TIME;
};

export function getClosestTimeSlots({
  availability,
  queriedTime,
  queriedDate,
  currentDateTime,
  maxSlots = 5,
}: {
  availability: string[];
  queriedTime?: string;
  queriedDate?: string;
  currentDateTime: Date;
  maxSlots?: number;
}) {
  const referenceDateTime = queriedTime
    ? new Date(
        `${queriedDate || format(currentDateTime, 'yyyy-MM-dd')}T${queriedTime}`,
      )
    : currentDateTime;

  // Finds and sets the index of the closest time slot to the current time or the queried time
  let minTimeDifference = Infinity;
  let closestIndex = -1;
  availability.forEach((dateTime, index) => {
    const availableDateTime = new Date(dateTime);
    const timeDifference = Math.abs(
      differenceInMinutes(availableDateTime, referenceDateTime),
    );
    if (timeDifference < minTimeDifference) {
      minTimeDifference = timeDifference;
      closestIndex = index;
    }
  });

  // Sets the start index to a few slots before the closest time slot
  let startIndex = Math.max(0, closestIndex - Math.floor(maxSlots / 2));
  let endIndex = startIndex + maxSlots;

  // Adjust start index if its too close to the end to always show maxSlots
  if (endIndex > availability.length) {
    endIndex = availability.length;
    startIndex = Math.max(0, endIndex - maxSlots);
  }

  return availability.slice(startIndex, endIndex);
}
