import { atom } from 'jotai';

import type { SearchViewType, ShopSearchQueryParamsType } from '@local/types';

import { DEFAULT_SEARCH_FILTERS, SNAP_MIDDLE_POINT } from './searchConstants';

export const searchFiltersAtom = atom<ShopSearchQueryParamsType>(
  DEFAULT_SEARCH_FILTERS,
);
export const searchValAtom = atom<string>('');
export const searchViewAtom = atom<SearchViewType>('default');
export const isHomepageSearchVisibleAtom = atom<boolean>(true);
export const searchResultsScrollAtom = atom<number>(0);

interface SearchResultsPanelType {
  snap: number | string | null;
  isOpen: boolean;
}
export const searchResultsPanelAtom = atom<SearchResultsPanelType>({
  snap: SNAP_MIDDLE_POINT,
  isOpen: true,
});
