import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { logger, sentryService } from '@local/services';

import { deleteSocialAuth } from '../accountApi';

export const useDeleteSocialAuthQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSocialAuth,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY, 'social_auths'],
      });
    },
    onError: (error) => {
      logger.error(error);
      sentryService.captureException({ error });
    },
  });
};
