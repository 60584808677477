import { LocaleCode } from '@tablecheck/locales';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useFetchCuisineListQuery } from './useFetchCuisineListQuery';
import { useSearchFilters } from './useSearchFilters';

// Maps kanji to their possible hiragana reading, from partial to full matches
const KANJI_READINGS: Record<string, string[]> = {
  料理: ['り', 'りょう', 'りょうり'],
  麺: ['め', 'めん'],
  飲茶: ['や', 'やむ', 'やむちゃ'],
  点心: ['て', 'てん', 'てんし', 'てんしん'],
  鍋: ['な', 'なべ'],
  寿司: ['す', 'すし'],
  焼き: ['や', 'やき'],
  日本: ['に', 'にほ', 'にほん'],
  中華: ['ち', 'ちゅ', 'ちゅう', 'ちゅうか'],
  韓国: ['か', 'かん', 'かんこ', 'かんこく'],
  広東: ['か', 'かん', 'かんと', 'かんとん'],
  北京: ['ぺ', 'ぺき', 'ぺきん'],
  上海: ['しゃ', 'しゃん', 'しゃんは', 'しゃんはい'],
  四川: ['し', 'しせ', 'しせん'],
  台湾: ['た', 'たい', 'たいわ', 'たいわん'],
  欧州: ['お', 'おう', 'おうしゅ', 'おうしゅう'],
  揚げ物: ['あ', 'あげ', 'あげも', 'あげもの'],
  朝食: ['ち', 'ちょ', 'ちょう', 'ちょうしょ', 'ちょうしょく'],
  懐石: ['か', 'かい', 'かいせ', 'かいせき'],
  会席: ['か', 'かい', 'かいせ', 'かいせき'],
  創作: ['そ', 'そう', 'そうさ', 'そうさく'],
  割烹: ['か', 'かっ', 'かっぽ', 'かっぽう'],
  居酒屋: ['い', 'いざ', 'いざか', 'いざかや'],
  炉端: ['ろ', 'ろば', 'ろばた'],
  鉄板: ['て', 'てっ', 'てっぱ', 'てっぱん'],
  冷麺: ['れ', 'れい', 'れいめ', 'れいめん'],
  つけ麺: ['つ', 'つけ', 'つけん', 'つけめん'],
  担担麺: ['た', 'たん', 'たんたん', 'たんたんめ', 'たんたんめん'],
  焼肉: ['や', 'やき', 'やきに', 'やきにく'],
  焼鳥: ['や', 'やき', 'やきと', 'やきとり'],
  焼きそば: ['や', 'やき', 'やきそ', 'やきそば'],
  串焼き: ['く', 'くし', 'くしや', 'くしやき'],
  鉄板焼き: ['て', 'てっ', 'てっぱん', 'てっぱんや', 'てっぱんやき'],
  火鍋: ['ひ', 'ひな', 'ひなべ'],
  すき焼き: ['す', 'すき', 'すきや', 'すきやき'],
  魚介: ['ぎょ', 'ぎょか', 'ぎょかい'],
  海老: ['え', 'えび'],
  鰻: ['う', 'うな', 'うなぎ'],
  餃子: ['ぎ', 'ぎょ', 'ぎょう', 'ぎょうざ'],
  お好み焼き: ['お', 'おこ', 'おこのみ', 'おこのみや', 'おこのみやき'],
  たこ焼き: ['た', 'たこ', 'たこや', 'たこやき'],
  天ぷら: ['て', 'てん', 'てんぷ', 'てんぷら'],
  喫茶店: ['き', 'きっ', 'きっさ', 'きっさて', 'きっさてん'],
  酒屋: ['さ', 'さか', 'さかや'],
  旅館: ['り', 'りょ', 'りょか', 'りょかん'],
  和食: ['わ', 'わしょ', 'わしょく'],
  洋食: ['よ', 'よう', 'ようしょ', 'ようしょく'],
  豆腐: ['と', 'とう', 'とうふ'],
  野菜: ['や', 'やさ', 'やさい'],
  肉: ['に', 'にく'],
  鳥: ['と', 'とり'],
  物: ['も', 'もの'],
  国: ['こ', 'こく'],
  焼: ['や', 'やき'],
  店: ['て', 'てん'],
  食: ['し', 'しょ', 'しょく'],
  海: ['か', 'かい'],
  一: ['い', 'いち'],
};

const toHiragana = (text: string): string =>
  text.replace(/[\u30A1-\u30F6]/g, (match) =>
    String.fromCharCode(match.charCodeAt(0) - 0x60),
  );

export const matchesJapaneseText = (
  translation: string,
  searchTerm: string,
): boolean => {
  const hiraganaSearch = toHiragana(searchTerm.toLowerCase());
  const hiraganaTranslation = toHiragana(translation.toLowerCase());

  for (const [kanji, readings] of Object.entries(KANJI_READINGS)) {
    if (
      translation.includes(kanji) &&
      readings.some((reading) => reading.startsWith(hiraganaSearch))
    ) {
      return true;
    }
  }

  return hiraganaTranslation.includes(hiraganaSearch);
};

export const matchesText = (
  translation: string,
  searchTerm: string,
  isJapanese: boolean,
): boolean => {
  if (
    isJapanese &&
    /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]/.test(searchTerm)
  ) {
    return matchesJapaneseText(translation, searchTerm);
  }
  return translation.toLowerCase().includes(searchTerm.toLowerCase());
};

export function useCuisineSearch({
  searchFilterView,
}: {
  searchFilterView: string;
}) {
  const [t, { language }] = useTranslation();
  const { searchFilters } = useSearchFilters();
  const { data: { cuisines: cuisineList = [] } = {}, status } =
    useFetchCuisineListQuery();

  const isCuisineListLoaded = React.useMemo(
    () => cuisineList.length > 0 && status === 'success',
    [cuisineList.length, status],
  );

  const [cuisineSearch, setCuisineSearch] = React.useState('');
  const [selectedCuisines, setSelectedCuisines] = React.useState(
    searchFilters.cuisines ?? [],
  );

  // Keep a memoized version of the sorted cuisine list that only updates when clicking update or typing in search
  const sortedCuisineList = React.useMemo(
    () =>
      [...cuisineList].sort((a, b) => {
        const isASelected = selectedCuisines.includes(a.field);
        const isBSelected = selectedCuisines.includes(b.field);
        if (isASelected && !isBSelected) return -1;
        if (!isASelected && isBSelected) return 1;
        return 0;
      }),
    // Do not add `selectedCuisines` in the dependencies array to avoid rerendering
    // On each cuisine selection, a reordering will happen which will put the cuisine on top
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cuisineList, searchFilters, cuisineSearch],
  );

  React.useEffect(() => {
    setSelectedCuisines(searchFilters.cuisines ?? []);
  }, [searchFilters.cuisines, searchFilterView]);

  const filteredCuisines = React.useMemo(() => {
    if (!cuisineSearch) return sortedCuisineList;

    return sortedCuisineList.filter((cuisine) => {
      const translation = t(`cuisine.${cuisine.field}`);
      return matchesText(
        translation,
        cuisineSearch,
        language === LocaleCode.Japanese,
      );
    });
  }, [sortedCuisineList, cuisineSearch, t, language]);

  React.useEffect(() => {
    setCuisineSearch('');
  }, [searchFilterView]);

  return {
    cuisineSearch,
    setCuisineSearch,
    selectedCuisines,
    setSelectedCuisines,
    filteredCuisines,
    isCuisineListLoaded,
    cuisineList,
  };
}
