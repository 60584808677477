import { Locked } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

const Wrapper = styled.section`
  & > p {
    font: var(--label);
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-l4) 0;
  gap: var(--spacing-l3);
  font: var(--body-1);

  & > svg {
    align-self: start;
    min-width: 24px;
  }

  & > div {
    align-self: start;
    justify-self: start;
    width: 100%;
  }
`;

interface Props {
  onClick: () => void;
}

export function ChangePassword({ onClick }: Props) {
  return (
    <Wrapper>
      <p>{t('account.change_password')}</p>
      <Content>
        <Locked size={24} />
        <div>
          <p>**********</p>
          {/* TODO: Add last updated date here DPOR-678 */}
        </div>
        <Button
          data-testid="Account Change Pwd Btn"
          data-variant="secondary"
          data-size="small"
          onClick={onClick}
        >
          {t('account.action_btns.change')}
        </Button>
      </Content>
    </Wrapper>
  );
}
