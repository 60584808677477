import { t } from 'i18next';
import { CountryCode } from 'libphonenumber-js/core';
import * as React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import ReactSelect, { createFilter, components } from 'react-select';

import { countrySelectStyles, PhoneWrapper, Flag } from './PhoneField.styles';
import { allCountries, I18nCountry } from './data/countries';

const formatOptionLabel = ({ shortName, code }: I18nCountry) => (
  <>
    <Flag
      data-country={shortName.toUpperCase()}
      data-testid={shortName.toUpperCase()}
    />{' '}
    +{code} {t(`monolith.countries.${shortName}`)}
  </>
);

const filterOptions = {
  ignoreCase: true,
  ignoreAccents: true,
  stringify: (option: { label: string; value: string; data: I18nCountry }) =>
    `${option.data.name} (${option.data.shortName}) +${option.data.code}`,
  trim: true,
};

interface Props {
  defaultCountry: I18nCountry;
  onChange: (value: string) => void;
  hasError?: boolean;
  customerPhoneNumber?: string;
}

export function PhoneField({
  defaultCountry,
  onChange,
  hasError,
  customerPhoneNumber,
}: Props): JSX.Element {
  const [selectedCountry, setSelectedCountry] =
    React.useState<I18nCountry>(defaultCountry);
  const [phoneNumber, setPhoneNumber] = React.useState(
    customerPhoneNumber ?? '',
  );

  const handleCountryChange = (selectedOption: I18nCountry) => {
    setSelectedCountry(selectedOption);
  };

  const handlePhoneChange = (value = '') => {
    setPhoneNumber(value);
    onChange(value);
  };

  return (
    <PhoneWrapper data-has-error={!!hasError}>
      <ReactSelect
        isMulti={false}
        options={allCountries}
        isClearable={false}
        onChange={(option) => {
          if (option) {
            handleCountryChange(option);
          }
        }}
        value={selectedCountry}
        formatOptionLabel={(option: I18nCountry) => formatOptionLabel(option)}
        getOptionValue={(option: I18nCountry) => option.shortName as string}
        filterOption={createFilter(filterOptions)}
        styles={countrySelectStyles}
        components={{
          SingleValue: (props) => (
            <components.SingleValue {...props}>
              <Flag data-country={selectedCountry.shortName} />
              <div>+{selectedCountry.code}</div>
            </components.SingleValue>
          ),
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Control: ({ innerProps, children, ...controlProps }) => (
            <components.Control
              {...controlProps}
              innerProps={
                {
                  ...innerProps,
                  'data-testid': 'Phone Country Select',
                } as typeof innerProps
              }
            >
              {children}
            </components.Control>
          ),
        }}
      />

      <PhoneInput
        id="phone"
        data-testid="Phone Input"
        country={selectedCountry.shortName as CountryCode}
        value={phoneNumber}
        onChange={(value) => handlePhoneChange(value)}
        placeholder={customerPhoneNumber ? '' : t('auth.phone_number')}
      />
    </PhoneWrapper>
  );
}
