import { CONFIG } from '@local/configs';
import { cookieService, logger, sentryService } from '@local/services';
import { OAuthProviders } from '@local/types';

import { getCsrfToken } from '../authApi';

export const useAuthInWindow = () => {
  const handleSocialLogin = async (provider: OAuthProviders) => {
    try {
      const currentUrl = window.location.href;
      const encodedRedirectUrl = encodeURIComponent(currentUrl);
      const authEndpoint = `${CONFIG.VITE_BASE_TABLECHECK_URL}auth/${provider}?redirect_url=${encodedRedirectUrl}`;

      await getCsrfToken();
      const csrfToken = cookieService.getCsrfToken();
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = authEndpoint;

      const csrfInput = document.createElement('input');
      csrfInput.type = 'hidden';
      csrfInput.name = 'authenticity_token';
      csrfInput.value = csrfToken || '';
      form.appendChild(csrfInput);

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      logger.error('Social login failed:', error);
      sentryService.captureException({ error });
    }
  };
  return { handleSocialLogin };
};
