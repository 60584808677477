import { useQuery } from '@tanstack/react-query';

import { STALETIME_1_HOUR } from '@local/constants';

import { fetchCuisineList } from '../searchApi';

export const useFetchCuisineListQuery = () =>
  useQuery({
    queryKey: ['cuisines'],
    queryFn: () => fetchCuisineList(),
    staleTime: STALETIME_1_HOUR * 2,
    select: (data) => ({
      ...data,
      cuisines: data.cuisines.map((cuisine) => ({
        ...cuisine,
        field: cuisine.field.replace(/-/g, '_'),
      })),
    }),
  });
