import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';

import { translate } from '@local/utils';

import { useVenueCardContext } from '../VenueCardContext';

const StyledParagraph = styled.span`
  overflow: hidden;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
`;

export function Description() {
  const { venue } = useVenueCardContext();
  const [, { language }] = useTranslation();

  if (!venue.description) return null;
  return (
    <StyledParagraph>{translate(venue.description, language)}</StyledParagraph>
  );
}
