import { styled } from '@linaria/react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { TABLECHECK_LOGO } from '@local/assets';
import {
  LanguageSelector,
  SmartLink,
  UniverseSelector,
} from '@local/components';
import { useRouting, useUniverse } from '@local/hooks';
import { searchViewAtom } from '@local/search';

const Header = styled.header`
  align-items: center;
  display: grid;
  justify-self: start;
  grid-template-columns: 1fr 1fr;
  padding: var(--spacing-l2) var(--spacing-l4);
  width: 100svw;
  border-bottom: 1px solid var(--lighter-grey-border);
  height: var(--mobile-header-height);
  font-size: 14px;
  z-index: var(--z-index-1);
  background-color: white;
  > :first-child {
    justify-self: start;
  }
  > :last-child {
    justify-self: end;
  }
  a {
    display: flex;
  }
`;

const TCLogo = styled.img`
  height: 20px;
`;

const SelectorsWrapper = styled.div`
  display: flex;
`;

export function MobileHeader() {
  const [, { language }] = useTranslation();
  const routing = useRouting();
  const searchView = useAtomValue(searchViewAtom);
  const shouldHideHeader =
    routing.isExplorePage || routing.isVenuePage || searchView === 'focused';
  const { universeName } = useUniverse();

  if (shouldHideHeader) return null;
  return (
    <Header data-testid="Mobile Main Header">
      <SmartLink href={`/${language}/${universeName}`}>
        <TCLogo src={TABLECHECK_LOGO} alt="TableCheck logo" />
      </SmartLink>
      <SelectorsWrapper>
        <UniverseSelector />
        <LanguageSelector />
      </SelectorsWrapper>
    </Header>
  );
}
