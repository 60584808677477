import { LocaleCode } from '@tablecheck/locales';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Locales } from './LocalesClass';
import { SUPPORTED_LOCALES } from './locales';

export function useSyncRouteWithLocale() {
  const location = useLocation();
  const navigate = useNavigate();
  const [, { language, isInitialized }] = useTranslation();

  React.useEffect(() => {
    const { pathname } = location;
    const pathLocale = pathname.split('/')[1] as LocaleCode;
    if (
      pathLocale !== language &&
      isInitialized &&
      SUPPORTED_LOCALES.includes(pathLocale)
    ) {
      navigate(
        Locales.getUrlWithNewLocale(window.location, language as LocaleCode),
      );
    }
  }, [location, isInitialized, language, navigate]);
}
