import { PII_FIELDS, SENSITIVE_HEADERS } from '@local/constants';

const SENSITIVE_FIELDS = [...PII_FIELDS, ...SENSITIVE_HEADERS];
const SCRUBBED_VALUE = 'Hidden Data';

const isPIIField = (key: string): boolean =>
  SENSITIVE_FIELDS.some(
    (field) =>
      key.toLowerCase() === field.toLowerCase() ||
      key.toLowerCase().includes(field.toLowerCase()),
  );

const scrubURLSearchParams = (url: URL): URL => {
  const scrubbedUrl = new URL(url.toString());

  for (const [key] of url.searchParams.entries()) {
    if (isPIIField(key)) {
      scrubbedUrl.searchParams.set(key, encodeURIComponent(SCRUBBED_VALUE));
    }
  }

  return scrubbedUrl;
};
const scrubValue = (key: string, value: unknown): unknown => {
  // Return early for null/undefined
  if (value === null || value === undefined) {
    return value;
  }

  // If it's a PII field, always return SCRUBBED_VALUE regardless of type
  if (isPIIField(key)) {
    return SCRUBBED_VALUE;
  }

  // Handle string values that might be URLs
  if (typeof value === 'string') {
    try {
      const url = new URL(value);
      return scrubURLSearchParams(url).toString();
    } catch {
      return value;
    }
  }

  // Handle arrays
  if (Array.isArray(value)) {
    return value.map((item) => scrubValue(key, item));
  }

  // Handle objects
  if (typeof value === 'object') {
    return Object.entries(value).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: scrubValue(k, v),
      }),
      {},
    );
  }

  return value;
};

export const withoutPII = <T>(input: T): T => {
  try {
    return JSON.parse(
      JSON.stringify(input, (key, value) => scrubValue(key, value)),
    ) as T;
  } catch (error) {
    console.error('withoutPII: Error processing data:', error);
    return { error: 'Error processing data' } as T;
  }
};
