import { useQuery } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';

import { getSocialAuths } from '../accountApi';

export const useFetchSocialAuthsQuery = () =>
  useQuery({
    queryKey: [USER_QUERY_KEY, 'social_auths'],
    queryFn: () => getSocialAuths(),
    staleTime: Infinity,
  });
