import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CONFIG } from '@local/configs';
import { useUniverse } from '@local/hooks';
import { MobileSubHeader } from '@local/layouts';
import type {
  GalleryResponse,
  MenuItem,
  MenuItemsResponse,
  VenueFavoritesResponse,
  VenueInfo,
} from '@local/types';
import {
  buildBookingFormUrl,
  CurrencyFormat,
  openUrl,
  translate,
} from '@local/utils';
import {
  Description,
  Experiences,
  Features,
  ImageCarousel,
  Information,
  MenuItemsSection,
  Overview,
} from '@local/venue';

const VenueContent = styled.div`
  display: grid;
  gap: var(--spacing-l7);
  margin: var(--spacing-l4);
  padding-bottom: calc(var(--spacing-l8) * 2);
`;

const Footer = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  display: flex;
  padding: var(--spacing-l4);
  &[data-is-cordova='true'] {
    padding-bottom: var(--spacing-l7);
  }
  background-color: var(--surface);
  border-top: 1px solid var(--border);
  z-index: 1;
  & button {
    width: 100%;
    padding: var(--spacing-l3);
  }
`;

const HeaderWrapper = styled.div`
  position: fixed;
  width: -webkit-fill-available;
  z-index: var(--z-index-1);
`;

interface Props {
  venueSlug: string;
  venueData: VenueInfo;
  gallery: GalleryResponse | undefined;
  menuItems: MenuItem[];
  favoritesData: VenueFavoritesResponse | undefined;
  allSavedShopsSet: Set<string>;
  experiences: MenuItemsResponse | undefined;
  shouldShowMenuItems: boolean;
  shouldShowExperiences: boolean;
}

export function VenueMobile({
  venueSlug,
  venueData,
  gallery,
  menuItems,
  favoritesData,
  allSavedShopsSet,
  experiences,
  shouldShowMenuItems,
  shouldShowExperiences,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const [t, { language }] = useTranslation();
  const headerTitle = translate(venueData.name_translations, language);
  const [searchParams] = useSearchParams();
  const { universeName } = useUniverse();
  const onFindTableClick = () => {
    const searchParamsObject = Object.fromEntries(searchParams);
    const bookingFormURL = buildBookingFormUrl(
      `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${venueSlug}/reserve`,
      searchParamsObject,
    );
    openUrl(bookingFormURL, '_blank');
  };

  const onGoBackClick = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/${language}/${universeName}/search`);
    }
  };

  return (
    <>
      <HeaderWrapper>
        <MobileSubHeader
          title={headerTitle}
          data-variant="transparent"
          onBackBtnClick={onGoBackClick}
        />
      </HeaderWrapper>
      <ImageCarousel images={gallery?.images} />
      <VenueContent>
        <Overview
          venueData={venueData}
          favoritesData={favoritesData?.favorites[0]}
          isSaved={allSavedShopsSet.has(venueData._id)}
        />
        <Description
          taglineTranslations={venueData.content_title_translations}
          descriptionTranslations={venueData.content_body_translations}
        />
        {CONFIG.VITE_IS_STORYBOOK && <Features features={venueData.features} />}
        {shouldShowExperiences && (
          <Experiences venueSlug={venueSlug} experiences={experiences} />
        )}
        {shouldShowMenuItems && (
          <MenuItemsSection
            items={menuItems}
            currency={venueData.currency as CurrencyFormat}
          />
        )}
        <Information venueInfo={venueData} />
      </VenueContent>
      <Footer data-is-cordova={CONFIG.IS_CORDOVA}>
        <Button
          data-variant="primary"
          type="button"
          onClick={onFindTableClick}
          data-testid="Venue Find Table Btn"
        >
          {t('venue_feat.find_table')}
        </Button>
      </Footer>
    </>
  );
}
