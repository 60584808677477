import {
  CheckmarkFilled,
  Close,
  Information,
  WarningAlt,
  WarningAltFilled,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import {
  AlertCloseButton,
  AlertDescription,
  AlertIconWrapper,
  AlertTitle,
  AlertCore as TK3Alert,
} from '@tablecheck/tablekit-react-css';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { DEFAULT_ICON_SIZE, ICON_SIZE_16 } from '@local/constants';

export const StyledAlert = styled(TK3Alert)`
  width: 100%;
  font: var(--small);

  &[data-portal='true'] {
    position: fixed;
    width: fit-content;
    top: 3%;
    left: 50%;
    transform: translateX(-50%);
    z-index: var(--zindex-flag);
  }
`;

export type BaseAlertProps = React.ComponentProps<typeof StyledAlert>;

export interface AlertProps extends BaseAlertProps {
  title?: string;
  isShow?: boolean;
  shouldUsePortal?: boolean;
  onClose?: () => void;
}

const iconMap: Record<BaseAlertProps['data-variant'], JSX.Element> = {
  success: <CheckmarkFilled size={ICON_SIZE_16} />,
  info: <Information size={ICON_SIZE_16} />,
  warning: <WarningAlt size={ICON_SIZE_16} />,
  error: <WarningAltFilled size={ICON_SIZE_16} />,
  neutral: <Information size={ICON_SIZE_16} />,
};

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      'data-variant': variant,
      'data-layout': layout,
      title,
      isShow,
      shouldUsePortal,
      children,
      onClose,
      ...props
    },
    ref,
  ) => {
    if (!isShow) return null;
    const shouldIncludeIcon = layout.includes('icon');
    const shouldIncludeTitle = layout.includes('title');
    const shouldIncludeClose = layout.includes('close') && onClose;

    const alertContent = (
      <StyledAlert
        data-variant={variant}
        data-layout={layout}
        data-portal={shouldUsePortal}
        ref={ref}
        {...props}
      >
        {shouldIncludeIcon && (
          <AlertIconWrapper>{iconMap[variant]}</AlertIconWrapper>
        )}
        {shouldIncludeTitle && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription>{children}</AlertDescription>
        {shouldIncludeClose && (
          <AlertCloseButton
            onClick={() => {
              onClose();
            }}
          >
            <Close size={DEFAULT_ICON_SIZE} />
          </AlertCloseButton>
        )}
      </StyledAlert>
    );

    return shouldUsePortal
      ? ReactDOM.createPortal(alertContent, document.body)
      : alertContent;
  },
);
