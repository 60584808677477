import { Helmet } from 'react-helmet-async';

import {
  JAPAN_HERO_BANNER_IMG,
  SINGAPORE_HERO_BANNER_IMG,
} from '@local/assets';
import { useUniverse } from '@local/hooks';

export function HomeHead() {
  const { isJapanUniverse } = useUniverse();
  const bannerImg = isJapanUniverse
    ? JAPAN_HERO_BANNER_IMG
    : SINGAPORE_HERO_BANNER_IMG;
  return (
    <Helmet prioritizeSeoTags>
      <link
        rel="preload"
        href={bannerImg.replace('.png', '.webp')}
        as="image"
        // eslint-disable-next-line react/no-unknown-property
        fetchPriority="high"
      />
    </Helmet>
  );
}
