import { RequestQuote } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useSetAtom } from 'jotai';

import { DEFAULT_ICON_SIZE } from '@local/constants';

import { panelStateAtom } from '../Panel/panelStore';

import { selectedVenueMemoAtom } from './memoStore';

const MemoField = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  gap: var(--spacing-l2);
  padding: var(--spacing-l2) var(--spacing-l3);
  align-items: center;
  border-radius: var(--border-radius-small);
  cursor: pointer;
  color: var(--grey-700);
  background-color: var(--grey-50);

  &[data-has-memo='true'] {
    color: var(--text);
    background: var(--surface-raised);
    border: 1px solid var(--surface-raised);
    &:hover {
      background: var(--surface-raised-hover);
    }
  }
  &[data-has-memo='false'] {
    border: 1px dashed var(--grey-500);
  }
  &:hover {
    background-color: var(--grey-100);
  }
`;

const UnderlinedSpan = styled.span`
  text-decoration: underline;
`;

interface Props {
  userMemo: string | null;
  venueSlug: string;
}

export function Memo({ userMemo, venueSlug }: Props): JSX.Element | null {
  const setPanelState = useSetAtom(panelStateAtom);
  const setSelectedVenueMemo = useSetAtom(selectedVenueMemoAtom);

  const hasMemo = userMemo && userMemo.length > 0;

  return (
    <MemoField
      onClick={() => {
        setSelectedVenueMemo({
          venueSlug,
          currentMemo: userMemo,
        });
        setPanelState('memo');
      }}
      data-has-memo={hasMemo}
      data-testid="Memo Area"
    >
      <RequestQuote size={DEFAULT_ICON_SIZE} />
      {hasMemo ? (
        <span>{userMemo}</span>
      ) : (
        <UnderlinedSpan>{t('venue_feat.memo.add_memo')}</UnderlinedSpan>
      )}
    </MemoField>
  );
}
