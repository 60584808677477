import { styled } from '@linaria/react';

export const Map = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &[data-is-loading='true'] {
    opacity: 0;
  }
`;
