import {
  Link,
  Location,
  Phone,
  RestaurantFine,
  Time,
  Train,
} from '@carbon/icons-react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { CONFIG } from '@local/configs';
import { ICON_SIZE_16 } from '@local/constants';
import type { VenueInfo } from '@local/types';
import { formatDistance, translate } from '@local/utils';

import { Map } from '../Map/Map';

import {
  BusinessHours,
  Info,
  InformationWrapper,
  OpeningHours,
  SectionTitle,
  VenueDetails,
  VenueLink,
} from './Information.styles';

export function Information({
  venueInfo,
}: {
  venueInfo: VenueInfo;
}): JSX.Element {
  const [, { language }] = useTranslation();

  const {
    address,
    url,
    phone,
    opening_hours,
    closest_station,
    stations,
    cuisines,
    geocode,
  } = venueInfo;
  return (
    <InformationWrapper>
      {!!geocode && <Map venueInfo={venueInfo} />}
      <Info>
        <Location size={ICON_SIZE_16} />
        <div>
          <SectionTitle>{t('venue_feat.information.address')}</SectionTitle>
          <VenueDetails>
            {`${address.postal_code} ${address.street}, ${address.city}, ${address.region}`}
          </VenueDetails>
        </div>
      </Info>
      {CONFIG.VITE_IS_STORYBOOK ? (
        <Info>
          <Train size={ICON_SIZE_16} />
          <div>
            <VenueDetails>
              <span>
                {t('venue_feat.information.station_walking_time', {
                  station: translate(
                    closest_station.name_translations,
                    language,
                  ),
                  duration: closest_station.walk_time,
                  distance: formatDistance(closest_station.distance),
                })}
              </span>
            </VenueDetails>
          </div>
        </Info>
      ) : (
        <>
          {stations?.length > 0 && (
            <Info>
              <Train size={ICON_SIZE_16} />
              <VenueDetails>
                <SectionTitle>
                  {t('venue_feat.information.closest_station')}
                </SectionTitle>
                {stations.map((station) => (
                  <span key={translate(station.name_translations, language)}>
                    {t('venue_feat.information.station_walking_time', {
                      station: translate(station.name_translations, language),
                      duration: '5', // Update this if duration varies by station
                      distance: formatDistance(
                        Math.floor(station.distance).toString(),
                      ),
                    })}
                  </span>
                ))}
              </VenueDetails>
            </Info>
          )}
        </>
      )}
      {cuisines.length > 0 && (
        <Info>
          <RestaurantFine size={ICON_SIZE_16} />
          <VenueDetails>
            <SectionTitle>{t('cuisine.title')}</SectionTitle>
            <div>
              {cuisines.map((cuisine, index) => (
                <span key={cuisine}>
                  {t(`cuisine.${cuisine}`, {
                    defaultValue:
                      cuisine.charAt(0).toUpperCase() + cuisine.slice(1),
                  })}
                  {index < cuisines.length - 1 && ', '}
                </span>
              ))}
            </div>
          </VenueDetails>
        </Info>
      )}

      {url && (
        <Info>
          <Link href="/#" size={ICON_SIZE_16} />
          <div>
            <SectionTitle>{t('venue_feat.information.website')}</SectionTitle>
            <VenueLink target="_blank" href={url}>
              {url}
            </VenueLink>
          </div>
        </Info>
      )}

      {phone && (
        <Info>
          <Phone size={ICON_SIZE_16} />
          <div>
            <SectionTitle>{t('venue_feat.information.phone')}</SectionTitle>
            <VenueLink href={`tel:${phone}`}>{phone}</VenueLink>
          </div>
        </Info>
      )}

      {opening_hours && (
        <Info>
          <Time size={ICON_SIZE_16} />
          <div>
            <SectionTitle>{t('general.open')}</SectionTitle>
            <OpeningHours>
              {Object.entries(opening_hours).map(([day, hours]) => (
                <BusinessHours key={day}>
                  <span>{day}</span>
                  <span>{hours || t('general.closed')}</span>
                </BusinessHours>
              ))}
            </OpeningHours>
          </div>
        </Info>
      )}
    </InformationWrapper>
  );
}
