import { styled } from '@linaria/react';
import { useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { useGetUserQuery } from '@local/auth';
import {
  nextPanelActionAtom,
  panelStateAtom,
  Spinner,
  VenueCard,
} from '@local/components';
import { useDeviceType, useInView } from '@local/hooks';
import { selectedVenueAtom } from '@local/map-system';
import type { Image } from '@local/types';
import { buildUrl, translate } from '@local/utils';

import { useExplore } from '../../hooks/useExplore';
import { useSearchFilters } from '../../hooks/useSearchFilters';
import { useSearchResultsScroll } from '../../hooks/useSearchResultsScroll';
import { searchResultsPanelAtom } from '../../searchStore';

import { NotFound } from './NotFound';

const Wrapper = styled.div`
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l6);
  /* Note: Updated negative height pixels, prev - 175px was causing a weird gap, I think we should consider a slight refactor of result list in future: DPOR-903 */
  height: calc(
    100svh - var(--mobile-footer-height) - var(--search-hack-negative-height)
  );
  padding-bottom: var(--search-bottom-padding);
  &[data-no-scroll='true'] {
    overflow: hidden;
  }
`;

const HiddenDiv = styled.div`
  visibility: hidden;
`;

interface Props {
  onResetSearch: () => void;
}

export function SearchResultsContent({ onResetSearch }: Props) {
  const { isDesktop } = useDeviceType();
  const { ref, isInView } = useInView();
  const [, { language }] = useTranslation();
  const { data: user } = useGetUserQuery();
  const { shopSearchQuery, allSavedShopsSet, searchCount, availabilityToShow } =
    useExplore();
  const [searchParams] = useSearchParams();
  const { searchFilters } = useSearchFilters();
  const setPanelState = useSetAtom(panelStateAtom);
  const setNextPanelAction = useSetAtom(nextPanelActionAtom);
  const setSelectedVenue = useSetAtom(selectedVenueAtom);
  const { snap } = useAtomValue(searchResultsPanelAtom);
  const {
    data,
    isLoading,
    isPending,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = shopSearchQuery;
  const wrapperRef = useSearchResultsScroll();

  React.useEffect(() => {
    if (isInView) {
      void fetchNextPage();
    }
  }, [fetchNextPage, isInView]);

  if (isLoading || isPending) return <Spinner isFullPage />;
  if (!isLoading && (!data || searchCount === 0))
    return <NotFound onResetSearch={onResetSearch} />;
  const shouldNotScroll = snap !== 1;
  return (
    <Wrapper
      data-testid="Search Results Body"
      data-no-scroll={!isDesktop && shouldNotScroll}
      ref={wrapperRef}
    >
      {data?.shops.map((shop) => {
        // shop.images contains the search image as well so we need to exclude it
        const nonSearchImages = new Set<Image['thumbnail']>();
        for (const image of shop.images) {
          if (nonSearchImages.size >= 3) break;
          if (image.thumbnail !== shop.search_image) {
            nonSearchImages.add(image.thumbnail);
          }
        }
        return (
          <VenueCard
            key={shop._id}
            venue={{
              slug: shop.slug,
              title: translate(shop.name_translations, language),
              cuisines: shop.cuisines,
              imageUrls: [shop.search_image, ...nonSearchImages],
              description: shop.content_body_translations,
              hasTableCheckPay: shop.is_smartpay,
              lunchAvg: shop.budget_lunch_avg,
              dinnerAvg: shop.budget_dinner_avg,
              availability: shop.availability,
            }}
            href={buildUrl(`/${language}/${shop.slug}`, searchParams)}
          >
            <VenueCard.Header
              icon={
                allSavedShopsSet.has(shop._id)
                  ? 'bookmarkFilled'
                  : 'bookmarkAdd'
              }
              onIconClick={() => {
                setSelectedVenue({
                  venue: {
                    id: shop._id,
                    slug: shop.slug,
                    search_image: shop.search_image || DEFAULT_VENUE_IMAGE,
                    name_translations: shop.name_translations,
                    location_name_translations: shop.location_name_translations,
                    cuisines: shop.cuisines,
                    geocode: { lng: shop.geocode?.lon, lat: shop.geocode?.lat },
                    budget_lunch_avg: shop.budget_lunch_avg,
                    budget_dinner_avg: shop.budget_dinner_avg,
                    availability: shop.availability,
                  },
                  shouldShowMapVenueCard: false,
                });
                if (user) {
                  setPanelState('save_list');
                } else {
                  setPanelState('login');
                  setNextPanelAction(() => () => setPanelState('save_list'));
                }
              }}
            />
            <VenueCard.Info />
            <VenueCard.TopPicture />
            <VenueCard.TagLine />
            <VenueCard.Description />
            <VenueCard.Budget />
            {availabilityToShow === 'time' && (
              <VenueCard.TimeSlots
                query={{
                  date: searchFilters.date,
                  pax: searchFilters.num_people,
                  time: searchFilters.time,
                }}
              />
            )}
            {availabilityToShow === 'date' && (
              <VenueCard.DateTable
                query={{
                  pax: searchFilters.num_people,
                  time: searchFilters.time,
                  dateMin: searchFilters.date_min,
                }}
              />
            )}
          </VenueCard>
        );
      })}

      {isFetchingNextPage && (
        <div>
          <Spinner />
        </div>
      )}

      {hasNextPage && <HiddenDiv ref={ref} />}
    </Wrapper>
  );
}
