import { useQuery } from '@tanstack/react-query';

import { STALETIME_1_MINUTE, USER_QUERY_KEY } from '@local/constants';
import { UserResponseDataType } from '@local/types';

import { fetchListDetail } from '../listApi';

export const useFetchListDetailQuery = (
  id: string,
  user: UserResponseDataType | undefined,
  queryParams: URLSearchParams,
) =>
  useQuery({
    queryKey: [USER_QUERY_KEY, 'lists', id, queryParams.toString()],
    queryFn: () => fetchListDetail(id, queryParams),
    enabled: !!id && !!user,
    staleTime: STALETIME_1_MINUTE,
    retry: false,
  });
