import { styled } from '@linaria/react';
import { t } from 'i18next';
import { SwiperSlide } from 'swiper/react';

import { CardCarousel } from '@local/components';
import { useDeviceType } from '@local/hooks';
import { getDefaultDate, getDefaultTime } from '@local/utils';

import { useFetchStoryblokContent } from '../hooks/useFetchStoryblokContent';

import { DesktopContentWrapper } from './DesktopContentWrapper';
import { MobileContentWrapper } from './MobileContentWrapper';
import { StoryblokCard } from './StoryblokCard';

const DesktopWrapper = styled.div`
  max-width: var(--desktop-content-width);
  padding: 0 var(--spacing-l4);
`;
const MobileWrapper = styled.div`
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > img,
  & > div {
    width: 40svw;
  }
`;
interface Props {
  type: 'cuisines' | 'area';
}

export function StoryblokSection({ type }: Props) {
  const { isDesktop } = useDeviceType();
  const { data: storyblokContent } = useFetchStoryblokContent();

  if (!storyblokContent) {
    return null;
  }

  const { title, dataArray } =
    type === 'cuisines'
      ? {
          title: t('homepage.browse_cuisine'),
          dataArray:
            storyblokContent?.content.find(
              (item) => item.component === 'landing_page_cuisine_cards',
            )?.cards ?? [],
        }
      : {
          title: t('homepage.browse_area'),
          dataArray:
            storyblokContent?.content.find(
              (item) => item.component === 'landing_page_location_cards',
            )?.cards ?? [],
        };

  const today = new Date();
  const defaultParams = {
    date: getDefaultDate(today),
    time: getDefaultTime(today),
    num_people: '2',
  };

  return (
    <>
      {isDesktop ? (
        <DesktopContentWrapper>
          <DesktopWrapper>
            <CardCarousel id={type} title={title}>
              {dataArray.map((card) => (
                <SwiperSlide key={card._uid}>
                  <StoryblokCard card={card} defaultParams={defaultParams} />
                </SwiperSlide>
              ))}
            </CardCarousel>
          </DesktopWrapper>
        </DesktopContentWrapper>
      ) : (
        <MobileContentWrapper title={title}>
          <MobileWrapper>
            {dataArray.map((card) => (
              <StoryblokCard
                key={card._uid}
                card={card}
                defaultParams={defaultParams}
              />
            ))}
          </MobileWrapper>
        </MobileContentWrapper>
      )}
    </>
  );
}
