import { styled } from '@linaria/react';
import {
  Button,
  Checkbox,
  CheckboxLabel,
} from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';

import { Alert, Footer } from '@local/components';

import { useDeleteAccountQuery } from '../../hooks/useDeleteAccountQuery';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4);
`;
const StyledAlert = styled(Alert)`
  svg {
    min-width: 20px;
    min-height: 20px;
  }
`;
const StyledCheckbox = styled(Checkbox)`
  &:checked {
    background-color: black !important;
    border-color: black !important;
  }
`;

interface Props {
  onCancel: () => void;
}

export function DeleteAccountContent({ onCancel }: Props) {
  const [isAcknowledged, setIsAcknowledged] = React.useState(false);

  const { mutate: deleteAccount, isPending } = useDeleteAccountQuery();

  return (
    <Form
      id="delete-account-form"
      onSubmit={(e) => {
        e.preventDefault();
        deleteAccount();
      }}
    >
      <StyledAlert data-variant="warning" data-layout="icon" isShow>
        <p>{t('account.delete_msg_warning')}</p>
      </StyledAlert>
      <CheckboxLabel>
        <StyledCheckbox
          data-testid="Account Delete Checkbox"
          checked={isAcknowledged}
          onChange={(e) => {
            setIsAcknowledged(e.target.checked);
          }}
        />
        {t('general.msg_confirm')}
      </CheckboxLabel>
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-testid="Account Delete Submit Btn"
          data-variant="danger"
          form="delete-account-form"
          aria-busy={isPending}
          disabled={!isAcknowledged}
          type="submit"
        >
          {t('general.delete')}
        </Button>
      </Footer>
    </Form>
  );
}
