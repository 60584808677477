import './styles.css';

import * as Sentry from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { useSSR } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { ErrorBoundaryFallback } from '@local/components';
import { CONFIG } from '@local/configs';
import { i18n } from '@local/i18n';
import { logger, sentryService } from '@local/services';

import { App, Providers } from './app';

const instance = i18n.init();
const basename = CONFIG.BASE_URL || '/';

async function enableMocking() {
  logger.info('Enabling Mocking');
  const { createWorker } = await import('@local/mocks');
  const { worker } = createWorker();
  return worker.start({
    serviceWorker: { url: `${basename}mockServiceWorker.js` },
    quiet: false,
    onUnhandledRequest: (request, print) => {
      if (
        /\/|protomaps\.com|chrome-extension|protomaps\.github\.io|as1\.ftcdn\.net|cdn1\.tablecheck\.com|cdn0\.tablecheck\.com|@fs/.test(
          request.url,
        )
      ) {
        return;
      }

      print.warning();
    },
  });
}
const queryClient = new QueryClient(
  CONFIG.VITE_APP_ENVIRONMENT === 'testing'
    ? {
        defaultOptions: {
          queries: {
            gcTime: 0,
            retry: false,
            staleTime: 0,
          },
        },
      }
    : {},
);

// eslint-disable-next-line react-refresh/only-export-components
function ClientSideRunTimeApp() {
  useSSR(window.initialI18nStore, window.initialLanguage);
  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={({ resetError }) => (
          <ErrorBoundaryFallback resetError={resetError} />
        )}
      >
        <BrowserRouter>
          <Providers i18n={instance} queryClient={queryClient}>
            <App />
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </Providers>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
}
let rootElement = document.getElementById('root');
if (!rootElement) {
  rootElement = document.createElement('div');
  document.body.appendChild(rootElement);
}

void (async () => {
  // disable mocking here when its e2e(testing) to avoid duplication of mocking in e2e tests
  if (
    CONFIG.VITE_IS_MOCKING_ENABLED &&
    CONFIG.VITE_APP_ENVIRONMENT !== 'testing'
  ) {
    await enableMocking();
  }
  await sentryService.init();
  if (CONFIG.IS_SSR) {
    ReactDOM.hydrateRoot(rootElement, <ClientSideRunTimeApp />);
  } else {
    ReactDOM.createRoot(rootElement).render(<ClientSideRunTimeApp />);
  }
})();
