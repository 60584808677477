import { styled } from '@linaria/react';
import { t } from 'i18next';

import { TABLECHECK_LOGO } from '@local/assets';

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100vh;
  padding: 25vh var(--spacing-l3);
  text-align: center;
`;

const NotFoundMessage = styled.h2`
  color: grey;
`;

const Logo = styled.img`
  height: 30px;
  width: 100%;
`;

export function NotFound() {
  return (
    <NotFoundWrapper data-testid="Not Found Root">
      <Logo alt="TableCheck logo" src={TABLECHECK_LOGO} />
      <NotFoundMessage>{t('errors.page_not_found')}</NotFoundMessage>
      <span>{t('tablecheck.tablecheck_inc')}</span>
    </NotFoundWrapper>
  );
}
