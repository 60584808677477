import { useInfiniteQuery } from '@tanstack/react-query';

import { STALETIME_10_SECONDS, USER_QUERY_KEY } from '@local/constants';

import { getAllPayments } from '../accountApi';

export const useFetchAllPayments = (locale: string) =>
  useInfiniteQuery({
    queryKey: [USER_QUERY_KEY, locale, 'payments'],
    queryFn: ({ pageParam }) => getAllPayments(locale, pageParam),
    staleTime: STALETIME_10_SECONDS,
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.page >= lastPage.meta.total_pages) return;
      return lastPage.meta.page + 1;
    },
    initialPageParam: 1,
    select: (data) => {
      const allPayments = data.pages.flatMap((page) => page.payments);

      // TODO: Pagination from BE seems to be broken, so this is needed to prevent duplicate will be resolved in (DPOR-720)
      const uniquePayments = Array.from(
        new Map(allPayments.map((payment) => [payment.id, payment])).values(),
      );

      return {
        pages: data.pages,
        payments: uniquePayments,
      };
    },
  });
