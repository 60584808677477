import { ChevronRight } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import * as React from 'react';

import { DEFAULT_ICON_SIZE } from '@local/constants';
import { ListOption } from '@local/types';

const Wrapper = styled.div`
  display: grid;
  padding: 0 var(--spacing-l4);
  hr {
    border-top: 1px solid var(--lighter-grey-border);
  }
`;

const OptionsSelectCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: var(--spacing-l4) 0;

  &[data-variant='danger'] > span {
    color: red;
  }
`;

interface ListOptionsProps {
  options: ListOption[];
}

export function ListOptionsContent({ options }: ListOptionsProps) {
  return (
    <Wrapper>
      {options.map((option, index) => (
        <React.Fragment key={option.label}>
          <OptionsSelectCard
            onClick={option.onClick}
            data-variant={option.variant ?? 'default'}
            data-testid={option.testId}
          >
            <span>{option.label}</span>
            <ChevronRight size={DEFAULT_ICON_SIZE} />
          </OptionsSelectCard>
          {index < options.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </Wrapper>
  );
}
