import { atom, SetStateAction } from 'jotai';

import type { PanelStates, SearchFilterViewType } from '@local/types';

export const panelStateAtom = atom<PanelStates>(null);

export const nextPanelActionAtom = atom<
  | (() =>
      | ((searchFilterView: SearchFilterViewType) => void)
      | ((args: SetStateAction<PanelStates>) => void))
  | null
>(null);
