import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RESERVATIONS_EMPTY_VIEW_IMG } from '@local/assets';
import { AuthContent } from '@local/auth';
import {
  EmptyView,
  LoginPrompt,
  Panel,
  Spinner,
  panelStateAtom,
} from '@local/components';
import { AUTH_PANEL_STATES } from '@local/constants';
import { useDeviceType, useUniverse } from '@local/hooks';
import {
  ReservationCard,
  ScopeSelector,
  useReservations,
} from '@local/reservations';

import { DesktopContentWrapper } from '../Account/DesktopContentWrapper';

const MobileContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  &[data-is-desktop='true'] {
    margin-top: 0;
    top: var(--desktop-header-content-height);
  }
  &[data-is-desktop='false'] > h1 {
    padding: 0 var(--spacing-l4);
    padding-top: var(--spacing-l4);
  }
`;
const CardsContainer = styled.section`
  padding: var(--spacing-l5) var(--spacing-l4);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mobile-footer-height);
  > div:not(:last-child) {
    margin-bottom: var(--spacing-l4);
  }
`;

const HiddenDiv = styled.div`
  visibility: hidden;
`;

const Title = styled.h1`
  color: var(--black);
`;

export function ReservationsPage() {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const { universeName } = useUniverse();
  const [, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);

  const {
    isInitialPageLoading,
    isLoadingReservations,
    isFetchingMoreReservations,
    reservations,
    shops,
    scope,
    hasMorePagesToFetch,
    pageScrollEndRef,
    isLoggedIn,
  } = useReservations();

  if (isInitialPageLoading) return <Spinner isFullPage />;

  const reservationsContainer = (
    <>
      {isLoadingReservations && <Spinner isFullPage />}
      {reservations.length > 0 ? (
        <CardsContainer>
          {reservations.map((reservation) => {
            const venue = shops?.find(
              (shop) => shop.slug === reservation.shop_slug,
            );
            if (!venue) return;
            return (
              <ReservationCard
                key={reservation.slug}
                reservation={reservation}
                venue={venue}
              />
            );
          })}
          {isFetchingMoreReservations && <Spinner />}
        </CardsContainer>
      ) : (
        <EmptyView
          title={t(`reservations_feat.no_${scope}_bookings`)}
          description={t(`reservations_feat.book_now_${scope}`)}
          imgSrc={RESERVATIONS_EMPTY_VIEW_IMG}
          imgAlt={t('reservations_feat.img_alt.empty_bookings')}
          ctaAction={() => navigate(`/${language}/${universeName}/search`)}
          ctaButtonText={t('list_feat.explore_venues')}
        />
      )}
      {hasMorePagesToFetch && <HiddenDiv ref={pageScrollEndRef} />}
    </>
  );

  return (
    <>
      <div data-testid="Reservations Page Root">
        {isLoggedIn ? (
          <>
            {isDesktop ? (
              <DesktopContentWrapper
                header={
                  <>
                    <Title>{t('reservations_feat.title')}</Title>
                    <StickyHeader data-is-desktop>
                      <ScopeSelector scope={scope} />
                    </StickyHeader>
                  </>
                }
              >
                {reservationsContainer}
              </DesktopContentWrapper>
            ) : (
              <MobileContentWrapper>
                <StickyHeader data-is-desktop={false}>
                  <h1>{t('reservations_feat.title')}</h1>
                  <ScopeSelector scope={scope} />
                </StickyHeader>
                {reservationsContainer}
              </MobileContentWrapper>
            )}
          </>
        ) : (
          <LoginPrompt
            image={RESERVATIONS_EMPTY_VIEW_IMG}
            title={t('reservations_feat.login_to_use')}
            subtitle={t('reservations_feat.info_msg')}
            onClick={() => setPanelState('login')}
          />
        )}
      </div>

      <Panel
        isOpen={!!panelState && AUTH_PANEL_STATES.includes(panelState)}
        onClose={() => setPanelState(null)}
      >
        <AuthContent
          onSubmit={() => {
            setPanelState(null);
          }}
          subheaderContent={
            <Trans
              i18nKey="auth.login_feat_unlock"
              components={{ bold: <strong /> }}
            />
          }
        />
      </Panel>
    </>
  );
}
