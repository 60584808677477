import {
  AddLarge,
  Bookmark,
  BookmarkFilled,
  CheckmarkFilled,
  DirectionBearRight_02 as Direction,
  FaceAdd,
  LocationStar,
  LocationStarFilled,
  Phone,
  Share,
} from '@carbon/icons-react';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { AuthContent } from '@local/auth';
import {
  MemoContent,
  Panel,
  panelStateAtom,
  useEditUserMemo,
} from '@local/components';
import { CONFIG } from '@local/configs';
import { AUTH_PANEL_STATES, DEFAULT_ICON_SIZE } from '@local/constants';
import { useDeviceType } from '@local/hooks';
import { CreateNewListContent, SaveToListContent } from '@local/list';
import type { VenueFavorites, VenueInfo } from '@local/types';
import { buildMapUrl, translate } from '@local/utils';

import { ShareContent } from '../Share/ShareContent';

import {
  ActionBtn,
  ActionBtnLink,
  ReviewEmoji,
  Wrapper,
} from './ActionBtns.styles';
import { useActionBtns } from './useActionBtns';

const reviewEmojis = ['😠', '😔', '😐', '🙂', '😍'];

export function ActionBtns({
  venueData,
  favoritesData,
  isSaved,
  userMemo,
}: {
  venueData: VenueInfo;
  favoritesData?: VenueFavorites;
  isSaved: boolean;
  userMemo: string;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const [onLoginSubmit, setOnLoginSubmit] = React.useState<() => void>(() =>
    setPanelState(null),
  );
  const {
    phone,
    phone_natl,
    search_image,
    name_translations,
    address,
    cuisines,
    slug,
  } = venueData;
  const { onSubmitMemo, onDeleteMemo } = useEditUserMemo(slug);
  const title = translate(name_translations, language);

  const {
    handleSave,
    handleSubscribe,
    handleReview,
    handleVisited,
    handleShare,
    handleCreateList,
    handleSaveToList,
  } = useActionBtns(venueData, setOnLoginSubmit);
  const { isDesktop } = useDeviceType();

  return (
    <>
      <Wrapper>
        <ActionBtn
          data-variant={
            favoritesData?.is_subscribed ? 'secondary-brand' : 'tertiary'
          }
          onClick={() =>
            handleSubscribe({ is_subscribed: !favoritesData?.is_subscribed })
          }
          data-active={favoritesData?.is_subscribed}
        >
          {favoritesData?.is_subscribed ? (
            <>
              <CheckmarkFilled size={DEFAULT_ICON_SIZE} />
              <span>{t(`venue_feat.action_btns.subscribed`)}</span>
            </>
          ) : (
            <>
              <AddLarge size={DEFAULT_ICON_SIZE} />
              <span>{t(`venue_feat.action_btns.subscribe`)}</span>
            </>
          )}
        </ActionBtn>
        <ActionBtn
          data-variant={isSaved ? 'secondary-brand' : 'tertiary'}
          onClick={handleSave}
          data-active={isSaved}
          data-testid="Venue Save Btn"
        >
          {/* TODO: will be fixed with DPOR-435 */}
          {isSaved ? (
            <>
              <BookmarkFilled size={DEFAULT_ICON_SIZE} />
              <span>{t(`venue_feat.action_btns.saved`)}</span>
            </>
          ) : (
            <>
              <Bookmark size={DEFAULT_ICON_SIZE} />
              <span>{t(`venue_feat.action_btns.save`)}</span>
            </>
          )}
        </ActionBtn>

        {favoritesData?.has_visited && (
          <ActionBtn
            onClick={handleReview}
            data-active={
              favoritesData?.reviews && favoritesData?.reviews.length > 0
            }
          >
            {favoritesData?.reviews && favoritesData?.reviews.length > 0 ? (
              <ReviewEmoji>
                {reviewEmojis[favoritesData.reviews[0].rating - 1]}
              </ReviewEmoji>
            ) : (
              <FaceAdd size={DEFAULT_ICON_SIZE} />
            )}
            <span>{t(`venue_feat.action_btns.review`)}</span>
          </ActionBtn>
        )}

        {CONFIG.VITE_IS_STORYBOOK && (
          <ActionBtn
            onClick={handleVisited}
            data-active={favoritesData?.has_visited}
          >
            {favoritesData?.has_visited ? (
              <LocationStarFilled size={DEFAULT_ICON_SIZE} />
            ) : (
              <LocationStar size={DEFAULT_ICON_SIZE} />
            )}
            <span>{t(`venue_feat.action_btns.visited`)}</span>
          </ActionBtn>
        )}

        <ActionBtn
          data-variant="tertiary"
          onClick={() => void handleShare()}
          data-testid="Venue Share Btn"
        >
          <Share size={DEFAULT_ICON_SIZE} />
          <span>{t(`venue_feat.action_btns.share`)}</span>
        </ActionBtn>

        {!!venueData.geocode && (
          <ActionBtnLink
            href={buildMapUrl(
              venueData.geocode.lat,
              venueData.geocode.lon,
              venueData.google_place_ref,
            )}
          >
            <Direction size={DEFAULT_ICON_SIZE} />
            <span>{t(`venue_feat.information.directions`)}</span>
          </ActionBtnLink>
        )}

        {phone && (
          <ActionBtnLink href={`tel:${phone}`}>
            <Phone size={DEFAULT_ICON_SIZE} />
            <span>
              {isDesktop ? phone_natl : t(`venue_feat.action_btns.call`)}
            </span>
          </ActionBtnLink>
        )}
      </Wrapper>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'save_list' && (
          <SaveToListContent
            venueId={venueData._id}
            onCreateListClick={() => setPanelState('create_list')}
            onSubmit={handleSaveToList}
            onCancel={() => setPanelState(null)}
          />
        )}

        {panelState === 'share_venue' && (
          <ShareContent
            image={search_image ?? DEFAULT_VENUE_IMAGE}
            title={title}
            address={address}
            cuisines={cuisines}
          />
        )}

        {panelState === 'create_list' && (
          <CreateNewListContent
            venueId={venueData._id}
            imageUrl={venueData.banner_image}
            onSubmit={handleCreateList}
            onCancel={() => setPanelState(null)}
          />
        )}

        {AUTH_PANEL_STATES.includes(panelState) && (
          <AuthContent
            onSubmit={onLoginSubmit}
            subheaderContent={
              <Trans
                i18nKey="auth.login_feat_unlock"
                components={{ bold: <strong /> }}
              />
            }
          />
        )}

        {panelState === 'memo' && (
          <MemoContent
            userMemo={userMemo}
            onSubmit={onSubmitMemo}
            onDelete={onDeleteMemo}
          />
        )}
      </Panel>
    </>
  );
}
