import {
  CloseFilled as InvalidatedFieldIcon,
  CheckmarkFilled as ValidatedFieldIcon,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';

import { ICON_SIZE_16 } from '@local/constants';
import type { PasswordValidationKey } from '@local/types';

import { getPasswordValidationErrors } from './passwordSchema';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);
  padding: var(--spacing-l2) 0;
  font: var(--small);

  div {
    display: flex;
    align-items: center;
    gap: var(--spacing-l2);

    svg {
      flex-shrink: 0;
    }

    svg[data-validated] {
      color: var(--success);
    }
    svg:not([data-validated]) {
      color: var(--text-placeholder);

      &[data-state='dirty'] {
        color: var(--error);
      }
    }
  }
`;

const VALIDATION_FIELDS: Record<PasswordValidationKey, string> = {
  min_length: 'auth.error.min_length',
  one_upper_case: 'auth.error.one_upper_case',
  one_lower_case: 'auth.error.one_lower_case',
  one_number: 'auth.error.one_number',
  one_symbol: 'auth.error.one_symbol',
  password_contains_email_substring:
    'auth.error.password_contains_email_substring',
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  password: string;
  email: string;
  isDirty: boolean;
  isSubmitted?: boolean;
}

export function PasswordRequirements({
  isDirty,
  password,
  email,
  isSubmitted,
  ...props
}: Props) {
  const validationErrors = getPasswordValidationErrors(password, email);

  return (
    <Wrapper {...props}>
      {Object.entries(VALIDATION_FIELDS).map(([key, message]) => {
        const isValid = !validationErrors.includes(key);
        const icon = isValid ? (
          <ValidatedFieldIcon data-validated size={ICON_SIZE_16} />
        ) : (
          <InvalidatedFieldIcon
            size={ICON_SIZE_16}
            data-state={isDirty || isSubmitted ? 'dirty' : 'default'}
          />
        );
        const text = t(message);

        return (
          <div key={text}>
            {icon}
            {text}
          </div>
        );
      })}
    </Wrapper>
  );
}
