import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';

import { useVenueCardContext } from '../VenueCardContext';

const StyledParagraph = styled.p`
  overflow: hidden;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  gap: var(--spacing-l2);
  align-items: center;
  font: var(--body-2);
`;

export function Info() {
  const { venue } = useVenueCardContext();
  const [t] = useTranslation();
  const { city, region, cuisines } = venue;
  return (
    <StyledParagraph>
      {cuisines && cuisines.length > 0 && (
        <span>
          {cuisines.map((cuisine, index) => (
            <span key={cuisine}>
              {t(`cuisine.${cuisine}`, {
                defaultValue:
                  cuisine.charAt(0).toUpperCase() + cuisine.slice(1),
              })}
              {index < cuisines.length - 1 && ', '}
            </span>
          ))}
        </span>
      )}

      {city && region && (
        <>
          <span>•</span>
          {t('general.city_region', {
            city,
            region,
          })}
        </>
      )}
    </StyledParagraph>
  );
}
