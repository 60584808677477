import { useQuery } from '@tanstack/react-query';

import { STALETIME_1_DAY } from '@local/constants';
import { useUniverse } from '@local/hooks';
import { logger } from '@local/services';
import { type GeolocationResponseType } from '@local/types';

import { fetchGeolocation } from '../searchApi';

enum LocalStorageItemKey {
  Geolocation = 'geolocation',
}

type GeolocationWithExpiry = GeolocationResponseType & {
  expiry: number;
  country?: string;
};

const getBrowserGeolocation = (): Promise<
  Pick<GeolocationResponseType, 'latitude' | 'longitude'>
> =>
  new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported'));
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        resolve({ latitude, longitude });
      },
      (error) => reject(error),
    );
  });

export const useFetchGeolocation = () => {
  const { universeDefaultLocation, universeSlug } = useUniverse();
  return useQuery({
    queryKey: ['geolocation'],
    queryFn: async () => {
      const geolocationWithExpiryStr = localStorage.getItem(
        LocalStorageItemKey.Geolocation,
      );
      if (geolocationWithExpiryStr) {
        const geolocationWithExpiry = JSON.parse(
          geolocationWithExpiryStr,
        ) as GeolocationWithExpiry;
        if (Date.now() > geolocationWithExpiry.expiry) {
          localStorage.removeItem(LocalStorageItemKey.Geolocation);
        } else {
          const { expiry, ...geolocation } = geolocationWithExpiry;
          return geolocation;
        }
      }

      try {
        const browserGeolocation = await getBrowserGeolocation();
        const ipGeolocation = await fetchGeolocation();
        const geolocation = { ...ipGeolocation, ...browserGeolocation };
        localStorage.setItem(
          LocalStorageItemKey.Geolocation,
          JSON.stringify({
            ...geolocation,
            expiry: Date.now() + STALETIME_1_DAY,
          }),
        );
        return geolocation;
      } catch (e) {
        logger.error(e);
        const ipGeolocation = await fetchGeolocation();
        if (ipGeolocation) {
          localStorage.setItem(
            LocalStorageItemKey.Geolocation,
            JSON.stringify({
              ...ipGeolocation,
              expiry: Date.now() + STALETIME_1_DAY,
            }),
          );
        }
        return ipGeolocation;
      }
    },
    staleTime: Infinity,
    select: (data) => {
      // if the user is in an universe, use its geolocation
      const isUserLocatedInUniverse = data?.country === universeSlug;
      if (isUserLocatedInUniverse) {
        return { ...data, isUserLocatedInUniverse };
      }
      // otherwise, use the universe default location
      return {
        longitude: universeDefaultLocation.lon,
        latitude: universeDefaultLocation.lat,
        country: universeSlug,
        isUserLocatedInUniverse,
      };
    },
  });
};
