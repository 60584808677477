import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { CONFIG } from '@local/configs';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  height: 100svh;
  justify-content: center;
  margin: var(--spacing-l4);
  text-align: center;
`;

interface Props {
  resetError: () => void;
}

export function ErrorBoundaryFallback({ resetError }: Props) {
  const [, { language }] = useTranslation();

  const handleReset = () => {
    resetError();
    if (CONFIG.IS_CORDOVA) {
      window.cordova.InAppBrowser.open(`/${language}`, '_self');
    } else {
      window.location.href = `/${language}`;
    }
  };

  return (
    <Wrapper>
      <p>{t('errors.app_issue')}</p>
      <Button type="button" onClick={() => handleReset()}>
        {t('general.reset')}
      </Button>
    </Wrapper>
  );
}
