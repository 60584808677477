import { apiService } from '@local/services';
import { ReservationsResponse, Scope } from '@local/types';

export const fetchReservations = async (
  locale: string,
  scope: Scope,
  pageParam: number,
): Promise<ReservationsResponse> =>
  apiService
    .get(
      `v2/user/reservations?scope=${scope}&page=${pageParam}&locale=${locale}`,
    )
    .json();
