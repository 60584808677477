import { z } from 'zod';

import { createPasswordSchema } from '@local/auth';

export const createChangePasswordValidator = (email: string) =>
  z.object({
    current_password: z.string().trim().min(1),
    password: createPasswordSchema(email),
  });

export type ChangePasswordSchema = z.infer<
  ReturnType<typeof createChangePasswordValidator>
>;
