import { z } from 'zod';

import type { PasswordValidationKey } from '@local/types';

const DEFAULT_MIN_LENGTH = 8;
const EMAIL_SUBSTRING_LENGTH = 5;

export const createPasswordSchema = (email: string) =>
  z
    .string()
    .min(DEFAULT_MIN_LENGTH, { message: 'min_length' })
    .refine((val) => /[A-Z]/.test(val), {
      message: 'one_upper_case' as PasswordValidationKey,
    })
    .refine((val) => /[a-z]/.test(val), {
      message: 'one_lower_case' as PasswordValidationKey,
    })
    .refine((val) => /\d/.test(val), { message: 'one_number' })
    .refine((val) => /[^a-zA-Z\d]/.test(val), {
      message: 'one_symbol' as PasswordValidationKey,
    })
    .refine(
      (val) => {
        if (val.length < 1) return false;
        if (
          email.length < EMAIL_SUBSTRING_LENGTH ||
          val.length < EMAIL_SUBSTRING_LENGTH
        )
          return true;

        for (let i = 0; i + EMAIL_SUBSTRING_LENGTH <= email.length; i += 1) {
          const emailSubstring = email.substring(i, i + EMAIL_SUBSTRING_LENGTH);
          if (val.toLowerCase().includes(emailSubstring.toLowerCase()))
            return false;
        }

        return true;
      },
      {
        message: 'password_contains_email_substring' as PasswordValidationKey,
      },
    );

export const getPasswordValidationErrors = (
  password: string,
  email: string,
) => {
  const passwordSchema = createPasswordSchema(email);
  try {
    passwordSchema.parse(password);
    return [];
  } catch (error) {
    if (error instanceof z.ZodError) {
      return error.errors.map((e) => e.message);
    }
    return [];
  }
};
