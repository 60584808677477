import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as React from 'react';

interface Props {
  triggerContent: JSX.Element;
  dropdownContent: JSX.Element;
  align?: 'start' | 'center' | 'end';
  sideOffset?: number;
  alignOffset?: number;
}

export function Dropdown({
  triggerContent,
  dropdownContent,
  align,
  sideOffset,
  alignOffset,
}: Props): React.ReactElement {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>{triggerContent}</DropdownMenu.Trigger>
      <DropdownMenu.Content
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
      >
        {dropdownContent}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
