import { styled } from '@linaria/react';
import { t } from 'i18next';

import { TABLECHECK_LOGO } from '@local/assets';
import { SmartLinkButton } from '@local/components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100vh;
  padding: 25vh var(--spacing-l3);
  text-align: center;
  align-items: center;
`;

const Message = styled.h2`
  color: grey;
`;

const Logo = styled.img`
  height: 30px;
  width: 100%;
`;

const StyledSmartLinkButton = styled(SmartLinkButton)`
  background-color: var(--brand-primary);
  border: 1px solid var(--brand-primary);
  color: var(--brand-primary-text) !important;
  display: inline-block;
  padding: 9px 11px;
  &:hover {
    background-color: var(--brand-primary-hover);
  }
`;
export function AppDownload() {
  return (
    <Wrapper>
      <Logo alt="TableCheck logo" src={TABLECHECK_LOGO} />
      <Message>{t('general.download_app.android')}</Message>
      <StyledSmartLinkButton href="/">
        {t('general.download_app.android_redirection')}
      </StyledSmartLinkButton>
      <span>{t('tablecheck.tablecheck_inc')}</span>
    </Wrapper>
  );
}
