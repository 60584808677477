import { LocaleCode } from '@tablecheck/locales';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const createEditProfileValidation = (language: string) =>
  z
    .object({
      kanji_last_name: z.string().trim().optional(),
      kanji_first_name: z.string().trim().optional(),
      last_name: z.string().trim().min(1),
      first_name: z.string().trim().min(1),
      email: z.string().trim().email(),
      sex: z.number().int().min(0).max(2),
      phone: z.string().refine((value) => isValidPhoneNumber(value)),
    })
    .superRefine((data, ctx) => {
      if (language === LocaleCode.Japanese) {
        if (!data.kanji_first_name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['kanji_first_name'],
          });
        }
        if (!data.kanji_last_name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['kanji_last_name'],
          });
        }
      }
    });

export type EditProfileSchema = z.infer<
  ReturnType<typeof createEditProfileValidation>
>;
