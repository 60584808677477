import { useMutation } from '@tanstack/react-query';

import { logger, sentryService } from '@local/services';

import { changePassword } from '../accountApi';
import { ChangePasswordSchema } from '../components/ChangePassword/changePasswordSchema';

export const useChangePasswordQuery = () =>
  useMutation({
    mutationFn: (data: ChangePasswordSchema) => changePassword(data),
    onError: (error) => {
      logger.error(error);
      sentryService.captureException({ error });
      return error;
    },
  });
