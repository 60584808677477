import { styled } from '@linaria/react';
// TODO: remove Tablekit Emotion version of DatePicker
import * as DatePicker from '@tablecheck/tablekit-react-datepicker';
import { setDay } from 'date-fns';
import { Props as DateProps } from 'dayzed';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIG } from '@local/configs';
import { DateFormat, formatDisplayDate } from '@local/utils';

const DatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-secondary);
  &[data-center='true'] {
    place-content: center;
  }
`;

const DatePickerArrowWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l2);
  align-self: flex-end;
`;

const CalendarWrapper = styled.div`
  width: -webkit-fill-available;
  max-width: 100%;
  table {
    text-align: center;
    width: -webkit-fill-available;
  }
  button[data-state='selected'] {
    cursor: pointer;
    --day-text: var(--text);
  }
  > div > div:not(:last-child) {
    margin-bottom: var(--spacing-l6);
  }
`;
type CalendarProps = Omit<DateProps, 'children' | 'render'> & {
  shouldShowArrows?: boolean;
  shouldCenterHeader?: boolean;
  monthsToDisplay?: number;
};
export const Calendar = React.forwardRef<HTMLDivElement, CalendarProps>(
  (props, ref) => {
    const [, { language }] = useTranslation();
    if (CONFIG.IS_SSR) return;
    const {
      date,
      minDate,
      onDateSelected,
      shouldShowArrows,
      shouldCenterHeader,
      monthsToDisplay,
      ...restProps
    } = props;
    const today = new Date();
    return (
      <CalendarWrapper>
        <DatePicker.Root
          ref={ref}
          date={new Date(date ? date.getMonth() : today.getMonth())}
          selected={date}
          minDate={minDate}
          onDateSelected={onDateSelected}
          id="tc-datepicker"
          {...restProps}
          data-testid="tc-datepicker"
          monthsToDisplay={monthsToDisplay}
        >
          <DatePicker.Months
            renderMonthHeader={(calendar) => (
              <DatePickerHeader data-center={shouldCenterHeader}>
                <h5>
                  {formatDisplayDate(
                    calendar.firstDayOfMonth,
                    DateFormat.YearMonth,
                    language,
                  )}
                </h5>
                {shouldShowArrows && (
                  <DatePickerArrowWrapper>
                    <DatePicker.PreviousMonth />
                    <DatePicker.NextMonth />
                  </DatePickerArrowWrapper>
                )}
              </DatePickerHeader>
            )}
            renderWeekdayHeader={(dayOfWeek) =>
              formatDisplayDate(
                setDay(new Date(), dayOfWeek),
                DateFormat.Day,
                language,
              )
            }
          />
        </DatePicker.Root>
      </CalendarWrapper>
    );
  },
);

Calendar.displayName = 'Calendar';
