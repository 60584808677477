import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import type { SocialAuth } from '@local/types';

const Wrapper = styled.div`
  display: flex;
  padding: var(--spacing-l4) 0;
  border-bottom: 1px solid var(--border-transparent);
  justify-content: space-between;

  & > div {
    display: flex;
    gap: var(--spacing-l2);
    align-items: center;
  }
`;

interface Props {
  provider: SocialAuth['provider'];
  icon: JSX.Element;
  isConnected: boolean;
  connectButtonProps?: React.ComponentProps<typeof Button>;
  disconnectButtonProps?: React.ComponentProps<typeof Button>;
}

export function SocialAccountItem({
  provider,
  icon,
  isConnected,
  connectButtonProps,
  disconnectButtonProps,
}: Props) {
  return (
    <Wrapper data-testid="Social Account Item">
      <div>
        {icon}
        {t(`account.providers.${provider}`)}
      </div>
      {isConnected ? (
        <Button
          data-testid={`${provider} Disconnect Btn`}
          data-variant="danger"
          data-size="small"
          {...disconnectButtonProps}
        >
          {t('general.disconnect')}
        </Button>
      ) : (
        <Button
          data-testid={`${provider} Connect Btn`}
          data-variant="secondary"
          data-size="small"
          {...connectButtonProps}
        >
          {t('general.connect')}
        </Button>
      )}
    </Wrapper>
  );
}
