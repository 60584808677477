import { styled } from '@linaria/react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { CONFIG } from '@local/configs';
import { isExternalTCUrl, openUrl } from '@local/utils';

const commonLinkStyles = `
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`;

const StyledAnchor = styled.a`
  ${commonLinkStyles}
`;

const StyledReactRouterLink = styled(Link)`
  ${commonLinkStyles}
`;

export interface SmartLinkProps extends React.ComponentPropsWithoutRef<'a'> {
  href: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

// React Router Dom's Link component seems to be able to handle external URLs based on the source code: https://github.com/remix-run/react-router/blob/195f00c173bb6ff7bc15a20f08b39ee19b8a8d13/packages/react-router-dom/index.tsx#L406-L473
// However, the latest information I could find seems to advise not using it for external URLs that open new tabs/windows: https://github.com/remix-run/react-router/issues/10839#issuecomment-1705697845
export const SmartLink = React.forwardRef<HTMLAnchorElement, SmartLinkProps>(
  ({ href, children, onClick, ...props }, ref) => {
    const isExternal = href.startsWith('http');

    if (isExternal) {
      return (
        <StyledAnchor
          ref={ref}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            if (CONFIG.IS_CORDOVA && isExternalTCUrl(href)) {
              e.preventDefault();
              openUrl(href, '_blank', 'location=no');
            }
            return onClick;
          }}
          {...props}
        >
          {children}
        </StyledAnchor>
      );
    }

    return (
      <StyledReactRouterLink ref={ref} to={href} onClick={onClick} {...props}>
        {children}
      </StyledReactRouterLink>
    );
  },
);
