import { t } from 'i18next';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { panelStateAtom, useToast } from '@local/components';
import { CONFIG } from '@local/configs';
import { useDeviceType } from '@local/hooks';
import { logger } from '@local/services';
import type { Reservation, ReservationVenueInfo } from '@local/types';
import { buildBookingFormUrl, shareUrl, translate } from '@local/utils';

// TODO: DPOR-666 merge this hook with libs/venue/src/components/ActionBtns/useActionBtns.tsx
export const useActionBtns = (
  reservation: Reservation,
  venueData: ReservationVenueInfo,
) => {
  const { isDesktop } = useDeviceType();
  const [, { language }] = useTranslation();
  const { name_translations, phone } = venueData;
  const setPanelState = useSetAtom(panelStateAtom);

  const { errorToastContent, setIsToastOpen, setToastContent } = useToast();

  return {
    getBookingFormUrl: () => {
      const path = reservation.booking_page_mode === 'v1' ? '/shops/' : '/';
      const url = buildBookingFormUrl(
        `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}${path}${venueData.slug}/reserve`,
      );
      return url;
    },

    getReservationUrl: () => {
      const path =
        reservation.booking_page_mode === 'v1' ? 'reservations' : 'reservation';
      const url = buildBookingFormUrl(
        `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${path}/${reservation.slug}`,
      );
      return url;
    },

    handleShare: async () => {
      if (isDesktop) {
        setPanelState('share_venue');
      } else {
        await shareUrl({
          title: t('venue_feat.directions.venue_on_tablecheck', {
            venue: translate(name_translations, language),
          }),
          url: CONFIG.IS_CORDOVA
            ? `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${venueData.slug}`
            : `${window.location.origin}/${language}/${venueData.slug}`,
        }).catch((error) => {
          logger.error('Error sharing link:', error);
          setToastContent(
            errorToastContent(t('toast.unsuccessful_venue_share')),
          );
          setIsToastOpen(true);
        });
      }
    },

    handleCall: () => `tel:${phone}`,
  };
};
