import { useQuery } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { UserResponseDataType } from '@local/types';

import { fetchUserMemoVenue } from '../userApi';

export const useFetchUserMemoQuery = (
  venueSlug: string,
  user: UserResponseDataType | undefined,
) =>
  useQuery({
    queryKey: [USER_QUERY_KEY, 'memo', venueSlug],
    queryFn: () => fetchUserMemoVenue(venueSlug),
    enabled: !!venueSlug && !!user,
    staleTime: Infinity,
    select: (data) => ({ memo: data.memo }),
  });
