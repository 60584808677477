import * as Dialog from '@radix-ui/react-dialog';
import { t } from 'i18next';
import { useAtomValue } from 'jotai';

import { useDeviceType } from '@local/hooks';
import { PanelStates } from '@local/types';

import { PanelDesktop } from './PanelDesktop';
import { PanelMobile } from './PanelMobile';
import { panelStateAtom } from './panelStore';

type PanelProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  isLarge?: boolean;
  title?: string | null;
  containerId?: string;
} & Dialog.DialogProps;

const stateToTranslation: Record<Exclude<PanelStates, null>, string> = {
  login: 'auth.login',
  reset_password: 'auth.reset_password',
  reset_password_confirmation: 'auth.reset_password',
  sign_up: 'auth.sign_up',
  change_pwd: 'account.change_password',
  delete_membership: 'account.membership.remove',
  delete_credit_card: 'account.credit_cards.remove',
  delete_account: 'account.delete_account',
  delete_subscription: 'account.communications.subscriptions.unsubscribe',
  terms_conditions: 'account.terms_conditions.title',
  save_list: 'list_feat.save_to_list',
  create_list: 'list_feat.create_new_list',
  update_list: 'list_feat.update_list',
  update_list_desc: 'list_feat.update_list',
  list_options: 'list_feat.manage_list',
  delete_list: 'list_feat.delete_list',
  memo: 'venue_feat.memo.add_memo',
  share_venue: 'venue_feat.action_btns.share',
  view_payment: 'account.view_payment',
  disconnect_social: 'account.disconnect_account',
};

export function Panel({
  isOpen,
  title,
  children,
  onClose,
  containerId,
  isLarge,
}: PanelProps): JSX.Element {
  const { isDesktop } = useDeviceType();
  const panelState = useAtomValue(panelStateAtom);
  const dialogTitle =
    title || (panelState && t(stateToTranslation[panelState]));

  return (
    <>
      {isDesktop ? (
        <PanelDesktop
          isLarge={isLarge}
          isOpen={isOpen}
          onClose={onClose}
          title={dialogTitle}
        >
          {children}
        </PanelDesktop>
      ) : (
        <PanelMobile
          isOpen={isOpen}
          onClose={onClose}
          containerId={containerId}
          title={dialogTitle}
        >
          {children}
        </PanelMobile>
      )}
    </>
  );
}
