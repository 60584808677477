import { styled } from '@linaria/react';
import { Badge } from '@tablecheck/tablekit-react-css';

export const Location = styled.div``;

export const Details = styled.div`
  display: flex;
  gap: var(--spacing-l2);
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  color: var(--black);
  a {
    color: var(--text);
    text-decoration: none;
  }
`;

export const AveragePrice = styled.div`
  display: flex;
  & svg {
    align-self: center;
  }

  & span:first-child {
    margin: 0 var(--spacing-l1);
    align-content: center !important;
  }
`;

export const TableCheckPay = styled(Badge)`
  span {
    font: var(--body-2);
  }
`;

export const TableCheckPayLogo = styled.img`
  align-self: center;
  width: 12px;
  height: 12px;
`;

export const VenueDetails = styled.div`
  display: grid;
  grid-template-rows: none;
  gap: var(--spacing-l1);
  h1 {
    overflow-wrap: anywhere;
  }
`;

export const OverviewSection = styled.section`
  display: grid;
  gap: var(--spacing-l4);
`;

export const StyledPrice = styled.div`
  & span {
    color: var(--text);
    font: var(--h5);
  }
  color: var(--text-subtle);
`;

export const Title = styled.h1`
  color: var(--black);
`;
