import { useQuery } from '@tanstack/react-query';

import { SHOP_SEARCH_QUERY_KEY, STALETIME_5_MINUTES } from '@local/constants';
import { useUniverse } from '@local/hooks';

import { fetchShopBySearchParams } from '../searchApi';

// a constant value ensures the same shops are returned if the location remains unchanged
const RANDOMIZE_GEO_VALUE = '1234';
const VENUES_TO_DISPLAY_PER_QUERY = '50';

export const useRandomShopSearchQuery = (
  queryParams: URLSearchParams,
  isEnabled = true,
) => {
  const { universeId } = useUniverse();
  const params = new URLSearchParams({
    ...Object.fromEntries(queryParams.entries()),
    per_page: VENUES_TO_DISPLAY_PER_QUERY,
    randomize_geo: RANDOMIZE_GEO_VALUE,
  });

  return useQuery({
    queryKey: [
      SHOP_SEARCH_QUERY_KEY,
      queryParams.toString(),
      universeId,
      'random',
    ],
    queryFn: async () => fetchShopBySearchParams(params, universeId),
    staleTime: STALETIME_5_MINUTES,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });
};
