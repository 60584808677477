import { useAtom, useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  DeleteCreditCardContent,
  TermsConditionsContent,
} from '@local/account';
import { useGetUserQuery } from '@local/auth';
import {
  Panel,
  panelStateAtom,
  Spinner,
  toastContentAtom,
  useToast,
} from '@local/components';
import { useDeviceType, useUniverse } from '@local/hooks';
import type { PaymentProfile } from '@local/types';

import { DesktopContentWrapper } from '../DesktopContentWrapper';
import { MobileContentWrapper } from '../MobileContentWrapper';

import { CreditCardsContent } from './CreditCardsContent';

export function CreditCardsRoot() {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const { universeName } = useUniverse();
  const [t, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const [selectedCreditCard, setSelectedCreditCard] =
    React.useState<PaymentProfile | null>(null);

  const { successToastContent, setIsToastOpen } = useToast();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();

  const handleOnSuccess = (content: string) => {
    setToastContent(successToastContent(content));
    setIsToastOpen(true);
    setPanelState(null);
  };

  React.useEffect(() => {
    if (!user && !isUserLoading) {
      navigate(`/${language}/${universeName}`);
    }
  }, [user, isUserLoading, navigate, language, universeName]);

  if (isUserLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <div data-testid="Account Credit Cards Page Root">
        {isDesktop ? (
          <DesktopContentWrapper header={<h1>{t('account.saved_cards')}</h1>}>
            <CreditCardsContent setSelectedCreditCard={setSelectedCreditCard} />
          </DesktopContentWrapper>
        ) : (
          <MobileContentWrapper title={t('account.saved_cards')}>
            <CreditCardsContent setSelectedCreditCard={setSelectedCreditCard} />
          </MobileContentWrapper>
        )}
      </div>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'terms_conditions' && (
          <TermsConditionsContent onClose={() => setPanelState(null)} />
        )}
        {panelState === 'delete_credit_card' && selectedCreditCard && (
          <DeleteCreditCardContent
            selectedCreditCard={selectedCreditCard}
            onSuccess={() => handleOnSuccess(t('toast.deleted_credit_card'))}
            onCancel={() => setPanelState(null)}
          />
        )}
      </Panel>
    </>
  );
}
