import { WarningAltFilled } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import {
  AlertCore as Alert,
  AlertDescription,
  AlertIconWrapper,
  Anchor,
  Button,
  Input,
} from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useSetAtom } from 'jotai';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { panelStateAtom } from '@local/components';
import { CONFIG } from '@local/configs';
import { DEFAULT_ICON_SIZE } from '@local/constants';

import { useLoginQuery } from '../../hooks/useLoginQuery';
import { useSocialAuthProviders } from '../../hooks/useSocialAuthProviders';
import { PasswordField } from '../PasswordField/PasswordField';

const Form = styled.form`
  display: grid;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4) var(--spacing-l4) var(--spacing-l4);
  span {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l2);
    font: var(--body-2);
  }
`;

const StyledAnchor = styled(Anchor)`
  color: var(--primary);
  cursor: pointer !important;
  text-decoration: none;
  width: fit-content;
`;

const StyledParagraph = styled.p`
  color: var(--text-alt);
  text-align: center;
`;

const StyledAlert = styled(Alert)`
  width: 100%;
`;

const OAuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);
  img {
    width: ${DEFAULT_ICON_SIZE}px;
    height: ${DEFAULT_ICON_SIZE}px;
  }
`;

interface Props {
  onSubmit: () => void;
  subheaderContent?: JSX.Element;
}

interface FormValues {
  email: string;
  password: string;
}

export function LoginContent({ onSubmit, subheaderContent }: Props) {
  const setPanelState = useSetAtom(panelStateAtom);
  const [shouldShowError, setShouldShowError] = React.useState(false);
  const form = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { mutate: login, isPending } = useLoginQuery();
  const { iconMap, providers } = useSocialAuthProviders();
  const [socialLoginLoading, setSocialLoginLoading] = React.useState<
    Record<string, boolean>
  >({});

  const handleSubmit = form.handleSubmit((fields) => {
    login(fields, {
      onSuccess: () => onSubmit(),
      onError: () => {
        setShouldShowError(true);
      },
    });
  });

  return (
    <Form
      data-testid="Login Modal"
      autoComplete="off"
      onSubmit={(e) => void handleSubmit(e)}
    >
      {subheaderContent && <p>{subheaderContent}</p>}
      {shouldShowError && (
        <StyledAlert data-variant="error" data-layout="icon">
          <AlertIconWrapper>
            <WarningAltFilled size={DEFAULT_ICON_SIZE} />
          </AlertIconWrapper>
          <AlertDescription>{t('auth.error.invalid_login')}</AlertDescription>
        </StyledAlert>
      )}
      <Input
        {...form.register('email', {
          required: true,
        })}
        type="email"
        aria-label="email"
        data-stretch
        placeholder={t('auth.email')}
        data-testid="Login Email Input"
      />
      <Controller
        name="password"
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <span>
            <PasswordField
              value={value}
              onChangeValue={onChange}
              name="password"
              aria-label="password"
              data-stretch
              placeholder={t('auth.password')}
              data-testid="Login Password Input"
            />
            <StyledAnchor
              role="button"
              onClick={() => setPanelState('reset_password')}
              data-testid="Forgot Password Btn"
            >
              {t('auth.forgot_password')}
            </StyledAnchor>
          </span>
        )}
      />
      <Button
        data-variant="primary"
        type="submit"
        data-testid="Login Submit Btn"
        aria-busy={isPending}
      >
        {t('auth.login')}
      </Button>
      {/* TODO: Remove social login feature flag in DPOR-711 */}
      {CONFIG.VITE_SOCIAL_LOGIN && providers.length > 0 && (
        <OAuthWrapper>
          {providers.map((provider) => (
            <Button
              key={provider.id}
              data-variant="tertiary"
              data-testid={`${provider.id} Login Btn`}
              onClick={() => {
                if (socialLoginLoading[provider.id] || isPending) {
                  return;
                }
                setSocialLoginLoading((prev) => ({
                  ...prev,
                  [provider.id]: true,
                }));
                try {
                  provider.onClick();
                } catch {
                  setSocialLoginLoading((prev) => ({
                    ...prev,
                    [provider.id]: false,
                  }));
                }
              }}
              aria-busy={socialLoginLoading[provider.id]}
            >
              {iconMap[provider.id]}
              {t(`auth.continue_with_${provider.id}`)}
            </Button>
          ))}
        </OAuthWrapper>
      )}
      <StyledParagraph>
        {t('auth.no_account')}{' '}
        <StyledAnchor
          role="button"
          onClick={() => setPanelState('sign_up')}
          data-testid="Sign Up Btn"
        >
          {t('auth.sign_up')}
        </StyledAnchor>
      </StyledParagraph>
    </Form>
  );
}
