import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';

import { useGetUserQuery } from '@local/auth';

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: var(--spacing-l8) 1fr;
  gap: var(--spacing-l4);
  padding: var(--spacing-l4);
  border: 1px var(--border-transparent);
  border-radius: var(--border-radius-large);
  box-shadow: 0px 0px 6px 0px var(--border-transparent);

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--spacing-l8);
    width: var(--spacing-l8);
    border-radius: 50%;
    background-color: var(--info-surface);
    font-size: 24px;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: start;
    overflow: hidden;

    & > h5,
    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  > * {
    width: 100%;
  }
  p {
    color: var(--text-subtle);
    font: var(--body-2);
  }
`;

export function Avatar() {
  const [, { language }] = useTranslation();
  const { data: user } = useGetUserQuery();

  const fullName =
    language === 'ja'
      ? `${user?.customer_user.last_name} ${user?.customer_user.first_name}`
      : `${user?.customer_user.first_name} ${user?.customer_user.last_name}`;
  return (
    <Wrapper data-testid="Logged In Profile Section">
      <div>🐤</div>
      <Details>
        <h5>{fullName}</h5>
        <p>{user?.customer_user.email}</p>
        {/* TODO: add more user related data such as "joined in", no of reservations and reviews */}
      </Details>
    </Wrapper>
  );
}
