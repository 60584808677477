import { TranslationsArray } from '@tablecheck/i18n';
import * as React from 'react';

interface VenueCardContextType {
  venue: Venue;
}

interface Venue {
  slug?: string;
  title?: string;
  city?: string;
  region?: string;
  cuisines?: string[];
  tagline?: string;
  lunchAvg?: string;
  dinnerAvg?: string;
  currency?: string;
  hasTableCheckPay?: boolean;
  imageUrls?: (string | undefined)[];
  description?: TranslationsArray;
  availability?: string[];
}

const VenueCardContext = React.createContext<VenueCardContextType | null>(null);

const useVenueCardContext = () => {
  const context = React.useContext(VenueCardContext);
  if (!context) {
    throw new Error(
      'VenueCard compound components cannot be rendered outside the VenueCard component',
    );
  }
  return context;
};

export { Venue, VenueCardContext, useVenueCardContext };
