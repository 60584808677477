import { styled } from '@linaria/react';
import { Button, Input, TextArea } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useAtomValue } from 'jotai';
import { useForm } from 'react-hook-form';

import { Footer, panelStateAtom } from '@local/components';
import {
  INPUT_MAX_CHAR_LENGTH,
  LIST_NAME_MAX_CHAR_LENGTH,
} from '@local/constants';
import type { UpdateListData } from '@local/types';

const Form = styled.form`
  display: grid;
  gap: var(--spacing-l4);
  font: var(--body-1);
  padding: 0 var(--spacing-l4);
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);

  & > label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font: var(--small);
    }
  }

  & div > textarea {
    resize: none;
    min-height: 100px;
  }
`;

interface UpdateListContentProps {
  name: string;
  description: string;
  onSubmit: (updatedData: UpdateListData) => void;
  onCancel: () => void;
}

export function UpdateListContent({
  name,
  description,
  onSubmit,
  onCancel,
}: UpdateListContentProps) {
  const form = useForm<UpdateListData>({
    defaultValues: {
      name,
      description,
    },
  });
  const panelState = useAtomValue(panelStateAtom);
  const shouldShowNameInput = panelState !== 'update_list_desc';

  const handleSubmit = form.handleSubmit((fields) => {
    onSubmit(fields);
  });

  return (
    <Form
      id="update-list-form"
      onSubmit={(e) => {
        e.preventDefault();
        void handleSubmit();
      }}
    >
      {shouldShowNameInput && (
        <ItemWrapper>
          <label htmlFor="name">
            {t('list_feat.list_name')}
            <span>
              {form.watch('name').length} / {LIST_NAME_MAX_CHAR_LENGTH}
            </span>
          </label>
          <Input
            {...form.register('name')}
            id="name"
            aria-label="List name"
            maxLength={LIST_NAME_MAX_CHAR_LENGTH}
            data-stretch
            data-testid="List Name Input"
          />
        </ItemWrapper>
      )}
      <ItemWrapper>
        <label htmlFor="description">
          {t('list_feat.description')}
          <span>
            {form.watch('description').length} / {INPUT_MAX_CHAR_LENGTH}
          </span>
        </label>
        <TextArea
          {...form.register('description')}
          id="description"
          aria-label="List description"
          maxLength={INPUT_MAX_CHAR_LENGTH}
          data-stretch
          data-testid="List Description Input"
        />
      </ItemWrapper>
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-variant="primary"
          form="update-list-form"
          type="submit"
          data-testid="Edit List Update Button"
          disabled={
            !form.formState.isDirty || form.watch('name').trim().length === 0
          }
        >
          {t('general.update')}
        </Button>
      </Footer>
    </Form>
  );
}
