import { ChevronLeft, Edit } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { IconButton } from '@tablecheck/tablekit-react-css';
import { useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import { panelStateAtom, VenueCard, Spinner } from '@local/components';
import {
  DEFAULT_ASIA_BOUNDS,
  DEFAULT_ASIA_CENTER,
  DEFAULT_ICON_SIZE,
  LISTS_DEFAULT_ZOOM,
} from '@local/constants';
import { useTypedRouteParams } from '@local/hooks';
import { listFiltersAtom, useListView } from '@local/list';
import { selectedVenueAtom, useMapService, Map } from '@local/map-system';
import { buildUrl, translate } from '@local/utils';

import { ListViewContent } from './ListViewContent';

const DesktopPageWrapper = styled.div`
  display: grid;
  grid-template-columns: 390px 1fr;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-l2);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  & > button {
    border: none;
  }
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  height: calc(100svh - var(--desktop-header-full-height));
`;

const MapSection = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MapVenueCard = styled(VenueCard)`
  position: absolute;
  left: 50%;
  bottom: 30px;
  width: var(--map-card-width);
  transform: translateX(-50%);
  border-radius: var(--border-radius-large);
  padding: var(--spacing-l2);
  box-shadow: var(--elevation-large);
`;
interface ListViewHeaderProps {
  onRightIconClick: () => void;
}

function ListViewHeader({ onRightIconClick }: ListViewHeaderProps) {
  const navigate = useNavigate();
  return (
    <Header>
      <IconButton data-variant="ghost" onClick={() => navigate(-1)}>
        <ChevronLeft size={DEFAULT_ICON_SIZE} />
      </IconButton>
      <IconButton data-variant="ghost" onClick={() => onRightIconClick()}>
        <Edit size={DEFAULT_ICON_SIZE} />
      </IconButton>
    </Header>
  );
}

interface ListRouteParams {
  listId: string;
}

export function ListViewDesktop() {
  const { listId } = useTypedRouteParams<ListRouteParams>();
  const setPanelState = useSetAtom(panelStateAtom);
  const listFilters = useAtomValue(listFiltersAtom);
  const selectedVenue = useAtomValue(selectedVenueAtom);
  const [, { language }] = useTranslation();
  const [searchParams] = useSearchParams();
  const { availabilityToShow, mapVenues } = useListView();
  const { venue, shouldShowMapVenueCard } = selectedVenue;
  const { data: user } = useGetUserQuery();

  const { isLoading: isMapLoading } = useMapService({
    venues: mapVenues,
    containerId: listId,
    language,
    center: DEFAULT_ASIA_CENTER as [number, number],
    zoom: LISTS_DEFAULT_ZOOM,
    cameraTransition: 'jumpTo',
    geolocateControl: {
      hideOnSelect: false,
    },
    bounds: DEFAULT_ASIA_BOUNDS,
  });

  return (
    <DesktopPageWrapper data-testid="List View Page Root">
      <ContentSection data-testid="List View Content">
        {user && (
          <ListViewHeader
            onRightIconClick={() => setPanelState('list_options')}
          />
        )}
        <ListViewContent />
      </ContentSection>

      <MapSection data-testid="List View Page Map">
        {isMapLoading && <StyledSpinner isFullPage />}
        <Map
          id={listId}
          style={
            { '--geolocate-control-bottom': '15px' } as React.CSSProperties
          }
          data-is-loading={isMapLoading}
        >
          {venue && shouldShowMapVenueCard && (
            <MapVenueCard
              data-testid="Map Venue Card"
              venue={{
                slug: venue.slug,
                title: translate(venue.name_translations, language),
                cuisines: venue.cuisines,
                lunchAvg: venue.budget_lunch_avg,
                dinnerAvg: venue.budget_dinner_avg,
                imageUrls: [venue.search_image],
                availability: venue.availability,
              }}
              href={buildUrl(`/${language}/${venue.slug}`, searchParams)}
            >
              <VenueCard.Header
                icon="bookmarkFilled"
                onIconClick={() => setPanelState(user ? 'save_list' : 'login')}
                buttonTestId="Map Venue Card Bookmark Btn"
              />
              <VenueCard.Info />
              <VenueCard.Budget />
              <VenueCard.SidePicture />
              {availabilityToShow() === 'date' && (
                <VenueCard.DateTable
                  query={{
                    pax: listFilters.num_people,
                    time: listFilters.time,
                    dateMin: listFilters.date_min,
                  }}
                />
              )}
              {availabilityToShow() === 'time' && (
                <VenueCard.TimeSlots
                  query={{
                    date: listFilters.date,
                    pax: listFilters.num_people,
                    time: listFilters.time,
                  }}
                />
              )}
            </MapVenueCard>
          )}
        </Map>
      </MapSection>
    </DesktopPageWrapper>
  );
}
