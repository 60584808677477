import { useQuery } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { cookieService } from '@local/services';

import { getUser } from '../authApi';

export const useGetUserQuery = () => {
  const authToken = cookieService.getAuthToken();
  return useQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: () => getUser(),
    staleTime: Infinity,
    enabled: !!authToken,
  });
};
