import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { fetchMenuItemsQuery } from '../venueApi';

export const useFetchMenuItemsQuery = (venueId: string) => {
  const [, { language }] = useTranslation();

  return useQuery({
    queryKey: ['menuItems', venueId, language],
    queryFn: () => fetchMenuItemsQuery(venueId, language),
    enabled: !!venueId,
    staleTime: Infinity,
  });
};
