import {
  DirectionBearRight_02 as Direction,
  Phone,
  Share,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import {
  Panel,
  panelStateAtom,
  SmartLink,
  SmartLinkButton,
} from '@local/components';
import { DEFAULT_ICON_SIZE } from '@local/constants';
import { useRouting } from '@local/hooks';
import type { Reservation, ReservationVenueInfo } from '@local/types';
import { buildMapUrl, translate } from '@local/utils';
import { ShareContent } from '@local/venue';

import { useActionBtns } from './useActionBtns';

const ActionBtnsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l3);
  height: 40px;
`;

const buttonCommonStyles = `
  flex-grow: 1;
  padding: 9px 11px;
`;

const CTAButtonLink = styled(SmartLinkButton)`
  ${buttonCommonStyles}
  background-color: var(--surface-secondary);
  color: var(--surface-secondary-text) !important;
  &:hover {
    background-color: var(--surface-secondary-hover);
    border-color: var(--surface-secondary-hover);
  }
`;

const SecondaryButtonLink = styled(SmartLinkButton)`
  ${buttonCommonStyles}
  border: 1px solid var(--border-transparent);
`;

const ActionBtn = styled.button`
  padding: 10px;
  border: 1px solid var(--lighter-grey-border);
  border-radius: var(--border-radius-small);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ActionBtnLink = styled(SmartLink)`
  padding: 10px;
  border: 1px solid var(--lighter-grey-border);
  border-radius: var(--border-radius-small);
  display: flex;
`;

export function ActionBtns({
  reservation,
  venue,
}: {
  reservation: Reservation;
  venue: ReservationVenueInfo;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const { isUpcomingReservationsPage } = useRouting();
  const { getBookingFormUrl, handleShare, handleCall, getReservationUrl } =
    useActionBtns(reservation, venue);
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const { name_translations, search_image, address_translations, cuisines } =
    venue;

  return (
    <>
      <ActionBtnsWrapper data-testid="Reservation Actions Btns">
        {isUpcomingReservationsPage ? (
          <>
            <CTAButtonLink
              data-testid="Manage Reservation Btn"
              href={getReservationUrl()}
            >
              {t('reservations_feat.manage')}
            </CTAButtonLink>
            {!!venue.geocode && (
              <ActionBtnLink
                href={buildMapUrl(
                  venue.geocode.lat,
                  venue.geocode.lon,
                  venue.google_place_ref,
                )}
              >
                <Direction size={DEFAULT_ICON_SIZE} />
              </ActionBtnLink>
            )}
            <ActionBtnLink href={handleCall()}>
              <Phone size={DEFAULT_ICON_SIZE} />
            </ActionBtnLink>
            <ActionBtn
              onClick={() => void handleShare()}
              data-testid="Reservation Share Venue Btn"
            >
              <Share size={DEFAULT_ICON_SIZE} />
            </ActionBtn>
          </>
        ) : (
          <>
            <CTAButtonLink
              data-testid="Rebook Reservation Btn"
              href={getBookingFormUrl()}
            >
              {t('reservations_feat.rebook')}
            </CTAButtonLink>
            <SecondaryButtonLink href={getReservationUrl()}>
              {t('reservations_feat.details')}
            </SecondaryButtonLink>
          </>
        )}
      </ActionBtnsWrapper>
      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'share_venue' && (
          <ShareContent
            image={search_image ?? DEFAULT_VENUE_IMAGE}
            title={translate(name_translations, language)}
            address={translate(address_translations, language)}
            cuisines={cuisines}
          />
        )}
      </Panel>
    </>
  );
}
