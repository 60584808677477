import { styled } from '@linaria/react';
import { t } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TRANSACTIONS_EMPTY_VIEW_IMG } from '@local/assets';
import { EmptyView, Spinner } from '@local/components';
import { useInView } from '@local/hooks';

import { useFetchAllPayments } from '../../../hooks/useFetchAllPayments';
import { usePayments } from '../../../hooks/usePayments';
import { PaymentItem } from '../PaymentItem/PaymentItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l5);
`;

const CardGroup = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    font-weight: 600;
  }
`;

const HiddenDiv = styled.div`
  visibility: hidden;
`;

interface Props {
  onCardClick: (paymentId: string) => void;
}

export function PaymentsList({ onCardClick }: Props) {
  const [, { language }] = useTranslation();
  const { ref, isInView } = useInView();
  const {
    data: allPayments,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFetchAllPayments(language);
  const { groupPaymentsByMonth } = usePayments();

  const groupedPayments = React.useMemo(
    () =>
      Array.from(
        groupPaymentsByMonth(allPayments?.payments ?? [], language).entries(),
      ),
    [allPayments?.payments, groupPaymentsByMonth, language],
  );

  React.useEffect(() => {
    if (isInView) {
      void fetchNextPage();
    }
  }, [isInView, fetchNextPage]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!isLoading && !groupedPayments?.length) {
    return (
      <EmptyView
        title={t('account.no_transactions')}
        description={t('account.empty_transaction_description')}
        imgSrc={TRANSACTIONS_EMPTY_VIEW_IMG}
        imgAlt={t('account.empty_transaction_description')}
      />
    );
  }

  return (
    <>
      <Wrapper>
        {groupedPayments.map(([monthYear, payments]) => (
          <CardGroup data-testid={`Payment Group ${monthYear}`} key={monthYear}>
            <p>{monthYear}</p>
            {payments.map((payment) => (
              <PaymentItem
                key={payment.id}
                shopName={payment.shop_name}
                paymentType={payment.payment_type}
                paymentStatus={payment.payment_status}
                currency={payment.currency}
                cardBrand={payment.brand}
                cardDigits={payment.card_digits}
                amount={payment.captured_amt ?? payment.authorized_amt}
                date={payment.created_at}
                searchImage={payment.shop_search_image}
                onClick={() => onCardClick(payment.id)}
              />
            ))}
          </CardGroup>
        ))}
        {isFetchingNextPage && <Spinner />}
      </Wrapper>
      {hasNextPage && <HiddenDiv ref={ref} />}
    </>
  );
}
