import { t } from 'i18next';
import { useAtom, useAtomValue } from 'jotai';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { AuthContent } from '@local/auth';
import {
  nextPanelActionAtom,
  Panel,
  panelStateAtom,
  useToast,
} from '@local/components';
import {
  AUTH_PANEL_STATES,
  EXPLORE_MAP_ID,
  JAPAN_UNIVERSE,
  SINGAPORE_UNIVERSE,
} from '@local/constants';
import { useDeviceType } from '@local/hooks';
import {
  CreateNewListContent,
  SaveToListContent,
  useCreateListQuery,
  useSaveOrUnsaveShopToListQuery,
} from '@local/list';
import { selectedVenueAtom, useMapService } from '@local/map-system';
import {
  searchFiltersAtom,
  useExplore,
  useFetchGeolocation,
} from '@local/search';
import type {
  GeolocationResponseType,
  MapVenue,
  ShopSearchResult,
} from '@local/types';

import { ExploreDesktop } from './ExploreDesktop';
import { ExploreMobile } from './ExploreMobile';

export function ExploreRoot() {
  const { isDesktop } = useDeviceType();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [, { language }] = useTranslation();
  const { venue } = useAtomValue(selectedVenueAtom);
  const searchFilters = useAtomValue(searchFiltersAtom);
  const mapUniverse = pathname.includes('/japan/search')
    ? JAPAN_UNIVERSE
    : SINGAPORE_UNIVERSE;
  const { mutate: createList } = useCreateListQuery();
  const { mutate: saveOrUnsaveShopToList } = useSaveOrUnsaveShopToListQuery();
  const { data: geolocation } = useFetchGeolocation();
  const isUserLocatedInUniverse = geolocation?.isUserLocatedInUniverse;
  const {
    saveVenueToastContent,
    errorToastContent,
    createVenueToastContent,
    setIsToastOpen,
    setToastContent,
  } = useToast();
  const {
    shopSearchQuery: {
      data: shopSearchResults,
      isLoading: isShopSearchLoading,
    },
    randomShopSearchQuery: {
      data: randomShopSearchResults,
      isLoading: isRandomShopSearchLoading,
    },
  } = useExplore();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const [nextPanelAction, setNextPanelAction] = useAtom(nextPanelActionAtom);
  const shouldUseUserLocation =
    searchParams.get('auto_geolocate') !== 'false' && isUserLocatedInUniverse;

  const venues = React.useMemo(() => {
    if (isShopSearchLoading || isRandomShopSearchLoading) return [];
    const uniqueShops = [
      ...(shopSearchResults?.shops ?? []),
      ...(randomShopSearchResults?.shops ?? []),
    ].reduce<Record<string, ShopSearchResult>>((acc, shop) => {
      if (!acc[shop._id] && !!shop.geocode) {
        acc[shop._id] = shop;
      }
      return acc;
    }, {});
    return Object.values(uniqueShops).map((shop) => ({
      id: shop._id,
      slug: shop.slug,
      geocode: {
        lng: shop.geocode.lon,
        lat: shop.geocode.lat,
      },
      name_translations: shop.name_translations,
      location_name_translations: shop.location_name_translations,
      cuisines: shop.cuisines,
      budget_dinner_avg: shop.budget_dinner_avg,
      budget_lunch_avg: shop.budget_lunch_avg,
      search_image: shop.search_image,
      availability: shop.availability,
    })) as MapVenue[];
  }, [
    isShopSearchLoading,
    isRandomShopSearchLoading,
    shopSearchResults?.shops,
    randomShopSearchResults?.shops,
  ]);

  const map = useMapService({
    venues,
    language,
    zoom: mapUniverse.zoomLevel,
    containerId: EXPLORE_MAP_ID,
    center: [
      searchFilters.geo_longitude ??
        geolocation?.longitude ??
        mapUniverse.defaultLocation.lon,
      searchFilters.geo_latitude ??
        geolocation?.latitude ??
        mapUniverse.defaultLocation.lat,
    ],
    geolocateControl: {
      auto: shouldUseUserLocation,
      hideOnSelect: !isDesktop,
      usersLocation: geolocation as GeolocationResponseType,
    },
    bounds: mapUniverse.boundaries,
  });

  return (
    <>
      {isDesktop ? <ExploreDesktop map={map} /> : <ExploreMobile map={map} />}

      <Panel
        isOpen={!!panelState}
        onClose={() => {
          setPanelState(null);
          setNextPanelAction(null);
        }}
      >
        {AUTH_PANEL_STATES.includes(panelState) && (
          <AuthContent
            onSubmit={() => {
              setPanelState(null);
              if (nextPanelAction) {
                nextPanelAction();
                setNextPanelAction(null);
              }
            }}
            subheaderContent={
              <Trans
                i18nKey="auth.login_feat_unlock"
                components={{ bold: <strong /> }}
              />
            }
          />
        )}

        {panelState === 'save_list' && venue && (
          <SaveToListContent
            venueId={venue.id}
            onCreateListClick={() => setPanelState('create_list')}
            onSubmit={(listsToUpdate: { save: string[]; unsave: string[] }) => {
              saveOrUnsaveShopToList(
                {
                  venueId: venue.id,
                  listIdsToUpdate: listsToUpdate,
                },
                {
                  onSuccess: (response) => {
                    setPanelState(null);
                    setToastContent(
                      saveVenueToastContent(
                        response.lists,
                        () => setPanelState('save_list'),
                        t('general.edit'),
                      ),
                    );
                    setIsToastOpen(true);
                  },
                  onError: () => {
                    setPanelState(null);
                    setToastContent(
                      errorToastContent(t('toast.unsuccessful_list_save')),
                    );
                    setIsToastOpen(true);
                  },
                },
              );
            }}
            onCancel={() => setPanelState(null)}
          />
        )}

        {panelState === 'create_list' && venue && (
          <CreateNewListContent
            venueId={venue.id}
            imageUrl={venue.search_image}
            onSubmit={(newListData) => {
              createList(newListData, {
                onSuccess: () => {
                  setToastContent(
                    createVenueToastContent(newListData.name, setPanelState),
                  );
                  setIsToastOpen(true);
                  setPanelState(null);
                },
              });
            }}
            onCancel={() => setPanelState(null)}
          />
        )}
      </Panel>
    </>
  );
}
