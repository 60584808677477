import { styled } from '@linaria/react';
import ReactSelect from 'react-select';

export const Text = styled.span`
  width: max-content;
  &:not([data-has-value='true']) {
    color: var(--text-placeholder);
  }
`;

export const StyledSelect = styled(ReactSelect)`
  & * {
    color: var(--black);
  }
`;

export const CalendarWrapper = styled.div`
  height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: inset 0px -20px 20px -20px var(--border-transparent);
  padding-top: 0px;
  padding: var(--spacing-l4);
  margin: calc(-1 * var(--spacing-l4));
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-l3);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 var(--spacing-l4);
  & > :first-child {
    margin-bottom: var(--spacing-l3);
  }
`;

export const CustomFooter = styled.div`
  display: grid;
  gap: var(--spacing-l4);
  margin: var(--spacing-l4) 0;
`;
