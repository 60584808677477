import { ChevronDown, SettingsAdjust } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useSetAtom } from 'jotai';

// TODO: Consider moving @local/auth to a common lib. REF: https://tablecheck.atlassian.net/browse/DPOR-585
import { useGetUserQuery } from '@local/auth';
import {
  AvailabilityChecker,
  Chip,
  nextPanelActionAtom,
  panelStateAtom,
} from '@local/components';
import {
  BUDGET_INFINITY,
  BUDGET_ZERO,
  DEFAULT_ICON_SIZE,
} from '@local/constants';
// TODO: Consider moving @local/list to a common lib or passing down from parent component (since that is a page component and not from a feature lib)
// REF: https://tablecheck.atlassian.net/browse/DPOR-698, https://tablecheck.atlassian.net/browse/DPOR-585
import { useFetchAllListsQuery } from '@local/list';
import type { SearchFilterViewType } from '@local/types';

import { useSearchFilters } from '../../hooks/useSearchFilters';

const Container = styled.div`
  display: flex;
  flex: none;
  gap: var(--spacing-l3);
  scrollbar-width: none;
  padding: var(--spacing-l1) var(--spacing-l4);
  z-index: 1;
  max-height: var(--search-filter-bar-max-height);

  overflow-x: scroll;
  overflow-y: visible;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &[data-is-desktop='true'] {
    background-color: white;
    padding-bottom: var(--spacing-l4);
    padding-top: 0px;
    border-bottom: 1px solid var(--border-transparent);
  }

  &[data-is-map='true'] {
    button {
      border: 1px solid var(--surface);
      box-shadow: var(--elevation-medium-raised);
    }
  }
`;

const FilterChip = styled(Chip)`
  padding: var(--spacing-l1) var(--spacing-l3);
  background-color: var(--white);
  &:first-child {
    padding: var(--spacing-l1) var(--spacing-l2);
  }
  &:hover {
    cursor: pointer;
  }
  &[data-has-value='true'] {
    background-color: var(--surface-raised-active);
    color: var(--text);
    border: 1px solid var(--primary);
  }
`;

interface SearchFilterBarProps {
  isDesktop?: boolean;
  isMap?: boolean;
  setSearchFilterView: (searchFilterView: SearchFilterViewType) => void;
}
export function SearchFilterBar({
  isDesktop,
  isMap,
  setSearchFilterView,
}: SearchFilterBarProps) {
  const { searchFilters, updateSearchFilters } = useSearchFilters();
  const { data: user } = useGetUserQuery();
  const { data: listsData } = useFetchAllListsQuery(user);
  const setPanelState = useSetAtom(panelStateAtom);
  const setNextPanelAction = useSetAtom(nextPanelActionAtom);

  // TODO: Refactor to DRY up these get...Text functions. REF: https://tablecheck.atlassian.net/browse/DPOR-693
  const getCuisineText = () => {
    const defaultValue = t('cuisine.title');

    if (!searchFilters?.cuisines || searchFilters.cuisines.length === 0) {
      return defaultValue;
    }
    const firstCuisineTranslation = t(
      `cuisine.${searchFilters.cuisines?.[0]}`,
      { defaultValue },
    );
    if (searchFilters.cuisines.length === 1) {
      return firstCuisineTranslation;
    }
    if (searchFilters.cuisines.length > 1) {
      return t('cuisine.multiple_cuisines', {
        cuisine: firstCuisineTranslation,
        other_selected_cuisines_count: searchFilters.cuisines.length - 1,
      });
    }
    return defaultValue;
  };

  const getListText = () => {
    const firstList = listsData?.lists?.filter(
      (list) => list.id === searchFilters.hub_shop_list_ids?.[0],
    )[0];
    if (!firstList) {
      return t('list_feat.title');
    }
    if (
      searchFilters.hub_shop_list_ids &&
      searchFilters.hub_shop_list_ids.length === 1
    ) {
      return firstList.name;
    }
    if (
      searchFilters.hub_shop_list_ids &&
      searchFilters.hub_shop_list_ids.length > 1
    ) {
      return t('list_feat.multiple_lists', {
        first_list_name: firstList.name,
        other_selected_lists_count: searchFilters.hub_shop_list_ids.length - 1,
      });
    }
  };

  return (
    <Container
      data-is-map={isMap}
      data-is-desktop={isDesktop}
      data-testid="Search Filters Bar"
    >
      <FilterChip
        onClick={() => setSearchFilterView('all')}
        data-testid="Search Filters Btn"
      >
        <SettingsAdjust size="20px" />
      </FilterChip>
      <AvailabilityChecker
        containerId="search"
        values={{
          date: searchFilters.date,
          num_people: searchFilters.num_people,
          time: searchFilters.time,
        }}
        onSubmit={(data) => {
          updateSearchFilters({ ...data });
        }}
        onReset={() => {
          updateSearchFilters({
            date: undefined,
            num_people: undefined,
            time: undefined,
          });
        }}
      />
      <FilterChip
        data-testid="Budget Filter"
        data-size="small"
        data-has-value={
          searchFilters.budget_dinner_avg_min !== BUDGET_ZERO ||
          searchFilters.budget_dinner_avg_max !== BUDGET_INFINITY
        }
        onClick={() => setSearchFilterView('budget')}
      >
        <span>{t('search.budget')}</span>
        <ChevronDown size={DEFAULT_ICON_SIZE} />
      </FilterChip>
      <FilterChip
        data-testid="Cuisines Filter"
        data-size="small"
        data-has-value={
          searchFilters.cuisines && searchFilters.cuisines.length > 0
        }
        onClick={() => setSearchFilterView('cuisines')}
      >
        <span>{getCuisineText()}</span>
        <ChevronDown size={DEFAULT_ICON_SIZE} />
      </FilterChip>
      <FilterChip
        data-testid="Lists Filter"
        data-size="small"
        data-has-value={
          searchFilters.hub_shop_list_ids &&
          searchFilters.hub_shop_list_ids.length > 0
        }
        onClick={() => {
          if (user) {
            setSearchFilterView('lists');
          } else {
            setPanelState('login');
            setNextPanelAction(() => () => setSearchFilterView('lists'));
          }
        }}
      >
        <span>{getListText()}</span>
        <ChevronDown size={DEFAULT_ICON_SIZE} />
      </FilterChip>
      <FilterChip
        data-testid="Smartpay Filter"
        data-size="small"
        data-has-value={searchFilters.is_smartpay}
        onClick={() => setSearchFilterView('smartpay')}
      >
        <span>{t('smartpay.title')}</span>
        <ChevronDown size={DEFAULT_ICON_SIZE} />
      </FilterChip>
      <FilterChip
        data-testid="Service Modes Filter"
        data-size="small"
        data-value={searchFilters.service_mode}
        onClick={() => setSearchFilterView('service_mode')}
        data-has-value={!!searchFilters.service_mode}
      >
        <span>
          {searchFilters.service_mode
            ? t(`service_modes.${searchFilters.service_mode}`)
            : t('service_modes.title')}
        </span>
        <ChevronDown size={DEFAULT_ICON_SIZE} />
      </FilterChip>
    </Container>
  );
}
