import { styled } from '@linaria/react';
import { Button, Input } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { Footer, panelStateAtom } from '@local/components';

import { useResetPasswordQuery } from '../../hooks/useResetPasswordQuery';

const Form = styled.form`
  display: grid;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4);
`;

const Wrapper = styled.div`
  display: grid;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4);
`;

export function ResetPasswordContent() {
  const form = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  });
  const { mutate: resetPassword, isPending } = useResetPasswordQuery();
  const [submittedEmail, setSubmittedEmail] = React.useState<string | null>(
    null,
  );
  const [panelState, setPanelState] = useAtom(panelStateAtom);

  const handleSubmit = form.handleSubmit((fields) => {
    resetPassword(fields, {
      onSuccess: () => {
        setSubmittedEmail(fields.email);
        setPanelState('reset_password_confirmation');
      },
    });
  });

  return (
    <>
      {panelState === 'reset_password' && (
        <Form autoComplete="off" onSubmit={(e) => void handleSubmit(e)}>
          <p>{t('auth.reset_instruction')}</p>
          <Input
            {...form.register('email', {
              required: true,
            })}
            type="email"
            aria-label="email"
            data-stretch
            placeholder={t('auth.email')}
          />
          <Footer>
            <Button data-variant="primary" type="submit" aria-busy={isPending}>
              {t('auth.reset_password')}
            </Button>
          </Footer>
        </Form>
      )}
      {panelState === 'reset_password_confirmation' && (
        <Wrapper>
          <p>
            <Trans
              i18nKey="auth.reset_instruction_sent"
              values={{ email: submittedEmail }}
              components={{ bold: <strong /> }}
            />
          </p>
          <Footer>
            <Button
              data-variant="tertiary"
              type="button"
              onClick={() => setPanelState('login')}
            >
              {t('auth.back_to_login')}
            </Button>
          </Footer>
        </Wrapper>
      )}
    </>
  );
}
