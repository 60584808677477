import { Renew } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useGetUserQuery } from '@local/auth';
import {
  nextPanelActionAtom,
  panelStateAtom,
  Spinner,
  VenueCard,
  ViewSwitcherBtn,
} from '@local/components';
import { CONFIG } from '@local/configs';
import {
  DEFAULT_ICON_SIZE,
  EXPLORE_MAP_ID,
  TABLET_BREAKPOINT,
} from '@local/constants';
import { useUniverse } from '@local/hooks';
import {
  Map,
  selectedVenueAtom,
  shouldFitBoundsToMarkersAtom,
  userMapMovementDetailFamilyAtom,
} from '@local/map-system';
import {
  SearchFilterBar,
  SearchFilterPanel,
  SearchInput,
  SearchResultsContent,
  SearchResultsPanel,
  searchResultsPanelAtom,
  searchViewAtom,
  SNAP_END_POINT,
  SNAP_MIDDLE_POINT,
  SNAP_START_POINT,
  useExplore,
  useFetchGeolocation,
  useSearchFilters,
  useSearchInput,
} from '@local/search';
import type { MapServiceApi, SearchFilterViewType } from '@local/types';
import { buildUrl, translate } from '@local/utils';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  gap: var(--spacing-l3);
  margin-top: var(--spacing-l4);
  background: transparent;
  &[data-has-focus='true'] {
    background: var(--surface);
    transition: background 0.3s ease-in-out;
  }
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MapSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;

  &[data-hidden='true'] {
    display: none;
  }
`;

const MapSearchBtn = styled(Button)`
  position: absolute;
  top: 140px;
  border-radius: var(--border-radius-full);
  z-index: var(--zindex-card);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: var(--elevation-medium-raised);
  border-color: var(--surface);
  color: var(--primary);
  padding: var(--spacing-l2) var(--spacing-l3) !important;

  &[data-hidden='true'] {
    display: none;
  }
`;
const MapVenueCard = styled(VenueCard)`
  position: absolute;
  width: 95svw;
  max-width: var(--map-card-width);
  bottom: calc(var(--mobile-footer-height) + var(--search-panel-header-height));
  &[data-is-cordova='true'] {
    bottom: calc(
      var(--mobile-footer-height) + var(--search-panel-header-height) +
        var(--spacing-l6)
    );
  }
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--border-radius-large);
  padding: var(--spacing-l2);
  box-shadow: var(--elevation-large-raised);
`;

interface Props {
  map: MapServiceApi;
}

export function ExploreMobile({ map }: Props): JSX.Element {
  const [searchParams] = useSearchParams();
  const [, { language }] = useTranslation();
  const { data: user } = useGetUserQuery();
  const { data: geolocation } = useFetchGeolocation();
  const { updateSearchFilters, searchFilters } = useSearchFilters();
  const setPanelState = useSetAtom(panelStateAtom);
  const setNextPanelAction = useSetAtom(nextPanelActionAtom);
  const { shopSearchQuery, allSavedShopsSet, availabilityToShow } =
    useExplore();
  const { universeSlug } = useUniverse();
  const userMapMovementDetail = useAtomValue(
    userMapMovementDetailFamilyAtom(EXPLORE_MAP_ID),
  );

  const [selectedVenue, setSelectedVenue] = useAtom(selectedVenueAtom);
  const { venue, shouldShowMapVenueCard } = selectedVenue;

  const [searchFilterView, setSearchFilterView] =
    React.useState<SearchFilterViewType>('none');
  const [searchView, setSearchView] = useAtom(searchViewAtom);
  const [{ snap }, setSearchResultsPanel] = useAtom(searchResultsPanelAtom);

  const shouldShowSearchResults = searchView === 'default';
  const isResultsPanelFullHeight = snap === SNAP_END_POINT;
  const shouldShowSearchFilterBar = searchView === 'default';
  const shouldShowMap = searchView === 'default' && !isResultsPanelFullHeight;
  const { searchInputRef } = useSearchInput();

  const setShouldFitBoundsToMarkers = useSetAtom(shouldFitBoundsToMarkersAtom);

  return (
    <>
      <Container
        id="search"
        data-has-focus={searchView === 'focused' || isResultsPanelFullHeight}
        data-testid="Explore Page Root"
      >
        <SearchInput isMap={!isResultsPanelFullHeight} ref={searchInputRef} />
        {shouldShowSearchFilterBar && (
          <SearchFilterBar
            isMap={!isResultsPanelFullHeight}
            setSearchFilterView={setSearchFilterView}
          />
        )}
        {shouldShowSearchResults && (
          <SearchResultsPanel>
            <SearchResultsContent
              onResetSearch={() => {
                if (map.instance && geolocation) {
                  map.instance.flyTo({
                    center: [geolocation.longitude, geolocation.latitude],
                  });
                }
              }}
            />
          </SearchResultsPanel>
        )}
        <SearchFilterPanel
          isOpen={searchFilterView !== 'none'}
          onClose={() => setSearchFilterView('none')}
          searchFilterView={searchFilterView}
        />
        {!shouldShowMap && (
          <ViewSwitcherBtn
            onClick={() => {
              setSearchView('default');
              setSearchResultsPanel((prev) => ({
                ...prev,
                snap: SNAP_START_POINT,
              }));
            }}
          />
        )}
      </Container>

      <MapSection
        data-testid="Explore Page Map"
        onTouchStart={() => {
          setSearchResultsPanel((prev) => ({
            ...prev,
            snap: SNAP_START_POINT,
          }));
        }}
        data-hidden={!shouldShowMap}
        data-universe={universeSlug}
      >
        {map.isLoading && <StyledSpinner isFullPage />}
        <Map
          id={EXPLORE_MAP_ID}
          data-is-loading={map.isLoading}
          style={
            {
              '--geolocate-control-bottom':
                snap === SNAP_MIDDLE_POINT
                  ? `calc(40svh - (100svh - ${TABLET_BREAKPOINT}) * 0.1)`
                  : 'calc(var(--mobile-footer-height) + var(--search-panel-header-height))',
            } as React.CSSProperties
          }
        >
          {userMapMovementDetail?.canPerformSearch && (
            <MapSearchBtn
              data-size="small"
              data-variant="tertiary"
              data-hidden={!shouldShowMap}
              aria-busy={
                shopSearchQuery.status === 'pending' ||
                shopSearchQuery.fetchStatus === 'fetching'
              }
              onClick={() => {
                setShouldFitBoundsToMarkers(false);
                setSelectedVenue({
                  venue: null,
                  shouldShowMapVenueCard: false,
                });
                updateSearchFilters({
                  geo_latitude: userMapMovementDetail?.center.lat,
                  geo_longitude: userMapMovementDetail?.center.lng,
                  geo_distance: `${userMapMovementDetail?.radiusInKm}km`,
                  auto_geolocate: false,
                });
              }}
            >
              <Renew size={DEFAULT_ICON_SIZE} />
              {t('search.action_btns.search_area')}
            </MapSearchBtn>
          )}
        </Map>
      </MapSection>
      {venue && shouldShowMapVenueCard && shouldShowMap && (
        <MapVenueCard
          data-testid="Map Venue Card"
          data-is-cordova={CONFIG.IS_CORDOVA}
          venue={{
            slug: venue.slug,
            title: translate(venue.name_translations, language),
            cuisines: venue.cuisines,
            lunchAvg: venue.budget_lunch_avg,
            dinnerAvg: venue.budget_dinner_avg,
            imageUrls: [venue.search_image],
            availability: venue.availability,
          }}
          href={buildUrl(`/${language}/${venue.slug}`, searchParams)}
        >
          <VenueCard.Header
            icon={
              allSavedShopsSet.has(venue.id) ? 'bookmarkFilled' : 'bookmarkAdd'
            }
            onIconClick={() => {
              if (user) {
                setPanelState('save_list');
              } else {
                setPanelState('login');
                setNextPanelAction(() => () => setPanelState('save_list'));
              }
            }}
            buttonTestId="Map Venue Card Bookmark Btn"
          />
          <VenueCard.Info />
          <VenueCard.Budget />
          <VenueCard.SidePicture />
          {availabilityToShow === 'time' && (
            <VenueCard.TimeSlots
              query={{
                date: searchFilters.date,
                pax: searchFilters.num_people,
                time: searchFilters.time,
              }}
            />
          )}
          {availabilityToShow === 'date' && (
            <VenueCard.DateTable
              query={{
                pax: searchFilters.num_people,
                time: searchFilters.time,
                dateMin: searchFilters.date_min,
              }}
            />
          )}
        </MapVenueCard>
      )}
    </>
  );
}
