import { styled } from '@linaria/react';

import { CONFIG } from '@local/configs';
import type {
  GalleryResponse,
  MenuItem,
  MenuItemsResponse,
  VenueFavoritesResponse,
  VenueInfo,
} from '@local/types';
import { CurrencyFormat } from '@local/utils';
import {
  Availability,
  Description,
  Experiences,
  Features,
  ImgMosaic,
  Information,
  MenuItemsSection,
  Overview,
} from '@local/venue';

interface Props {
  venueSlug: string;
  venueData: VenueInfo;
  gallery: GalleryResponse | undefined;
  menuItems: MenuItem[];
  favoritesData: VenueFavoritesResponse | undefined;
  allSavedShopsSet: Set<string>;
  experiences: MenuItemsResponse | undefined;
  shouldShowMenuItems: boolean;
  shouldShowExperiences: boolean;
}

const VenueContent = styled.div`
  display: grid;
  gap: var(--spacing-l7);
  padding-bottom: 60px;
  grid-column: 1 / 2;
`;

const Content = styled.div`
  margin-top: var(--desktop-header-spacer);
  display: grid;
  grid-template-columns: auto var(--desktop-venue-sidebar);
  grid-template-rows: var(--desktop-mosaic-height) auto;
  gap: var(--spacing-l8) var(--spacing-l8);
`;

const MosaicWrapper = styled.div`
  grid-column: 1 / 3;
`;

export function VenueDesktop({
  venueSlug,
  venueData,
  gallery,
  menuItems,
  favoritesData,
  allSavedShopsSet,
  experiences,
  shouldShowMenuItems,
  shouldShowExperiences,
}: Props): JSX.Element {
  return (
    <Content>
      <MosaicWrapper>
        <ImgMosaic images={gallery?.images} />
      </MosaicWrapper>
      <VenueContent>
        <Overview
          venueData={venueData}
          favoritesData={favoritesData?.favorites[0]}
          isSaved={allSavedShopsSet.has(venueData._id)}
        />
        <Description
          taglineTranslations={venueData.content_title_translations}
          descriptionTranslations={venueData.content_body_translations}
        />
        {CONFIG.VITE_IS_STORYBOOK && <Features features={venueData.features} />}
        {shouldShowExperiences && (
          <Experiences venueSlug={venueSlug} experiences={experiences} />
        )}
        {shouldShowMenuItems && (
          <MenuItemsSection
            items={menuItems}
            currency={venueData.currency as CurrencyFormat}
          />
        )}
        <Information venueInfo={venueData} />
      </VenueContent>

      <Availability venueSlug={venueSlug} />
    </Content>
  );
}
