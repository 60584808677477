import { LogoFacebook } from '@carbon/icons-react';

import { GOOGLE_LOGO, YAHOO_JAPAN_LOGO } from '@local/assets';
import { ICON_SIZE_24 } from '@local/constants';
import type { SocialAuth } from '@local/types';

import { useAuthInWindow } from './useAuthInWindow';

interface SocialAuthProvidersHookApi {
  iconMap: Record<SocialAuth['provider'], JSX.Element>;
  providers: {
    id: SocialAuth['provider'];
    onClick: () => void;
  }[];
}

export function useSocialAuthProviders(): SocialAuthProvidersHookApi {
  const { handleSocialLogin } = useAuthInWindow();

  const iconMap = {
    google_oauth2: <img width={ICON_SIZE_24} alt="Google" src={GOOGLE_LOGO} />,
    facebook: <LogoFacebook size={ICON_SIZE_24} fill="var(--light-blue)" />,
    yahoojp: <img width={ICON_SIZE_24} alt="Yahoo JP" src={YAHOO_JAPAN_LOGO} />,
  };

  const providers: SocialAuthProvidersHookApi['providers'] = [
    {
      id: 'google_oauth2',
      onClick: () => void handleSocialLogin('google_oauth2'),
    },
    {
      id: 'facebook',
      onClick: () => void handleSocialLogin('facebook'),
    },
    {
      id: 'yahoojp',
      onClick: () => void handleSocialLogin('yahoojp'),
    },
  ];

  return {
    iconMap,
    providers,
  };
}
