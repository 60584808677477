import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { logger, sentryService } from '@local/services';
import type { SaveListsRequest } from '@local/types';

import { saveOrUnsaveShopToList } from '../listApi';

export const useSaveOrUnsaveShopToListQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      venueId,
      listIdsToUpdate,
    }: {
      venueId: string;
      listIdsToUpdate: SaveListsRequest;
    }) => saveOrUnsaveShopToList(venueId, listIdsToUpdate),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [USER_QUERY_KEY, 'lists'] })
        .catch(logger.error);
    },
    onError: (error) => {
      logger.error('Error updating list:', error);
      sentryService.captureException({ error });
    },
  });
};
