import { AddFilled } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { IconButton } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: var(--spacing-l3);
  padding: var(--spacing-l4) 0;
  cursor: pointer;
  flex-shrink: 0;
  border-bottom: 1px solid var(--lighter-grey-border);
`;

const IconWrapper = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: var(--border-radius-small);
  background-color: var(--surface-low-active);
  border: none;
`;

const CardText = styled.div`
  display: flex;
  align-items: center;
  font: var(--body-1);
`;

interface Props {
  onClick: () => void;
}

export function CreateNewListCard({ onClick }: Props) {
  return (
    <Wrapper onClick={onClick} data-testid="Create List Card">
      <IconWrapper>
        <AddFilled size={24} fill="var(--primary)" />
      </IconWrapper>
      <CardText>{t('list_feat.create_list')}</CardText>
    </Wrapper>
  );
}
