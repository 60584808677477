import { styled } from '@linaria/react';
import * as React from 'react';

const Wrapper = styled.div`
  z-index: 0;
  overflow: visible;
  max-width: 100svw;
  & > section {
    position: relative;
  }
`;

interface Props {
  children: React.ReactNode;
}

export function DesktopContentWrapper({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}
