import { styled } from '@linaria/react';
import { useAtomValue } from 'jotai';
import { Trans } from 'react-i18next';

import {
  JAPAN_HERO_BANNER_IMG,
  SINGAPORE_HERO_BANNER_IMG,
} from '@local/assets';
import {
  BackgroundImg,
  CampaignSection,
  Catchphrase,
  OverlayContent,
  StoryblokSection,
} from '@local/home';
import { useUniverse } from '@local/hooks';
import { SearchInput, searchViewAtom, useSearchInput } from '@local/search';

const SearchPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: var(--zindex-modal);
  display: flex;
  flex-direction: column;
  & form {
    padding: var(--spacing-l4);
    max-width: none;
  }
`;

const Content = styled.div`
  display: grid;
  margin: var(--spacing-l4);
  margin-top: var(--spacing-l7);
`;
export function HomeMobile(): JSX.Element {
  const searchView = useAtomValue(searchViewAtom);
  const { searchInputRef } = useSearchInput();

  const { isJapanUniverse } = useUniverse();
  const bannerImg = isJapanUniverse
    ? JAPAN_HERO_BANNER_IMG
    : SINGAPORE_HERO_BANNER_IMG;

  return (
    <div data-testid="Home Page Root">
      {/* //TODO: Temporary image until Sean provides a static one DPOR-717 */}
      <BackgroundImg
        decoding="async"
        src={bannerImg}
        sources={[
          { srcSet: bannerImg.replace('.png', '.webp'), type: 'image/webp' },
        ]}
        alt="TableCheck Diner Portal Home Banner"
      />
      <OverlayContent>
        <Catchphrase>
          <Trans i18nKey="homepage.catchphrase" components={{ br: <br /> }} />
        </Catchphrase>
        {searchView === 'focused' ? (
          <SearchPanel data-testid="Home Search Panel">
            <SearchInput
              dataTestId="Home Search Bar"
              ref={searchInputRef}
              allowAutoFocus
            />
          </SearchPanel>
        ) : (
          <SearchInput
            dataTestId="Home Search Bar"
            hasNoShadow
            ref={searchInputRef}
          />
        )}
      </OverlayContent>

      <Content>
        {isJapanUniverse && <CampaignSection />}
        <StoryblokSection type="cuisines" />
        <StoryblokSection type="area" />
      </Content>
    </div>
  );
}
