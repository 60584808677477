import { z } from 'zod';

import { baseSchema } from './baseSchema';

export const testingConfig: Partial<z.infer<typeof baseSchema>> = {
  VITE_APP_ENVIRONMENT: 'testing',
  VITE_BASE_MOCK_API_URL: 'http://localhost:8080/',
  VITE_BASE_API_URL: 'http://localhost:8080/',
  VITE_BASE_AUTH_API_URL: 'http://localhost:8080/',
  VITE_BASE_SEARCH_AUTOCOMPLETE_API_URL: 'http://localhost:8080/',
};
