import { styled } from '@linaria/react';
import { LocaleCode } from '@tablecheck/locales';
import { Root } from 'hast';
import { flow } from 'lodash-es';
import Mikan from 'mikanjs';
import * as React from 'react';
import { rehype } from 'rehype';
import sanitize, { defaultSchema } from 'rehype-sanitize';
import type { Parent } from 'unist';
import { CONTINUE, visit } from 'unist-util-visit';

type Node =
  | {
      type: 'element';
      tagName: string;
      properties: Record<string, string>;
      children?: Node[];
      position?: {
        start: { line: number; column: number; offset: number };
        end: { line: number; column: number; offset: number };
      };
    }
  | {
      type: 'text';
      value: string;
      properties: Record<string, string>;
      position?: {
        start: { line: number; column: number; offset: number };
        end: { line: number; column: number; offset: number };
      };
    }
  | {
      type: 'root';
      children: Node[];
      properties: Record<string, string>;
    };

const getClass = (nodeName: string) => `tc-markup-${nodeName.toLowerCase()}`;

const ALLOWED_TAGS = ['B', 'I', 'U', 'S', 'RED', 'HR'];

const processTree = (ast: Root, shouldWordWrap: boolean): Root => {
  visit(
    ast as unknown as Node,
    '',
    (node: Node, childIndex: number, parent: Parent | null) => {
      const isElement = node.type === 'element';
      const isTextNode = node.type === 'text';
      const nodeName = isElement ? node.tagName.toUpperCase() : undefined;
      const isLink = isElement && nodeName === 'A';
      const isAllowed =
        isElement && nodeName && ALLOWED_TAGS.includes(nodeName);

      if (isLink && nodeName) {
        let href = '';
        let isValid = true;
        try {
          href = new URL(
            (
              node as { type: 'element'; properties: Record<string, string> }
            ).properties.href,
          ).toString();
        } catch (e) {
          isValid = false;
        }

        if (!isValid) {
          parent?.children.splice(childIndex, 1, {
            type: 'text',
            // @ts-expect-error @types/hast not up to date
            value: rehype.stringify(node),
            position: node.position,
          } as Node);

          return [CONTINUE, childIndex + 1];
        }
        node.properties = {
          href,
          target: '_blank',
          class: getClass(nodeName),
        };
      } else if (isAllowed && nodeName) {
        node.tagName = 'span';
        node.properties = {
          ...(node.properties || {}),
          class: getClass(nodeName),
        };
      } else if (isTextNode && shouldWordWrap) {
        const parts =
          node.value?.split(/(\n)/g).reduce<string[]>((acc, str) => {
            if (str === '') return acc;
            if (str !== '\n') return acc.concat(Mikan.split(str));
            return acc.concat(str);
          }, []) || [];
        const nextSiblings = parts.reduce<Node[]>((acc, str) => {
          if (str === '\n')
            return acc.concat({
              type: 'element',
              tagName: 'br',
              properties: {},
            });
          return acc.concat({
            type: 'element',
            tagName: 'pre',
            properties: {},
            children: [
              {
                type: 'text',
                value: str,
                properties: {},
              },
            ],
          });
        }, []);

        parent?.children.splice(childIndex, 1, ...nextSiblings);

        return [CONTINUE, childIndex + nextSiblings.length];
      } else if (isTextNode && !shouldWordWrap) {
        const nextSiblings =
          node.value?.split(/(\n)/g).reduce<Node[]>((acc, str) => {
            if (str === '') return acc;
            if (str === '\n')
              return acc.concat({
                type: 'element',
                properties: {},
                tagName: 'br',
              });
            return acc.concat({
              type: 'text',
              value: str,
              properties: {},
            });
          }, []) ?? [];

        parent?.children.splice(childIndex, 1, ...nextSiblings);

        return [CONTINUE, childIndex + nextSiblings.length];
      }

      return CONTINUE;
    },
  );

  return ast;
};

export const processMarkup = (
  rawInput: string,
  shouldWordWrap: boolean,
): string => {
  if (!rawInput) return '';
  return flow(
    (input: string): Root => {
      const result = rehype.parse(input);
      return result;
    },
    sanitize({
      ...defaultSchema,
      protocols: {
        ...defaultSchema.protocols,
        href: ['http', 'https', 'mailto', 'xmpp', 'irc', 'ircs', 'tel'],
      },
      strip: ['script', 'style', 'iframe'],
      tagNames: ['a', 'b', 'i', 'u', 's', 'red', 'hr'],
    }) as (tree: Root) => Root,
    (ast: Root): Root => processTree(ast, shouldWordWrap),
    (ast: Root): string => rehype.stringify(ast),
  )(rawInput);
};

interface Props {
  language?: string | undefined;
  markup: string;
  /**
   * should only be used for japanese text in some places
   * note that places with inline-block text should override this rule such as input labels
   */
  shouldWordWrap?: boolean;
}

const Text = styled.span`
  display: inline !important;

  & pre {
    display: inline-block;
    text-decoration: inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
  }

  & .tc-markup-b {
    display: inline;
    font-weight: bold;
  }

  & .tc-markup-i {
    font-style: italic;
  }

  & .tc-markup-a {
    text-decoration: underline;
  }

  & .tc-markup-u {
    text-decoration: underline;
  }

  & .tc-markup-s {
    text-decoration: line-through;
  }

  & .tc-markup-red {
    color: red;
  }

  & .tc-markup-hr {
    display: block;
    width: 100%;
    height: 1px;
  }
`;

export function ProcessedMarkup({
  markup,
  language,
  shouldWordWrap = false,
  ...props
}: Props): JSX.Element {
  const [html, didWordWrap] = React.useMemo(() => {
    const willWordWrap =
      (shouldWordWrap || typeof shouldWordWrap === 'undefined') &&
      language === LocaleCode.Japanese;
    const result = processMarkup(markup, willWordWrap);
    return [result, willWordWrap];
  }, [markup, language, shouldWordWrap]);

  return (
    <Text
      data-wordwrap={didWordWrap}
      role="presentation"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      {...props}
    />
  );
}
