export type PanelStates =
  | 'login'
  | 'reset_password'
  | 'reset_password_confirmation'
  | 'sign_up'
  | 'change_pwd'
  | 'delete_membership'
  | 'delete_account'
  | 'terms_conditions'
  | 'save_list'
  | 'create_list'
  | 'update_list'
  | 'update_list_desc'
  | 'list_options'
  | 'delete_list'
  | 'memo'
  | 'delete_credit_card'
  | 'view_payment'
  | 'disconnect_social'
  | 'delete_subscription'
  | 'share_venue'
  | null;

export enum Cookie {
  AuthenticationKey = 'tablecheck_oauth_token',
  CsrfToken = 'csrf_token',
  TCUniverse = 'tc_universe',
}
