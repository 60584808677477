import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';

import {
  DOWNLOAD_APP_STORE_LOGO,
  QR_CODE,
  TABLECHECK_LOGO,
} from '@local/assets';
import { SmartLink } from '@local/components';
import { CONFIG } from '@local/configs';
import {
  APP_STORE_URL,
  DEFAULT_ICON_SIZE,
  TABLECHECK_COMPANY_NAME,
} from '@local/constants';

import { useFooter } from './useFooter';

const Wrapper = styled.footer`
  background-color: #f9f9f9;
  width: 100vw;
  text-align: -webkit-center;
  align-self: center;
  margin-top: var(--desktop-footer-spacer);
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-l8);
  max-width: var(--desktop-content-width);
  text-align-last: left;
  padding: var(--spacing-l4);
  color: var(--black);
`;

const Section = styled.section`
  display: grid;
  gap: var(--spacing-l2);
  height: fit-content;

  & h3 {
    font-size: 16px;
  }
  & a {
    font-size: 14px;
  }
`;

const LegalFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  max-width: var(--desktop-content-width);
  padding: var(--spacing-l4);
  text-align-last: left;
  color: var(--text-subtle);
  font-size: 14px;
  gap: var(--spacing-l2);
  align-items: center;
`;

const TableCheckLogo = styled.img`
  cursor: pointer;
  height: 12px;
  filter: brightness(0) saturate(100%) invert(52%) sepia(6%) saturate(0%)
    hue-rotate(201deg) brightness(80%) contrast(91%);
`;

const SNSLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-l2);
  justify-self: right;
  & svg:hover {
    color: var(--black);
  }
`;

const Hr = styled.hr`
  border: 1px solid var(--border-transparent);
`;

const AppStoresLogo = styled.img`
  cursor: pointer;
  height: 40px;
`;

const AppStoreWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l2);
`;

const QrCodeSection = styled(AppStoreWrapper)`
  font-size: 16px;
  text-align: left;

  & span {
    align-content: center;
  }

  // TODO: This should be removed when the QR code is added
  & img {
    background: grey;
    height: 56px;
    width: 56px;
    margin: var(--spacing-l1);
    border-radius: var(--border-radius-small);
  }
`;

export function DesktopFooter() {
  const [t, { language }] = useTranslation();
  const { tablecheckOptions, companyOptions, legalOptions, snsOptions } =
    useFooter();

  return (
    <Wrapper data-testid="Desktop Footer">
      <Content>
        <Section>
          <h3>{t('layout.tablecheck_app')}</h3>
          <QrCodeSection>
            <img alt="QRCode" src={QR_CODE} />
            <span>{t('layout.scan_to_download')}</span>
          </QrCodeSection>

          <AppStoreWrapper>
            <SmartLink href={APP_STORE_URL}>
              <AppStoresLogo src={DOWNLOAD_APP_STORE_LOGO} alt="App Store" />
            </SmartLink>
            {/* <SmartLink href={GOOGLE_PLAY_URL}>
                <AppStoresLogo
                  src={DOWNLOAD_GOOGLE_PLAY_LOGO}
                  alt="Google Play"
                />
              </SmartLink> */}
          </AppStoreWrapper>
        </Section>
        <Section>
          <h3>{TABLECHECK_COMPANY_NAME}</h3>
          {tablecheckOptions.map((option) => (
            <SmartLink
              key={option.title}
              href={option.href}
              data-testid={option.dataTestId}
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                if (option.onClick) {
                  option.onClick(e);
                }
              }}
            >
              {option.title}
            </SmartLink>
          ))}
        </Section>
        <Section>
          <h3>{t('layout.company')}</h3>
          {companyOptions.map((option) => (
            <SmartLink key={option.title} href={option.href}>
              {option.title}
            </SmartLink>
          ))}
        </Section>
        <Section>
          <h3>{t('layout.legal')}</h3>
          {legalOptions.map((option) => (
            <SmartLink key={option.title} href={option.href}>
              {option.title}
            </SmartLink>
          ))}
        </Section>
      </Content>
      <Hr />
      <LegalFooter>
        <SmartLink
          href={`${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/join`}
        >
          <TableCheckLogo src={TABLECHECK_LOGO} alt="TableCheck logo" />
        </SmartLink>
        <span>{t('layout.all_rights_reserved')}</span>
        <SNSLogos>
          {snsOptions.map((option) => (
            <SmartLink href={option.href} key={option.href}>
              <option.icon size={DEFAULT_ICON_SIZE} />
            </SmartLink>
          ))}
        </SNSLogos>
      </LegalFooter>
    </Wrapper>
  );
}
