import {
  ChevronLeft,
  ChevronRight,
  Close,
  Image,
  Store,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button, IconButton } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  DEFAULT_ICON_SIZE,
  FIRST_THREE_ITEMS,
  ICON_SIZE_24,
} from '@local/constants';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Wrapper = styled.div`
  cursor: pointer;
  height: var(--desktop-mosaic-height);
  max-width: var(--desktop-content-width);
  width: 100%;
  display: flex;
`;

const Mosaic = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  gap: var(--spacing-l1);
  border-radius: var(--border-radius-large);
  overflow: hidden;
  > img {
    min-height: -webkit-fill-available;
  }

  &[data-images-length='2'] {
    grid-template-columns: 65% 35%;
    > img:first-child {
      grid-column: 1 / 2;
    }
  }

  &[data-images-length='>=3'] {
    grid-template-columns: 65% 35%;
    grid-template-rows: 1fr 1fr;

    > img:first-child {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }

    > img:nth-child(2) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    > img:nth-child(3) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
`;

const Img = styled.img`
  height: 100%;
  object-fit: cover;
  width: -webkit-fill-available;
`;

const GalleryImg = styled(Img)`
  height: auto;
  width: 75%;
  flex-basis: fit-content;
  min-height: 60% !important;
`;

const DefaultImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: var(--border-radius-small);
  background-color: #eeeeee;
  cursor: auto;
  svg {
    width: var(--card-side-icon);
    height: var(--card-side-icon);
    fill: var(--text-placeholder);
  }
`;

const ImgCarousel = styled.div`
  cursor: pointer;
  display: flex;

  &[data-show-gallery='true'] {
    width: 100%;
    position: fixed;
    z-index: var(--z-index-4);
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: hsla(0, 0%, 0%, 0.8);
  }

  & span {
    transition: 200ms left !important;
    background: white !important;
    opacity: 1 !important;
  }
`;

const ViewPhotosBtn = styled(Button)`
  box-shadow: var(--elevation-medium-raised);
  position: absolute;
  bottom: var(--spacing-l4);
  right: var(--spacing-l4);
  background-color: white;
  font-size: 14px;
  line-height: 16px;
  padding: var(--spacing-l2);

  &[data-show-gallery='true'] {
    display: none;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  color: white;
  border-radius: 50%;
  right: var(--spacing-l4);
  top: var(--spacing-l4);
`;

const Arrow = styled(IconButton)`
  color: white;
  border-radius: 50%;
  margin: 0 var(--spacing-l4);
  height: min-content;
  top: 50%;

  &.swiper-button-disabled {
    opacity: 0.2;
  }
`;

const INITIAL_IMAGES_LOADED = 3;
const NEXT_IMAGES_PRELOADED = 2;
const PRELOADING_DELAY = 1000;

export function ImgMosaic({
  images,
}: {
  images?: { large: string; id: string }[];
}): JSX.Element {
  const [isShowingGallery, setShowGallery] = React.useState(false);
  const [loadedImages, setLoadedImages] = React.useState(INITIAL_IMAGES_LOADED);
  const imagesLength = images ? images.length : 0;
  const hasGalleryImgs = imagesLength > 0;

  React.useEffect(() => {
    if (isShowingGallery) {
      const interval = setInterval(() => {
        setLoadedImages((loadedImgs) =>
          Math.min(loadedImgs + NEXT_IMAGES_PRELOADED, imagesLength),
        );

        if (loadedImages >= imagesLength) {
          clearInterval(interval);
        }
      }, PRELOADING_DELAY);
      return () => clearInterval(interval);
    }
  }, [imagesLength, loadedImages, isShowingGallery]);

  return (
    <>
      <Wrapper
        onClick={() =>
          hasGalleryImgs ? setShowGallery(!isShowingGallery) : null
        }
      >
        {hasGalleryImgs ? (
          <Mosaic
            data-testid="Venue Img Mosaic"
            data-images-length={imagesLength >= 3 ? '>=3' : imagesLength}
          >
            {images
              ?.slice(0, FIRST_THREE_ITEMS)
              .map((img) => <Img key={img.id} src={img.large} alt={img.id} />)}
            {imagesLength > 3 && (
              <ViewPhotosBtn
                data-show-gallery={isShowingGallery}
                data-variant="ghost"
                data-size="small"
                type="button"
              >
                <Image size={DEFAULT_ICON_SIZE} />
                {t('venue_feat.view_photos', {
                  count: imagesLength,
                })}
              </ViewPhotosBtn>
            )}
          </Mosaic>
        ) : (
          <DefaultImage>
            <Store />
          </DefaultImage>
        )}
      </Wrapper>

      <ImgCarousel
        data-show-gallery={isShowingGallery}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          const target = e.target as Element;
          if (!target.closest('.swiper')) return;
          setShowGallery(!isShowingGallery);
        }}
      >
        {isShowingGallery && (
          <>
            <CloseButton
              type="button"
              data-variant="bare"
              onClick={() => setShowGallery(!isShowingGallery)}
            >
              <Close size={ICON_SIZE_24} />
            </CloseButton>

            <Arrow
              type="button"
              data-variant="bare"
              className="arrow-left arrow"
            >
              <ChevronLeft size={DEFAULT_ICON_SIZE} />
            </Arrow>
            <Swiper
              data-testid="Venue Img Gallery"
              modules={[Pagination, Navigation]}
              pagination={{ clickable: true, dynamicBullets: true }}
              navigation={{
                nextEl: '.arrow-right',
                prevEl: '.arrow-left',
              }}
            >
              {images?.slice(0, loadedImages).map((img) => (
                <SwiperSlide
                  key={img.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <GalleryImg src={img.large} alt={img.id} loading="lazy" />
                </SwiperSlide>
              ))}
            </Swiper>
            <Arrow
              type="button"
              data-variant="bare"
              className="arrow-right arrow"
            >
              <ChevronRight size={DEFAULT_ICON_SIZE} />
            </Arrow>
          </>
        )}
      </ImgCarousel>
    </>
  );
}
