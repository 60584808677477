import { styled } from '@linaria/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CONFIG } from '@local/configs';
import { buildBookingFormUrl, getClosestTimeSlots } from '@local/utils';

import { SmartLinkButton } from '../../SmartLink/SmartLinkButton';
import { useVenueCardContext } from '../VenueCardContext';

const Wrapper = styled.div`
  display: flex;
  gap: var(--spacing-l1);
  margin-top: var(--spacing-l3);
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledSmartLinkButton = styled(SmartLinkButton)`
  background-color: var(--brand-primary);
  border: 1px solid var(--brand-primary);
  color: var(--brand-primary-text) !important;
  display: inline-block;
  flex-basis: 20%;
  padding: 9px 11px;
  &:hover {
    background-color: var(--brand-primary-hover);
  }
  &[data-is-disabled='true'] {
    background-color: var(--surface-disabled);
    border-color: var(--surface-disabled);
    color: var(--text-disabled) !important;
    cursor: not-allowed;
  }
`;

interface Props {
  query: {
    date?: string;
    time?: string;
    pax?: string;
  };
}

export function TimeSlots({ query }: Props) {
  const { venue } = useVenueCardContext();
  const { availability, slug } = venue;
  const [, { language }] = useTranslation();

  if (!availability || availability.length === 0) {
    return null;
  }

  const currentDateTime = new Date();
  const closestTimesSlots = getClosestTimeSlots({
    availability,
    queriedTime: query.time,
    queriedDate: query.date,
    currentDateTime,
  });

  return (
    <Wrapper>
      {closestTimesSlots.map((dateTime: string) => {
        const parsedDateTime = new Date(dateTime);
        const time = format(parsedDateTime, 'HH:mm');
        const date = format(parsedDateTime, 'yyyy-MM-dd');
        const isPast = parsedDateTime < currentDateTime;

        const bookingLink = buildBookingFormUrl(
          `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${slug}/reserve`,
          {
            start_date: date,
            start_time: time,
            ...(query.pax && { num_people: query.pax }),
          },
        );

        return (
          <StyledSmartLinkButton
            key={dateTime}
            data-testid="Common Venue Card Time Slot Btn"
            data-is-disabled={isPast}
            href={bookingLink}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.stopPropagation();
              if (isPast) {
                e.preventDefault();
              }
            }}
          >
            {time}
          </StyledSmartLinkButton>
        );
      })}
    </Wrapper>
  );
}
