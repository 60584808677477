import { useAtom, useSetAtom } from 'jotai';
import * as React from 'react';

import { DEFAULT_MAP_ID } from '@local/constants';
import type {
  MapServiceApi,
  PreviousZoomCenter,
  UseMapServiceOptions,
  UserMapMovementDetail,
} from '@local/types';

import { MAP_EVENTS, MapService } from '../map/mapService';
import {
  mapMaxZoomOnSearchAtom,
  mapPreviousZoomCenterFamilyAtom,
  selectedVenueAtom,
  selectedVenueIdFamilyAtom,
  shouldFitBoundsToMarkersAtom,
  userMapMovementDetailFamilyAtom,
  mapShouldUsePreviousLocationAtom,
} from '../map/mapStore';

export const useMapService = ({
  venues = [],
  language,
  center,
  zoom,
  containerId = DEFAULT_MAP_ID,
  interactive = true,
  geolocateControl,
  cameraTransition = 'flyTo',
  bounds,
}: UseMapServiceOptions): MapServiceApi => {
  const mapServiceRef = React.useRef<MapService | null>(null);
  const [isInitialized, setIsInitialized] = React.useState(true);
  const [isSettingPreviousLocation, setIsSettingPreviousLocation] =
    React.useState(true);
  const setSelectedVenue = useSetAtom(selectedVenueAtom);
  const setUserMapMovementDetail = useSetAtom(
    userMapMovementDetailFamilyAtom(containerId),
  );
  const [shouldUsePreviousLocation, setShouldUsePreviousLocation] = useAtom(
    mapShouldUsePreviousLocationAtom,
  );
  const [previousZoomCenter, setPreviousZoomCenter] = useAtom(
    mapPreviousZoomCenterFamilyAtom(containerId),
  );
  const [selectedVenueId, setSelectedVenueId] = useAtom(
    selectedVenueIdFamilyAtom(containerId),
  );
  const [maxZoomOnSearch, setMaxZoomOnSearch] = useAtom(mapMaxZoomOnSearchAtom);
  const [shouldFitBoundsToMarkers, setShouldFitBoundsToMarkers] = useAtom(
    shouldFitBoundsToMarkersAtom,
  );

  React.useEffect(() => {
    mapServiceRef.current = new MapService({
      venues,
      language,
      center,
      zoom,
      containerId,
      interactive,
      hideControlsOnSelect: geolocateControl?.hideOnSelect,
      bounds,
    });

    const handleVenueSelected = (event: CustomEvent<string>) => {
      setSelectedVenueId(event.detail);
    };
    const handleUserMapMovement = (
      event: CustomEvent<UserMapMovementDetail>,
    ) => {
      setUserMapMovementDetail(event.detail);
    };
    const handleUpdatePreviousZoomCenter = (
      event: CustomEvent<PreviousZoomCenter>,
    ) => {
      setPreviousZoomCenter(event.detail);
    };
    const handleMapInitialized = () => {
      setIsInitialized(false);
    };

    mapServiceRef.current.eventTarget.addEventListener(
      MAP_EVENTS.VENUE_SELECTED,
      handleVenueSelected as EventListener,
    );
    mapServiceRef.current.eventTarget.addEventListener(
      MAP_EVENTS.USER_MOVEMENT,
      handleUserMapMovement as EventListener,
    );
    mapServiceRef.current.eventTarget.addEventListener(
      MAP_EVENTS.PREV_ZOOM_CENTER,
      handleUpdatePreviousZoomCenter as EventListener,
    );
    mapServiceRef.current.eventTarget.addEventListener(
      MAP_EVENTS.INITIALIZED,
      handleMapInitialized as EventListener,
    );

    return () => {
      mapServiceRef.current?.cleanup();
      mapServiceRef.current?.eventTarget.removeEventListener(
        MAP_EVENTS.VENUE_SELECTED,
        handleVenueSelected as EventListener,
      );
      mapServiceRef.current?.eventTarget.removeEventListener(
        MAP_EVENTS.USER_MOVEMENT,
        handleUserMapMovement as EventListener,
      );
      mapServiceRef.current?.eventTarget.removeEventListener(
        MAP_EVENTS.PREV_ZOOM_CENTER,
        handleUpdatePreviousZoomCenter as EventListener,
      );
      mapServiceRef.current?.eventTarget.removeEventListener(
        MAP_EVENTS.INITIALIZED,
        handleMapInitialized as EventListener,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      isInitialized ||
      !interactive ||
      !mapServiceRef.current ||
      venues.length === 0
    )
      return;

    mapServiceRef.current.updateVenues(venues);
    if (!shouldFitBoundsToMarkers) {
      setShouldFitBoundsToMarkers(true);
      return;
    }
    if (maxZoomOnSearch) {
      void mapServiceRef.current?.instance.once('moveend', () => {
        setMaxZoomOnSearch(null);
      });
      mapServiceRef.current.fitBoundsToMarkers('jumpTo', maxZoomOnSearch);
    } else {
      mapServiceRef.current.fitBoundsToMarkers(
        cameraTransition,
        maxZoomOnSearch,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    venues,
    isInitialized,
    interactive,
    cameraTransition,
    setMaxZoomOnSearch,
  ]);

  React.useEffect(() => {
    if (isInitialized || !interactive) return;
    if (selectedVenueId) {
      const foundVenue =
        venues.find((venue) => venue.id === selectedVenueId) ?? null;
      setSelectedVenue({
        venue: foundVenue,
        shouldShowMapVenueCard: !!foundVenue,
      });
    } else {
      setSelectedVenue({ venue: null, shouldShowMapVenueCard: false });
    }
  }, [selectedVenueId, venues, setSelectedVenue, isInitialized, interactive]);

  React.useEffect(() => {
    if (isInitialized || !interactive) return;
    if (!previousZoomCenter?.zoom && !previousZoomCenter?.center) {
      setIsSettingPreviousLocation(false);
      return;
    }
    // used by storyblok locations in the homepage and location suggestions to stop the map from setting the previous location
    if (!shouldUsePreviousLocation) {
      setShouldUsePreviousLocation(true);
      setSelectedVenueId(null);
      setIsSettingPreviousLocation(false);
      return;
    }
    // adjusting map to the previous state by selecting the previously selected venue and setting the camera
    mapServiceRef.current?.selectVenue(selectedVenueId);
    mapServiceRef.current?.instance.jumpTo({
      center: previousZoomCenter.center,
      zoom: previousZoomCenter.zoom,
    });
    setIsSettingPreviousLocation(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, interactive]);

  React.useEffect(() => {
    if (!isInitialized && language) {
      mapServiceRef.current?.changeLanguage(language);
    }
  }, [language, isInitialized]);

  React.useEffect(() => {
    if (isInitialized || !geolocateControl?.auto) return;

    if (geolocateControl?.usersLocation)
      mapServiceRef.current?.instance.flyTo({
        center: [
          geolocateControl?.usersLocation.longitude,
          geolocateControl?.usersLocation.latitude,
        ],
      });
    mapServiceRef.current?.triggerGeolocate();
  }, [isInitialized, geolocateControl?.usersLocation, geolocateControl?.auto]);

  return {
    isLoading: isInitialized || isSettingPreviousLocation,
    instance: mapServiceRef.current?.instance,
  };
};
