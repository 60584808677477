import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { Trans } from 'react-i18next';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  margin: var(--spacing-l4);

  & > img {
    max-width: 240px;
  }
  &[data-is-small='true'] {
    img {
      max-width: 90px;
    }
  }

  & > p {
    color: var(--text-subtle);
  }
`;

interface Props {
  title: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
  ctaButtonText?: string;
  ctaAction?: () => void;
  isSmall?: boolean;
}

export function EmptyView({
  title,
  description,
  imgSrc,
  imgAlt,
  ctaAction,
  ctaButtonText,
  isSmall,
}: Props) {
  return (
    <Wrapper data-is-small={isSmall} data-testid="Empty View">
      <img src={imgSrc} alt={imgAlt} />
      <h4>{title}</h4>
      <p>
        <Trans i18nKey={description} components={{ br: <br /> }} />
      </p>
      {ctaButtonText && (
        <Button
          data-size="medium"
          onClick={ctaAction}
          data-testid="Explore Venues CTA Btn"
        >
          {ctaButtonText}
        </Button>
      )}
    </Wrapper>
  );
}
