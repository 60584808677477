import { LocaleCode } from '@tablecheck/i18n';
import { locales } from '@tablecheck/locales';
import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { CONFIG } from '@local/configs';

const BASE_CDN_FAVICON_V1_1_URL = `${CONFIG.VITE_BASE_CDN_IMAGES_URL}favicons/tc/v1.1.0/`;
const BASE_CDN_FAVICON_V1_0_URL = `${CONFIG.VITE_BASE_CDN_IMAGES_URL}favicons/tc/v1.0.0/`;
const APPLE_TOUCH_ICON_SIZES = [57, 60, 72, 76, 114, 120, 144, 152, 180];
const FAVICON_SIZES = [16, 32, 192];
const X_ICON_SIZES = [16, 32];

const generateAppleTouchIcons = () =>
  APPLE_TOUCH_ICON_SIZES.map((size) => ({
    rel: 'apple-touch-icon',
    sizes: `${size}x${size}`,
    href: `${BASE_CDN_FAVICON_V1_0_URL}apple-icon-${size}x${size}.png`,
  }));

const generateFaviconLinks = () =>
  FAVICON_SIZES.map((size) => ({
    rel: 'icon',
    sizes: `${size}x${size}`,
    href: `${BASE_CDN_FAVICON_V1_1_URL}favicon-${size}x${size}.png`,
  }));

const generateXIconLinks = () =>
  X_ICON_SIZES.map((size) => ({
    rel: 'icon',
    sizes: `${size}x${size}`,
    type: 'image/x-icon',
    href: `${BASE_CDN_FAVICON_V1_1_URL}favicon-${size}x${size}.png`,
  }));
const FONT_FACE_URL = 'https://cdn0.tablecheck.com/common/css/font-faces.css';

export function Head(): JSX.Element {
  const [, { language }] = useTranslation();
  const title = t('meta.title', { lng: language });
  const description = t('meta.description', { lng: language });
  const location = useLocation();
  // remove language from path
  const pathWithoutLocale = location.pathname.replace(`/${language}/`, '');
  return (
    <Helmet>
      <title>{title}</title>
      <link
        rel="apple-touch-icon"
        href={`${BASE_CDN_FAVICON_V1_1_URL}apple-touch-icon.png`}
      />

      <link
        rel="icon"
        type="image/x-icon"
        href={`${BASE_CDN_FAVICON_V1_0_URL}apple-icon-precomposed.png`}
      />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={`${BASE_CDN_FAVICON_V1_1_URL}favicon.ico`}
      />

      <link rel="preload" href={FONT_FACE_URL} as="style" />

      {generateXIconLinks().map(({ href, rel, sizes, type }) => (
        <link key={href} href={href} rel={rel} sizes={sizes} type={type} />
      ))}

      {generateAppleTouchIcons().map(({ href, rel, sizes }) => (
        <link key={href} href={href} rel={rel} sizes={sizes} />
      ))}
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="google-signin-client_id"
        content={CONFIG.VITE_GOOGLE_SIGNIN_CLIENT_ID}
      />
      <meta
        name="facebook-domain-verification"
        content={CONFIG.VITE_FACEBOOK_DOMAIN_VERIFICATION}
      />
      <meta property="fb:app_id" content={CONFIG.VITE_FACEBOOK_APP_ID} />

      {/* for IE11 and Windows 8/10 */}
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta
        name="msapplication-square70x70logo"
        content={`${BASE_CDN_FAVICON_V1_1_URL}mstile-70x70.png`}
      />
      <meta
        name="msapplication-square144x144logo"
        content={`${BASE_CDN_FAVICON_V1_1_URL}mstile-144x144.png`}
      />
      <meta
        name="msapplication-square150x150logo"
        content={`${BASE_CDN_FAVICON_V1_1_URL}mstile-150x150.png`}
      />
      <meta
        name="msapplication-square310x310logo"
        content={`${BASE_CDN_FAVICON_V1_1_URL}mstile-310x310.png`}
      />
      <meta
        name="msapplication-wide310x150logo"
        content={`${BASE_CDN_FAVICON_V1_1_URL}mstile-310x150.png`}
      />

      <meta name="theme-color" content="#ffffff" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* TODO: DPOR-972: Add og banner */}
      {/* <meta
        property="og:image"
        content=""
      /> */}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="TableCheck" />

      <meta
        property="og:url"
        content={`${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}`}
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={`${BASE_CDN_FAVICON_V1_1_URL}apple-touch-icon.png`}
      />

      <link
        rel="manifest"
        href={`${BASE_CDN_FAVICON_V1_1_URL}favicon-manifest.json`}
      />

      <link
        rel="mask-icon"
        href={`${BASE_CDN_FAVICON_V1_1_URL}maskable_icon.png`}
      />
      <link rel="icon" href={`${BASE_CDN_FAVICON_V1_1_URL}favicon.ico`} />
      {generateFaviconLinks().map(({ href, rel, sizes }) => (
        <link key={href} href={href} rel={rel} sizes={sizes} />
      ))}

      <link
        href={`${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/${pathWithoutLocale}`}
        rel="canonical"
      />
      <link
        href={`${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${pathWithoutLocale}`}
        hrefLang="x-default"
        rel="alternate"
      />
      {Object.keys(locales).map((locale) => [
        <link
          key={`google-${locale}`}
          rel="alternate"
          hrefLang={locale}
          href={`${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${locale}/${pathWithoutLocale}`}
        />,
        <meta
          key={`facebook-${locale}`}
          property={language === locale ? 'og:locale' : 'og:locale:alternate'}
          content={locales[locale as LocaleCode].facebook}
        />,
      ])}
    </Helmet>
  );
}
