import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { TABLECHECK_LOGO_SYMBOL } from '@local/assets';
import { useGetUserQuery } from '@local/auth';
import { Memo, Price } from '@local/components';
import type { VenueFavorites, VenueInfo } from '@local/types';
import { useFetchUserMemoQuery } from '@local/user';
import { CurrencyFormat, formatDistance, translate } from '@local/utils';

import { ActionBtns } from '../ActionBtns/ActionBtns';

import {
  Details,
  Location,
  OverviewSection,
  StyledPrice,
  TableCheckPay,
  TableCheckPayLogo,
  Title,
  VenueDetails,
} from './Overview.styles';

export function Overview({
  venueData,
  favoritesData,
  isSaved,
}: {
  venueData: VenueInfo;
  favoritesData?: VenueFavorites;
  isSaved: boolean;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const {
    name_translations,
    stations,
    cuisines,
    address,
    budget_lunch_avg,
    budget_dinner_avg,
    currency,
    is_smartpay: allowsSmartpay,
    slug,
  } = venueData;

  const { data: user } = useGetUserQuery();
  const { data: userMemo } = useFetchUserMemoQuery(slug, user);

  return (
    <OverviewSection>
      <VenueDetails>
        <Title data-testid="Venue Page Title">
          {translate(name_translations, language)}
        </Title>
        <Details>
          <Location>
            {t('general.city_region', {
              city: address.city,
              region: address.region,
            })}
          </Location>

          {stations?.length > 0 && (
            <Details>
              (
              {t('venue_feat.information.distance_from_station', {
                // TODO: Change to duration when API is ready
                length: formatDistance(stations[0].distance.toString()),
                station: translate(stations[0].name_translations, language),
              })}
              )
            </Details>
          )}
        </Details>
        <Details>
          {cuisines && cuisines.length > 0 && (
            <span>
              {cuisines.map((cuisine, index) => (
                <span key={cuisine}>
                  {t(`cuisine.${cuisine}`, {
                    defaultValue:
                      cuisine.charAt(0).toUpperCase() + cuisine.slice(1),
                  })}
                  {index < cuisines.length - 1 && ', '}
                </span>
              ))}
            </span>
          )}
        </Details>

        <Details>
          {(budget_lunch_avg || budget_dinner_avg) && (
            <>
              {!!budget_lunch_avg && (
                <StyledPrice>
                  <Price
                    amount={budget_lunch_avg}
                    currency={currency as CurrencyFormat}
                    withAmountLimit
                  />{' '}
                  {t('general.lunch')}
                </StyledPrice>
              )}
              {!!budget_dinner_avg && (
                <StyledPrice>
                  <Price
                    amount={budget_dinner_avg}
                    currency={currency as CurrencyFormat}
                    withAmountLimit
                  />{' '}
                  {t('general.dinner')}
                </StyledPrice>
              )}
            </>
          )}
          {allowsSmartpay && (
            <TableCheckPay data-size="x-small">
              <TableCheckPayLogo
                src={TABLECHECK_LOGO_SYMBOL}
                alt="TableCheck pay logo"
              />
              <span>{t('search.smartpay')}</span>
            </TableCheckPay>
          )}
        </Details>
      </VenueDetails>

      <ActionBtns
        venueData={venueData}
        favoritesData={favoritesData}
        isSaved={isSaved}
        userMemo={userMemo?.memo ?? ''}
      />

      {/* // TODO: will be fixed with DPOR-435 */}
      {isSaved && <Memo userMemo={userMemo?.memo ?? ''} venueSlug={slug} />}
    </OverviewSection>
  );
}
