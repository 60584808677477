import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { panelStateAtom, VenueCard } from '@local/components';
import { useListFilters, useListView } from '@local/list';
import { selectedVenueAtom } from '@local/map-system';
import { ShopDetails } from '@local/types';
import { buildUrl, translate } from '@local/utils';

export function ListVenueCard({ shop }: { shop: ShopDetails }) {
  const [, { language }] = useTranslation();
  const setPanelState = useSetAtom(panelStateAtom);
  const setSelectedVenue = useSetAtom(selectedVenueAtom);
  const [searchParams] = useSearchParams();
  const { availabilityToShow } = useListView();
  const { listFilters } = useListFilters();

  return (
    <VenueCard
      venue={{
        slug: shop.slug,
        title: translate(shop.name_translations, language),
        cuisines: shop.cuisines,
        lunchAvg: shop.budget_lunch_avg,
        dinnerAvg: shop.budget_dinner_avg,
        currency: shop.currency,
        imageUrls: [shop.image_url],
        availability: shop.availability,
      }}
      href={buildUrl(`/${language}/${shop.slug}`, searchParams)}
    >
      <VenueCard.Header
        icon="bookmarkFilled"
        onIconClick={() => {
          setSelectedVenue({
            venue: {
              id: shop.id,
              slug: shop.slug,
              search_image: shop.image_url || DEFAULT_VENUE_IMAGE,
              name_translations: shop.name_translations,
              location_name_translations: [],
              cuisines: shop.cuisines,
              geocode: { lng: shop.longitude, lat: shop.latitude },
              budget_lunch_avg: shop.budget_lunch_avg,
              budget_dinner_avg: shop.budget_dinner_avg,
              availability: shop.availability,
            },
            shouldShowMapVenueCard: false,
          });
          setPanelState('save_list');
        }}
      />
      <VenueCard.Info />
      <VenueCard.SidePicture />
      <VenueCard.Budget />
      {availabilityToShow() === 'date' && (
        <VenueCard.DateTable
          query={{
            pax: listFilters.num_people,
            time: listFilters.time,
          }}
        />
      )}
      {availabilityToShow() === 'time' && (
        <VenueCard.TimeSlots
          query={{
            date: listFilters.date,
            pax: listFilters.num_people,
            time: listFilters.time,
          }}
        />
      )}
      <VenueCard.Memo userMemo={shop.memo} venueSlug={shop.slug} />
    </VenueCard>
  );
}
