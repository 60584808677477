import { styled } from '@linaria/react';
import { useAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';

import { DEFAULT_LIST_IMAGE, LIST_EMPTY_VIEW_IMG } from '@local/assets';
import { AuthContent, useGetUserQuery } from '@local/auth';
import {
  LoginPrompt,
  Panel,
  panelStateAtom,
  SmartLink,
  Spinner,
} from '@local/components';
import { AUTH_PANEL_STATES } from '@local/constants';
import { useDeviceType } from '@local/hooks';
import {
  CreateNewListCard,
  CreateNewListContent,
  ListCard,
  useCreateListQuery,
  useFetchAllListsQuery,
} from '@local/list';

import { DesktopContentWrapper } from '../Account/DesktopContentWrapper';

const MobileContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l4);

  & > h1 {
    padding-top: var(--spacing-l4);
  }
`;

const CardContainer = styled.main`
  display: flex;
  flex-direction: column;
`;

export function ListsPage() {
  const { isDesktop } = useDeviceType();
  const [t, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const {
    data: listsData,
    status: listsStatus,
    isLoading: isListsLoading,
  } = useFetchAllListsQuery(user);
  const { mutate: createList } = useCreateListQuery();

  const listsContainer = (
    <CardContainer data-testid="Lists Card Container">
      <CreateNewListCard onClick={() => setPanelState('create_list')} />
      {listsData?.lists.map((list) => (
        <SmartLink href={`/${language}/lists/${list.id}`} key={list.id}>
          <ListCard
            name={list.name}
            shopIds={list.shop_ids}
            isFavorite={list.is_favorite}
            imageUrl={list.image_url}
          />
        </SmartLink>
      ))}
    </CardContainer>
  );

  const listsContent = () => {
    if (!!listsData && listsStatus === 'success') {
      return (
        <>
          {isDesktop ? (
            <DesktopContentWrapper header={<h1>{t('list_feat.title')}</h1>}>
              {listsContainer}
            </DesktopContentWrapper>
          ) : (
            <MobileContentWrapper>
              <h1>{t('list_feat.title')}</h1>
              {listsContainer}
            </MobileContentWrapper>
          )}
        </>
      );
    }
    if (isListsLoading || isUserLoading) {
      return (
        <CardContainer>
          <Spinner isFullPage />
        </CardContainer>
      );
    }
    return (
      <LoginPrompt
        image={LIST_EMPTY_VIEW_IMG}
        title={t('list_feat.login_to_use')}
        subtitle={t('list_feat.info_msg')}
        onClick={() => setPanelState('login')}
      />
    );
  };

  return (
    <>
      <div data-testid="Lists Page Root">{listsContent()}</div>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {AUTH_PANEL_STATES.includes(panelState) && (
          <AuthContent
            subheaderContent={
              <Trans
                i18nKey="auth.login_feat_unlock"
                components={{ bold: <strong /> }}
              />
            }
          />
        )}

        {panelState === 'create_list' && (
          <CreateNewListContent
            imageUrl={DEFAULT_LIST_IMAGE}
            onSubmit={(newListData) => {
              createList(newListData, {
                onSuccess: () => {
                  setPanelState(null);
                },
              });
            }}
            onCancel={() => {
              setPanelState(null);
            }}
          />
        )}
      </Panel>
    </>
  );
}
