import { zodResolver } from '@hookform/resolvers/zod';
import { styled } from '@linaria/react';
import { LocaleCode } from '@tablecheck/locales';
import { Button, Input } from '@tablecheck/tablekit-react-css';
import { useReactSelectConfig } from '@tablecheck/tablekit-react-select';
import { t } from 'i18next';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'react-phone-number-input';
import ReactSelect, { ControlProps, CSSObjectWithLabel } from 'react-select';

import { useGetUserQuery } from '@local/auth';
import {
  Alert,
  allCountries,
  DEFAULT_SELECTED_COUNTRY,
  I18nCountry,
  PhoneField,
  Spinner,
} from '@local/components';
import { isAsianLocale } from '@local/i18n';

import { useEditProfileQuery } from '../../hooks/useEditProfileQuery';

import {
  createEditProfileValidation,
  EditProfileSchema,
} from './editProfileSchema';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);

  & > fieldset {
    display: flex;
    gap: var(--spacing-l4);
  }

  & > button {
    margin-top: calc(var(--spacing-l2) * -1);
    align-self: end;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-l2);
`;

interface OptionType {
  label: string;
  value: number;
}

interface Props {
  onSuccess: () => void;
}

export function EditProfileForm({ onSuccess }: Props) {
  const [, { language }] = useTranslation();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { mutate: editProfile, isPending } = useEditProfileQuery();
  const customerPhoneCountry = React.useMemo(
    () =>
      allCountries.find(
        (country: I18nCountry) =>
          user?.customer_user.phone &&
          country.shortName ===
            parsePhoneNumber(user.customer_user.phone)?.country,
      ) ?? DEFAULT_SELECTED_COUNTRY,
    [user?.customer_user.phone],
  );

  const {
    watch,
    formState: { errors, isDirty },
    control,
    handleSubmit,
    setValue,
    register,
  } = useForm<EditProfileSchema>({
    resolver: zodResolver(createEditProfileValidation(language)),
    mode: 'onChange',
    defaultValues: {
      ...(user?.customer_user?.kanji_first_name && {
        kanji_first_name: user.customer_user.kanji_first_name,
      }),
      ...(user?.customer_user?.kanji_last_name && {
        kanji_last_name: user.customer_user.kanji_last_name,
      }),
      first_name: user?.customer_user.first_name,
      last_name: user?.customer_user.last_name,
      email: user?.customer_user.email,
      sex: user?.customer_user.sex,
      phone: user?.customer_user.phone,
    },
  });
  const selectedSex = watch('sex');

  const { styles: baseSelectStyles, components: sexComponents } =
    useReactSelectConfig<OptionType>({
      dataTestId: 'Account Edit Sex Select',
    });

  const sexStyles = {
    ...baseSelectStyles,
    control: (
      provided: CSSObjectWithLabel,
      state: ControlProps<OptionType, false>,
    ) => {
      const baseControlStyles = baseSelectStyles.control
        ? baseSelectStyles.control(provided, state)
        : provided;
      return {
        ...baseControlStyles,
        padding: '4px 12px',
      };
    },
  };

  const sexOptions: OptionType[] = [
    { label: t('account.sex_unspecified'), value: 0 },
    { label: t('account.sex_male'), value: 1 },
    { label: t('account.sex_female'), value: 2 },
  ];

  const handleFormSubmit = handleSubmit((fields) => {
    editProfile(fields, {
      onSuccess,
    });
  });

  if (isUserLoading) {
    return <Spinner />;
  }

  const firstNameInput = (
    <InputWrapper>
      <label htmlFor="first_name">{t('auth.first_name')}</label>
      <Input
        id="first_name"
        aria-label="first name"
        data-testid="Account Edit First Name Input"
        data-stretch
        data-size="large"
        data-variant={errors.first_name && 'error'}
        {...register('first_name')}
        disabled={isPending}
      />
      <Alert
        data-variant="error"
        data-layout="icon"
        isShow={!!errors.first_name}
      >
        {t('auth.error.first_name_required')}
      </Alert>
    </InputWrapper>
  );

  const lastNameInput = (
    <InputWrapper>
      <label htmlFor="last_name">{t('auth.last_name')}</label>
      <Input
        id="last_name"
        aria-label="last name"
        data-testid="Account Edit Last Name Input"
        data-stretch
        data-size="large"
        data-variant={errors.last_name && 'error'}
        {...register('last_name')}
        disabled={isPending}
      />
      <Alert
        data-variant="error"
        data-layout="icon"
        isShow={!!errors.last_name}
      >
        {t('auth.error.last_name_required')}
      </Alert>
    </InputWrapper>
  );

  return (
    <Form
      onSubmit={(e) => {
        void handleFormSubmit(e);
      }}
    >
      {language === LocaleCode.Japanese && (
        <fieldset>
          <InputWrapper>
            <label htmlFor="kanji_last_name">{t('auth.kanji_last_name')}</label>
            <Input
              id="kanji_last_name"
              aria-label="kanji last name"
              data-testid="Account Edit Kanji Last Name Input"
              data-stretch
              data-size="large"
              data-variant={errors.kanji_last_name && 'error'}
              {...register('kanji_last_name')}
              disabled={isPending}
            />
            <Alert
              data-variant="error"
              data-layout="icon"
              isShow={!!errors.kanji_last_name}
            >
              {t('auth.error.kanji_last_name_required')}
            </Alert>
          </InputWrapper>

          <InputWrapper>
            <label htmlFor="kanji_first_name">
              {t('auth.kanji_first_name')}
            </label>
            <Input
              id="kanji_first_name"
              aria-label="kanji first name"
              data-testid="Account Edit Kanji First Name Input"
              data-stretch
              data-size="large"
              data-variant={errors.kanji_first_name && 'error'}
              {...register('kanji_first_name')}
              disabled={isPending}
            />
            <Alert
              data-variant="error"
              data-layout="icon"
              isShow={!!errors.kanji_first_name}
            >
              {t('auth.error.kanji_first_name_required')}
            </Alert>
          </InputWrapper>
        </fieldset>
      )}

      <fieldset>
        {isAsianLocale(language) ? (
          <>
            {lastNameInput}
            {firstNameInput}
          </>
        ) : (
          <>
            {firstNameInput}
            {lastNameInput}
          </>
        )}
      </fieldset>

      <InputWrapper>
        <label htmlFor="email">{t('auth.email')}</label>
        <Input
          id="email"
          aria-label="email"
          data-size="medium"
          data-stretch
          {...register('email')}
          data-variant={errors.email && 'error'}
          disabled={!user?.customer_user.has_set_password}
        />
        <Alert data-variant="error" data-layout="icon" isShow={!!errors.email}>
          {t('auth.error.email_required')}
        </Alert>
      </InputWrapper>

      <InputWrapper>
        <label htmlFor="phone">{t('auth.phone_number')}</label>
        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange } }) => (
            <InputWrapper>
              <PhoneField
                onChange={onChange}
                defaultCountry={customerPhoneCountry}
                hasError={!!errors.phone}
                customerPhoneNumber={user?.customer_user.phone}
              />
              <Alert
                data-testid="Error Phone"
                data-variant="error"
                data-layout="icon"
                isShow={!!errors.phone}
              >
                {t('auth.error.phone_number_required')}
              </Alert>
            </InputWrapper>
          )}
        />
      </InputWrapper>

      <InputWrapper>
        <label htmlFor="sex">{t('auth.gender')}</label>
        <ReactSelect
          id="sex"
          styles={sexStyles}
          components={sexComponents}
          isSearchable={false}
          options={sexOptions}
          onChange={(option) => {
            setValue('sex', (option as { value: number }).value, {
              shouldDirty: true,
            });
          }}
          value={sexOptions.find((option) => option.value === selectedSex)}
          isDisabled={isPending}
        />
      </InputWrapper>

      <Button
        data-testid="Account Edit Submit Btn"
        aria-busy={isPending}
        disabled={!isDirty}
        type="submit"
      >
        {t('account.action_btns.save_profile')}
      </Button>
    </Form>
  );
}
