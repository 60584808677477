import { useQuery } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import {
  Subscription,
  UserResponseDataType,
  VenueFavorites,
} from '@local/types';

import { fetchUserFavorites } from '../userApi';

export const useFetchUserSubscriptionsQuery = (
  user: UserResponseDataType | undefined,
) =>
  useQuery({
    queryKey: [USER_QUERY_KEY, 'favorites'],
    queryFn: () => fetchUserFavorites(),
    enabled: !!user,
    select: (data) => {
      const { shops } = data;
      const { favorites: favoritedOrSubscribedVenues } = data;
      const subscriptions: Subscription[] = [];

      // TODO: Unfortunately the endpoint currently returns both shops and favorites arrays. We need the is_subscribed values from the favorites array and the shop name from the shops array. The only value the two have in common is shop slug
      // REF: https://tablecheck.atlassian.net/browse/DPOR-752
      const subscribedVenuesSlugsLookup = favoritedOrSubscribedVenues?.reduce(
        (acc: Record<VenueFavorites['shop_slug'], true>, venue) => {
          if (venue.is_subscribed && venue.shop_slug) {
            acc[venue.shop_slug] = true; // Value is true because this object is used as a lookup table and therefore the value doesn't matter as long as it's truthy
          }
          return acc;
        },
        {},
      );

      shops?.forEach((shop) => {
        if (subscribedVenuesSlugsLookup?.[shop.slug]) {
          subscriptions.push({
            shop_slug: shop.slug,
            shop_name_translations: shop.name_translations,
          });
        }
      });

      return subscriptions;
    },
  });
