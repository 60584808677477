import * as React from 'react';

export function useOutsideClick<T extends HTMLElement = HTMLElement>(
  callback: () => void,
  externalRef?: React.RefObject<T>,
): React.RefObject<T> {
  const internalRef = React.useRef<T>(null);
  const ref = externalRef ?? internalRef;

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, ref]);

  return ref;
}
