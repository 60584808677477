import Cookies, { CookieAttributes } from 'js-cookie';

import { CONFIG } from '@local/configs';
import { type AuthToken, Cookie, type UniverseSlug } from '@local/types';

const cookieAttributes: CookieAttributes = {
  path: '/',
  secure: CONFIG.VITE_APP_ENVIRONMENT !== 'development',
  sameSite: 'strict',
};

const CookieStringEncoder = Cookies.withConverter({
  write(value: string) {
    return encodeURIComponent(value);
  },
  read(value: string) {
    return decodeURIComponent(value);
  },
});
// TODO: change cookieService to storageService
// Storage handler that uses localStorage for Cordova and cookies for web
const storage = {
  set(key: string, value: string): void {
    if (CONFIG.IS_CORDOVA) {
      localStorage.setItem(key, encodeURIComponent(value));
    } else {
      CookieStringEncoder.set(key, value, cookieAttributes);
    }
  },

  get(key: string): string | null {
    if (CONFIG.IS_CORDOVA) {
      const value = localStorage.getItem(key);
      return value ? decodeURIComponent(value) : null;
    }
    return CookieStringEncoder.get(key) || null;
  },

  remove(key: string): void {
    if (CONFIG.IS_CORDOVA) {
      localStorage.removeItem(key);
    } else {
      CookieStringEncoder.remove(key, cookieAttributes);
    }
  },
};

export const cookieService = {
  isTokenExpired(authToken: AuthToken): boolean {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= authToken.created_at + authToken.expires_in;
  },

  getAuthToken(): AuthToken | null {
    const token = storage.get(Cookie.AuthenticationKey);
    if (!token) {
      return null;
    }
    try {
      return JSON.parse(token) as AuthToken;
    } catch {
      return null;
    }
  },

  setAuthToken(authToken: AuthToken): void {
    const authTokenString = JSON.stringify(authToken);
    storage.set(Cookie.AuthenticationKey, authTokenString);
  },

  removeAuthToken(): void {
    storage.remove(Cookie.AuthenticationKey);
  },

  getCsrfToken(): string | null {
    const csrfToken = storage.get(Cookie.CsrfToken);
    if (!csrfToken) {
      return null;
    }
    return csrfToken;
  },

  setUniverse(universe: UniverseSlug): void {
    storage.set(Cookie.TCUniverse, universe as string);
  },

  getUniverse(): UniverseSlug | null {
    const universe = storage.get(Cookie.TCUniverse);
    if (!universe) {
      return null;
    }
    return universe as UniverseSlug;
  },
} as const;
