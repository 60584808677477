import { t } from 'i18next';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useGetUserQuery } from '@local/auth';
import { panelStateAtom, useToast } from '@local/components';
import { CONFIG } from '@local/configs';
import { useDeviceType } from '@local/hooks';
import {
  useCreateListQuery,
  useSaveOrUnsaveShopToListQuery,
} from '@local/list';
import { logger } from '@local/services';
import type {
  CreateListData,
  SaveListsRequest,
  VenueFavorites,
  VenueInfo,
} from '@local/types';
import {
  useFetchUserFavoritesVenueQuery,
  useUpdateUserFavoritesVenueQuery,
} from '@local/user';
import { shareUrl, translate } from '@local/utils';

export const useActionBtns = (
  venueData: VenueInfo,
  setOnLoginSubmit: (onLoginSubmit: () => void) => void,
) => {
  const { isDesktop } = useDeviceType();
  const [, { language }] = useTranslation();
  const setPanelState = useSetAtom(panelStateAtom);
  const { _id: id, slug, name_translations } = venueData;

  const {
    saveVenueToastContent,
    createVenueToastContent,
    subscribeVenueToastContent,
    errorToastContent,
    setIsToastOpen,
    setToastContent,
  } = useToast();

  const { data: user } = useGetUserQuery();
  const { refetch } = useFetchUserFavoritesVenueQuery(slug, user);
  const { mutate: updateFavorites } = useUpdateUserFavoritesVenueQuery(slug);
  const { mutate: createList } = useCreateListQuery();
  const { mutate: saveOrUnsaveShopToList } = useSaveOrUnsaveShopToListQuery();

  return {
    handleSave: () => {
      const onSaveClick = () => setPanelState('save_list');

      if (user) {
        onSaveClick();
      } else {
        setOnLoginSubmit(() => onSaveClick);
        setPanelState('login');
      }
    },
    handleSubscribe: (data: Partial<VenueFavorites>) => {
      const onSubscribeClick = () =>
        updateFavorites(data, {
          onSuccess: (response) => {
            setToastContent(
              subscribeVenueToastContent(
                response.favorites[0].is_subscribed,
                translate(name_translations, language),
              ),
            );
            setIsToastOpen(true);
            if (!user) setPanelState(null);
          },
        });

      if (user) {
        onSubscribeClick();
      } else {
        setOnLoginSubmit(() => onSubscribeClick);
        setPanelState('login');
      }
    },
    handleReview: () => {
      if (user) {
        // will be handled in later ticket
      } else {
        setPanelState('login');
      }
    },
    handleVisited: () => {
      if (user) {
        // will be handled in later ticket
      } else {
        setPanelState('login');
      }
    },
    handleShare: async () => {
      if (isDesktop) {
        setPanelState('share_venue');
      } else {
        await shareUrl({
          title: t('venue_feat.directions.venue_on_tablecheck', {
            venue: translate(name_translations, language),
          }),
          url: CONFIG.IS_CORDOVA
            ? `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${slug}`
            : `${window.location.origin}/${language}/${slug}`,
        }).catch((error) => {
          logger.error('Error sharing link:', error);
          setToastContent(
            errorToastContent(t('toast.unsuccessful_venue_share')),
          );
          setIsToastOpen(true);
        });
      }
    },

    handleCreateList: (newListData: CreateListData) => {
      createList(newListData, {
        onSuccess: () => {
          void refetch();
          setPanelState(null);
          setToastContent(
            createVenueToastContent(newListData.name, setPanelState),
          );
          setIsToastOpen(true);
        },
      });
    },
    handleSaveToList: (listsToUpdate: SaveListsRequest) => {
      saveOrUnsaveShopToList(
        { venueId: id, listIdsToUpdate: listsToUpdate },
        {
          onSuccess: (response) => {
            void refetch();
            setPanelState(null);
            setToastContent(
              saveVenueToastContent(
                response.lists,
                () => {
                  setPanelState('save_list');
                },
                t('general.edit'),
              ),
            );
            setIsToastOpen(true);
          },
          onError: () => {
            setPanelState(null);
            setToastContent(
              errorToastContent(t('toast.unsuccessful_list_save')),
            );
            setIsToastOpen(true);
          },
        },
      );
    },
  };
};
