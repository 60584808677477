import { FavoriteFilled } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useSetAtom } from 'jotai';

import { DEFAULT_LIST_IMAGE, LIST_EMPTY_VIEW_IMG } from '@local/assets';
import { useGetUserQuery } from '@local/auth';
import {
  AvailabilityChecker,
  LoginPrompt,
  Spinner,
  panelStateAtom,
} from '@local/components';
import { EmptyListView, useListFilters, useListView } from '@local/list';

import { ListVenueCard } from './ListVenueCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l4);
  gap: var(--spacing-l4);

  & > h1 {
    font-size: 32px;
    line-height: 42px;
    word-wrap: break-word;
  }
  & > img {
    width: 100%;
    height: var(--list-view-img-height);
    object-fit: cover;
    border-radius: var(--border-radius-small);
  }
  & > p {
    font: var(--body-1);
    word-wrap: break-word;
  }
`;

const AddDescriptionButton = styled.span`
  color: var(--text-subtle);
  text-decoration: underline;
  font: var(--body-1);
  width: fit-content;
  cursor: pointer;
`;

const FavoriteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--list-view-img-height);
  border-radius: var(--border-radius-small);
  background-color: var(--surface-raised-hover);
`;

const CardContainer = styled.main`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-l6);
  & > h3 {
    margin-bottom: var(--spacing-l5);
    font: var(--label);
    font-size: 16px;
  }
  & > section {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l6);
  }
`;

export function ListViewContent() {
  const { data: user } = useGetUserQuery();
  const setPanelState = useSetAtom(panelStateAtom);
  const { listFilters, updateListFilters } = useListFilters();
  const {
    listDetail: { data: listData, isLoading: isListLoading },
  } = useListView();

  if (!user) {
    return (
      <LoginPrompt
        image={LIST_EMPTY_VIEW_IMG}
        title={t('list_feat.login_to_view_list')}
        onClick={() => setPanelState('login')}
      />
    );
  }

  if (!listData || isListLoading) {
    return <Spinner isFullPage />;
  }

  if (listData.list.shop_ids.length === 0) {
    return (
      <EmptyListView
        title={listData.list.name}
        description={listData.list.description}
      />
    );
  }

  return (
    <Wrapper>
      <h1 data-testid="List Title">
        {listData.list.is_favorite
          ? t('list_feat.favorites')
          : listData.list.name}
      </h1>
      {listData.list.is_favorite ? (
        <FavoriteIconWrapper>
          <FavoriteFilled size={64} fill="var(--text-subtle)" />
        </FavoriteIconWrapper>
      ) : (
        <img
          src={
            listData.list.image_url?.length > 0
              ? listData.list.image_url
              : DEFAULT_LIST_IMAGE
          }
          alt={listData.list.name}
          loading="lazy"
        />
      )}
      {listData.list.description?.length > 0 ? (
        <p data-testid="List Description">{listData.list.description}</p>
      ) : (
        <AddDescriptionButton
          data-testid="List Add Description Btn"
          role="button"
          onClick={() => setPanelState('update_list_desc')}
        >
          {t('list_feat.add_description')}
        </AddDescriptionButton>
      )}
      <div>
        <AvailabilityChecker
          values={{
            date: listFilters.date,
            num_people: listFilters.num_people,
            time: listFilters.time,
          }}
          onSubmit={(data) => {
            updateListFilters({ ...data });
          }}
          onReset={() => {
            updateListFilters(undefined, { reset: true });
          }}
        />
      </div>
      <CardContainer>
        <h3>
          {t('list_feat.venue_number', {
            count: listData.list.shop_ids.length,
          })}
        </h3>
        <section>
          {listData.list.shops.map((shop) => (
            <ListVenueCard key={shop.id} shop={shop} />
          ))}
        </section>
      </CardContainer>
    </Wrapper>
  );
}
