import { zodResolver } from '@hookform/resolvers/zod';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';

import { PasswordField, PasswordRequirements } from '@local/auth';
import { Alert, Footer } from '@local/components';
import type { ChangePasswordErrorResponse } from '@local/types';

import { useChangePasswordQuery } from '../../../hooks/useChangePasswordQuery';
import {
  ChangePasswordSchema,
  createChangePasswordValidator,
} from '../changePasswordSchema';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4);

  & > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l2);
  }
`;

const StyledPasswordRequirements = styled(PasswordRequirements)`
  padding: 0 var(--spacing-l4);
`;

interface Props {
  email: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export function ChangePasswordContent({ email, onSuccess, onCancel }: Props) {
  const { mutate: changePw, isPending } = useChangePasswordQuery();
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
    setError,
  } = useForm<ChangePasswordSchema>({
    resolver: zodResolver(createChangePasswordValidator(email)),
    mode: 'onChange',
    defaultValues: {
      current_password: '',
      password: '',
    },
  });
  const handleFormSubmit = handleSubmit((fields) => {
    changePw(fields, {
      onSuccess,
      onError: (error) => {
        const errorResponse = error as unknown as ChangePasswordErrorResponse;
        if (errorResponse.errors.current_password) {
          setError('current_password', {
            type: 'backend',
            message: errorResponse.errors.current_password[0],
          });
        }

        if (errorResponse.errors.password) {
          setError('password', {
            type: 'backend',
            message: errorResponse.errors.password[0],
          });
        }
      },
    });
  });

  return (
    <Form
      id="change-pw-form"
      autoComplete="off"
      onSubmit={(e) => {
        void handleFormSubmit(e);
      }}
    >
      <Controller
        name="current_password"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            <label htmlFor="current_password">
              {t('account.current_password')}
            </label>
            <PasswordField
              data-testid="Account Current Pwd Input"
              id="current_password"
              name="current_password"
              value={value}
              onChangeValue={onChange}
              data-variant={errors.current_password && 'error'}
              aria-label="current_password"
              data-stretch
              placeholder={t('account.enter_current_password')}
            />
            <Alert
              data-testid="Account Current Pwd Error"
              data-variant="error"
              data-layout="icon"
              isShow={errors.current_password?.type === 'backend'}
            >
              {t('account.errors.password_incorrect')}
            </Alert>
          </div>
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            <label htmlFor="password">{t('account.new_password')}</label>
            <PasswordField
              data-testid="Account New Pwd Input"
              id="password"
              value={value}
              onChangeValue={onChange}
              data-variant={errors.password && 'error'}
              name="password"
              aria-label="password"
              data-stretch
              placeholder={t('account.enter_new_password')}
            />
            <Alert
              data-testid="Account New Pwd Error"
              data-variant="error"
              data-layout="icon"
              isShow={errors.password?.type === 'backend'}
            >
              {t('account.errors.password_used')}
            </Alert>
            <StyledPasswordRequirements
              password={value}
              email={email}
              isDirty={!!dirtyFields.password}
            />
          </div>
        )}
      />
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-variant="primary"
          form="change-pw-form"
          data-testid="Account Update Pwd Submit Btn"
          aria-busy={isPending}
          disabled={!isValid}
          type="submit"
        >
          {t('general.update')}
        </Button>
      </Footer>
    </Form>
  );
}
