import { useAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  PaymentsList,
  TermsConditionsContent,
  ViewPaymentContent,
} from '@local/account';
import { useGetUserQuery } from '@local/auth';
import { Panel, panelStateAtom, Spinner } from '@local/components';
import { useDeviceType, useUniverse } from '@local/hooks';

import { DesktopContentWrapper } from '../DesktopContentWrapper';
import { MobileContentWrapper } from '../MobileContentWrapper';

export function PaymentsRoot() {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const { universeName } = useUniverse();
  const [t, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const [selectedPaymentId, setSelectedPaymentId] = React.useState<
    string | null
  >(null);
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();

  React.useEffect(() => {
    if (!user && !isUserLoading) {
      navigate(`/${language}/${universeName}`);
    }
  }, [user, isUserLoading, navigate, language, universeName]);

  if (isUserLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <div data-testid="Account Payments Page Root">
        {isDesktop ? (
          <DesktopContentWrapper header={<h1>{t('account.transactions')}</h1>}>
            <PaymentsList
              onCardClick={(paymentId) => {
                setSelectedPaymentId(paymentId);
                setPanelState('view_payment');
              }}
            />
          </DesktopContentWrapper>
        ) : (
          <MobileContentWrapper title={t('account.transactions')}>
            <PaymentsList
              onCardClick={(paymentId) => {
                setSelectedPaymentId(paymentId);
                setPanelState('view_payment');
              }}
            />
          </MobileContentWrapper>
        )}
      </div>

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'terms_conditions' && (
          <TermsConditionsContent onClose={() => setPanelState(null)} />
        )}
        {panelState === 'view_payment' && selectedPaymentId && (
          <ViewPaymentContent
            selectedPaymentId={selectedPaymentId}
            onClose={() => {
              setPanelState(null);
            }}
          />
        )}
      </Panel>
    </>
  );
}
