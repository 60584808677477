import { parseISO } from 'date-fns';

import { Payment } from '@local/types';
import { DateFormat, formatDisplayDate } from '@local/utils';

export function usePayments() {
  const mapPaymentStatusToStr = (paymentStatus: Payment['payment_status']) => {
    switch (paymentStatus) {
      case 'paid':
      case 'cancel_fee':
        return 'paid';
      case 'refunded':
        return 'refunded';
      case 'authorized':
        return 'deposit';
      case 'pending':
      case 'pending_3ds':
        return 'pending';
      case 'stored':
        return 'stored';
      case 'voided':
        return 'voided';
      default:
        return paymentStatus;
    }
  };

  const mapPaymentTypeToStr = (
    paymentType: Payment['payment_type'],
    paymentStatus: Payment['payment_status'],
  ) => {
    switch (paymentType) {
      case 'prepay':
        return 'prepay';
      case 'smartpay':
        return 'contactless_pay';
      case 'deposit':
      case 'register':
        return paymentStatus === 'cancel_fee' ? 'cancel_fee' : 'auth_hold';
      default:
        return paymentType;
    }
  };

  const matchStatusToVariant = (status: string) => {
    switch (status) {
      case 'paid':
        return 'success';
      case 'refunded':
        return 'info';
      case 'deposit':
        return 'warning';
      case 'voided':
        return 'neutral';
      default:
        return 'neutral';
    }
  };

  const groupPaymentsByMonth = (payments: Payment[], language: string) =>
    payments.reduce((paymentsMap, payment) => {
      const monthYear = formatDisplayDate(
        parseISO(payment.created_at),
        DateFormat.YearMonth,
        language,
      );
      if (!paymentsMap.has(monthYear)) {
        paymentsMap.set(monthYear, []);
      }
      paymentsMap.get(monthYear)?.push(payment);
      return paymentsMap;
    }, new Map<string, Payment[]>());

  return {
    mapPaymentStatusToStr,
    mapPaymentTypeToStr,
    matchStatusToVariant,
    groupPaymentsByMonth,
  };
}
