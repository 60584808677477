import { useQuery } from '@tanstack/react-query';

import { STALETIME_10_SECONDS, USER_QUERY_KEY } from '@local/constants';
import type { UserResponseDataType } from '@local/types';

import { fetchAllLists } from '../listApi';

export const useFetchAllListsQuery = (user: UserResponseDataType | undefined) =>
  useQuery({
    queryKey: [USER_QUERY_KEY, 'lists'],
    queryFn: () => fetchAllLists(),
    staleTime: STALETIME_10_SECONDS,
    enabled: !!user,
  });
