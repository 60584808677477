import { Copy, Email, LogoFacebook, LogoX } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useSetAtom } from 'jotai';
import * as React from 'react';

import { panelStateAtom, Spacer, useToast } from '@local/components';
import { FACEBOOK_BASE_URL, X_BASE_URL } from '@local/constants';
import { logger } from '@local/services';
import { VenueInfo } from '@local/types';
import { openUrl } from '@local/utils';

const Wrapper = styled.section`
  margin: var(--spacing-l4);
`;
const Description = styled.div`
  display: flex;
`;
const Info = styled.div`
  margin: 0 var(--spacing-l4);
  align-content: center;

  * {
    line-height: 30px;
  }
`;
const Image = styled.img`
  height: 60px;
  width: 60px;
  border-radius: var(--border-radius-small);
  object-fit: cover;
`;
const BtnWrapper = styled.div`
  margin-top: var(--spacing-l4);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: var(--spacing-l4);

  button {
    justify-content: flex-start;
  }
`;

interface Props {
  image: string;
  title: string;
  address: VenueInfo['address'] | string;
  cuisines: VenueInfo['cuisines'];
}

export function ShareContent({
  image,
  title,
  address,
  cuisines,
}: Props): JSX.Element {
  const setPanelState = useSetAtom(panelStateAtom);
  const {
    errorToastContent,
    successToastContent,
    setIsToastOpen,
    setToastContent,
  } = useToast();

  const shareBtns = React.useMemo(
    () => [
      {
        icon: <Copy />,
        text: t('venue_feat.share.copy_link'),
        dataTestId: 'Venue Copy Link',
        onClick: () => {
          navigator.clipboard
            .writeText(window.location.href)
            .then(() => {
              setToastContent(successToastContent(t('toast.successful_copy')));
              setIsToastOpen(true);
            })
            .catch((err) => {
              logger.error('Failed to copy: ', err);
              setToastContent(
                errorToastContent(t('toast.unsuccessful_venue_copy')),
              );
              setIsToastOpen(true);
            });
          setPanelState(null);
        },
      },
      {
        icon: <LogoX />,
        text: t('venue_feat.share.on_x'),
        onClick: () => {
          const text = t('venue_feat.directions.venue_on_tablecheck', {
            venue: title,
          });
          const xUrl = `${X_BASE_URL}intent/post?url=${window.location.href}&text=${text}`;
          openUrl(xUrl, '_blank', 'noreferrer');
        },
      },
      {
        icon: <LogoFacebook />,
        text: t('venue_feat.share.on_fb'),
        onClick: () => {
          const facebookUrl = `${FACEBOOK_BASE_URL}sharer/sharer.php?u=${window.location.href}`;
          openUrl(facebookUrl, '_blank', 'noreferrer');
        },
      },
      {
        icon: <Email />,
        text: t('venue_feat.share.via_email'),
        onClick: () => {
          const subject = t('venue_feat.directions.venue_on_tablecheck', {
            venue: title,
          });
          window.location.href = `mailto:?subject=${subject}&body=${window.location.href}`;
        },
      },
    ],
    [],
  );
  return (
    <Wrapper data-testid="Venue Share Modal">
      <Description>
        <Image src={image} alt={title} />
        <Info>
          <h4>{title}</h4>
          <div>
            <span>
              {typeof address === 'string'
                ? address
                : t('general.city_region', {
                    city: address.city,
                    region: address.region,
                  })}
              {cuisines.length > 0 && (
                <>
                  <Spacer showBullet />
                  {t(`cuisine.${cuisines[0]}`)}
                </>
              )}
            </span>
          </div>
        </Info>
      </Description>
      <BtnWrapper>
        {shareBtns.map((item) => (
          <Button
            key={item.text}
            onClick={item.onClick}
            data-variant="tertiary"
            data-testid={item.dataTestId}
          >
            {item.icon}
            {item.text}
          </Button>
        ))}
      </BtnWrapper>
    </Wrapper>
  );
}
