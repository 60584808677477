import { LocaleCode, TranslationsArray } from '@tablecheck/i18n';
import { useTranslation } from 'react-i18next';

import type { ReservationVenueInfo } from './reservationsTypes';

export interface Address {
  id: string;
  city: string;
  country: string;
  postal_code: string;
  region: string;
  street: string;
  street2: string;
  tag: string;
}

export interface VenueInfo {
  _id: string;
  locale: string;
  booking_page_mode: BookingPageMode;
  slug: string;
  rating: string;
  price: string;
  reviews_count: string;
  cuisines: string[];
  location_name_translations: TranslationsArray;
  current_status: VenueStatus;
  image_urls?: { original: string; id: string }[];
  name_translations: TranslationsArray;
  stations: {
    name_translations: TranslationsArray;
    distance: number;
    geocode: {
      lon: number;
      lat: number;
    };
  }[];
  closest_station: {
    name_translations: TranslationsArray;
    distance: string;
    walk_time: string;
  };
  budget_lunch_avg: string;
  budget_dinner_avg: string;
  budget_avg: string;
  currency: string;
  is_smartpay: boolean;
  url: string;
  banner_image?: string;
  search_image?: string | null;
  geocode: {
    lon: number;
    lat: number;
  } | null;
  google_place_ref: string;
  phone?: string;
  phone_natl?: string;
  opening_hours?: Record<
    | 'Sunday'
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday',
    string | null
  >;
  address: Address;
  alt_address: Address;
  features: string[];
  tagline_translations: TranslationsArray;
  content_title_translations: TranslationsArray;
  content_body_translations: TranslationsArray;
}

export type BookingPageMode = 'v1' | 'v2' | 'both';

export interface Review {
  rating: number;
  likings: string[];
  comment: string;
  date: string;
  imageUrls: string[];
  status: string;
}

export interface VenueFavorites {
  shop_slug: string;
  is_subscribed: boolean;
  is_favorite: boolean;
  is_deleted: boolean;
  shop_id: string;
  has_visited?: boolean; // TODO: These two fields are curently not present in the API response so have been marked as optional. REF: https://tablecheck.atlassian.net/browse/DPOR-398, https://tablecheck.atlassian.net/browse/DPOR-335
  reviews?: Review[];
}

export interface VenueCard {
  id: string;
  name_translations: TranslationsArray;
  rating: string;
  price: string;
  reviews_count: string;
  cuisines: string[];
  location_name_translations: TranslationsArray;
  distance: string;
  current_status: VenueStatus;
  image: { original: string; id: string }[];
  description: string;
}

export interface VenueAvailability {
  daily_avail: { date: string; day: string; avail: boolean }[];
  time_avail: Record<string, boolean>;
}

export interface CardCarouselProps {
  id: string;
  children: React.ReactNode;
  showViewMore?: boolean;
  title?: string;
  showMoreAction?: () => void;
}

export type TFunction = ReturnType<typeof useTranslation>[0];

type TranslatableField =
  | 'addressTranslations'
  | 'descriptionTranslations'
  | 'labelTranslations'
  | 'cancelPolicyTranslations'
  | 'name_translations'
  | 'contentTitleTranslations'
  | 'previewTranslations'
  | 'bookingPolicyTranslations'
  | 'footerTranslations'
  | 'termsCheckboxTextTranslations'
  | 'offersCheckboxTextTranslations'
  | 'termsUrlTranslations'
  | 'privacyUrlTranslations'
  | 'contentBodyTranslations'
  | 'questionTranslations'
  | 'taglineTranslations'
  | 'howToRedeemTranslations'
  | 'finePrintTranslations'
  | 'urlTranslations'
  | 'returnUrlTranslations'
  | 'waitlistPolicyTranslations'
  | 'requestPolicyTranslations';

export type MerchantTranslatable<
  TranslatableFieldKey extends TranslatableField,
> = {
  [key in TranslatableFieldKey]: TranslationsArray;
};

export interface Fields {
  days: MenuItemDay[];
  meals?: Meal[];
  isHoliday?: boolean;
  validStartTime?: number | null;
  validEndTime?: number | null;
  validDateRanges?: DateRange[];
  journeyDate?: string | null;
  language: string;
}

export type RestrictionType = 'times' | 'datesDays';

export type MenuItemDay = 'su' | 'm' | 'tu' | 'w' | 'th' | 'f' | 'sa';

export type Meal =
  | 'breakfast'
  | 'lunch'
  | 'tea'
  | 'dinner'
  | 'night'
  | 'all_day';

export interface DateRange {
  endDate: string;
  id: string;
  startDate: string;
}
export type ShopServiceCategory = {
  id: string;
  imageUrl?: string;
} & MerchantTranslatable<'name_translations' | 'descriptionTranslations'>;

export interface Restriction {
  id: 'times' | 'datesDays' | 'dates' | 'days';
  getLabel: (t: TFunction) => string;
  getText: (t: TFunction) => string;
  'data-testid': string;
}

export interface MinimalistCardType {
  titleTranslations: TranslationsArray;
  owner?: { name: string; image: string };
  venues?: string[];
  image: { original: string; id: string }[];
}

export interface VenueStatus {
  is_open: boolean;
  opening_time: string;
  closing_time: string;
}

export enum TaxFee {
  Included = 'included',
  Excluded = 'excluded',
  Exempt = 'exempt',
}

export enum ServiceFee {
  Included = 'included',
  Excluded = 'excluded',
  None = 'none',
  Amount = 'amount',
  Percent = 'percent',
}

export interface VenueResponse {
  shops: VenueInfo[];
}

export interface VenueFavoritesResponse {
  shops: ReservationVenueInfo[];
  favorites: VenueFavorites[];
}

export interface GalleryResponse {
  images: {
    id: string;
    thumbnail: string;
    original: string;
    small: string;
    tiny: string;
    large: string;
    position: number;
  }[];
}

export interface MenuItemsResponse {
  availability: string[];
  menu_items: MenuItem[];
}

export interface MenuItem {
  id: string;
  name_translations: { locale: LocaleCode; translation: string }[];
  image_urls: { original: string; id: string };
  price: string;
  quantity: string;
  description_translations: { locale: LocaleCode; translation: string }[];
  tax_type: TaxFee;
  service_fee_type: ServiceFee;
  ingredients: { translation: string; locale: LocaleCode }[][];
  valid_date_ranges: DateRange[];
  valid_start_time: number | null | undefined;
  valid_end_time: number | null | undefined;
  days: MenuItemDay[];
  is_holiday: boolean;
}

export interface AvailabilityResponse {
  availability_calendar: {
    type: 'error' | 'success';
    data: Record<string, Record<string, boolean>>;
  };
}
