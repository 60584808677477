import { useAtom } from 'jotai';

import { panelStateAtom } from '@local/components';

import { LoginContent } from '../LoginContent/LoginContent';
import { ResetPasswordContent } from '../ResetPasswordContent/ResetPasswordContent';
import { SignUpContent } from '../SignUpContent/SignUpContent';

export function AuthContent({
  subheaderContent,
  onSubmit,
}: {
  subheaderContent?: JSX.Element;
  onSubmit?: () => void;
}) {
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  // TODO: Look into whether nextAction() can be replaced with nextPanelAction(). REF: https://tablecheck.atlassian.net/browse/DPOR-797
  const nextAction = () => (onSubmit ? onSubmit() : setPanelState(null));
  return (
    <>
      {panelState === 'login' && (
        <LoginContent
          data-testid="Login Content"
          onSubmit={nextAction}
          subheaderContent={subheaderContent}
        />
      )}
      {(panelState === 'reset_password' ||
        panelState === 'reset_password_confirmation') && (
        <ResetPasswordContent />
      )}
      {panelState === 'sign_up' && <SignUpContent onSubmit={nextAction} />}
    </>
  );
}
