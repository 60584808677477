import { styled } from '@linaria/react';
import * as React from 'react';

const Wrapper = styled.div`
  margin: 0 calc(var(--spacing-l4) * -1);
  display: flex;
  position: relative;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: var(--spacing-l6);
  max-height: 400px;
  & > div {
    display: flex;
    gap: var(--spacing-l3);
    padding-inline: var(--spacing-l4);
  }
`;

const Title = styled.h5`
  margin: var(--spacing-l2) 0;
`;

interface Props {
  children: React.ReactNode;
  title?: string;
}

export function MobileContentWrapper({ children, title }: Props) {
  return (
    <>
      {title && <Title>{title}</Title>}
      <Wrapper>{children}</Wrapper>
    </>
  );
}
