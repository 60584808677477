import { Draggable } from '@carbon/icons-react';
import Slider, { SliderProps } from 'rc-slider';

import { ICON_SIZE_16 } from '@local/constants';
import 'rc-slider/assets/index.css';

type SliderHandleNode = React.ReactElement<{
  style?: React.CSSProperties;
  className?: string;
  value?: number;
  offset?: number;
  children?: React.ReactNode;
}>;

export function RcSlider({ ...props }: SliderProps) {
  const handleRender = (node: SliderHandleNode): SliderHandleNode => ({
    ...node,
    props: {
      ...node.props,
      children: (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Draggable size={ICON_SIZE_16} />
        </div>
      ),
    },
  });

  return (
    <Slider
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 var(--spacing-l3)',
      }}
      range
      allowCross={false}
      handleRender={handleRender}
      styles={{
        track: {
          backgroundColor: 'var(--black)',
          height: 'var(--spacing-l2)',
        },
        rail: {
          backgroundColor: 'var(--grey-400)',
          height: 'var(--spacing-l2)',
        },
        handle: {
          backgroundColor: 'white',
          width: 'var(--spacing-l6)',
          height: 'var(--spacing-l6)',
          alignSelf: 'center',
          opacity: 1,
          boxShadow: 'var(--elevation-small)',
          border: '1px solid var(--border-transparent)',
        },
      }}
      {...props}
    />
  );
}
