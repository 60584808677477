import { styled } from '@linaria/react';
import { t } from 'i18next';
import * as React from 'react';

const Grid = styled.div`
  display: grid;
  gap: var(--spacing-l3);
  grid-auto-rows: min-content;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: auto;
  width: 100%;
`;

const ShowMoreButton = styled.button`
  color: var(--primary);
  cursor: pointer;
  font: var(--body-2);
  text-decoration: underline;
  width: fit-content;
`;

interface Props {
  initialVisibleCount?: number;
  isGrid?: boolean;
  children: React.ReactNode;
}

export function Collapsible({
  initialVisibleCount = 3,
  isGrid = false,
  children,
}: Props) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const numOfChildrenElements = React.Children.count(children);
  const shouldShowButton = numOfChildrenElements > initialVisibleCount;
  const visibleChildren = isExpanded
    ? children
    : React.Children.toArray(children).slice(0, initialVisibleCount);

  return (
    <>
      {isGrid ? <Grid>{visibleChildren}</Grid> : visibleChildren}
      {shouldShowButton && (
        <ShowMoreButton
          type="button"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          {isExpanded ? t('general.show_less') : t('general.show_more')}
        </ShowMoreButton>
      )}
    </>
  );
}
