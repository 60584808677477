import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { logger } from '@local/services';

import { deleteUserMemoVenue } from '../userApi';

export const useDeleteUserMemoQuery = (venueSlug: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteUserMemoVenue(venueSlug),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY, 'memo', venueSlug],
      });

      await queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === USER_QUERY_KEY &&
          query.queryKey[1] === 'lists' &&
          !!query.queryKey[2],
      });
    },
    onError: (error) => {
      logger.error('Error deleting user memo:', error);
    },
  });
};
