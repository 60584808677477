import {
  Bookmark,
  Bullhorn,
  Calendar,
  ChevronRight,
  Document,
  Help,
  Purchase,
  Receipt,
  Store,
  UserAvatar,
} from '@carbon/icons-react';
import { t } from 'i18next';
import { useSetAtom } from 'jotai';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetUserQuery } from '@local/auth';
import { panelStateAtom } from '@local/components';
import { CONFIG } from '@local/configs';
import { BASE_SUPPORT_SITE_URL, ICON_SIZE_24 } from '@local/constants';
import { useDeviceType } from '@local/hooks';

type UserOptions =
  | {
      title: string;
      icon: JSX.Element;
      href: string;
      onClick?: () => void;
      dataTestId?: string;
      rightIcon?: JSX.Element | null;
      path?: string;
    }
  | 'separator';

export const useMenuOptions = (): UserOptions[] => {
  const { isDesktop } = useDeviceType();
  const { data: user } = useGetUserQuery();
  const [, { language }] = useTranslation();
  const setPanelState = useSetAtom(panelStateAtom);

  const loggedInOptions: UserOptions[] = React.useMemo(
    () => [
      {
        title: t('account.profile'),
        icon: <UserAvatar size={ICON_SIZE_24} />,
        href: `/${language}/account/edit`,
        dataTestId: 'Account Profile Btn',
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        path: '/edit',
      },
      {
        title: t('navbar.bookings'),
        icon: <Calendar size={ICON_SIZE_24} />,
        href: `/${language}/account/reservations/upcoming`,
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        path: '/reservations',
      },
      {
        title: t('navbar.lists'),
        icon: <Bookmark size={ICON_SIZE_24} />,
        href: `/${language}/lists`,
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        path: '/lists',
      },
      {
        title: t('account.transactions'),
        icon: <Receipt size={ICON_SIZE_24} />,
        href: `/${language}/account/payments`,
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        dataTestId: 'Account Payments Btn',
        path: '/payments',
      },
      {
        title: t('account.saved_cards'),
        icon: <Purchase size={ICON_SIZE_24} />,
        href: `/${language}/account/credit-cards`,
        dataTestId: 'Account Credit Cards Btn',
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        path: '/credit-cards',
      },
      {
        title: t('account.communications.title'),
        icon: <Bullhorn size={ICON_SIZE_24} />,
        href: `/${language}/account/favorites`,
        dataTestId: 'Account Communications Btn',
        rightIcon: isDesktop ? null : <ChevronRight size={ICON_SIZE_24} />,
        path: '/favorites',
      },
      'separator',
    ],
    [isDesktop, language],
  );

  const commonOptions: UserOptions[] = React.useMemo(
    () => [
      {
        title: t('account.help'),
        icon: <Help size={ICON_SIZE_24} />,
        href: `${BASE_SUPPORT_SITE_URL}hc/${language}`,
      },
      {
        title: t('account.for_restaurateurs'),
        icon: <Store size={ICON_SIZE_24} />,
        href: `${CONFIG.VITE_BASE_PROD_TABLECHECK_URL}${language}/join/`,
      },
      {
        title: t('account.terms_conditions.title'),
        icon: <Document size={ICON_SIZE_24} />,
        href: '',
        onClick: () => setPanelState('terms_conditions'),
      },
    ],
    [language, setPanelState],
  );

  return React.useMemo(
    () => (user ? [...loggedInOptions, ...commonOptions] : commonOptions),
    [user, loggedInOptions, commonOptions],
  );
};
