import { CONFIG } from '@local/configs';
import { apiService } from '@local/services';
import type { StoryblokContent } from '@local/types';

const storyblokService = apiService.extend({
  prefixUrl: CONFIG.VITE_STORYBLOK_API_URL,
});

export const fetchStoryblokContent = async (): Promise<StoryblokContent> =>
  storyblokService
    .get(
      `v1/cdn/stories?starts_with=[default]/diner-app/&token=${CONFIG.VITE_STORYBLOK_TOKEN}&version=draft`,
    )
    .json();
