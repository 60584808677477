import { useSetAtom, useStore } from 'jotai';
import { throttle } from 'lodash-es';
import * as React from 'react';

import { searchResultsScrollAtom } from '../searchStore';

export function useSearchResultsScroll() {
  const setSearchResultsScroll = useSetAtom(searchResultsScrollAtom);
  // the store is used here to read the value of searchResultsScrollAtom without subscribing to it
  const store = useStore();

  const [element, setElement] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!element) return;
    const storedScrollPosition = store.get(searchResultsScrollAtom) || 0;
    element.scrollTop = storedScrollPosition;

    const throttledHandleScroll = throttle(() => {
      setSearchResultsScroll(element.scrollTop);
    }, 500);

    element.addEventListener('scroll', throttledHandleScroll);

    return () => {
      element.removeEventListener('scroll', throttledHandleScroll);
      throttledHandleScroll.cancel();
    };
  }, [element, setSearchResultsScroll, store]);

  const ref = React.useCallback((node: HTMLDivElement | null) => {
    setElement(node);
  }, []);

  return ref;
}
