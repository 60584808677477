import { CONFIG } from '@local/configs';
import { apiService } from '@local/services';
import type {
  AuthToken,
  CreateAccountDataType,
  LoginDataType,
  ResetPasswordDataType,
  UserResponseDataType,
} from '@local/types';

const authApi = apiService.extend({
  prefixUrl: CONFIG.VITE_BASE_AUTH_API_URL,
});

const csrfApi = apiService.extend({
  prefixUrl:
    CONFIG.VITE_APP_ENVIRONMENT === 'production'
      ? CONFIG.VITE_BASE_TABLECHECK_URL
      : CONFIG.VITE_BASE_AUTH_API_URL,
});

export const login = async (data: LoginDataType): Promise<AuthToken> =>
  authApi.post(`v2/user/token`, { json: data }).json<AuthToken>();

export const logout = async (data: AuthToken | null): Promise<void> =>
  authApi.post(`v2/user/revoke`, { json: data }).json();

export const resetPassword = async (
  data: ResetPasswordDataType,
): Promise<void> => authApi.post(`v2/user/password`, { json: data }).json();

export const createAccount = async (
  data: CreateAccountDataType,
  locale: string,
): Promise<AuthToken> => {
  try {
    return await authApi
      .post(`v2/user?locale=${locale}`, { json: data })
      .json<AuthToken>();
  } catch (error: unknown) {
    const { message } = error as { message?: string };
    if (message) {
      let errorMessage;
      try {
        const parsedMessage = JSON.parse(message);
        if (parsedMessage.errors) {
          errorMessage = parsedMessage;
        }
      } catch (e) {
        errorMessage = `Something went wrong while parsing error message: ${JSON.stringify(e)}`;
      }
      if (errorMessage) throw errorMessage;
    }

    throw new Error(
      'Create Account: Network error or unexpected error occurred',
    );
  }
};

export const getUser = async (): Promise<UserResponseDataType> =>
  authApi.get(`v2/user`).json();

export const getCsrfToken = async () => csrfApi.get(`v2/csrf_token`);
