import { LocaleCode, ordered } from '@tablecheck/locales';

import ar from './locales/ar.json';
import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import id from './locales/id.json';
import it from './locales/it.json';
import ja from './locales/ja.json';
import ko from './locales/ko.json';
import lo from './locales/lo.json';
import ms from './locales/ms.json';
import pt from './locales/pt.json';
import ru from './locales/ru.json';
import th from './locales/th.json';
import tl from './locales/tl.json';
import tr from './locales/tr.json';
import vi from './locales/vi.json';
import zhCN from './locales/zh-CN.json';
import zhTW from './locales/zh-TW.json';

export const SUPPORTED_LOCALES = ordered.map((locale) => locale.code);
type TranslationData = string | { [key: string]: TranslationData };

export type AllLocales = {
  [key in LocaleCode]: TranslationData;
};

export const allLocales: AllLocales = {
  [LocaleCode.English]: en,
  [LocaleCode.Japanese]: ja,
  [LocaleCode.Korean]: ko,
  [LocaleCode.Arabic]: ar,
  [LocaleCode.ChineseSimplified]: zhCN,
  [LocaleCode.ChineseTraditional]: zhTW,
  [LocaleCode.French]: fr,
  [LocaleCode.German]: de,
  [LocaleCode.Indonesian]: id,
  [LocaleCode.Italian]: it,
  [LocaleCode.Lao]: lo,
  [LocaleCode.Malay]: ms,
  [LocaleCode.Portuguese]: pt,
  [LocaleCode.Russian]: ru,
  [LocaleCode.Spanish]: es,
  [LocaleCode.Tagalog]: tl,
  [LocaleCode.Thai]: th,
  [LocaleCode.Turkish]: tr,
  [LocaleCode.Vietnamese]: vi,
};
export function loadLocaleData(locale: string): TranslationData {
  const isValid = SUPPORTED_LOCALES.some(
    (supportedLocale) => supportedLocale === locale,
  );
  const validLocale = isValid ? locale : LocaleCode.English;

  return (
    allLocales[validLocale as LocaleCode] || allLocales[LocaleCode.English]
  );
}
