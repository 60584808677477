import { styled } from '@linaria/react';
import { Spinner as SpinnerElement } from '@tablecheck/tablekit-react-css';

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  width: 100%;

  & > span {
    align-self: center;
    color: var(--black);
  }
  &[data-is-full-page='true'] {
    height: 100vh;
  }
`;

export function Spinner({
  isFullPage,
  className,
  ...props
}: {
  isFullPage?: boolean;
  className?: string;
}) {
  return (
    <Wrapper data-is-full-page={!!isFullPage} className={className} {...props}>
      <SpinnerElement aria-busy />
    </Wrapper>
  );
}
