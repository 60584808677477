import { ArrowsVertical } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Radio, RadioLabel } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';

import { Panel } from '@local/components';
import { SearchSortBy } from '@local/types';

import { useSearchFilters } from '../../hooks/useSearchFilters';

const SortIcon = styled(ArrowsVertical)`
  cursor: pointer;
`;
const Container = styled.div`
  display: flex;
  font: var(--body-2);
  gap: var(--spacing-l2);
  align-items: center;
  cursor: pointer;
`;
const RadioInputWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l2);
  align-items: center;
  cursor: pointer;
`;
const Content = styled.div`
  display: grid;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4) var(--spacing-l4) var(--spacing-l4);
`;

const SORT_BY: { lokalizeKey: string; value: SearchSortBy }[] = [
  { lokalizeKey: 'sort_by.distance', value: 'distance' },
  { lokalizeKey: 'sort_by.price', value: 'budget_dinner_avg' },
];

const getLokalizeKey = (value: SearchSortBy): string =>
  SORT_BY.find((sort) => sort.value === value)?.lokalizeKey || '';

export function SortBy() {
  const { searchFilters, updateSearchFilters } = useSearchFilters();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleSortBy = (value: SearchSortBy) => {
    updateSearchFilters({ sort_by: value, sort_order: 'asc' });
    setIsOpen(false);
  };
  return (
    <>
      <Container onClick={() => setIsOpen(true)}>
        <div data-testid="Search Sort Title">
          {t(getLokalizeKey(searchFilters.sort_by))}
        </div>
        <SortIcon size="20px" data-testid="Search Sort By Btn" />
      </Container>
      <Panel
        title={t('general.sort_by')}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <Content>
          {SORT_BY.map((sort) => (
            <RadioInputWrapper
              key={sort.value}
              data-testid="Search Sort By Options"
              onClick={() => handleSortBy(sort.value)}
            >
              <Radio
                value={sort.value}
                key={sort.value}
                checked={searchFilters.sort_by === sort.value}
              />
              <RadioLabel>{t(sort.lokalizeKey)}</RadioLabel>
            </RadioInputWrapper>
          ))}
        </Content>
      </Panel>
    </>
  );
}
