import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { useTranslation } from 'react-i18next';

import { Footer } from '@local/components';
import type { Subscription } from '@local/types';
import { useUpdateUserFavoritesQuery } from '@local/user';
import { translate } from '@local/utils';

const Wrapper = styled.div`
  padding: 0 var(--spacing-l4);
`;

interface Props {
  selectedSubscription: Subscription;
  onSuccess: () => void;
  onCancel: () => void;
}

export function DeleteSubscriptionContent({
  selectedSubscription,
  onSuccess,
  onCancel,
}: Props) {
  const { mutate: removeSubscription, isPending } =
    useUpdateUserFavoritesQuery();
  const [t, { language }] = useTranslation();

  const shopName = translate(
    selectedSubscription.shop_name_translations,
    language,
  );

  return (
    <Wrapper>
      <p>
        {t('account.communications.subscriptions.remove_msg', {
          shop_name: shopName,
        })}
      </p>
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-testid="Subscription Remove Submit Btn"
          data-variant="danger"
          aria-busy={isPending}
          onClick={() =>
            removeSubscription(
              {
                isSubscribed: false,
                venueSlug: selectedSubscription.shop_slug,
              },
              {
                onSuccess,
              },
            )
          }
        >
          {t('account.action_btns.remove')}
        </Button>
      </Footer>
    </Wrapper>
  );
}
