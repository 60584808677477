import { useQuery } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { UserResponseDataType } from '@local/types';

import { fetchUserFavoritesVenue } from '../userApi';

export const useFetchUserFavoritesVenueQuery = (
  venueSlug: string | undefined,
  user: UserResponseDataType | undefined,
) =>
  useQuery({
    queryKey: [USER_QUERY_KEY, 'favorites', venueSlug],
    queryFn: () => fetchUserFavoritesVenue(venueSlug),
    enabled: !!venueSlug && !!user,
  });
