import { CONFIG } from '@local/configs';

import { isExternalTCUrl } from './helpers';

interface ShareOptions {
  text?: string;
  title?: string;
  url?: string;
}
export const shareUrl = async ({ text, title, url }: ShareOptions) => {
  if (CONFIG.IS_CORDOVA) {
    window.plugins.socialsharing.share(text, title, undefined, url);
  } else if (navigator.share) {
    await navigator.share({
      title,
      text,
      url,
    });
  }
};

export const openUrl = (
  url: string,
  target: '_blank' | '_self' | '_system' = '_blank',
  features?: string,
) => {
  if (CONFIG.IS_CORDOVA) {
    // open external urls in the app browser
    if (isExternalTCUrl(url)) {
      const feat = features ? `${features},location=no` : 'location=no';
      return window.cordova.InAppBrowser.open(url, '_blank', feat);
    }
    return window.cordova.InAppBrowser.open(url, target, features);
  }
  return window.open(url, target, features);
};
