import { styled } from '@linaria/react';
import { TranslationsArray } from '@tablecheck/i18n';
import { useTranslation } from 'react-i18next';

import { translate } from '@local/utils';

export const DescriptionContent = styled.div`
  font-weight: 400;
`;

export const Wrapper = styled.section`
  overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l1);
`;

export function Description({
  taglineTranslations,
  descriptionTranslations,
}: {
  taglineTranslations: TranslationsArray;
  descriptionTranslations: TranslationsArray;
}): JSX.Element | null {
  const [, { language }] = useTranslation();

  if (!taglineTranslations[0] && !descriptionTranslations[0]) {
    return null;
  }

  return (
    <Wrapper>
      {taglineTranslations[0] && (
        <h3>{translate(taglineTranslations, language)}</h3>
      )}
      {descriptionTranslations[0] && (
        <p>{translate(descriptionTranslations, language)}</p>
      )}
    </Wrapper>
  );
}
