import { useMediaQuery } from 'react-responsive';

import { CONFIG } from '@local/configs';
import { TABLET_BREAKPOINT } from '@local/constants';

export const useDeviceType = () => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_BREAKPOINT})`,
  });
  return { isDesktop: CONFIG.IS_SSR ? true : isDesktop };
};
