import { RestaurantFine } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_MENU_ITEM_IMAGE } from '@local/assets';
import { Panel, Price, ProcessedMarkup } from '@local/components';
import { DEFAULT_ICON_SIZE } from '@local/constants';
import { useDeviceType } from '@local/hooks';
import type { MenuItem as MenuItemType } from '@local/types';
import {
  CurrencyFormat,
  getServiceFeeAndTaxTypeKey,
  translate,
  translateForMarkup,
} from '@local/utils';

import { MenuItemContent } from './MenuItemContent';

const MenuItemWrapper = styled.div`
  width: 100%;
  max-height: -webkit-fill-available;
  display: flex;
  gap: var(--spacing-l2);
  cursor: pointer;
  padding: var(--spacing-l4) 0;
  border-bottom: 1px solid var(--border-transparent);
  &[data-is-mobile='false'] {
    padding: var(--spacing-l3);
    border: 1px solid var(--border-transparent);
    border-radius: var(--border-radius-large);
    transition: background 300ms ease-in-out;
    &:hover {
      background: var(--surface-raised);
    }
  }
`;

const ItemImg = styled.img`
  border-radius: var(--border-radius-small);
  object-fit: cover;
  height: var(--card-side-icon);
  width: var(--card-side-icon);
`;

const ItemDetails = styled.div`
  width: calc(100% - 80px);
`;

const ItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--spacing-l1);
  white-space: break-spaces;
  color: black;
`;

const Title = styled.div`
  font: var(--label);
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
const Description = styled.div`
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font: var(--body-2);
  overflow-wrap: anywhere;
`;
const ImgPlaceholder = styled.div`
  background-color: #eeeeee;
  border-radius: var(--border-radius-small);
  height: var(--card-side-icon);
  width: var(--card-side-icon);
  display: flex;
  justify-content: center;
  color: var(--text-placeholder);
  align-items: center;
`;

const PriceContainer = styled.div`
  display: flex;
  gap: var(--spacing-l1);
  text-align: left;
  align-items: center;
  font: var(--body-2);
  color: var(--text-subtle);
  span {
    color: var(--text);
    font: var(--label);
  }
`;
interface Props {
  item: MenuItemType;
  currency: CurrencyFormat;
}

export function MenuItemCard({ item, currency }: Props): JSX.Element {
  const { isDesktop } = useDeviceType();
  const [, { language }] = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  const [descriptionMarkup, descriptionLanguage] = translateForMarkup(
    item.description_translations,
    language,
  );

  return (
    <>
      <MenuItemWrapper
        onClick={() => {
          setIsOpen(true);
        }}
        data-is-mobile={!isDesktop}
      >
        <ItemDetails>
          <ItemHeader>
            <Title>{translate(item.name_translations, language)}</Title>
            <Description>
              <ProcessedMarkup
                markup={descriptionMarkup}
                language={descriptionLanguage}
              />
            </Description>
            <PriceContainer>
              <Price amount={item.price} currency={currency} />
              {t(
                getServiceFeeAndTaxTypeKey(
                  item.tax_type,
                  item.service_fee_type,
                ),
              )}
            </PriceContainer>
          </ItemHeader>
        </ItemDetails>
        {item.image_urls.original ? (
          <ItemImg
            alt={translate(item.name_translations, language)}
            src={item.image_urls.original || DEFAULT_MENU_ITEM_IMAGE}
          />
        ) : (
          <ImgPlaceholder>
            <RestaurantFine size={DEFAULT_ICON_SIZE} />
          </ImgPlaceholder>
        )}
      </MenuItemWrapper>

      <Panel
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <MenuItemContent
          descriptionMarkup={descriptionMarkup}
          descriptionLanguage={descriptionLanguage}
          item={item}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </Panel>
    </>
  );
}
