import { apiService } from '@local/services';
import type {
  AllListResponse,
  CreateListData,
  ListDetailsResponse,
  SaveListsRequest,
  SavedShopToListResponse,
  StandardMsgResponse,
  UpdateListData,
} from '@local/types';

export const fetchAllLists = async (): Promise<AllListResponse> =>
  apiService.get('v2/hub/shop_lists').json();

export const deleteList = async (id: string): Promise<void> =>
  apiService.delete(`v2/hub/shop_lists/${id}`).json();

export const createList = async (
  data: CreateListData,
): Promise<StandardMsgResponse> =>
  apiService
    .post(`v2/hub/shop_lists`, {
      json: data,
    })
    .json<StandardMsgResponse>();

export const fetchListDetail = async (
  id: string,
  queryParams: URLSearchParams,
): Promise<ListDetailsResponse> => {
  const data = await apiService
    .get(`v2/hub/shop_lists/${id}`, {
      searchParams: queryParams,
    })
    .json<ListDetailsResponse>();

  return data;
};

export const updateListDetail = async (
  id: string,
  data: UpdateListData,
): Promise<ListDetailsResponse> =>
  apiService
    .patch(`v2/hub/shop_lists/${id}`, {
      json: data,
    })
    .json<ListDetailsResponse>();

export const saveOrUnsaveShopToList = async (
  venueId: string,
  listIdsToUpdate: SaveListsRequest,
): Promise<SavedShopToListResponse> =>
  apiService
    .post(`v2/hub/shop_lists/shops/${venueId}`, {
      json: listIdsToUpdate,
    })
    .json<SavedShopToListResponse>();
