import { styled } from '@linaria/react';
import { Chip as Tk3Chip } from '@tablecheck/tablekit-react-css';

export const Chip = styled(Tk3Chip)`
  &[data-has-value='true'] {
    color: var(--surface);
    background-color: var(--text);
  }

  & > svg:first-child {
    display: none;
  }
`;
