import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';

import { SmartLink } from '@local/components';

export const ActionBtn = styled(Button)`
  background-color: var(--white);
  color: var(--black);
  &[data-active='true'] {
    border: 1px solid var(--primary);
    background: var(--surface-raised-active);
    fill: var(--primary);
  }
`;

export const ActionBtnLink = styled(SmartLink)`
  background-color: var(--white);
  color: var(--black);
  --loading-transition: 300ms ease-in-out;
  align-items: center;
  background-color: var(--tk-button-background-color);
  border: 1px solid var(--border);
  border-radius: var(--border-radius-small);
  cursor: pointer;
  display: flex;
  font-weight: 400;
  gap: var(--spacing-l2);
  justify-content: center;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition:
    color var(--loading-transition),
    background-color var(--loading-transition),
    border-color var(--loading-transition);
  white-space: nowrap;

  font-size: 16px;
  line-height: 20px;
  padding: 9px 11px;
  &:hover {
    background-color: var(--surface-active);
  }
  &[data-active='true'] {
    background-color: var(--surface-active);
    & svg {
      fill: var(--black);
    }
  }
`;

export const Wrapper = styled.div`
  overflow: scroll;
  display: flex;
  gap: var(--spacing-l2);
  scrollbar-width: none;
  margin: 0 calc(-1 * var(--spacing-l4));
  padding: 0 var(--spacing-l4);
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ReviewEmoji = styled.span`
  font-size: 20px;
`;
