import { styled } from '@linaria/react';
import { CSSObjectWithLabel, ControlProps, OptionProps } from 'react-select';

import { I18nCountry } from './data/countries';
import { flagStyles } from './data/flagStyles';

const generateFlagStyles = () =>
  Object.entries(flagStyles)
    .map(
      ([country, style]) => `
    &[data-country="${country}"] {
      width: ${style.width || '20px'};
      height: ${style.height || '15px'};
      background-position: ${style.backgroundPosition || '0 0'};
    }
  `,
    )
    .join('');

export const Flag = styled.span`
  display: inline-block;
  margin-left: 1px;
  background-image: url('/flags.png');
  background-repeat: no-repeat;
  box-shadow: 0 0 0 1px var(--border-transparent);
  ${generateFlagStyles()}
`;

export const PhoneWrapper = styled.section`
  direction: ltr;
  display: flex;
  z-index: 1;
  border: 1px solid var(--border-transparent);
  border-radius: var(--border-radius-small);

  &:focus-within,
  &:focus-visible,
  &:focus {
    border: 2px solid var(--focus);
  }

  &[data-has-error='true'] {
    border: 2px solid var(--error);
  }
  & input {
    margin-left: var(--spacing-l2);
    flex: 1;
    outline: none;

    &::placeholder {
      color: var(--text-placeholder);
    }
  }
`;

export const countrySelectStyles = {
  control: (
    baseStyles: CSSObjectWithLabel,
    state: ControlProps<I18nCountry, false>,
  ) => ({
    ...baseStyles,
    border: 'none',
    boxShadow: 'none',
    ...(state.isFocused
      ? {
          boxShadow: 'none',
        }
      : {}),
    '&:hover': {
      borderColor: 'var(--border)',
    },
  }),
  valueContainer: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    height: '38px',
    paddingRight: '0',
  }),
  singleValue: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    div: {
      marginLeft: '8px',
    },
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    border: 'none',
    width: '200px',
  }),
  menuList: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    maxHeight: '280px',
  }),
  option: (
    baseStyles: CSSObjectWithLabel,
    state: OptionProps<I18nCountry, false>,
  ) => {
    const backgroundColor = state.isSelected
      ? 'var(--surface-active)'
      : 'white';
    return {
      ...baseStyles,
      color: 'var(--text)',
      backgroundColor,
      '&:hover': {
        backgroundColor: state.isSelected
          ? 'var(--surface-active)'
          : 'var(--surface-hover)',
      },
    };
  },
};
