import { styled } from '@linaria/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from '@tablecheck/tablekit-react-css';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { TABLECHECK_LOGO } from '@local/assets';
import { AuthContent, useGetUserQuery, useLogoutQuery } from '@local/auth';
import {
  Dropdown,
  LanguageSelector,
  Panel,
  SmartLink,
  Spinner,
  UniverseSelector,
  panelStateAtom,
} from '@local/components';
import { AUTH_PANEL_STATES } from '@local/constants';
import { useUniverse } from '@local/hooks';
import { SearchInput, useSearchInput } from '@local/search';

import { DropdownContentItem, useHeader } from './useHeader';

const UtilityHeader = styled.header`
  width: 100svw;
  display: flex;
  align-self: center;
  justify-content: center;
  z-index: var(--z-index-1);
  padding: var(--spacing-l2) 0;
  background-color: var(--utility-header-color);
  color: var(--black);
  max-height: var(--desktop-header-utility-height);
  z-index: 20;
  & div {
    display: flex;
    gap: var(--spacing-l5);
    font-size: 12px;
    line-height: 18px;
    padding: 0 var(--spacing-l4);
    max-width: var(--desktop-content-width);
    width: 100%;
    justify-content: right;
    & a {
      font-size: 12px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &[data-max-width='true'] {
    justify-content: right !important;
  }
`;

const Content = styled.header`
  box-shadow: var(--elevation-medium);
  top: 0;
  position: sticky;
  width: 100svw;
  display: flex;
  align-self: center;
  background-color: white;
  padding: var(--spacing-l4) 0;
  justify-content: center;
  z-index: var(--z-index-1);
  min-height: var(--desktop-header-content-height);
  & > div {
    display: flex;
    width: var(--desktop-content-width);
    padding: 0 var(--spacing-l4);
    place-content: space-between;
    & section {
      display: flex;
      place-items: center;
      gap: var(--spacing-l4);
      & div {
        display: flex;
        & svg {
          align-self: center;
        }
      }
      & a {
        display: flex;
      }
    }
    &[data-spread='true'] {
      min-width: -webkit-fill-available;
    }
  }
`;

const TCLogo = styled.img`
  height: 24px;
`;

const ProfileTrigger = styled.div`
  width: 32px;
  height: 32px;
  font-size: 22px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: var(--info-surface);
  border-radius: var(--border-radius-full);
  &:hover {
    background-color: var(--info-surface-hover);
  }
`;

const DropdownWrapper = styled.div`
  cursor: pointer;
  background-color: white;
  display: block !important;
  border-radius: var(--border-radius-large);
  box-shadow: var(--elevation-large);
  overflow: hidden;
  min-width: 240px;
  padding: var(--spacing-l2) 0px;
`;

const DropdownItem = styled.div`
  padding: var(--spacing-l3);
  width: -webkit-fill-available;
  gap: var(--spacing-l2);
  &:hover {
    background-color: var(--grey-100);
  }
  font: var(--body-2);
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Separator = styled.hr`
  border: 1px var(--grey-200) solid;
`;

export function DesktopHeader({
  'data-max-width': isMaxWidthHeader,
}: {
  'data-max-width': boolean;
}): JSX.Element {
  const { data: user } = useGetUserQuery();
  const [t, { language }] = useTranslation();
  const { isPending: isLogoutPending } = useLogoutQuery();
  const { universeName } = useUniverse();
  const { profileDropdown, utilityHeaderOptions, shouldShowSearchInput } =
    useHeader();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const { searchInputRef } = useSearchInput();
  return (
    <>
      <UtilityHeader data-max-width={isMaxWidthHeader}>
        <div>
          {utilityHeaderOptions.map((option) => (
            <SmartLink key={option.label} href={option.href}>
              {option.label}
            </SmartLink>
          ))}
        </div>
      </UtilityHeader>
      <Content data-testid="Desktop Header" data-max-width={isMaxWidthHeader}>
        <div data-spread={isMaxWidthHeader}>
          <section>
            <SmartLink href={`/${language}/${universeName}`}>
              <TCLogo
                data-testid="Header TableCheck Logo"
                src={TABLECHECK_LOGO}
                alt="TableCheck logo"
              />
            </SmartLink>
            {shouldShowSearchInput && (
              <SearchInput
                ref={searchInputRef}
                dataTestId="Header Search Bar"
              />
            )}
          </section>
          <section>
            <UniverseSelector />
            <LanguageSelector />
            {user ? (
              <Dropdown
                align="end"
                sideOffset={8}
                alignOffset={0}
                triggerContent={
                  <ProfileTrigger>
                    {isLogoutPending ? (
                      <Spinner />
                    ) : (
                      <span data-testid="Header Profile Icon">🐤</span>
                    )}
                  </ProfileTrigger>
                }
                dropdownContent={
                  <DropdownWrapper>
                    {profileDropdown.map((option: DropdownContentItem) => {
                      if (option === 'separator') {
                        return <Separator key={option} />;
                      }
                      return (
                        <DropdownMenu.Item key={option.label}>
                          <DropdownItem
                            onClick={option.onClick}
                            data-testid={option['data-testid']}
                          >
                            {option.icon} {option.label}
                          </DropdownItem>
                        </DropdownMenu.Item>
                      );
                    })}
                  </DropdownWrapper>
                }
              />
            ) : (
              <Button
                data-variant="secondary"
                onClick={() => void setPanelState('login')}
                data-testid="Desktop Header Login Btn"
              >
                {t('auth.login')}
              </Button>
            )}
          </section>
        </div>
      </Content>
      <Panel
        isOpen={AUTH_PANEL_STATES.includes(panelState)}
        onClose={() => void setPanelState(null)}
      >
        <AuthContent />
      </Panel>
    </>
  );
}
