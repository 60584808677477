import { useAtom, useAtomValue } from 'jotai';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthContent, useGetUserQuery } from '@local/auth';
import {
  MemoContent,
  Panel,
  panelStateAtom,
  selectedVenueMemoAtom,
  Spinner,
  useEditUserMemo,
  useToast,
} from '@local/components';
import { AUTH_PANEL_STATES } from '@local/constants';
import { useDeviceType, useTypedRouteParams } from '@local/hooks';
import {
  DeleteListContent,
  ListOptionsContent,
  SaveToListContent,
  UpdateListContent,
  useDeleteListQuery,
  useListView,
  useSaveOrUnsaveShopToListQuery,
  useUpdateListDetailQuery,
} from '@local/list';
import { selectedVenueAtom } from '@local/map-system';
import { ListOption, UpdateListData } from '@local/types';

import { ListViewDesktop } from './ListViewDesktop';
import { ListViewMobile } from './ListViewMobile';

interface ListRouteParams {
  listId: string;
}

export function ListViewRoot() {
  const { isDesktop } = useDeviceType();
  const [t, { language }] = useTranslation();
  const [panelState, setPanelState] = useAtom(panelStateAtom);
  const { venue } = useAtomValue(selectedVenueAtom);
  const selectedVenueMemo = useAtomValue(selectedVenueMemoAtom);
  const { listId } = useTypedRouteParams<ListRouteParams>();
  const {
    saveVenueToastContent,
    updateListToastContent,
    errorToastContent,
    successToastContent,
    setIsToastOpen,
    setToastContent,
  } = useToast();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const {
    listDetail: { data: listData, error: isListError },
  } = useListView();
  const { onSubmitMemo, onDeleteMemo } = useEditUserMemo(
    selectedVenueMemo.venueSlug ?? '',
  );
  const { mutate: updateList } = useUpdateListDetailQuery(listId);
  const { mutate: deleteList } = useDeleteListQuery(listId);
  const { mutate: saveOrUnsaveShopToList } = useSaveOrUnsaveShopToListQuery();
  const lastListsToUpdateRef = React.useRef<{
    save: string[];
    unsave: string[];
  } | null>(null);

  const navigate = useNavigate();

  const handleUpdate = (updatedData: UpdateListData) => {
    updateList(
      {
        name: updatedData.name,
        description: updatedData.description,
      },
      {
        onSuccess: () => {
          setToastContent(updateListToastContent('update_list'));
          setIsToastOpen(true);
          setPanelState(null);
        },
      },
    );
  };

  const handleDelete = () => {
    deleteList(undefined, {
      onSuccess: () => {
        setPanelState(null);
        setToastContent(updateListToastContent('delete_list'));
        setIsToastOpen(true);
        navigate(`/${language}/lists`);
      },
    });
  };

  const handleUndo = () => {
    if (!lastListsToUpdateRef.current || !venue) return;
    const { save, unsave } = lastListsToUpdateRef.current;
    const reverseListsToUpdate = {
      save: unsave,
      unsave: save,
    };

    saveOrUnsaveShopToList(
      {
        venueId: venue.id,
        listIdsToUpdate: reverseListsToUpdate,
      },
      {
        onSuccess: () => {
          setToastContent(successToastContent(t('toast.updated_list')));
          setIsToastOpen(true);
        },
        onError: () => {
          setToastContent(errorToastContent(t('toast.unsuccessful_list_save')));
          setIsToastOpen(true);
        },
      },
    );
    lastListsToUpdateRef.current = null;
  };

  const listOptions: ListOption[] = React.useMemo(() => {
    if (listData?.list.is_favorite) {
      return [
        {
          label: t('list_feat.edit_desc'),
          onClick: () => setPanelState('update_list_desc'),
          testId: 'List Option Edit Desc Btn',
        },
      ];
    }

    return [
      {
        label: t('list_feat.edit_list'),
        onClick: () => setPanelState('update_list'),
        testId: 'List Option Edit Btn',
      },
      {
        label: t('list_feat.delete_list'),
        onClick: () => setPanelState('delete_list'),
        variant: 'danger',
        testId: 'List Option Delete Btn',
      },
    ];
  }, [listData?.list.is_favorite, t, setPanelState]);

  React.useEffect(() => {
    if (isListError) navigate(`/${language}/lists`);
    if (isUserLoading) return;
    setPanelState(user ? null : 'login');
  }, [user, isUserLoading, isListError, navigate, language, setPanelState]);

  if (isUserLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      {isDesktop ? <ListViewDesktop /> : <ListViewMobile />}

      <Panel isOpen={!!panelState} onClose={() => setPanelState(null)}>
        {panelState === 'list_options' && (
          <ListOptionsContent options={listOptions} />
        )}
        {listData &&
          (panelState === 'update_list' ||
            panelState === 'update_list_desc') && (
            <UpdateListContent
              name={listData.list.name}
              description={listData.list.description}
              onSubmit={handleUpdate}
              onCancel={() => setPanelState(null)}
            />
          )}
        {listData && panelState === 'delete_list' && (
          <DeleteListContent
            shopName={listData.list.name}
            onDelete={handleDelete}
            onCancel={() => setPanelState(null)}
          />
        )}
        {panelState === 'save_list' && venue && (
          <SaveToListContent
            venueId={venue.id}
            onCreateListClick={() => setPanelState('create_list')}
            onSubmit={(listsToUpdate: { save: string[]; unsave: string[] }) => {
              setIsToastOpen(false);
              lastListsToUpdateRef.current = listsToUpdate;
              saveOrUnsaveShopToList(
                {
                  venueId: venue.id,
                  listIdsToUpdate: listsToUpdate,
                },
                {
                  onSuccess: (response) => {
                    setPanelState(null);
                    setToastContent(
                      saveVenueToastContent(
                        response.lists,
                        handleUndo,
                        t('general.undo'),
                      ),
                    );
                    setIsToastOpen(true);
                  },
                  onError: () => {
                    setPanelState(null);
                    setToastContent(
                      errorToastContent(t('toast.unsuccessful_list_save')),
                    );
                    setIsToastOpen(true);
                  },
                },
              );
            }}
            onCancel={() => setPanelState(null)}
          />
        )}
        {panelState === 'memo' && (
          <MemoContent
            userMemo={selectedVenueMemo.currentMemo ?? ''}
            onSubmit={onSubmitMemo}
            onDelete={onDeleteMemo}
          />
        )}
        {AUTH_PANEL_STATES.includes(panelState) && (
          <AuthContent
            subheaderContent={
              <Trans
                i18nKey="auth.login_feat_unlock"
                components={{ bold: <strong /> }}
              />
            }
          />
        )}
      </Panel>
    </>
  );
}
