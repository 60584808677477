import { styled } from '@linaria/react';
import * as React from 'react';

import { useDeviceType } from '@local/hooks';

interface Props {
  children: React.ReactNode;
  hasShadow?: boolean;
}

const FooterWrapper = styled.div`
  bottom: 0;
  position: sticky;
  width: calc(100% + var(--spacing-l4) + var(--spacing-l4));
  margin: 0 calc(-1 * var(--spacing-l4));
  display: flex;
  padding: var(--spacing-l4);
  z-index: var(--z-index-1);
  background: var(--surface);

  & button {
    width: 100%;
    padding: var(--spacing-l3);
  }

  &[data-grid='true'] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-l4);
  }

  &[data-has-shadow='true'] {
    box-shadow: var(--elevation-medium);
  }

  &[data-is-desktop='true'] {
    max-width: -webkit-fill-available;
    grid-template-columns: min-content min-content;
    justify-content: end;
    align-items: self-end;
    & button {
      max-width: min-content;
    }
  }
`;

export function Footer({ children, hasShadow }: Props): JSX.Element {
  const { isDesktop } = useDeviceType();
  const validChildren = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );
  const numOfButtons = validChildren.length;
  return (
    <FooterWrapper
      data-is-desktop={isDesktop}
      data-grid={numOfButtons > 1}
      data-has-shadow={hasShadow}
    >
      {children}
    </FooterWrapper>
  );
}
