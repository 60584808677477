import { CircleStroke, Close } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { addDays, eachDayOfInterval, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CONFIG } from '@local/configs';
import { ICON_SIZE_16 } from '@local/constants';
import {
  buildBookingFormUrl,
  DateFormat,
  formatDisplayDate,
} from '@local/utils';

import { SmartLink } from '../../SmartLink/SmartLink';
import { useVenueCardContext } from '../VenueCardContext';

const TableWrapper = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: separate;
  table-layout: fixed;

  th {
    font: var(--small);
    color: var(--text-subtle);
  }

  td {
    border: 1px solid var(--border-transparent);
  }

  td a {
    padding: var(--spacing-l2) var(--spacing-l3);
    width: 100%;
    font: var(--small);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-l1);

    &[data-disabled='true'] {
      cursor: not-allowed;
    }
  }

  tbody td:first-child {
    border-top-left-radius: var(--border-radius-small);
    border-bottom-left-radius: var(--border-radius-small);
  }

  tbody td:last-child {
    border-top-right-radius: var(--border-radius-small);
    border-bottom-right-radius: var(--border-radius-small);
  }

  td + td {
    /* removes borders for cells in the middle from stacking on each other */
    border-left: none;
  }

  td[data-disabled='false']:hover {
    background-color: var(--grey-100);
  }

  td[data-disabled='true']:hover {
    cursor: not-allowed;
  }
`;

interface Props {
  query: {
    time?: string;
    pax?: string;
    dateMin?: string;
  };
}

export function DateTable({ query }: Props) {
  const { venue } = useVenueCardContext();
  const [, { language }] = useTranslation();
  const { availability, slug } = venue;

  if (!availability || availability.length === 0) {
    return null;
  }

  const startDate = query.dateMin ? new Date(query.dateMin) : new Date();
  const fullWeek = eachDayOfInterval({
    start: startDate,
    end: addDays(startDate, 6),
  });

  return (
    <TableWrapper data-testid="Common Venue Card Date Table">
      <thead>
        <tr>
          {fullWeek.map((day) => (
            <th key={day.toString()} scope="col">
              {formatDisplayDate(day, DateFormat.Day, language)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {fullWeek.map((day) => {
            const date = format(day, 'yyyy-MM-dd');
            const isAvailable = availability.includes(date);
            let bookingUrl = '';
            if (isAvailable) {
              bookingUrl = buildBookingFormUrl(
                `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${slug}/reserve`,
                {
                  start_date: date,
                  ...(query.time && { start_time: query.time }),
                  ...(query.pax && { num_people: query.pax }),
                },
              );
            }

            return (
              <td key={day.toString()} data-disabled={!isAvailable}>
                <SmartLink href={bookingUrl} data-disabled={!isAvailable}>
                  {formatDisplayDate(day, DateFormat.MonthDayShort, language)}
                  {isAvailable ? (
                    <CircleStroke size={ICON_SIZE_16} fill="var(--primary)" />
                  ) : (
                    <Close size={ICON_SIZE_16} />
                  )}
                </SmartLink>
              </td>
            );
          })}
        </tr>
      </tbody>
    </TableWrapper>
  );
}
