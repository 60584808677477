import { Logout } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';

import { useGetUserQuery, useLogoutQuery } from '@local/auth';
import { SmartLinkButton, Spinner } from '@local/components';
import { DEFAULT_ICON_SIZE } from '@local/constants';

import { useMenuOptions } from './useMenuOptions';

const OptionsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l1);
  & > a,
  button {
    border: none;
    display: flex;
    gap: var(--spacing-l2);
    justify-content: start;
    padding: var(--spacing-l3) !important;

    &:hover {
      background-color: var(--surface-hover-transparent);
    }

    &[data-right-icon='true'] {
      & > svg:last-child {
        margin-inline-start: auto;
      }
    }

    &[data-is-active='true'] {
      background-color: var(--surface-raised-active);
      color: var(--primary);
      svg {
        fill: var(--primary);
      }
    }
  }
`;

const LogoutBtn = styled(Button)`
  color: var(--error-text) !important;

  &:hover {
    color: var(--error-text);
  }
  & > svg {
    fill: var(--error-text);
  }
`;

const Separator = styled.hr`
  border: 1px var(--border-transparent) solid;
  margin: var(--spacing-l2) 0;
`;

export function MenuOptions() {
  const location = useLocation();
  const { mutate: logout, isPending } = useLogoutQuery();
  const { data: user } = useGetUserQuery();
  const options = useMenuOptions();

  return (
    <OptionsSection>
      {options.map((option) => {
        if (option === 'separator') {
          return <Separator key={option} />;
        }
        return (
          <SmartLinkButton
            key={option.title}
            data-testid={option.dataTestId}
            data-right-icon={!!option.rightIcon}
            data-is-active={
              option.path && location.pathname.includes(option.path)
            }
            href={option.href}
            onClick={() => {
              if (option.onClick) {
                option.onClick();
              }
            }}
          >
            {option.icon}
            {option.title}
            {option.rightIcon}
          </SmartLinkButton>
        );
      })}
      {user && (
        <LogoutBtn
          data-variant="ghost"
          data-testid="Account Logout Btn"
          onClick={() => logout()}
        >
          {isPending ? (
            <Spinner />
          ) : (
            <>
              <Logout size={DEFAULT_ICON_SIZE} />
              {t('auth.logout')}
            </>
          )}
        </LogoutBtn>
      )}
    </OptionsSection>
  );
}
