import { addDays, format, parseISO } from 'date-fns';
import { useAtom } from 'jotai';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_LIST_FILTERS } from '@local/constants';
import type { ListQueryParamsType } from '@local/types';
import {
  getSearchParamsObject,
  transformFiltersToParams,
  transformParamsToFilters,
} from '@local/utils';

import { listFiltersAtom } from '../listStore';

interface FilterOptionsType {
  reset?: boolean;
}

export function useListFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [listFilters, setListFilters] =
    useAtom<ListQueryParamsType>(listFiltersAtom);

  React.useEffect(() => {
    if (searchParams.toString() === '') return;
    const newListFilters = transformParamsToFilters(
      searchParams,
      DEFAULT_LIST_FILTERS,
    );
    setListFilters(newListFilters);
  }, [searchParams, setListFilters]);

  React.useEffect(() => {
    const existingParams = getSearchParamsObject(searchParams);
    const mergedFilters = {
      ...listFilters,
      ...existingParams,
      ...(existingParams.date_min && {
        date_max: format(
          addDays(parseISO(existingParams.date_min as string), 7),
          'yyyy-MM-dd',
        ),
      }),
    };
    setListFilters(mergedFilters);
    setSearchParams(transformFiltersToParams(mergedFilters), { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateListFilters = (
    newListFilters: Partial<ListQueryParamsType> = {},
    filterOptions: FilterOptionsType = {},
  ) => {
    if (filterOptions.reset) {
      const updatedFilters = {
        ...DEFAULT_LIST_FILTERS,
        ...newListFilters,
      };
      setListFilters(updatedFilters);
      setSearchParams(transformFiltersToParams(updatedFilters), {
        replace: true,
      });
    } else {
      const updatedFilters = {
        ...listFilters,
        ...newListFilters,
      };
      setSearchParams(transformFiltersToParams(updatedFilters), {
        replace: true,
      });
    }
  };

  return {
    listFilters,
    updateListFilters,
    transformFiltersToParams,
  };
}
