import { styled } from '@linaria/react';

import { useVenueCardContext } from '../VenueCardContext';

const StyledParagraph = styled.p`
  font: var(--body-2);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 20px;
    height: var(--spacing-l5);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
`;

export function TagLine() {
  const { venue } = useVenueCardContext();

  if (!venue.tagline) return null;
  return <StyledParagraph>{venue.tagline}</StyledParagraph>;
}
