import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { STALETIME_10_SECONDS } from '@local/constants';
import type { Scope, UserResponseDataType } from '@local/types';

import { fetchReservations } from '../ReservationsApi';

export const useFetchFutureReservations = (
  user: UserResponseDataType | undefined,
  scope: Scope,
) => {
  const [, { language }] = useTranslation();

  return useInfiniteQuery({
    queryKey: ['reservations', 'future'],
    queryFn: ({ pageParam }) =>
      fetchReservations(language, 'future', pageParam),
    staleTime: STALETIME_10_SECONDS,
    enabled: !!user && scope === 'future',
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.page >= lastPage.meta.total_pages) return;
      return lastPage.meta.page + 1;
    },
    initialPageParam: 0,
    select: (data) => ({
      pages: data.pages,
      shops: data.pages.at(-1)?.shops,
      reservations: data.pages.flatMap((page) => page.reservations),
    }),
  });
};
