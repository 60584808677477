import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { Footer } from '@local/components';

const Wrapper = styled.div`
  padding: 0 var(--spacing-l4);
`;

interface DeleteListContentProps {
  shopName: string;
  onDelete: () => void;
  onCancel: () => void;
}

export function DeleteListContent({
  shopName,
  onDelete,
  onCancel,
}: DeleteListContentProps) {
  return (
    <Wrapper>
      <p>
        {t('list_feat.delete_prompt', {
          venue: `"${shopName}"`,
        })}
      </p>
      <Footer>
        <Button data-variant="tertiary" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-variant="danger"
          onClick={onDelete}
          data-testid="Confirmation Delete List Btn"
        >
          {t('general.delete')}
        </Button>
      </Footer>
    </Wrapper>
  );
}
