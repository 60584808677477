import { CountryCode } from 'libphonenumber-js/core';

export interface I18nCountry {
  code: string;
  name?: string;
  shortName: CountryCode | 'AQ' | 'BV' | 'TF' | 'HM' | 'PN' | 'GS' | 'UM';
  suggested?: boolean;
}

export const allCountries: I18nCountry[] = [
  { name: 'Afghanistan', shortName: 'AF', code: '93' },
  { name: 'Albania', shortName: 'AL', code: '355' },
  { name: 'Algeria', shortName: 'DZ', code: '213' },
  { name: 'American Samoa', shortName: 'AS', code: '1-684' },
  { name: 'Andorra', shortName: 'AD', code: '376' },
  { name: 'Angola', shortName: 'AO', code: '244' },
  { name: 'Anguilla', shortName: 'AI', code: '1-264' },
  { name: 'Antarctica', shortName: 'AQ', code: '672' },
  { name: 'Antigua and Barbuda', shortName: 'AG', code: '1-268' },
  { name: 'Argentina', shortName: 'AR', code: '54' },
  { name: 'Armenia', shortName: 'AM', code: '374' },
  { name: 'Aruba', shortName: 'AW', code: '297' },
  { name: 'Australia', shortName: 'AU', code: '61', suggested: true },
  { name: 'Austria', shortName: 'AT', code: '43' },
  { name: 'Azerbaijan', shortName: 'AZ', code: '994' },
  { name: 'Bahamas', shortName: 'BS', code: '1-242' },
  { name: 'Bahrain', shortName: 'BH', code: '973' },
  { name: 'Bangladesh', shortName: 'BD', code: '880' },
  { name: 'Barbados', shortName: 'BB', code: '1-246' },
  { name: 'Belarus', shortName: 'BY', code: '375' },
  { name: 'Belgium', shortName: 'BE', code: '32' },
  { name: 'Belize', shortName: 'BZ', code: '501' },
  { name: 'Benin', shortName: 'BJ', code: '229' },
  { name: 'Bermuda', shortName: 'BM', code: '1-441' },
  { name: 'Bhutan', shortName: 'BT', code: '975' },
  { name: 'Bolivia', shortName: 'BO', code: '591' },
  { name: 'Bosnia and Herzegovina', shortName: 'BA', code: '387' },
  { name: 'Botswana', shortName: 'BW', code: '267' },
  { name: 'Bouvet Island', shortName: 'BV', code: '47' },
  { name: 'Brazil', shortName: 'BR', code: '55' },
  {
    name: 'British Indian Ocean Territory',
    shortName: 'IO',
    code: '246',
  },
  { name: 'British Virgin Islands', shortName: 'VG', code: '1-284' },
  { name: 'Brunei Darussalam', shortName: 'BN', code: '673' },
  { name: 'Bulgaria', shortName: 'BG', code: '359' },
  { name: 'Burkina Faso', shortName: 'BF', code: '226' },
  { name: 'Burundi', shortName: 'BI', code: '257' },
  { name: 'Cambodia', shortName: 'KH', code: '855' },
  { name: 'Cameroon', shortName: 'CM', code: '237' },
  { name: 'Canada', shortName: 'CA', code: '1', suggested: true },
  { name: 'Cape Verde', shortName: 'CV', code: '238' },
  { name: 'Cayman Islands', shortName: 'KY', code: '1-345' },
  { name: 'Central African Republic', shortName: 'CF', code: '236' },
  { name: 'Chad', shortName: 'TD', code: '235' },
  { name: 'Chile', shortName: 'CL', code: '56' },
  { name: 'China', shortName: 'CN', code: '86' },
  { name: 'Christmas Island', shortName: 'CX', code: '61' },
  { name: 'Cocos (Keeling) Islands', shortName: 'CC', code: '61' },
  { name: 'Colombia', shortName: 'CO', code: '57' },
  { name: 'Comoros', shortName: 'KM', code: '269' },
  { name: 'Congo', shortName: 'CG', code: '242' },
  { name: 'Cook Islands', shortName: 'CK', code: '682' },
  { name: 'Costa Rica', shortName: 'CR', code: '506' },
  { name: "Cote d'Ivoire", shortName: 'CI', code: '225' },
  { name: 'Croatia', shortName: 'HR', code: '385' },
  { name: 'Cuba', shortName: 'CU', code: '53' },
  { name: 'Curacao', shortName: 'CW', code: '599' },
  { name: 'Cyprus', shortName: 'CY', code: '357' },
  { name: 'Czech Republic', shortName: 'CZ', code: '420' },
  {
    name: 'Democratic Republic of the Congo',
    shortName: 'CD',
    code: '243',
  },
  { name: 'Denmark', shortName: 'DK', code: '45' },
  { name: 'Djibouti', shortName: 'DJ', code: '253' },
  { name: 'Dominica', shortName: 'DM', code: '1-767' },
  { name: 'Dominican Republic', shortName: 'DO', code: '1-809' },
  { name: 'Ecuador', shortName: 'EC', code: '593' },
  { name: 'Egypt', shortName: 'EG', code: '20' },
  { name: 'El Salvador', shortName: 'SV', code: '503' },
  { name: 'Equatorial Guinea', shortName: 'GQ', code: '240' },
  { name: 'Eritrea', shortName: 'ER', code: '291' },
  { name: 'Estonia', shortName: 'EE', code: '372' },
  { name: 'Ethiopia', shortName: 'ET', code: '251' },
  { name: 'Falkland Islands (Malvinas)', shortName: 'FK', code: '500' },
  { name: 'Faroe Islands', shortName: 'FO', code: '298' },
  { name: 'Fiji', shortName: 'FJ', code: '679' },
  { name: 'Finland', shortName: 'FI', code: '358' },
  { name: 'France', shortName: 'FR', code: '33', suggested: true },
  { name: 'French Guiana', shortName: 'GF', code: '594' },
  { name: 'French Polynesia', shortName: 'PF', code: '689' },
  { name: 'French Southern Territories', shortName: 'TF', code: '262' },
  { name: 'Gabon', shortName: 'GA', code: '241' },
  { name: 'Gambia', shortName: 'GM', code: '220' },
  { name: 'Georgia', shortName: 'GE', code: '995' },
  { name: 'Germany', shortName: 'DE', code: '49', suggested: true },
  { name: 'Ghana', shortName: 'GH', code: '233' },
  { name: 'Gibraltar', shortName: 'GI', code: '350' },
  { name: 'Greece', shortName: 'GR', code: '30' },
  { name: 'Greenland', shortName: 'GL', code: '299' },
  { name: 'Grenada', shortName: 'GD', code: '1-473' },
  { name: 'Guadeloupe', shortName: 'GP', code: '590' },
  { name: 'Guam', shortName: 'GU', code: '1-671' },
  { name: 'Guatemala', shortName: 'GT', code: '502' },
  { name: 'Guernsey', shortName: 'GG', code: '44' },
  { name: 'Guinea-Bissau', shortName: 'GW', code: '245' },
  { name: 'Guinea', shortName: 'GN', code: '224' },
  { name: 'Guyana', shortName: 'GY', code: '592' },
  { name: 'Haiti', shortName: 'HT', code: '509' },
  {
    name: 'Heard Island and McDonald Islands',
    shortName: 'HM',
    code: '672',
  },
  {
    name: 'Holy See (Vatican City State)',
    shortName: 'VA',
    code: '379',
  },
  { name: 'Honduras', shortName: 'HN', code: '504' },
  { name: 'Hong Kong', shortName: 'HK', code: '852' },
  { name: 'Hungary', shortName: 'HU', code: '36' },
  { name: 'Iceland', shortName: 'IS', code: '354' },
  { name: 'India', shortName: 'IN', code: '91' },
  { name: 'Indonesia', shortName: 'ID', code: '62' },
  { name: 'Iran', shortName: 'IR', code: '98' }, // 'Iran, Islamic Republic of'
  { name: 'Iraq', shortName: 'IQ', code: '964' },
  { name: 'Ireland', shortName: 'IE', code: '353' },
  { name: 'Isle of Man', shortName: 'IM', code: '44' },
  { name: 'Israel', shortName: 'IL', code: '972' },
  { name: 'Italy', shortName: 'IT', code: '39' },
  { name: 'Jamaica', shortName: 'JM', code: '1-876' },
  { name: 'Japan', shortName: 'JP', code: '81', suggested: true },
  { name: 'Jersey', shortName: 'JE', code: '44' },
  { name: 'Jordan', shortName: 'JO', code: '962' },
  { name: 'Kazakhstan', shortName: 'KZ', code: '7' },
  { name: 'Kenya', shortName: 'KE', code: '254' },
  { name: 'Kiribati', shortName: 'KI', code: '686' },
  {
    name: 'North Korea', // "Korea, Democratic People's Republic of",
    shortName: 'KP',
    code: '850',
  },
  { name: 'South Korea', shortName: 'KR', code: '82' }, // 'Korea, Republic of'
  { name: 'Kuwait', shortName: 'KW', code: '965' },
  { name: 'Kyrgyzstan', shortName: 'KG', code: '996' },
  {
    name: "Lao People's Democratic Republic",
    shortName: 'LA',
    code: '856',
  },
  { name: 'Latvia', shortName: 'LV', code: '371' },
  { name: 'Lebanon', shortName: 'LB', code: '961' },
  { name: 'Lesotho', shortName: 'LS', code: '266' },
  { name: 'Liberia', shortName: 'LR', code: '231' },
  { name: 'Libya', shortName: 'LY', code: '218' },
  { name: 'Liechtenstein', shortName: 'LI', code: '423' },
  { name: 'Lithuania', shortName: 'LT', code: '370' },
  { name: 'Luxembourg', shortName: 'LU', code: '352' },
  { name: 'Macao', shortName: 'MO', code: '853' },
  {
    name: 'Macedonia', // 'Macedonia, the Former Yugoslav Republic of'
    shortName: 'MK',
    code: '389',
  },
  { name: 'Madagascar', shortName: 'MG', code: '261' },
  { name: 'Malawi', shortName: 'MW', code: '265' },
  { name: 'Malaysia', shortName: 'MY', code: '60' },
  { name: 'Maldives', shortName: 'MV', code: '960' },
  { name: 'Mali', shortName: 'ML', code: '223' },
  { name: 'Malta', shortName: 'MT', code: '356' },
  { name: 'Marshall Islands', shortName: 'MH', code: '692' },
  { name: 'Martinique', shortName: 'MQ', code: '596' },
  { name: 'Mauritania', shortName: 'MR', code: '222' },
  { name: 'Mauritius', shortName: 'MU', code: '230' },
  { name: 'Mayotte', shortName: 'YT', code: '262' },
  { name: 'Mexico', shortName: 'MX', code: '52' },
  {
    name: 'Micronesia', // 'Micronesia, Federated States of'
    shortName: 'FM',
    code: '691',
  },
  { name: 'Moldova', shortName: 'MD', code: '373' }, // 'Moldova, Republic of'
  { name: 'Monaco', shortName: 'MC', code: '377' },
  { name: 'Mongolia', shortName: 'MN', code: '976' },
  { name: 'Montenegro', shortName: 'ME', code: '382' },
  { name: 'Montserrat', shortName: 'MS', code: '1-664' },
  { name: 'Morocco', shortName: 'MA', code: '212' },
  { name: 'Mozambique', shortName: 'MZ', code: '258' },
  { name: 'Myanmar', shortName: 'MM', code: '95' },
  { name: 'Namibia', shortName: 'NA', code: '264' },
  { name: 'Nauru', shortName: 'NR', code: '674' },
  { name: 'Nepal', shortName: 'NP', code: '977' },
  { name: 'Netherlands', shortName: 'NL', code: '31' },
  { name: 'New Caledonia', shortName: 'NC', code: '687' },
  { name: 'New Zealand', shortName: 'NZ', code: '64' },
  { name: 'Nicaragua', shortName: 'NI', code: '505' },
  { name: 'Niger', shortName: 'NE', code: '227' },
  { name: 'Nigeria', shortName: 'NG', code: '234' },
  { name: 'Niue', shortName: 'NU', code: '683' },
  { name: 'Norfolk Island', shortName: 'NF', code: '672' },
  { name: 'Northern Mariana Islands', shortName: 'MP', code: '1-670' },
  { name: 'Norway', shortName: 'NO', code: '47' },
  { name: 'Oman', shortName: 'OM', code: '968' },
  { name: 'Pakistan', shortName: 'PK', code: '92' },
  { name: 'Palau', shortName: 'PW', code: '680' },
  { name: 'Palestine', shortName: 'PS', code: '970' }, // 'Palestine, State of'
  { name: 'Panama', shortName: 'PA', code: '507' },
  { name: 'Papua New Guinea', shortName: 'PG', code: '675' },
  { name: 'Paraguay', shortName: 'PY', code: '595' },
  { name: 'Peru', shortName: 'PE', code: '51' },
  { name: 'Philippines', shortName: 'PH', code: '63' },
  { name: 'Pitcairn', shortName: 'PN', code: '870' },
  { name: 'Poland', shortName: 'PL', code: '48' },
  { name: 'Portugal', shortName: 'PT', code: '351' },
  { name: 'Puerto Rico', shortName: 'PR', code: '1' },
  { name: 'Qatar', shortName: 'QA', code: '974' },
  { name: 'Reunion', shortName: 'RE', code: '262' },
  { name: 'Romania', shortName: 'RO', code: '40' },
  { name: 'Russian Federation', shortName: 'RU', code: '7' },
  { name: 'Rwanda', shortName: 'RW', code: '250' },
  { name: 'Saint Barthelemy', shortName: 'BL', code: '590' },
  { name: 'Saint Helena', shortName: 'SH', code: '290' },
  { name: 'Saint Kitts and Nevis', shortName: 'KN', code: '1-869' },
  { name: 'Saint Lucia', shortName: 'LC', code: '1-758' },
  { name: 'Saint Martin (French part)', shortName: 'MF', code: '590' },
  { name: 'Saint Pierre and Miquelon', shortName: 'PM', code: '508' },
  {
    name: 'Saint Vincent and the Grenadines',
    shortName: 'VC',
    code: '1-784',
  },
  { name: 'Samoa', shortName: 'WS', code: '685' },
  { name: 'San Marino', shortName: 'SM', code: '378' },
  { name: 'Sao Tome and Principe', shortName: 'ST', code: '239' },
  { name: 'Saudi Arabia', shortName: 'SA', code: '966' },
  { name: 'Senegal', shortName: 'SN', code: '221' },
  { name: 'Serbia', shortName: 'RS', code: '381' },
  { name: 'Seychelles', shortName: 'SC', code: '248' },
  { name: 'Sierra Leone', shortName: 'SL', code: '232' },
  { name: 'Singapore', shortName: 'SG', code: '65' },
  { name: 'Sint Maarten (Dutch part)', shortName: 'SX', code: '1-721' },
  { name: 'Slovakia', shortName: 'SK', code: '421' },
  { name: 'Slovenia', shortName: 'SI', code: '386' },
  { name: 'Solomon Islands', shortName: 'SB', code: '677' },
  { name: 'Somalia', shortName: 'SO', code: '252' },
  { name: 'South Africa', shortName: 'ZA', code: '27' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    shortName: 'GS',
    code: '500',
  },
  { name: 'South Sudan', shortName: 'SS', code: '211' },
  { name: 'Spain', shortName: 'ES', code: '34' },
  { name: 'Sri Lanka', shortName: 'LK', code: '94' },
  { name: 'Sudan', shortName: 'SD', code: '249' },
  { name: 'Suriname', shortName: 'SR', code: '597' },
  { name: 'Svalbard and Jan Mayen', shortName: 'SJ', code: '47' },
  { name: 'Swaziland', shortName: 'SZ', code: '268' },
  { name: 'Sweden', shortName: 'SE', code: '46' },
  { name: 'Switzerland', shortName: 'CH', code: '41' },
  { name: 'Syrian Arab Republic', shortName: 'SY', code: '963' },
  { name: 'Taiwan, Province of China', shortName: 'TW', code: '886' },
  { name: 'Tajikistan', shortName: 'TJ', code: '992' },
  { name: 'Thailand', shortName: 'TH', code: '66' },
  { name: 'Timor-Leste', shortName: 'TL', code: '670' },
  { name: 'Togo', shortName: 'TG', code: '228' },
  { name: 'Tokelau', shortName: 'TK', code: '690' },
  { name: 'Tonga', shortName: 'TO', code: '676' },
  { name: 'Trinidad and Tobago', shortName: 'TT', code: '1-868' },
  { name: 'Tunisia', shortName: 'TN', code: '216' },
  { name: 'Turkey', shortName: 'TR', code: '90' },
  { name: 'Turkmenistan', shortName: 'TM', code: '993' },
  { name: 'Turks and Caicos Islands', shortName: 'TC', code: '1-649' },
  { name: 'Tuvalu', shortName: 'TV', code: '688' },
  { name: 'Uganda', shortName: 'UG', code: '256' },
  { name: 'Ukraine', shortName: 'UA', code: '380' },
  { name: 'United Arab Emirates', shortName: 'AE', code: '971' },
  { name: 'United Kingdom', shortName: 'GB', code: '44' },
  { name: 'United Republic of Tanzania', shortName: 'TZ', code: '255' },
  {
    name: 'United States Minor Outlying Islands',
    shortName: 'UM',
    code: '1',
  },
  { name: 'United States', shortName: 'US', code: '1', suggested: true },
  { name: 'Uruguay', shortName: 'UY', code: '598' },
  { name: 'US Virgin Islands', shortName: 'VI', code: '1-340' },
  { name: 'Uzbekistan', shortName: 'UZ', code: '998' },
  { name: 'Vanuatu', shortName: 'VU', code: '678' },
  { name: 'Venezuela', shortName: 'VE', code: '58' },
  { name: 'Viet Nam', shortName: 'VN', code: '84' },
  { name: 'Wallis and Futuna', shortName: 'WF', code: '681' },
  { name: 'Western Sahara', shortName: 'EH', code: '212' },
  { name: 'Yemen', shortName: 'YE', code: '967' },
  { name: 'Zambia', shortName: 'ZM', code: '260' },
  { name: 'Zimbabwe', shortName: 'ZW', code: '263' },
];

export const DEFAULT_SELECTED_COUNTRY: I18nCountry = {
  name: 'Japan',
  shortName: 'JP',
  code: '81',
  suggested: true,
};
