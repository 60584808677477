import { LocaleCode } from '@tablecheck/locales';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

import { createPasswordSchema } from '../PasswordRequirements/passwordSchema';

export const createSignUpSchema = (language: string) =>
  z
    .object({
      kanji_last_name: z.string().trim().optional(),
      kanji_first_name: z.string().trim().optional(),
      last_name: z.string().trim().min(1),
      first_name: z.string().trim().min(1),
      email: z.string().trim().email(),
      password: z.string(),
      phone: z.string().refine((value) => isValidPhoneNumber(value)),
      locale: z.string(),
    })
    .superRefine((data, ctx) => {
      const passwordValidation = createPasswordSchema(data.email).safeParse(
        data.password,
      );
      if (!passwordValidation.success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['password'],
        });
      }
      if (language === LocaleCode.Japanese) {
        if (!data.kanji_first_name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['kanji_first_name'],
          });
        }
        if (!data.kanji_last_name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['kanji_last_name'],
          });
        }
      }
    });

export type SignUpSchemaType = z.infer<ReturnType<typeof createSignUpSchema>>;
