import { LocaleCode, locales } from '@tablecheck/locales';

export const PRIORITIZED_LANGUAGES_BY_COUNTRY = {
  JP: [
    locales[LocaleCode.Japanese],
    locales[LocaleCode.English],
    locales[LocaleCode.Korean],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.ChineseTraditional],
  ],
  KR: [
    locales[LocaleCode.Korean],
    locales[LocaleCode.English],
    locales[LocaleCode.Japanese],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.ChineseTraditional],
  ],
  CN: [
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.ChineseTraditional],
  ],
  SG: [
    locales[LocaleCode.English],
    locales[LocaleCode.ChineseTraditional],
    locales[LocaleCode.ChineseSimplified],
  ],
  TW: [
    locales[LocaleCode.ChineseTraditional],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.English],
  ],
  HK: [
    locales[LocaleCode.ChineseTraditional],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.English],
  ],
  MO: [
    locales[LocaleCode.ChineseTraditional],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.English],
    locales[LocaleCode.Portuguese],
  ],
  TH: [
    locales[LocaleCode.Thai],
    locales[LocaleCode.English],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.ChineseTraditional],
  ],
  ID: [
    locales[LocaleCode.Indonesian],
    locales[LocaleCode.English],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.ChineseTraditional],
  ],
  MY: [
    locales[LocaleCode.Malay],
    locales[LocaleCode.English],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.ChineseTraditional],
  ],
  LA: [
    locales[LocaleCode.Lao],
    locales[LocaleCode.English],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.ChineseTraditional],
  ],
  VN: [
    locales[LocaleCode.Vietnamese],
    locales[LocaleCode.English],
    locales[LocaleCode.ChineseSimplified],
    locales[LocaleCode.ChineseTraditional],
  ],
  DE: [locales[LocaleCode.German]],
  AT: [locales[LocaleCode.German]],
  ES: [locales[LocaleCode.Spanish]],
  MX: [locales[LocaleCode.Spanish]],
  FR: [locales[LocaleCode.French]],
  PT: [locales[LocaleCode.Portuguese]],
  BR: [locales[LocaleCode.Portuguese]],
  IT: [locales[LocaleCode.Italian]],
  RU: [locales[LocaleCode.Russian]],
  PH: [locales[LocaleCode.Tagalog]],
  ...[
    'DZ',
    'BH',
    'KM',
    'DJ',
    'EG',
    'IQ',
    'JO',
    'KW',
    'LB',
    'LY',
    'MR',
    'MA',
    'OM',
    'PS',
    'QA',
    'SA',
    'SO',
    'SD',
    'SY',
    'TN',
    'AE',
    'YE',
  ].reduce(
    (acc, countryCode) => ({
      ...acc,
      [countryCode]: [locales[LocaleCode.Arabic]],
    }),
    {},
  ),
};
