import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { CONFIG } from '@local/configs';

import { useFooter } from './useFooter';

const Wrapper = styled.nav`
  border-top: 1px solid var(--light-grey-border);
  position: fixed;
  bottom: 0;
  width: 100svw;
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0;
  text-align: -webkit-center;
  justify-items: center;
  background-color: white;
  z-index: var(--zindex-topnav);
  &[data-is-cordova='true'] {
    padding-bottom: var(--spacing-l5);
  }
`;

const Button = styled.button`
  display: grid;
  height: var(--mobile-footer-height);
  justify-items: center;
  padding: var(--spacing-l3) 0;
  text-align: -webkit-center;
  color: var(--text-subtle);
  font-size: 11px;
  line-height: normal;
  min-width: -webkit-fill-available;
  position: relative;
  &[data-is-active='true'] {
    color: var(--primary);
  }
  &[data-is-active='true']::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1.5px;
    background-color: var(--primary);
  }
  & svg {
    padding: 2px;
  }
  & span {
    position: relative;
  }
`;

const Spacer = styled.div`
  height: 77px;
`;

const isActivePath = (currentPath: string, key: string, path: string) => {
  if (currentPath.includes('/reservations')) {
    return key === 'bookings';
  }
  if (currentPath.includes('/search')) {
    return key === 'explore';
  }
  return currentPath.includes(path);
};

export function MobileFooter() {
  const location = useLocation();
  const navigate = useNavigate();
  const { mobileActionBtns, shouldShowFooter } = useFooter();

  const createActionBtn = (
    key: string,
    path: string,
    icon: JSX.Element,
    testid: string,
  ) => (
    <Button
      key={key}
      onClick={() => navigate(path)}
      data-is-active={isActivePath(location.pathname, key, path)}
      data-testid={testid}
    >
      {icon}
      <span>{t(`layout.${key}`)}</span>
    </Button>
  );

  if (!shouldShowFooter) return null;
  return (
    <>
      <Wrapper data-is-cordova={CONFIG.IS_CORDOVA} data-testid="Mobile Footer">
        {mobileActionBtns.map(({ key, path, icon, testid }) =>
          createActionBtn(key, path, icon, testid),
        )}
      </Wrapper>
      <Spacer />
    </>
  );
}
