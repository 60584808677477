import { LocationFilled } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Button, ButtonProps } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { CONFIG } from '@local/configs';
import { DEFAULT_ICON_SIZE } from '@local/constants';

const Container = styled.div`
  display: flex;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--zindex-layer);
  bottom: calc(var(--mobile-footer-height) + var(--spacing-l4));
  &[data-is-cordova='true'] {
    bottom: calc(var(--mobile-footer-height) + var(--spacing-l5) * 2);
  }
`;
const StyledButton = styled(Button)`
  border-radius: var(--border-radius-full);
  padding: var(--spacing-l2) var(--spacing-l3);
  box-shadow: var(--elevation-medium-raised);
`;

export function ViewSwitcherBtn({ ...props }: ButtonProps) {
  return (
    <Container data-is-cordova={CONFIG.IS_CORDOVA}>
      <StyledButton
        data-testid="View Map Btn"
        data-size="medium"
        data-variant="tertiary"
        {...props}
      >
        <LocationFilled size={DEFAULT_ICON_SIZE} />
        {t('search.action_btns.view_map')}
      </StyledButton>
    </Container>
  );
}
