import { styled } from '@linaria/react';
import { t } from 'i18next';
import * as React from 'react';

import { CONFIG } from '@local/configs';

const NotExpandedContent = styled.p`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--line-count, 3);
`;

const ExpandedContent = styled(NotExpandedContent)`
  -webkit-line-clamp: unset;
`;

const ShowMoreButton = styled.a`
  color: var(--primary);
  cursor: pointer;
  margin-top: var(--spacing-l1);
`;

const ESTIMATED_ONE_LINE_CHAR_COUNT = 60;

export function CollapsibleText({
  linesToShow = 3,
  content,
}: {
  linesToShow?: number;
  content: string;
}) {
  const contentRef = React.useRef<null | HTMLDivElement>(null);
  // rough estimate if we should show more based on content length
  const canInitiallyShowReadMore =
    content.length > ESTIMATED_ONE_LINE_CHAR_COUNT * linesToShow;
  const [shouldShowReadMore, setShowReadMore] = React.useState(
    canInitiallyShowReadMore,
  );
  const [isContentExpanded, setIsContentExpanded] = React.useState(false);

  React.useEffect(() => {
    if (CONFIG.IS_SSR && contentRef.current) {
      const { lineHeight } = getComputedStyle(contentRef.current);
      const { clientHeight, scrollHeight } = contentRef.current;
      const maxHeight = parseFloat(lineHeight) * linesToShow;
      setShowReadMore(
        clientHeight !== scrollHeight && clientHeight >= maxHeight,
      );
    }
  }, [linesToShow]);

  const ContentComponent = isContentExpanded
    ? ExpandedContent
    : NotExpandedContent;

  return (
    <>
      <ContentComponent ref={contentRef}>{content}</ContentComponent>
      {shouldShowReadMore && (
        <ShowMoreButton
          onClick={() => setIsContentExpanded(!isContentExpanded)}
          type="button"
        >
          {isContentExpanded ? t('general.show_less') : t('general.show_more')}
        </ShowMoreButton>
      )}
    </>
  );
}
