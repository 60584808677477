import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import {
  PreviousZoomCenter,
  SelectVenueAtomType,
  UserMapMovementDetail,
} from '@local/types';

export const selectedVenueAtom = atom<SelectVenueAtomType>({
  venue: null,
  shouldShowMapVenueCard: false,
});

const selectedVenueIdAtom = atom<Record<string, string | null>>({});

export const selectedVenueIdFamilyAtom = atomFamily((containerId: string) =>
  atom(
    (get) => get(selectedVenueIdAtom)[containerId],
    (get, set, arg: string | null) => {
      const prev = get(selectedVenueIdAtom);
      set(selectedVenueIdAtom, {
        ...prev,
        [containerId]: arg,
      });
    },
  ),
);

const userMapMovementDetailAtom = atom<Record<string, UserMapMovementDetail>>(
  {},
);

export const userMapMovementDetailFamilyAtom = atomFamily(
  (containerId: string) =>
    atom(
      (get) => get(userMapMovementDetailAtom)[containerId],
      (get, set, arg: UserMapMovementDetail) => {
        const prev = get(userMapMovementDetailAtom);
        set(userMapMovementDetailAtom, {
          ...prev,
          [containerId]: arg,
        });
      },
    ),
);

export const mapMaxZoomOnSearchAtom = atom<number | null>(null);

const mapPreviousZoomCenterAtom = atom<Record<string, PreviousZoomCenter>>({});

export const mapPreviousZoomCenterFamilyAtom = atomFamily(
  (containerId: string) =>
    atom(
      (get) => get(mapPreviousZoomCenterAtom)[containerId],
      (get, set, arg: PreviousZoomCenter) => {
        const prev = get(mapPreviousZoomCenterAtom);
        set(mapPreviousZoomCenterAtom, {
          ...prev,
          [containerId]: arg,
        });
      },
    ),
);

export const shouldFitBoundsToMarkersAtom = atom<boolean>(true);

export const mapShouldUsePreviousLocationAtom = atom<boolean>(true);
