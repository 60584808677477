import {
  Calendar,
  CheckmarkFilled,
  Hourglass,
  Misuse,
  Store,
  Timer,
  UserMultiple,
} from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';

import { SmartLink } from '@local/components';
import { DEFAULT_ICON_SIZE, ICON_SIZE_40 } from '@local/constants';
import type { Reservation, ReservationVenueInfo } from '@local/types';
import { DateFormat, formatDisplayDate, translate } from '@local/utils';

import { ActionBtns } from '../ActionBtns/ActionBtns';

const ReservationCardWrapper = styled.div`
  padding: var(--spacing-l4);
  border: 1px var(--lighter-grey-border) solid;
  border-radius: var(--border-radius-large);
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-l2);
  margin-bottom: var(--spacing-l2);

  > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l2);
  }

  h5 {
    overflow-wrap: anywhere;
  }
`;

const DatePaxWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-l2);
  align-items: center;
  gap: var(--spacing-l2);
  font: var(--body-2);
  > div {
    display: flex;
    align-items: center;
    gap: var(--spacing-l1);
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-l1);
  font: var(--body-2);

  span {
    width: fit-content;
  }
  &[data-status='accepted'],
  &[data-status='confirmed'],
  &[data-status='waitlist_called'],
  &[data-status='tentative'] {
    color: var(--success);
  }
  &[data-status='pending'] {
    color: var(--light-blue);
  }
  &[data-status='attended'] {
    color: var(--brand-primary);
  }
  &[data-status='request'],
  &[data-status='waiting'] {
    color: var(--warning-text);
  }
  &[data-status='cancelled'],
  &[data-status='rejected'] {
    color: var(--error-text);
  }
  &[data-status='expired'],
  &[data-status='noshow'] {
    color: var(--lighter-text-color);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--card-side-icon);
  height: var(--card-side-icon);
  border-radius: var(--border-radius-small);
  background-color: var(--surface-raised-hover);
`;

const VenueImg = styled.img`
  border-radius: var(--border-radius-small);
  width: var(--card-side-icon);
  height: var(--card-side-icon);
  object-fit: cover;
`;

const statusIconMap = {
  accepted: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  confirmed: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  tentative: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  waitlist_called: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  pending: <Timer size={DEFAULT_ICON_SIZE} />,
  attended: <CheckmarkFilled size={DEFAULT_ICON_SIZE} />,
  request: <Timer size={DEFAULT_ICON_SIZE} />,
  cancelled: <Misuse size={DEFAULT_ICON_SIZE} />,
  rejected: <Misuse size={DEFAULT_ICON_SIZE} />,
  expired: <Misuse size={DEFAULT_ICON_SIZE} />,
  noshow: <Misuse size={DEFAULT_ICON_SIZE} />,
  waiting: <Hourglass size={DEFAULT_ICON_SIZE} />,
};

export function ReservationCard({
  reservation,
  venue,
}: {
  reservation: Reservation;
  venue: ReservationVenueInfo;
}): JSX.Element {
  const [t, { language }] = useTranslation();
  const venueUrl = `/${language}/${venue.slug}`;
  return (
    <ReservationCardWrapper data-testid="Reservation Card">
      <Details>
        <div>
          <SmartLink href={venueUrl}>
            <h5>{translate(venue.name_translations, language)}</h5>
          </SmartLink>
          <DatePaxWrap>
            <div data-testid="Reservation Pax">
              <UserMultiple size={DEFAULT_ICON_SIZE} />
              <span>{reservation.num_people}</span>
            </div>
            <div data-testid="Reservation Date">
              <Calendar size={DEFAULT_ICON_SIZE} />
              <span>
                {formatDisplayDate(
                  new Date(reservation.start_at),
                  DateFormat.MonthDayTime,
                  language,
                  venue.time_zone,
                )}
              </span>
            </div>
          </DatePaxWrap>
          <Status
            data-testid="Reservation Status"
            data-status={reservation.customer_status}
          >
            {statusIconMap[reservation.customer_status]}
            <span>
              {t(`reservations_feat.status.${reservation.customer_status}`)}
            </span>
          </Status>
        </div>
        <SmartLink href={venueUrl}>
          {venue.search_image ? (
            <VenueImg
              src={venue.search_image}
              alt={venue.slug}
              data-testid="Reservation Venue Image"
            />
          ) : (
            <IconWrapper data-testid="Reservation Venue Image">
              <Store fill="var(--text-placeholder)" size={ICON_SIZE_40} />
            </IconWrapper>
          )}
        </SmartLink>
      </Details>

      <ActionBtns reservation={reservation} venue={venue} />

      {/* TODO: add Bill component DPOR-656 */}
    </ReservationCardWrapper>
  );
}
