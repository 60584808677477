import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { fetchGalleryQuery } from '../venueApi';

export const useFetchGalleryQuery = (venueId: string) => {
  const [, { language }] = useTranslation();

  return useQuery({
    queryKey: ['gallery', venueId, language],
    queryFn: () => fetchGalleryQuery(venueId, language),
    enabled: !!venueId,
    staleTime: Infinity,
  });
};
