import { useQuery } from '@tanstack/react-query';

import { STALETIME_10_SECONDS, USER_QUERY_KEY } from '@local/constants';
import { TransformedMembershipAuthsResponse } from '@local/types';

import { getMemberships } from '../accountApi';

export const useFetchMemberships = (locale: string) =>
  useQuery({
    queryKey: [USER_QUERY_KEY, 'memberships'],
    queryFn: () => getMemberships(locale),
    staleTime: STALETIME_10_SECONDS,
    select: (data): TransformedMembershipAuthsResponse[] => {
      const { membership_auths, membership_providers, shops } = data;
      if (!membership_auths || membership_auths.length === 0) {
        return [];
      }

      const providerMap = new Map(
        membership_providers?.map((provider) => [provider.id, provider.name]),
      );
      const shopMap = new Map(shops?.map((shop) => [shop.id, shop.name]));

      return membership_auths.map((membership) => {
        const providerName = providerMap.get(membership.membership_provider_id);
        const shopNames = membership.shop_ids.map((shopId) =>
          shopMap.get(shopId),
        );

        return {
          membershipAuthId: membership.id,
          providerName,
          membershipCode: membership.code,
          startDate: membership.start_date,
          locationsUsed: shopNames,
        };
      });
    },
  });
