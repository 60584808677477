import { Launch } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { Badge, Button } from '@tablecheck/tablekit-react-css';
import { useTranslation } from 'react-i18next';

import { Footer, Price, SmartLink, Spinner } from '@local/components';
import { CONFIG } from '@local/configs';
import { DPOR_UTM_SOURCE_BETA1 } from '@local/constants';
import {
  CurrencyFormat,
  DateFormat,
  buildBookingFormUrl,
  formatDisplayDate,
} from '@local/utils';

import { useFetchSinglePayment } from '../../../hooks/useFetchSinglePayment';
import { usePayments } from '../../../hooks/usePayments';

import { GenerateReceiptSection } from './GenerateReceiptSection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l4);
`;

const Table = styled.table`
  font: var(--small);
  border-collapse: collapse;
  border-radius: var(--border-radius-small);
  overflow: hidden;

  col:first-child {
    background-color: var(--surface-raised);
  }
  col:last-child {
    width: 100%;
  }

  th {
    font-weight: 600;
  }

  th,
  td {
    place-content: center;
    white-space: nowrap;
    padding: 15px var(--spacing-l3);
    text-align: left;
    border: 1px solid var(--border);

    & > span {
      font-weight: normal;
    }
  }
`;

const StyledSmartLink = styled(SmartLink)`
  display: flex;
  gap: var(--spacing-l1);
  align-items: center;
  color: var(--text);
`;

const CardDetailsWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;

  & > img {
    width: 30px;
  }
`;

interface Props {
  selectedPaymentId: string;
  onClose: () => void;
}

export function ViewPaymentContent({ selectedPaymentId, onClose }: Props) {
  const [t, { language }] = useTranslation();
  const { data: { payments: [payment] = [] } = {}, isLoading } =
    useFetchSinglePayment(selectedPaymentId, language);
  const { mapPaymentStatusToStr, mapPaymentTypeToStr, matchStatusToVariant } =
    usePayments();

  return (
    <Wrapper>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Table
            data-testid="Payment Details Table"
            aria-label={`Payment details for ${payment.shop_name}`}
          >
            <colgroup>
              <col span={1} />
              <col span={1} />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{t('general.amount')}</th>
                <td>
                  <Price
                    amount={payment.authorized_amt ?? payment.captured_amt}
                    currency={payment.currency as CurrencyFormat}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">{t('general.venue')}</th>
                <td>
                  <StyledSmartLink
                    target="_blank"
                    href={buildBookingFormUrl(
                      `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${payment.shop_slug}/reserve`,
                    )}
                    rel="noopener noreferrer"
                  >
                    {payment.shop_name}
                    <Launch size={12} />
                  </StyledSmartLink>
                </td>
              </tr>
              <tr>
                <th scope="row">{t('general.reservation')}</th>
                <td>
                  {payment.reservation_code && (
                    <StyledSmartLink
                      target="_blank"
                      href={`${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/reservation/${payment.reservation_code}?utm_source=${DPOR_UTM_SOURCE_BETA1}`}
                      rel="noopener noreferrer"
                    >
                      {payment.reservation_code}
                      <Launch size={12} />
                    </StyledSmartLink>
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">{t('general.time')}</th>
                <td>
                  {formatDisplayDate(
                    new Date(payment.created_at),
                    DateFormat.YearMonthDayTime,
                    language,
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">{t('general.type')}</th>
                <td>
                  {t(
                    `account.payment_type.${mapPaymentTypeToStr(payment.payment_type, payment.payment_status)}`,
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">{t('general.status')}</th>
                <td>
                  <Badge
                    data-variant={matchStatusToVariant(
                      mapPaymentStatusToStr(payment.payment_status),
                    )}
                    data-size="x-small"
                  >
                    {t(
                      `account.payment_status.${mapPaymentStatusToStr(payment.payment_status)}`,
                    )}
                  </Badge>
                </td>
              </tr>
              <tr>
                <th scope="row">{t('general.payment_method')}</th>
                <td>
                  <CardDetailsWrapper>
                    <img
                      src={`${CONFIG.VITE_BASE_CDN_IMAGES_URL}logos/credit-cards/v1.0.0/${payment.brand}.svg`}
                      alt={payment.brand}
                    />
                    {payment.card_digits}
                  </CardDetailsWrapper>
                </td>
              </tr>
            </tbody>
          </Table>
          <GenerateReceiptSection
            paymentId={payment.id}
            status={payment.ereceipt_status}
          />
        </>
      )}
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onClose}>
          {t('general.close')}
        </Button>
      </Footer>
    </Wrapper>
  );
}
