import { DirectionBearRight_02 as Direction } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_VENUE_IMAGE } from '@local/assets';
import { SmartLink } from '@local/components';
import { ICON_SIZE_16 } from '@local/constants';
import { useUniverse } from '@local/hooks';
import { useMapService } from '@local/map-system';
import type { MapVenue, VenueInfo } from '@local/types';
import { buildMapUrl } from '@local/utils';

const MapWrapper = styled(SmartLink)`
  position: relative;
  height: var(--desktop-venue-map-height);
  width: 100%;
  border-radius: var(--border-radius-large);
  overflow: hidden;
`;

const DirectionButton = styled.div`
  background-color: var(--white);
  box-shadow: var(--elevation-small-raised);
  border: 1px solid var(--border-transparent);
  border-radius: var(--border-radius-small);
  bottom: var(--spacing-l4);
  display: flex;
  gap: var(--spacing-l2);
  padding: 6px 11px;
  position: absolute;
  right: var(--spacing-l4);
  align-items: center;
`;
const DEFAULT_VENUE_ZOOM = 12;

export function Map({ venueInfo }: { venueInfo: VenueInfo }): JSX.Element {
  const [, { language }] = useTranslation();
  const { universeDefaultLocation } = useUniverse();

  const {
    _id: id,
    geocode,
    name_translations,
    location_name_translations,
    search_image,
    slug,
    cuisines,
    google_place_ref,
  } = venueInfo;
  const longitude = geocode?.lon ?? universeDefaultLocation.lon;
  const latitude = geocode?.lat ?? universeDefaultLocation.lat;

  const venues: MapVenue[] = React.useMemo(() => {
    if (geocode) {
      return [
        {
          id,
          slug,
          name_translations,
          location_name_translations,
          geocode: {
            lng: geocode.lon,
            lat: geocode.lat,
          },
          search_image: search_image ?? DEFAULT_VENUE_IMAGE,
          cuisines,
        },
      ];
    }
    return [];
  }, []);

  useMapService({
    venues,
    language,
    center: [longitude, latitude],
    zoom: DEFAULT_VENUE_ZOOM,
    interactive: false,
  });

  return (
    <MapWrapper
      href={
        geocode ? buildMapUrl(geocode.lat, geocode.lon, google_place_ref) : ''
      }
    >
      <div id="map" style={{ width: '100%', height: '100%' }} />
      <DirectionButton>
        <Direction size={ICON_SIZE_16} />
        {t('venue_feat.information.directions')}
      </DirectionButton>
    </MapWrapper>
  );
}
