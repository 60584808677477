import { useDeviceType } from '@local/hooks';

import { HomeDesktop } from './HomeDesktop';
import { HomeHead } from './HomeHead';
import { HomeMobile } from './HomeMobile';

export function HomeRoot() {
  const { isDesktop } = useDeviceType();
  return (
    <>
      <HomeHead />
      {isDesktop ? <HomeDesktop /> : <HomeMobile />}
    </>
  );
}
