import { styled } from '@linaria/react';

import { SmartLink } from '@local/components';

export const Info = styled.section`
  display: grid;
  gap: var(--spacing-l1);
  align-items: baseline;
  grid-template-columns: 20px auto;
  width: 100%;
  color: var(--text-subtle);

  a {
    color: var(--link);
  }
  svg {
    position: relative;
    top: 2px;
    fill: var(--text);
  }
`;

export const SectionTitle = styled.div`
  font: var(--label);
  color: var(--text);
`;

export const VenueLink = styled(SmartLink)`
  text-decoration: underline;
`;

export const VenueDetails = styled.div`
  font: var(--body-2);
  a {
    font: var(--body-2);
  }
  display: flex;
  gap: var(--spacing-l1);
  flex-direction: column;
`;

export const BusinessHours = styled.div`
  color: var(--text-subtle);
  display: flex;
  justify-content: space-between;
`;

export const OpeningHours = styled(BusinessHours)`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-l2);
  margin-top: var(--spacing-l2);
`;

export const InformationWrapper = styled.section`
  word-break: break-all;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
`;
