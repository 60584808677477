import { styled } from '@linaria/react';
import { t } from 'i18next';

import { Spinner } from '@local/components';
import type {
  CuisineSearchSuggestionsResponseType,
  LocationSearchSuggestionsResponseType,
  ShopSearchSuggestionsResponseType,
} from '@local/types';

import { useSearchInput } from '../../hooks/useSearchInput';

import { SuggestionItem } from './SuggestionItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div[data-testid*='Suggestion Item']:first-of-type {
    background-color: var(--surface-active);
  }
`;

const LoadingWrapper = styled.div`
  margin-top: var(--spacing-l4);
  height: 60px;
`;

type SearchSuggestion =
  | CuisineSearchSuggestionsResponseType
  | LocationSearchSuggestionsResponseType
  | ShopSearchSuggestionsResponseType;

interface Props {
  suggestions?: SearchSuggestion[];
  isLoading?: boolean;
}

export function SearchSuggestions({ suggestions, isLoading }: Props) {
  const { handleSuggestionClick } = useSearchInput();

  if (isLoading)
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  if (!suggestions) return null;

  return (
    <Wrapper data-testid="Search Suggestions">
      {suggestions.length === 0 && (
        <SuggestionItem
          type="Fallback"
          onClick={() => void handleSuggestionClick()}
          title={t('availability.explore_venues')}
          iconName="locationCurrent"
        />
      )}

      {suggestions.map((suggestion) => {
        if (suggestion.type === 'cuisines') {
          return (
            <SuggestionItem
              type="Cuisines"
              onClick={() => void handleSuggestionClick(suggestion)}
              title={suggestion.text}
              key={suggestion.payload.term}
              description={t('cuisine.title')}
              iconName="cuisine"
            />
          );
        }
        if (suggestion.type === 'locations') {
          return (
            <SuggestionItem
              type="Locations"
              onClick={() => void handleSuggestionClick(suggestion)}
              title={suggestion.text}
              key={suggestion.payload.term + suggestion.payload.area}
              description={suggestion.payload.area}
              iconName="location"
            />
          );
        }
        if (suggestion.type === 'shops') {
          return (
            <SuggestionItem
              type="Shops"
              onClick={() => void handleSuggestionClick(suggestion)}
              title={suggestion.text}
              imgUrl={suggestion.payload.image_url}
              key={suggestion.payload.shop_slug}
              description={suggestion.payload.location_name}
              iconName="store"
            />
          );
        }
        return null;
      })}
    </Wrapper>
  );
}
