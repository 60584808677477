import {
  getTranslation,
  Translation,
  TranslationsArray,
} from '@tablecheck/i18n';
import { LocaleCode } from '@tablecheck/locales';

export const translate = (
  rawTranslation: Translation | TranslationsArray | string | undefined,
  locale: string,
): string => {
  if (!rawTranslation) return '';

  const translations =
    typeof rawTranslation === 'string'
      ? rawTranslation
      : ([] as TranslationsArray).concat(rawTranslation).map((translation) => ({
          locale: translation.locale as LocaleCode,
          text: translation.translation,
        }));

  // Try primary requested locale
  const primaryTranslation = getTranslation(translations, locale as LocaleCode);
  if (primaryTranslation) return primaryTranslation;

  // Fallback to English as secondary locale
  const englishTranslation = getTranslation(translations, 'en' as LocaleCode);
  if (englishTranslation) return englishTranslation;

  // Fallback to Japanese as ternary locale
  const japaneseTranslation = getTranslation(translations, 'ja' as LocaleCode);
  if (japaneseTranslation) return japaneseTranslation;

  return '';
};

// the locale of the best match translation can differ from the current application locale
// even if the app locale is japanese, we need to ensure we never word wrap non-japanese languages
export const translateForMarkup = (
  ...args: Parameters<typeof translate>
): [string, string | undefined] => {
  const bestMatch = translate(...args);
  const [rawTranslations] = args;

  if (rawTranslations && Array.isArray(rawTranslations)) {
    const foundTranslation = rawTranslations.find(
      ({ translation }) => translation === bestMatch,
    );
    return [bestMatch, foundTranslation ? foundTranslation.locale : undefined];
  }
  return [bestMatch, undefined];
};

export const hasKanjiOrJapaneseCharacters = (text?: string): boolean => {
  if (!text) return false;
  return (
    /[\u4E00-\u9FFF]/.test(text) || // Test if translation has kanjis
    /[\u3040-\u309F]/.test(text) || // Test if translation has hiragana
    /[\u30A0-\u30FF]/.test(text) || // Test if translation has full-width katakana
    /[\uFF66-\uFF9F]/.test(text) // Test if translation has half-width katakana
  );
};

export const hasAlphabeticalCharacters = (
  text: string | undefined,
): boolean => {
  if (!text) return false;
  return /[a-zA-Z]/.test(text);
};
