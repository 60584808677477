import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MobileSubHeader } from '@local/layouts';

const Title = styled.h1`
  font-size: xx-large;
  line-height: 42px;
  padding: 0 var(--spacing-l4);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l4);
  gap: var(--spacing-l6);

  & > div,
  & > form {
    margin-top: var(--spacing-l4);
  }
`;

interface Props {
  children: React.ReactNode;
  title: string;
}

export function MobileContentWrapper({ children, title }: Props) {
  const [, { language }] = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <MobileSubHeader
        onBackBtnClick={() => navigate(`/${language}/account/profile`)}
        withGradient={false}
      />
      <Title>{title}</Title>
      <Content>{children}</Content>
    </>
  );
}
