import { styled } from '@linaria/react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { SmartImage, SmartLink } from '@local/components';
import { useUniverse } from '@local/hooks';
import { mapShouldUsePreviousLocationAtom } from '@local/map-system';
import { searchValAtom, useSearchFilters } from '@local/search';
import type { ContentCard } from '@local/types';
import { buildUrl } from '@local/utils';

const StyledSmartLink = styled(SmartLink)`
  cursor: pointer;
  p {
    font: var(--label);
  }
`;

const Image = styled(SmartImage)`
  height: 112px;
  width: calc(100svw / 2.5);
  max-width: 150px;
  margin-bottom: var(--spacing-l1);
  border-radius: var(--border-radius-large);
  object-fit: cover;
`;

interface Props {
  card: ContentCard;
  defaultParams: { date: string; time: string; num_people: string };
}

export function StoryblokCard({ card, defaultParams }: Props) {
  const [t, { language }] = useTranslation();
  const { universeName } = useUniverse();
  const setSearchVal = useSetAtom(searchValAtom);
  const { updateSearchFilters } = useSearchFilters();
  const setMapShouldUsePreviousLocation = useSetAtom(
    mapShouldUsePreviousLocationAtom,
  );
  const isCuisineType = 'key' in card;
  const { text, url, dataTestId, onClick } = isCuisineType
    ? {
        text: t(`cuisine.${card.key.toLowerCase().replace(/ /g, '_')}`),
        url: buildUrl(`/${language}/${universeName}/search`, {
          ...defaultParams,
          'cuisines[]': card.key,
        }),
        dataTestId: 'Cuisine Card',
        onClick: () => {
          setSearchVal('');
          updateSearchFilters({ cuisines: [card.key] }, { reset: true });
        },
      }
    : {
        text: t(`area.${card.name.toLowerCase().replace(/ /g, '_')}`),
        url: buildUrl(`/${language}/${universeName}/search`, {
          ...defaultParams,
          geo_latitude: card.latitude,
          geo_longitude: card.longitude,
          geo_distance: card.distance,
          auto_geolocate: 'false',
        }),
        dataTestId: 'Area Card',
        onClick: () => {
          setSearchVal('');
          setMapShouldUsePreviousLocation(false);
          updateSearchFilters(
            {
              geo_latitude: Number(card.latitude),
              geo_longitude: Number(card.longitude),
              geo_distance: card.distance,
              auto_geolocate: false,
            },
            { reset: true },
          );
        },
      };

  return (
    <StyledSmartLink data-testid={dataTestId} href={url} onClick={onClick}>
      <Image
        src={`${card.image.filename}/m/filters:format(png)`}
        alt={text}
        loading="lazy"
        decoding="async"
        sources={[
          {
            srcSet: `${card.image.filename}/m/filters:format(webp)`,
            type: 'image/webp',
          },
        ]}
      />
      <p>{text}</p>
    </StyledSmartLink>
  );
}
