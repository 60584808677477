import { Group, Time } from '@carbon/icons-react';
import { useReactSelectConfig } from '@tablecheck/tablekit-react-select';
import { format, isEqual, parseISO } from 'date-fns';
import { t } from 'i18next';
import * as React from 'react';
import {
  Control,
  SubmitHandler,
  UseFormReset,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { MenuPlacement } from 'react-select';

import {
  DEFAULT_ICON_SIZE,
  DEFAULT_PAX,
  MAX_NUM_PEOPLE_COUNT,
} from '@local/constants';
import type { AvailabilityFormInputsType } from '@local/types';
import {
  generate30MinIntervals,
  generatePaxValues,
  getDefaultTime,
  getFormattedApiDate,
  getFormattedDisplayDate,
} from '@local/utils';

import { Text } from './AvailabilityChecker.styles';

interface Props {
  values: AvailabilityFormInputsType;
  setValue: UseFormSetValue<AvailabilityFormInputsType>;
  setIsOpen: (value: boolean) => void;
  onSubmit: (data: AvailabilityFormInputsType) => void;
  onReset: () => void;
  reset: UseFormReset<AvailabilityFormInputsType>;
  control: Control<AvailabilityFormInputsType>;
  isOpen: boolean;
}

const availabilityChipText = (values: {
  date?: string;
  time?: string;
  num_people?: string;
}) => {
  const textElements: JSX.Element[] = [];
  const pushSeparator = () => {
    if (textElements.length > 0) {
      textElements.push(
        <Text key={`separator-${textElements.length}`}> · </Text>,
      );
    }
  };
  const pushText = (content: string) => {
    textElements.push(
      <Text key={`text-${textElements.length}`} data-has-value>
        {content}
      </Text>,
    );
  };

  if (values.date) {
    pushText(getFormattedDisplayDate(values.date));
  }
  if (values.time) {
    pushSeparator();
    pushText(values.time);
  }
  if (values.num_people) {
    pushSeparator();
    pushText(
      t('general.pax_number', {
        pax_number: values.num_people,
        count: Number(values.num_people) ?? MAX_NUM_PEOPLE_COUNT,
      }),
    );
  }
  return textElements;
};

// Both icon components kept camel-cased as otherwise we get the following warning "Fast refresh only works when a file only exports components. Move your component(s) to a separate file."
const timeIcon = <Time size={DEFAULT_ICON_SIZE} />;
const groupIcon = <Group size={DEFAULT_ICON_SIZE} />;

export function useAvailChecker({
  values,
  setValue,
  setIsOpen,
  onSubmit,
  onReset,
  reset,
  control,
  isOpen,
}: Props) {
  const today = new Date();
  const time = useWatch({ name: 'time', control });
  const date = useWatch({ name: 'date', control });
  const numPeople = useWatch({ name: 'num_people', control });

  const handleFormSubmit: SubmitHandler<AvailabilityFormInputsType> = (
    data,
  ) => {
    // format date to yyyy-mm-dd for ease of use with api
    if (data.date) {
      data.date = getFormattedApiDate(data.date);
    }
    onSubmit(data);
    setIsOpen(false);
  };

  const handleReset = () => {
    reset({});
    onReset();
    setIsOpen(false);
  };

  const handleClose = () => {
    reset(values);
    setIsOpen(false);
  };

  const handleTimeChange = (newValue: unknown) => {
    const option = newValue as { value: string };
    setValue('time', option.value === 'all_day' ? undefined : option.value);
  };

  const handlePaxChange = (newValue: unknown) => {
    const option = newValue as { value: string };
    setValue('num_people', option.value === 'any' ? undefined : option.value);
  };

  const handleDateSelected = (e: { date: Date }): void => {
    if (isEqual(e.date, parseISO(date ?? ''))) {
      setValue('date', undefined); // allows date unselection
    } else {
      setValue('date', format(e.date, 'yyyy-MM-dd'));
    }
  };

  const hasValue = React.useMemo(
    () => Object.values(values).some(Boolean),
    [values],
  );
  const defaultValues = {
    date: undefined,
    time: getDefaultTime(today),
    num_people: DEFAULT_PAX,
  };

  React.useEffect(() => {
    if (!isOpen) return;
    if (hasValue) {
      // if one availability value is selected, we should use it and set the rest to undefined
      setValue('date', values.date);
      setValue('time', values.time);
      setValue('num_people', values.num_people);
    } else {
      // Otherwise use default values
      setValue('date', defaultValues.date);
      setValue('time', defaultValues.time);
      setValue('num_people', defaultValues.num_people);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const timeOptions = [
    {
      label: t('availability.all_day'),
      value: 'all_day',
    },
    ...generate30MinIntervals(date).map((value) => ({
      label: value,
      value,
    })),
  ];

  const paxOptions = [
    {
      label: t('availability.any_party_size'),
      value: 'any',
    },
    ...generatePaxValues().map((value) => ({
      label: t(`general.pax_number`, {
        pax_number: value,
        count: Number(value) ?? MAX_NUM_PEOPLE_COUNT,
      }),
      value: value.toString(),
    })),
  ];

  const { styles: timeStyles, components: timeComponents } =
    useReactSelectConfig({
      dataTestId: 'Availability Time Selector',
      isMulti: false,
      icon: timeIcon,
    });
  const { styles: paxStyles, components: paxComponents } = useReactSelectConfig(
    {
      dataTestId: 'Availability Pax Selector',
      isMulti: false,
      icon: groupIcon,
      isCompact: true,
    },
  );

  const timeValue = time
    ? { label: time, value: time }
    : { label: t('availability.all_day'), value: 'all_day' };

  const paxValue = numPeople
    ? paxOptions.find((option) => option.value === numPeople.toString())
    : paxOptions[0];

  const chipContent = hasValue
    ? availabilityChipText(values)
    : t('availability.find_avail');

  const timeSelectProps = {
    styles: timeStyles,
    components: {
      ...timeComponents,
    },
    options: timeOptions,
    onChange: handleTimeChange,
    value: timeValue,
    isSearchable: false,
    placeholder: t('availability.all_day'),
    menuPlacement: 'top' as MenuPlacement,
  };

  const paxSelectProps = {
    styles: paxStyles,
    components: {
      ...paxComponents,
    },
    options: paxOptions,
    onChange: handlePaxChange,
    value: paxValue,
    isSearchable: false,
    placeholder: t('availability.any_party_size'),
    menuPlacement: 'top' as MenuPlacement,
  };

  return {
    date,
    time,
    numPeople,
    handleFormSubmit,
    handleDateSelected,
    handleClose,
    handleReset,
    hasValue,
    chipContent,
    timeSelectProps,
    paxSelectProps,
  };
}
