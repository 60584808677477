/* eslint-disable max-lines */
import { TranslationsArray } from '@tablecheck/i18n';
import { LocaleCode } from '@tablecheck/locales';
import { debounce } from 'lodash-es';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import Supercluster from 'supercluster';

import { DEFAULT_MAP_MARKER_IMAGE } from '@local/assets';
import { DEFAULT_MAP_ID } from '@local/constants';
import { logger } from '@local/services';
import type {
  CameraTransitionOptions,
  ClusterOrPoint,
  CustomClusterFeature,
  CustomPointFeature,
  CustomSupercluster,
  DebouncedFunc,
  MapBounds,
  MapServiceOptions,
  MapVenue,
  PreviousZoomCenter,
  UserMapMovementDetail,
} from '@local/types';
import { translate } from '@local/utils';

import { mapStyle } from './mapStyle';
import './styles.css';

export const MAP_EVENTS = {
  USER_MOVEMENT: 'userMovement',
  PREV_ZOOM_CENTER: 'previousZoomCenter',
  VENUE_SELECTED: 'venueSelected',
  INITIALIZED: 'initialized',
};

// TODO: May need to make this more dynamic by increasing sensitivity for low zoom levels and decreasing for high zoom levels
const RADIUS_DISTANCE_PERCENTAGE = 0.1;
const DEBOUNCED_CHECK_MAP_MOVEMENT_TIME = 200;
const MIN_EXPANDED_BOUNDS_PADDING = 2;
const VENUE_MARKER_CLASS_NAME = 'custom-marker';
const CLUSTER_MARKER_CLASS_NAME = 'custom-cluster-marker';
const VENUE_TITLE_CROPPED_LENGTH = 15;
export class MapService {
  private map!: maplibregl.Map;

  private geolocateControl!: maplibregl.GeolocateControl;

  private superCluster!: CustomSupercluster;

  private markerMap = new Map<string | number, maplibregl.Marker>();

  private languageInitialized = false;

  private previousStatus!: {
    searchedZoom: number;
    searchedCenter: maplibregl.LngLat;
  };

  private debouncedCheckMapMovement!: DebouncedFunc;

  private selectedMarker: HTMLElement | null = null;

  private options: MapServiceOptions;

  private isMapInteractive: boolean;

  private hideControlsOnSelect: boolean;

  public eventTarget = new EventTarget();

  constructor(options: MapServiceOptions) {
    this.options = options;
    this.isMapInteractive = options.interactive ?? true;
    this.hideControlsOnSelect = options.hideControlsOnSelect ?? true;
    this.initializeMap();
  }

  private initializeMap(): void {
    this.map = new maplibregl.Map({
      container: this.options.containerId ?? DEFAULT_MAP_ID,
      style: mapStyle as maplibregl.StyleSpecification,
      center: this.options.center,
      zoom: this.options.zoom,
      interactive: this.isMapInteractive,
      attributionControl: false,
    });

    this.map.on('load', () => {
      this.initializeSupercluster();
      this.updateClustersAndMarkers();
      this.changeLanguage(this.options.language ?? LocaleCode.English);

      if (this.isMapInteractive) {
        this.previousStatus = {
          searchedZoom: this.options.zoom,
          searchedCenter: new maplibregl.LngLat(
            (this.options.center as [number, number])[0],
            (this.options.center as [number, number])[1],
          ),
        };
        this.debouncedCheckMapMovement = debounce(() => {
          this.checkMapMovement();
        }, DEBOUNCED_CHECK_MAP_MOVEMENT_TIME);

        this.initializeGeolocationControl();
        this.addEventListeners();
        if (this.options.bounds) {
          this.map.setMaxBounds([
            this.options.bounds.southWest,
            this.options.bounds.northEast,
          ] as maplibregl.LngLatBoundsLike);
        }
      }
      this.eventTarget.dispatchEvent(new Event(MAP_EVENTS.INITIALIZED));
    });
  }

  private initializeSupercluster(): void {
    const geoJsonFeatures = this.createGeoJSONFeatures();
    this.superCluster = new Supercluster({
      radius: 40,
      maxZoom: 16,
    }).load(geoJsonFeatures) as CustomSupercluster;
  }

  private initializeGeolocationControl(): void {
    this.geolocateControl = new maplibregl.GeolocateControl({
      fitBoundsOptions: { maxZoom: this.options.zoom },
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
    });

    this.geolocateControl.on('outofmaxbounds', (e) => {
      logger.warn('out of bounds', e);
    });
    this.map.addControl(this.geolocateControl, 'bottom-right');
  }

  public setBounds({ southWest, northEast }: MapBounds): void {
    this.map.setMaxBounds([
      southWest,
      northEast,
    ] as maplibregl.LngLatBoundsLike);
  }

  private updateClustersAndMarkers(): void {
    const bounds = this.map.getBounds();
    const bbox: GeoJSON.BBox = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ];

    const allVisibleFeatures = this.superCluster.getClusters(
      bbox,
      this.map.getZoom(),
    ) as ClusterOrPoint[];
    const currentVenueMarkerIds = new Set<string>();
    const currentClusterMarkerIds = new Set<number>();

    allVisibleFeatures.forEach((feature) => {
      if (MapService.isCluster(feature)) {
        const clusterId = feature.properties.cluster_id;
        currentClusterMarkerIds.add(clusterId);

        // create/unhide cluster markers
        if (!this.markerMap.has(clusterId)) {
          const clusterMarker = this.createClusterMarker(feature);
          clusterMarker
            .setLngLat([
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1],
            ])
            .addTo(this.map);
          this.markerMap.set(clusterId, clusterMarker);
        } else {
          const clusterMarker = this.markerMap.get(clusterId);
          if (clusterMarker) {
            clusterMarker.getElement().style.display = 'block';
          }
        }
        // create/unhide venue unclustered markers
      } else {
        const { properties } = feature;
        const venueId = properties.id;
        currentVenueMarkerIds.add(venueId);

        if (!this.markerMap.has(venueId)) {
          const venueMarker = this.createVenueMarker(properties);
          venueMarker
            .setLngLat([properties.geocode.lng, properties.geocode.lat])
            .addTo(this.map);
          this.markerMap.set(venueId, venueMarker);
        } else {
          const venueMarker = this.markerMap.get(venueId);
          if (venueMarker) {
            venueMarker.getElement().style.display = 'block';
          }
        }
      }
    });

    // Hide venue markers  that are now clustered and cluster markers that are now unclustered
    this.markerMap.forEach((marker, id) => {
      if (
        (typeof id === 'string' && !currentVenueMarkerIds.has(id)) ||
        (typeof id === 'number' && !currentClusterMarkerIds.has(id))
      ) {
        marker.getElement().style.display = 'none';
      }
    });
  }

  private createVenueMarker(venue: CustomPointFeature['properties']) {
    const markerDiv = document.createElement('div');
    markerDiv.className = VENUE_MARKER_CLASS_NAME;
    markerDiv.setAttribute('data-testid', 'Map Venue Marker');
    markerDiv.setAttribute('data-shop-slug', venue.slug);
    markerDiv.setAttribute('data-name', venue.translated_name);
    markerDiv.style.backgroundImage = `url(${venue.search_image}), url(${DEFAULT_MAP_MARKER_IMAGE})`;

    if (this.isMapInteractive) {
      markerDiv.addEventListener('click', (event) => {
        event.stopPropagation();
        this.handleMarkerClick(venue, markerDiv);
      });
    }

    return new maplibregl.Marker({ element: markerDiv });
  }

  private createClusterMarker(cluster: CustomClusterFeature) {
    const clusterDiv = document.createElement('div');
    clusterDiv.className = CLUSTER_MARKER_CLASS_NAME;
    clusterDiv.textContent = cluster.properties.point_count.toString();
    clusterDiv.setAttribute('data-testid', 'Map Cluster Marker');

    clusterDiv.addEventListener(
      'click',
      this.handleClusterClick.bind(this, cluster),
    );

    return new maplibregl.Marker({ element: clusterDiv });
  }

  private removeAllMarkers(): void {
    this.markerMap.forEach((marker) => marker.remove());
    this.markerMap.clear();
  }

  private addEventListeners(): void {
    this.map.on('moveend', () => {
      this.updateClustersAndMarkers();
      this.updatePreviousZoomCenter();
    });
    this.map.on('dragend', () => {
      this.debouncedCheckMapMovement();
    });
    this.map.on('click', () => {
      this.deselectMarker();
      if (this.hideControlsOnSelect) {
        this.showControlGroup(true);
      }
      const event = new CustomEvent(MAP_EVENTS.VENUE_SELECTED, {
        detail: null,
      });
      this.eventTarget.dispatchEvent(event);
    });
  }

  private handleClusterClick(cluster: CustomClusterFeature): void {
    const clusterId = cluster.properties.cluster_id;
    const leaves = this.superCluster.getLeaves(clusterId, Infinity);
    const points = leaves.map(
      (leaf) => leaf.geometry.coordinates as [number, number],
    );
    const bbox = MapService.calculateBbox(points);
    const cameraBounds = this.map.cameraForBounds(
      bbox as maplibregl.LngLatBoundsLike,
      {
        // more padding at top and bottom to account for footer and header
        padding: { top: 100, bottom: 100, left: 40, right: 40 },
        maxZoom: this.map.getMaxZoom(),
      },
    );

    if (cameraBounds) this.map.easeTo(cameraBounds);
  }

  private handleMarkerClick(
    venue: CustomPointFeature['properties'],
    markerElement: HTMLElement,
  ) {
    if (this.selectedMarker === markerElement) {
      return;
    }

    // Stop highlighting the previous marker and highlight the new one
    this.deselectMarker();
    markerElement.classList.add('selected');
    this.selectedMarker = markerElement;
    if (this.hideControlsOnSelect) {
      this.showControlGroup(false);
    }

    const event = new CustomEvent(MAP_EVENTS.VENUE_SELECTED, {
      detail: venue.id,
    });
    this.eventTarget.dispatchEvent(event);
    this.map.easeTo({
      center: [venue.geocode.lng, venue.geocode.lat],
      zoom: this.map.getZoom(),
    });
  }

  private deselectMarker() {
    if (this.selectedMarker) {
      this.selectedMarker.classList.remove('selected');
      this.selectedMarker = null;
    }
  }

  private showControlGroup(show: boolean): void {
    const controlGroupContainer = this.map
      .getContainer()
      .querySelector('.maplibregl-ctrl-group');
    if (controlGroupContainer) {
      (controlGroupContainer as HTMLElement).style.display = show
        ? 'block'
        : 'none';
    }
  }

  public get instance(): maplibregl.Map {
    return this.map;
  }

  public updateVenues(newVenues: MapVenue[]): void {
    this.options.venues = newVenues;
    const newGeoJSONFeatures = this.createGeoJSONFeatures();
    this.superCluster.load(newGeoJSONFeatures);
    this.removeAllMarkers();
    this.updateClustersAndMarkers();
    this.previousStatus.searchedCenter = this.map.getCenter();
    this.previousStatus.searchedZoom = this.map.getZoom();
    this.checkMapMovement();
  }

  public changeLanguage(languageCode: string): void {
    if (this.languageInitialized && this.options.language === languageCode) {
      return;
    }

    const style = this.map.getStyle();

    if (style.layers) {
      style.layers.forEach((layer) => {
        if (layer.type === 'symbol' && layer.layout) {
          this.map.setLayoutProperty(layer.id, 'text-field', [
            'get',
            `name:${languageCode}`,
          ]);
        }
      });
    }

    this.options.language = languageCode;
    this.languageInitialized = true;

    this.updateMarkerNameTranslations(languageCode);
  }

  private updateMarkerNameTranslations(languageCode: string): void {
    this.superCluster.points.forEach((feature) => {
      if (!MapService.isCluster(feature)) {
        const { properties } = feature;
        properties.translated_name = translate(
          properties.name_translations,
          languageCode,
        );

        const marker = this.markerMap.get(String(properties.id));
        if (marker) {
          marker
            .getElement()
            .setAttribute('data-name', properties.translated_name);
        }
      }
    });

    this.markerMap.forEach((marker, id) => {
      if (typeof id === 'number') {
        const markerEle = marker.getElement();
        const translations: TranslationsArray = JSON.parse(
          markerEle.getAttribute('data-translations') || '[]',
        );
        const pointCount = markerEle.getAttribute('data-point-count');
        const clusterName = `${translate(translations, languageCode)} +${pointCount}`;
        markerEle.setAttribute('data-name', clusterName);
      }
    });
  }

  private checkMapMovement() {
    const currentCenter = this.map.getCenter();
    const currentZoom = this.map.getZoom();

    const distanceThresholdInMetres =
      this.getRadiusFromCenter() * RADIUS_DISTANCE_PERCENTAGE;
    const isMoveSignificant =
      currentCenter.distanceTo(this.previousStatus.searchedCenter) >
      distanceThresholdInMetres;
    const isZoomOutSignificant =
      Math.floor(currentZoom) !== Math.floor(this.previousStatus.searchedZoom);
    const canPerformSearch = isMoveSignificant || isZoomOutSignificant;

    const event = new CustomEvent<UserMapMovementDetail>(
      MAP_EVENTS.USER_MOVEMENT,
      {
        detail: {
          canPerformSearch,
          center: currentCenter,
          zoom: currentZoom,
          radiusInKm: this.getRadiusFromCenter() / 1000,
        },
      },
    );
    this.eventTarget.dispatchEvent(event);
  }

  private updatePreviousZoomCenter(): void {
    const event = new CustomEvent<PreviousZoomCenter>(
      MAP_EVENTS.PREV_ZOOM_CENTER,
      {
        detail: {
          center: this.map.getCenter(),
          zoom: this.map.getZoom(),
        },
      },
    );
    this.eventTarget.dispatchEvent(event);
  }

  public getRadiusFromCenter(): number {
    const centerPoint = this.map.getCenter();
    const northEastCorner = this.map.getBounds().getNorthEast();
    const distanceToCorner = centerPoint.distanceTo(northEastCorner);
    return distanceToCorner;
  }

  public cleanup(): void {
    if (this.map) {
      if (this.isMapInteractive && this.debouncedCheckMapMovement) {
        this.debouncedCheckMapMovement.cancel();
      }
      this.map.remove();
    }
  }

  public fitBoundsToMarkers(
    transitionOption: CameraTransitionOptions,
    maxZoom: number | null,
  ): void {
    if (this.options.venues.length === 0) {
      return;
    }

    const allPoints: [number, number][] = this.options.venues.map((venue) => [
      venue.geocode.lng,
      venue.geocode.lat,
    ]);

    const bbox = MapService.calculateBbox(allPoints);
    const cameraBounds = this.map.cameraForBounds(
      bbox as maplibregl.LngLatBoundsLike,
      {
        padding: 50,
        maxZoom: maxZoom ?? this.map.getZoom(),
      },
    );
    if (cameraBounds) {
      this.map[transitionOption](cameraBounds);
    }
  }

  public triggerGeolocate(): void {
    if (this.geolocateControl) {
      this.geolocateControl.trigger();
    }
  }

  public selectVenue(venueId: string | null): void {
    if (!venueId) return;

    // need to expand the bounds as shop search may include venues just outside on first load
    const bounds = this.map.getBounds();
    const west = bounds.getWest();
    const south = bounds.getSouth();
    const east = bounds.getEast();
    const north = bounds.getNorth();
    const expandedWest = west - MIN_EXPANDED_BOUNDS_PADDING;
    const expandedSouth = south - MIN_EXPANDED_BOUNDS_PADDING;
    const expandedEast = east + MIN_EXPANDED_BOUNDS_PADDING;
    const expandedNorth = north + MIN_EXPANDED_BOUNDS_PADDING;

    // ensure expanded bounds are within valid ranges
    const expandedBounds = new maplibregl.LngLatBounds(
      [Math.max(-180, expandedWest), Math.max(-90, expandedSouth)],
      [Math.min(180, expandedEast), Math.min(90, expandedNorth)],
    );

    const bbox: GeoJSON.BBox = [
      expandedBounds.getWest(),
      expandedBounds.getSouth(),
      expandedBounds.getEast(),
      expandedBounds.getNorth(),
    ];

    const allVisibleFeatures = this.superCluster.getClusters(
      bbox,
      this.map.getMaxZoom(),
    ) as ClusterOrPoint[];

    for (const feature of allVisibleFeatures) {
      if (!MapService.isCluster(feature)) {
        if (feature.properties.id === venueId) {
          this.map.jumpTo({
            center: [
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1],
            ],
            zoom: this.map.getMaxZoom(),
          });
          const marker = this.markerMap.get(venueId);
          marker?.getElement().click();
          break;
        }
      }
    }
  }

  private static isCluster(
    feature: ClusterOrPoint,
  ): feature is CustomClusterFeature {
    return !!(feature as CustomClusterFeature).properties.cluster;
  }

  private static calculateBbox(points: [number, number][]): GeoJSON.BBox {
    const bbox = points.reduce(
      (acc, point) => {
        const [lng, lat] = point;

        acc[0] = Math.min(acc[0], lng); // minLng
        acc[1] = Math.min(acc[1], lat); // minLat
        acc[2] = Math.max(acc[2], lng); // maxLng
        acc[3] = Math.max(acc[3], lat); // maxLat

        return acc;
      },
      [Infinity, Infinity, -Infinity, -Infinity],
    );
    return bbox as GeoJSON.BBox;
  }

  private createGeoJSONFeatures(): CustomPointFeature[] {
    return this.options.venues.map((venue) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [venue.geocode.lng, venue.geocode.lat],
      },
      properties: {
        id: venue.id,
        slug: venue.slug,
        name_translations: venue.name_translations,
        translated_name: this.cropTranslation(venue.name_translations),
        geocode: venue.geocode,
        search_image:
          venue.search_image && venue.search_image?.length > 0
            ? venue.search_image
            : DEFAULT_MAP_MARKER_IMAGE,
      },
    }));
  }

  private cropTranslation(translation: TranslationsArray): string {
    const translatedName = translate(translation, this.options.language!);
    return translatedName.length > VENUE_TITLE_CROPPED_LENGTH
      ? `${translatedName.substring(0, VENUE_TITLE_CROPPED_LENGTH)}...`
      : translatedName;
  }
}
/* eslint-enable max-lines */
