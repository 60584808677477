import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { TransformedMembershipAuthsResponse } from '@local/types';
import { DateFormat, formatDisplayDate } from '@local/utils';

const Table = styled.table`
  font: var(--small);
  border-collapse: collapse;
  border-radius: var(--border-radius-small);
  overflow: hidden;

  col:first-child {
    background-color: var(--surface-raised);
  }
  col:last-child {
    width: 100%;
  }

  th {
    font-weight: 600;
    padding: 15px var(--spacing-l3);
  }

  td {
    white-space: pre-wrap !important;
    position: relative;

    & > button {
      position: absolute;
      background-color: white;
    }
  }

  th,
  td {
    align-content: center;
    white-space: nowrap;
    text-align: left;
    border: 1px solid var(--border);
  }
  tr:not(:first-child) td {
    padding: 15px var(--spacing-l3);
  }
`;

const StyledCell = styled.div`
  display: grid;
  align-items: center;
  gap: var(--spacing-l4);
  grid-template-columns: 1fr auto;
  padding: var(--spacing-l2) var(--spacing-l3);
`;

interface Props {
  membership: TransformedMembershipAuthsResponse;
  onClick: () => void;
}

export function MembershipTable({ membership, onClick }: Props) {
  const [, { language }] = useTranslation();
  const { providerName, membershipCode, startDate, locationsUsed } = membership;
  const membershipStartDate = startDate
    ? formatDisplayDate(new Date(startDate), DateFormat.YearMonthDay, language)
    : '-';

  return (
    <Table
      data-testid="Membership Table"
      aria-label={`${providerName} membership details`}
    >
      <colgroup>
        <col span={1} />
        <col span={1} />
      </colgroup>
      <tbody>
        <tr>
          <th scope="row">{t('account.membership.program')}</th>
          <td>
            <StyledCell>
              <span>{providerName || '-'}</span>
              <Button
                data-testid="Membership Remove Btn"
                data-variant="danger"
                data-size="small"
                onClick={onClick}
              >
                {t('account.action_btns.remove')}
              </Button>
            </StyledCell>
          </td>
        </tr>
        <tr>
          <th scope="row">{t('account.membership.number')}</th>
          <td>{membershipCode}</td>
        </tr>
        <tr>
          <th scope="row">{t('account.membership.joined')}</th>
          <td>{membershipStartDate}</td>
        </tr>
        <tr>
          <th scope="row">{t('account.membership.locations_used')}</th>
          <td>{locationsUsed.length > 0 ? locationsUsed.join(', ') : '-'}</td>
        </tr>
      </tbody>
    </Table>
  );
}
