import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { logger, sentryService } from '@local/services';

import { deleteMembership } from '../accountApi';

export const useDeleteMembershipQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteMembership,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY, 'memberships'],
      });
    },
    onError: (error) => {
      logger.error(error);
      sentryService.captureException({ error });
    },
  });
};
