import { Location } from '@carbon/icons-react';
import { styled } from '@linaria/react';
import { t } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ICON_SIZE, ICON_SIZE_16 } from '@local/constants';
import { useDeviceType, useUniverse } from '@local/hooks';
import { cookieService } from '@local/services';
import { UniverseName, UniverseSlug } from '@local/types';

import { Panel } from '../Panel/Panel';
import { SmartLinkButton } from '../SmartLink/SmartLinkButton';

const UniverseTrigger = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--spacing-l2);
  padding: var(--spacing-l2);
  border-radius: var(--border-radius-small);
  color: var(--black);
  &:hover {
    background-color: var(--grey-100);
  }
`;

const Item = styled(SmartLinkButton)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
  padding: var(--spacing-l2) var(--spacing-l3);
  &:hover {
    background-color: var(--surface-hover-transparent);
  }
  border: 1px solid var(--grey-300);
  &[data-is-active='true'] {
    background-color: var(--surface-raised-active);
    color: var(--text);
    border: 1px solid var(--brand-primary);
  }
`;

const Title = styled.span`
  font: var(--body-1);
`;

const Text = styled.small`
  color: var(--text-subtle);
`;

const SelectorWrapper = styled.div`
  display: flex;
  gap: var(--spacing-l2);
  padding: var(--spacing-l4);
  padding-top: 0;
  flex-direction: column;
`;

export function UniverseSelector() {
  const { isDesktop } = useDeviceType();
  const [, { language }] = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const iconSize = isDesktop ? DEFAULT_ICON_SIZE : ICON_SIZE_16;
  const { universeName, universeSlug } = useUniverse();

  const handleUniverseChange = (newUniverse: UniverseSlug) => {
    if (newUniverse === universeSlug) {
      setIsOpen(false);
      return;
    }
    cookieService.setUniverse(newUniverse);
    setIsOpen(false);
  };

  return (
    <>
      <UniverseTrigger
        onClick={() => setIsOpen(true)}
        data-testid="Universe Selector Btn"
        data-value={universeSlug}
      >
        <Location size={iconSize} aria-label={universeName} />
        {t(`monolith.countries.${universeSlug}`)}
      </UniverseTrigger>

      <Panel
        isOpen={isOpen}
        title={t(`universe.select_region`)}
        onClose={() => setIsOpen(false)}
      >
        <SelectorWrapper
          data-is-desktop={isDesktop}
          data-testid="Universe Selector Panel"
        >
          <Item
            data-is-active={universeSlug === UniverseSlug.JP}
            data-variant="tertiary"
            type="button"
            data-testid="Universe Selector Japan"
            onClick={() => handleUniverseChange(UniverseSlug.JP)}
            href={`/${language}/${UniverseName.Japan}`}
          >
            <Title>{t(`monolith.countries.JP`)}</Title>
            <Text>{t('universe.japan_text')}</Text>
          </Item>
          <Item
            data-is-active={universeSlug === UniverseSlug.SG}
            data-variant="tertiary"
            type="button"
            data-testid="Universe Selector Singapore"
            onClick={() => handleUniverseChange(UniverseSlug.SG)}
            href={`/${language}/${UniverseName.Singapore}`}
          >
            <Title>{t(`monolith.countries.SG`)}</Title>
            <Text>{t('universe.singapore_text')}</Text>
          </Item>
        </SelectorWrapper>
      </Panel>
    </>
  );
}
