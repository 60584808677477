import { useLocation } from 'react-router-dom';

export class Routing {
  private pathname: string;

  constructor(pathname: string) {
    this.pathname = pathname;
  }

  public get isJapanHomepage(): boolean {
    return this.pathname.endsWith('/japan');
  }

  public get isSingaporeHomepage(): boolean {
    return this.pathname.endsWith('/singapore');
  }

  public get isHomepage(): boolean {
    return this.isJapanHomepage || this.isSingaporeHomepage;
  }

  public get isExplorePage(): boolean {
    return this.pathname.endsWith('/search');
  }

  public get isVenuePage(): boolean {
    return !(
      this.isHomepage ||
      this.isExplorePage ||
      this.isAnAccountPage ||
      this.isAListPage ||
      this.isNotFound
    );
  }

  public get isAnAccountPage(): boolean {
    return this.pathname.includes('/account');
  }

  public get isAReservationsPage(): boolean {
    return this.pathname.includes('/reservations/');
  }

  public get isUpcomingReservationsPage(): boolean {
    return this.pathname.endsWith('/reservations/upcoming');
  }

  public get isPastReservationsPage(): boolean {
    return this.pathname.endsWith('/reservations/past');
  }

  public get isAListPage(): boolean {
    return this.pathname.includes('/lists');
  }

  public get isListsPage(): boolean {
    return this.pathname.endsWith('/lists');
  }

  public get isSingleListPage(): boolean {
    return this.pathname.includes('/lists/');
  }

  public get isProfilePage(): boolean {
    return this.pathname.endsWith('/account/profile');
  }

  public get isEditPage(): boolean {
    return this.pathname.endsWith('/account/edit');
  }

  public get isPaymentsPage(): boolean {
    return this.pathname.endsWith('/account/payments');
  }

  public get isCreditCardsPage(): boolean {
    return this.pathname.endsWith('/account/credit-cards');
  }

  public get isFavoritesPage(): boolean {
    return this.pathname.endsWith('/account/favorites');
  }

  public get isNotFound(): boolean {
    return this.pathname.endsWith('/not-found');
  }
}

export function useRouting(): Routing {
  const { pathname } = useLocation();

  return new Routing(pathname);
}
