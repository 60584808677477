import { styled } from '@linaria/react';
import { Trans, useTranslation } from 'react-i18next';

import { Spinner } from '@local/components';
import { MembershipInfo } from '@local/types';

import { useFetchMemberships } from '../../hooks/useFetchMemberships';

import { MembershipTable } from './MembershipTable/MembershipTable';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);

  & > p {
    font: var(--label);
  }
`;

const NoMembershipMsg = styled.p`
  text-align: center;
  padding: var(--spacing-l4);
  font: var(--body-2);
  color: var(--text-subtle);
  background-color: var(--surface-disabled);
  font-weight: 400 !important;
`;

interface Props {
  onRemoveClick: (membership: MembershipInfo) => void;
}

export function Memberships({ onRemoveClick }: Props) {
  const [t, { language }] = useTranslation();
  const { data: membershipsData, isLoading } = useFetchMemberships(language);

  const content = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (!membershipsData || membershipsData.length === 0) {
      return (
        <NoMembershipMsg data-testid="No Membership Msg">
          <Trans
            i18nKey="account.membership.no_memberships_msg"
            components={{ br: <br /> }}
          />
        </NoMembershipMsg>
      );
    }
    return membershipsData.map((membership) => (
      <MembershipTable
        key={membership.membershipAuthId}
        membership={membership}
        onClick={() =>
          onRemoveClick({
            membershipAuthId: membership.membershipAuthId,
            providerName: membership.providerName || '',
            membershipCode: membership.membershipCode,
          })
        }
      />
    ));
  };

  return (
    <Wrapper>
      <p>{t('account.membership.title')}</p>
      {content()}
    </Wrapper>
  );
}
