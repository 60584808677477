import { styled } from '@linaria/react';
import { useAtom } from 'jotai';
import * as React from 'react';
import { Trans } from 'react-i18next';

import {
  JAPAN_HERO_BANNER_IMG,
  SINGAPORE_HERO_BANNER_IMG,
} from '@local/assets';
import { SmartImage } from '@local/components';
import {
  CampaignSection,
  Catchphrase,
  OverlayContent,
  StoryblokSection,
} from '@local/home';
import { useInView, useOutsideClick, useUniverse } from '@local/hooks';
import {
  SearchInput,
  isHomepageSearchVisibleAtom,
  useSearchInput,
} from '@local/search';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBackgroundImg = styled(SmartImage)`
  height: 50svh;
  width: 100svw;
`;

const StyledOverlay = styled(OverlayContent)`
  top: 25svh;
  padding: 0;
  width: auto;
  left: var(--spacing-l4);
`;

const Content = styled.div`
  display: grid;
  gap: var(--spacing-l8);
  margin: var(--spacing-l8) var(--spacing-l4);
`;

export function HomeDesktop(): JSX.Element {
  const [isHomepageSearchVisible, setIsHomepageSearchVisible] = useAtom(
    isHomepageSearchVisibleAtom,
  );
  const { ref, isInView } = useInView(
    {
      rootMargin: '-83px 0px 0px 0px',
    },
    true,
  );
  const { setSearchView } = useSearchInput();

  React.useEffect(() => {
    setIsHomepageSearchVisible(isInView);
  }, [isInView, setIsHomepageSearchVisible]);

  const searchInputRef = useOutsideClick<HTMLDivElement>(() => {
    if (isHomepageSearchVisible) setSearchView('default');
  }, ref);

  const { isJapanUniverse } = useUniverse();
  const bannerImg = isJapanUniverse
    ? JAPAN_HERO_BANNER_IMG
    : SINGAPORE_HERO_BANNER_IMG;

  return (
    <Wrapper data-testid="Home Page Root">
      <StyledBackgroundImg
        decoding="async"
        src={bannerImg}
        sources={[
          {
            srcSet: `${bannerImg}`.replace('.png', '.webp'),
            type: 'image/webp',
          },
        ]}
        alt="TableCheck Diner Portal Home Banner"
      />
      <StyledOverlay>
        <Catchphrase>
          <Trans i18nKey="homepage.catchphrase" components={{ br: <br /> }} />
        </Catchphrase>
        <SearchInput dataTestId="Home Search Bar" ref={searchInputRef} />
      </StyledOverlay>

      <Content>
        {isJapanUniverse && <CampaignSection />}
        <StoryblokSection type="cuisines" />
        <StoryblokSection type="area" />
      </Content>
    </Wrapper>
  );
}
