import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DEFAULT_GEO_DISTANCE } from '@local/constants';
import {
  useDebounce,
  useOutsideClick,
  useRouting,
  useUniverse,
} from '@local/hooks';
import {
  mapMaxZoomOnSearchAtom,
  mapShouldUsePreviousLocationAtom,
} from '@local/map-system';
import type {
  CuisineSearchSuggestionsResponseType,
  LocationSearchSuggestionsResponseType,
  ShopSearchSuggestionsResponseType,
} from '@local/types';
import { buildUrl } from '@local/utils';

import {
  SEARCH_SUGGESTIONS_DEBOUNCE_TIME,
  SEARCH_SUGGESTIONS_MIN_LENGTH,
} from '../searchConstants';
import { searchValAtom, searchViewAtom } from '../searchStore';

import { useFetchSearchSuggestionsQuery } from './useFetchSearchSuggestionsQuery';
import { useSearchFilters } from './useSearchFilters';

const getDistanceAndZoom = (locationType: string) => {
  switch (locationType) {
    case 'station':
      return { geoDistance: '1km', maxZoom: 16 };
    case 'ward':
      return { geoDistance: '3km', maxZoom: 14 };
    case 'city':
    case 'towns':
      return { geoDistance: '5km', maxZoom: 12 };
    case 'province':
    case 'prefecture':
      return { geoDistance: '10km', maxZoom: 10 };
    default:
      return { geoDistance: DEFAULT_GEO_DISTANCE, maxZoom: null };
  }
};

export function useSearchInput() {
  const navigate = useNavigate();
  const [, { language }] = useTranslation();
  const [searchParams] = useSearchParams();
  const { updateSearchFilters } = useSearchFilters();
  const [searchVal, setSearchVal] = useAtom(searchValAtom);
  const [searchView, setSearchView] = useAtom(searchViewAtom);
  const setMapMaxZoomOnSearch = useSetAtom(mapMaxZoomOnSearchAtom);
  const setMapUsePreviousLocation = useSetAtom(
    mapShouldUsePreviousLocationAtom,
  );
  const isNotExplorePage = !useRouting().isExplorePage;
  const { universeName } = useUniverse();

  const isInputFocused = searchView === 'focused';
  const debouncedSearchVal = useDebounce(
    searchVal.trim(),
    SEARCH_SUGGESTIONS_DEBOUNCE_TIME,
  );
  const shouldShowSearchSuggestions =
    searchVal.length >= SEARCH_SUGGESTIONS_MIN_LENGTH;
  // TODO: change `shouldShowPlainTextSearch = searchVal.length > 0;` when AI search is implemented
  const shouldShowPlainTextSearch = false;
  const searchSuggestionsQuery =
    useFetchSearchSuggestionsQuery(debouncedSearchVal);
  const shouldShowDropDown =
    (shouldShowSearchSuggestions ||
      shouldShowPlainTextSearch ||
      searchSuggestionsQuery.isLoading) &&
    searchView === 'focused';
  const searchInputRef = useOutsideClick<HTMLDivElement>(() =>
    setSearchView('default'),
  );

  const handleSuggestionClick = (
    suggestion?:
      | ShopSearchSuggestionsResponseType
      | CuisineSearchSuggestionsResponseType
      | LocationSearchSuggestionsResponseType,
  ) => {
    switch (suggestion?.type) {
      case 'shops':
        navigate(
          buildUrl(
            `/${language}/${suggestion.payload.shop_slug}`,
            searchParams,
          ),
        );
        break;
      case 'cuisines':
        updateSearchFilters({
          cuisines: [suggestion.payload.term.toLowerCase()],
        });
        setSearchVal(suggestion.text);
        if (isNotExplorePage) navigate(`/${language}/${universeName}/search`);
        break;
      case 'locations': {
        const { geoDistance, maxZoom } = getDistanceAndZoom(
          suggestion.payload.location_type,
        );
        updateSearchFilters(
          {
            geo_latitude: suggestion.payload.geo.lat,
            geo_longitude: suggestion.payload.geo.lon,
            auto_geolocate: false,
            geo_distance: geoDistance,
          },
          { reset: true },
        );
        setSearchVal(suggestion.text);
        setMapMaxZoomOnSearch(maxZoom);
        if (isNotExplorePage) {
          setMapUsePreviousLocation(false);
          navigate(`/${language}/${universeName}/search`);
        }
        break;
      }
      default:
        navigate(`/${language}/${universeName}/search`);
        setSearchVal('');
        break;
    }
    setSearchView('default');
  };

  return {
    searchVal,
    setSearchVal,
    searchInputRef,
    setSearchView,
    isInputFocused,
    shouldShowDropDown,
    searchSuggestionsQuery,
    shouldShowPlainTextSearch,
    shouldShowSearchSuggestions,
    onPlainTextSuggestionClick: () => {
      updateSearchFilters({ search_text: searchVal }, { reset: true });
      setSearchView('default');
      if (isNotExplorePage) navigate(`/${language}/${universeName}/search`);
    },
    handleSuggestionClick,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (searchSuggestionsQuery.data?.suggestions[0]) {
        handleSuggestionClick(searchSuggestionsQuery.data.suggestions[0]);
      } else {
        handleSuggestionClick();
      }

      // TODO: uncomment `if` block below when AI search is implemented. And remove the `if` block above
      // // resets other filters except for search_text
      // if (searchVal.length > 0) {
      //   updateSearchFilters({ search_text: searchVal }, { reset: true });
      //   setSearchView('default');
      //   if (isNotExplorePage) navigate(`/${language}/${universeName}/search`);
      // }
    },
    handleReset: () => {
      setSearchVal('');
    },
    onBackClick: () => {
      updateSearchFilters({ search_text: '' }, { reset: true });
      setSearchView('default');
    },
  };
}
