import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import * as React from 'react';

import { useDeviceType } from '@local/hooks';
import type { MenuItem } from '@local/types';
import { CurrencyFormat } from '@local/utils';

import { MenuItemCard } from './MenuItemCard';

const SectionTitle = styled.div`
  font: var(--h3);
  padding-bottom: var(--spacing-l3);
  color: var(--black);
`;

const ItemsSection = styled.section`
  position: relative;
`;

const CardContainer = styled.section`
  &[data-is-mobile='false'] {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-l3);
  }
  &[data-is-mobile='true'] {
    border-top: 1px solid var(--border-transparent);
  }
`;

const ExpandButton = styled(Button)`
  margin-top: var(--spacing-l5);
`;

const ExpandSection = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 57px;
  text-align: -webkit-center;

  &[data-is-expanded='false'] {
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &[data-is-expanded='true'] {
    height: 30px;

    & button {
      margin-top: var(--spacing-l4);
    }
  }
`;

export function MenuItemsSection({
  items,
  currency,
}: {
  items: MenuItem[];
  currency: CurrencyFormat;
}): JSX.Element {
  const { isDesktop } = useDeviceType();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const shownMenuItemsList = isExpanded ? items : items?.slice(0, 4);
  return (
    <ItemsSection data-testid="Menu Item Section">
      <SectionTitle>
        {t('venue_feat.menu_items.title.dining_courses')}
      </SectionTitle>
      <CardContainer data-is-mobile={!isDesktop}>
        {shownMenuItemsList.map((item) => (
          <MenuItemCard item={item} key={item.id} currency={currency} />
        ))}
      </CardContainer>
      {items.length > 4 && (
        <ExpandSection
          data-is-expanded={isExpanded}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <ExpandButton data-size="small" data-variant="secondary">
            {isExpanded ? t('general.view_less') : t('general.view_more')}
          </ExpandButton>
        </ExpandSection>
      )}
    </ItemsSection>
  );
}
