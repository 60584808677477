import { useQuery } from '@tanstack/react-query';

import { STALETIME_10_SECONDS, USER_QUERY_KEY } from '@local/constants';

import { getSinglePayment } from '../accountApi';

export const useFetchSinglePayment = (paymentId: string, locale: string) =>
  useQuery({
    queryKey: [USER_QUERY_KEY, locale, 'payments', paymentId],
    queryFn: () => getSinglePayment(paymentId, locale),
    staleTime: STALETIME_10_SECONDS,
  });
