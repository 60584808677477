import { addDays, format } from 'date-fns';

import { CONFIG } from '@local/configs';
import {
  BUDGET_INFINITY,
  BUDGET_ZERO,
  DPOR_UTM_SOURCE_BETA1,
} from '@local/constants';
import { ListQueryParamsType, ShopSearchQueryParamsType } from '@local/types';

export const buildUrl = (
  baseUrl: string,
  params?: Record<string, string> | URLSearchParams,
) => {
  const stringifiedParams =
    params instanceof URLSearchParams
      ? params.toString()
      : new URLSearchParams(params ?? {}).toString();
  return decodeURIComponent(
    stringifiedParams ? `${baseUrl}?${stringifiedParams}` : baseUrl,
  );
};

interface BookingParams {
  start_date?: string;
  start_time?: string;
  num_people?: string;
}
// should return start_date, start_time, num_people with utm_source only
export const buildBookingFormUrl = (
  baseUrl: string,
  params?: Record<string, string>,
) => {
  let modifiedParams = {};
  if (params) {
    const filteredParams = Object.entries(params).filter(([key]) =>
      ['date', 'time', 'num_people'].some((param) =>
        key.toLowerCase().includes(param),
      ),
    );

    modifiedParams = filteredParams.reduce((acc, [key, value]) => {
      if (key.toLowerCase().includes('date')) {
        acc.start_date = value;
      } else if (key.toLowerCase().includes('time')) {
        acc.start_time = value;
      } else if (key.toLowerCase().includes('num_people')) {
        acc.num_people = value;
      }
      return acc;
    }, {} as BookingParams);
  }

  return buildUrl(baseUrl, {
    ...modifiedParams,
    utm_source: DPOR_UTM_SOURCE_BETA1,
  });
};

export const isSafari = (): boolean => {
  if (!CONFIG.IS_SSR) {
    return (
      !navigator.userAgent.includes('Chrome') &&
      navigator.userAgent.includes('Safari')
    );
  }
  return false;
};

export const escapeRegexChars = (str: string) =>
  str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

// If we used Object.fromEntries, it would keep only one instance of a key
// which deletes multiple values of cuisines[]
export const getSearchParamsObject = (
  searchParams: URLSearchParams,
): Record<string, string | string[]> => {
  const paramsObject: Record<string, string | string[]> = {};

  searchParams.forEach((value, key) => {
    if (key.endsWith('[]')) {
      if (!(key in paramsObject)) {
        paramsObject[key] = [];
      }
      (paramsObject[key] as string[]).push(value);
    } else {
      paramsObject[key] = value;
    }
  });
  return paramsObject;
};

export const transformParamsToFilters = <
  T extends ListQueryParamsType | ShopSearchQueryParamsType,
>(
  searchParams: URLSearchParams,
  defaultFilters: T,
): T => {
  const filters: Record<string, unknown> = { ...defaultFilters };

  searchParams.forEach((value, key) => {
    if (key === 'is_smartpay') {
      filters[key] = value === 'true';
    } else if (key.endsWith('[]')) {
      const arrayKey = key.slice(0, -2);
      filters[arrayKey] = searchParams.getAll(key);
    } else {
      filters[key] = value;
    }
  });

  return filters as T;
};

const isValidValue = (v: unknown): v is string | number | boolean =>
  (typeof v === 'number' && !Number.isNaN(v)) ||
  typeof v === 'boolean' ||
  (typeof v === 'string' && v !== '');

export const transformFiltersToParams = <
  T extends ListQueryParamsType | ShopSearchQueryParamsType,
>(
  filters: T,
): URLSearchParams => {
  const searchParams = new URLSearchParams();
  const processedArrays: Record<string, Set<string>> = {};

  Object.entries(filters).forEach(([key, value]) => {
    // Remove '[]' from the end of the key if it exists
    const cleanKey = key.endsWith('[]') ? key.slice(0, -2) : key;

    // Skips the following filters if they're not present
    if (
      (cleanKey === 'geo_distance' &&
        'geo_latitude' in filters &&
        !filters.geo_latitude) ||
      (cleanKey === 'is_smartpay' &&
        'is_smartpay' in filters &&
        !filters.is_smartpay) ||
      (cleanKey === 'budget_dinner_avg_min' && value === BUDGET_ZERO) ||
      (cleanKey === 'budget_dinner_avg_max' && value === BUDGET_INFINITY)
    ) {
      return;
    }

    if (Array.isArray(value)) {
      // For array values, we need to use a set to avoid duplicates added in the array.
      if (!processedArrays[cleanKey]) {
        processedArrays[cleanKey] = new Set();
      }
      value.forEach((v) => {
        if (isValidValue(v)) {
          processedArrays[cleanKey].add(v.toString());
        }
      });
    } else if (isValidValue(value)) {
      searchParams.set(cleanKey, value.toString());
    }
  });

  // Adds processed arrays to searchParams
  Object.entries(processedArrays).forEach(([key, valueSet]) => {
    valueSet.forEach((value) => {
      searchParams.append(`${key}[]`, value);
    });
  });

  // TODO : Add test to ensure these params are added correctly REF: https://tablecheck.atlassian.net/browse/DPOR-770
  const { date, time, num_people, date_min } = filters;

  if (date || time || num_people) {
    const availabilityFormat = date ? 'datetime' : 'date';
    searchParams.set('availability_format', availabilityFormat);
    searchParams.set('availability_mode', 'same_meal_time');

    // When filter is pax or time only we want to set the date_min and date_max to the current date and 7 days from now if its not already part of the filters
    if ((num_people || time) && !date && !date_min) {
      searchParams.set('date_min', format(new Date(), 'yyyy-MM-dd'));
      searchParams.set(
        'date_max',
        format(addDays(new Date(), 7), 'yyyy-MM-dd'),
      );
    } else if (date && date_min) {
      // need to remove date_min if date is present, since no venues are returned if the date is past the date_min/max range
      searchParams.delete('date_min');
      searchParams.delete('date_max');
    }
  }

  return searchParams;
};

export const buildMapUrl = (
  lat: number,
  lon: number,
  google_place_ref?: string,
) => {
  const query = google_place_ref
    ? `query=${lat},${lon}&query_place_id=${google_place_ref}`
    : `query=${lat},${lon}`;
  return encodeURI(`https://www.google.com/maps/search/?api=1&${query}`);
};
export const isExternalTCUrl = (url: string): boolean =>
  new URL(url).origin.toLowerCase().includes('tablecheck') &&
  url.startsWith('http');
