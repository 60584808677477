import { useMutation, useQueryClient } from '@tanstack/react-query';

import { USER_QUERY_KEY } from '@local/constants';
import { cookieService, logger, sentryService } from '@local/services';
import type {
  CreateAccountDataType,
  CreateAccountErrorResponse,
} from '@local/types';

import { createAccount, getUser } from '../authApi';

export const useCreateAccountQuery = (locale: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateAccountDataType) => createAccount(data, locale),
    onSuccess: (authToken) => {
      cookieService.setAuthToken(authToken);
      void queryClient.prefetchQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: () => getUser(),
        staleTime: Infinity,
      });
    },
    onError: (error: CreateAccountErrorResponse) => {
      logger.error('Error creating account:', error);
      sentryService.captureException({ error });
      return error;
    },
  });
};
