import { LocaleCode } from '@tablecheck/locales';
import { useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import {
  hasAlphabeticalCharacters,
  hasKanjiOrJapaneseCharacters,
} from '@local/utils';

import { fetchVenueData } from '../venueApi';

export const useFetchVenueDataQuery = (venueId: string) => {
  const [, { language }] = useTranslation();

  return useQuery({
    queryKey: ['venue', venueId, language],
    queryFn: () => fetchVenueData(venueId, language),
    enabled: !!venueId,
    staleTime: Infinity,
    select: (data) => {
      const venueData = data.shops[0];

      // Handle translated address if needed
      const translatedAddress =
        language === venueData.locale
          ? venueData.address
          : venueData.alt_address ?? venueData.address;

      // Format cuisines to match lokalize keys
      const formattedCuisines = venueData.cuisines.map((cuisine) =>
        cuisine.replace(/-/g, '_'),
      );

      const usesKanjiOrJapaneseCharacters = [
        LocaleCode.Japanese as string,
        LocaleCode.ChineseSimplified as string,
        LocaleCode.ChineseTraditional as string,
      ].includes(language);

      // TODO: Will be removed and enhanced by DPOR-1011
      const filteredStations = Object.values(
        // First: groups duplicates by rounding distance (type can be string or number)
        groupBy(venueData.stations, (station) =>
          Math.floor(Number(station.distance)),
        ),
      ).map((stations) => {
        // Then we will select one of the duplicate with the correct translation:
        // For Kanji based language, take the first locale translation with Kanji
        // For other languages, take the first locale translation with alphabetical letters
        let stationWithTranslation;
        if (usesKanjiOrJapaneseCharacters) {
          stationWithTranslation = stations.find((station) =>
            hasKanjiOrJapaneseCharacters(
              station.name_translations.find((t) => t.locale === language)
                ?.translation,
            ),
          );
        } else {
          stationWithTranslation = stations.find((station) =>
            hasAlphabeticalCharacters(
              station.name_translations.find((t) => t.locale === language)
                ?.translation,
            ),
          );
        }
        return stationWithTranslation ?? stations[0]; // if no match, take the first station
      });

      const geocodeOrNull =
        venueData.geocode?.lon && venueData.geocode?.lat
          ? { lon: venueData.geocode.lon, lat: venueData.geocode.lat }
          : null;

      return {
        ...venueData,
        address: translatedAddress,
        cuisines: formattedCuisines,
        stations: filteredStations,
        geocode: geocodeOrNull,
      };
    },
  });
};
