import { styled } from '@linaria/react';

import { SmartLinkProps as Props, SmartLink } from './SmartLink';

const StyledSmartLink = styled(SmartLink)`
  --loading-transition: 300ms ease-in-out;
  align-items: center;
  border-radius: var(--border-radius-small);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  line-height: 20px;
  text-align: center;
  transition:
    color var(--loading-transition),
    background-color var(--loading-transition),
    border-color var(--loading-transition);
  white-space: nowrap;
`;

// TODO: Consider updating to accept the various Button data-variants (bare, danger, etc.). https://tablecheck.atlassian.net/browse/DPOR-919
export function SmartLinkButton({ href, children, onClick, ...props }: Props) {
  return (
    <StyledSmartLink href={href} onClick={onClick} {...props}>
      {children}
    </StyledSmartLink>
  );
}
