import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { STALETIME_1_HOUR } from '@local/constants';
import { useUniverse } from '@local/hooks';

import { fetchSearchSuggestions } from '../searchApi';
import { SEARCH_SUGGESTIONS_MIN_LENGTH } from '../searchConstants';

export const useFetchSearchSuggestionsQuery = (searchText: string) => {
  const { universeId } = useUniverse();
  const [, { language }] = useTranslation();
  return useQuery({
    queryKey: ['searchSuggestions', searchText, language, universeId],
    queryFn: () => fetchSearchSuggestions(searchText, language, universeId),
    staleTime: STALETIME_1_HOUR,
    enabled: searchText.length >= SEARCH_SUGGESTIONS_MIN_LENGTH,
    select: (data) => ({
      suggestions: [
        ...(data.cuisines ?? []),
        ...(data.locations ?? []),
        ...(data.shops ?? []),
      ],
    }),
  });
};
