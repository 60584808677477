import { useAtomValue } from 'jotai';
import * as React from 'react';

import { useGetUserQuery } from '@local/auth';
import { useTypedRouteParams } from '@local/hooks';
import { MapVenue } from '@local/types';
import { transformFiltersToParams } from '@local/utils';

import { listFiltersAtom } from '../listStore';

import { useFetchListDetailQuery } from './useFetchListDetailQuery';

interface ListRouteParams {
  listId: string;
}

export function useListView() {
  const { listId } = useTypedRouteParams<ListRouteParams>();
  const listFilters = useAtomValue(listFiltersAtom);
  const { data: user } = useGetUserQuery();
  const listDetail = useFetchListDetailQuery(
    listId,
    user,
    transformFiltersToParams(listFilters),
  );

  const availabilityToShow = (): 'time' | 'date' | null => {
    if (!listFilters.date && (listFilters.time || listFilters.num_people)) {
      return 'date';
    }
    if (listFilters.date) {
      return 'time';
    }
    return null;
  };

  const mapVenues = React.useMemo(() => {
    if (listDetail.data?.list?.shops) {
      return listDetail.data.list.shops
        .filter((shop) => shop.longitude !== null && shop.latitude !== null)
        .map((shop) => ({
          id: shop.id,
          slug: shop.slug,
          name_translations: shop.name_translations,
          geocode: {
            lng: shop.longitude,
            lat: shop.latitude,
          },
          cuisines: shop.cuisines,
          budget_dinner_avg: shop.budget_dinner_avg,
          budget_lunch_avg: shop.budget_lunch_avg,
          search_image: shop.image_url,
          availability: shop.availability,
        })) as MapVenue[];
    }
    return [];
  }, [listDetail.data?.list.shops]);

  return { mapVenues, listDetail, availabilityToShow };
}
