import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';

import { Footer } from '@local/components';
import { MembershipInfo } from '@local/types';

import { useDeleteMembershipQuery } from '../../../hooks/useDeleteMembershipQuery';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  padding: 0 var(--spacing-l4);

  & > div:first-child {
    padding: var(--spacing-l2);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--border-transparent);
  }
`;

interface Props {
  selectedMembership: MembershipInfo;
  onSuccess: () => void;
  onCancel: () => void;
}

export function DeleteMembershipContent({
  selectedMembership,
  onSuccess,
  onCancel,
}: Props) {
  const { mutate: deleteMembership, isPending } = useDeleteMembershipQuery();

  return (
    <Wrapper>
      <div>
        {t('account.membership.name_and_number', {
          membership_name: selectedMembership.providerName,
          membership_number: selectedMembership.membershipCode,
        })}
      </div>
      <p>{t('account.membership.remove_msg')} </p>
      <Footer>
        <Button data-variant="tertiary" type="button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        <Button
          data-testid="Membership Remove Submit Btn"
          data-variant="danger"
          aria-busy={isPending}
          onClick={() => {
            deleteMembership(selectedMembership.membershipAuthId, {
              onSuccess,
            });
          }}
        >
          {t('account.action_btns.remove')}
        </Button>
      </Footer>
    </Wrapper>
  );
}
