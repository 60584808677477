import { styled } from '@linaria/react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { CardCarousel, ExperienceCard } from '@local/components';
import { CONFIG } from '@local/configs';
import { FIRST_THREE_ITEMS } from '@local/constants';
import { MenuItem, MenuItemsResponse } from '@local/types';
import { buildBookingFormUrl, openUrl, translate } from '@local/utils';

const Wrapper = styled.div`
  min-width: -webkit-fill-available;
`;

export function Experiences({
  venueSlug,
  experiences,
}: {
  venueSlug: string;
  experiences: MenuItemsResponse | undefined;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const [searchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams);

  return (
    <Wrapper>
      <CardCarousel
        id="experiences"
        showViewMore={!!experiences && experiences.menu_items.length > 3}
        showMoreAction={() =>
          openUrl(
            buildBookingFormUrl(
              `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${venueSlug}/reserve/experiences`,
              searchParamsObject,
            ),
            '_blank',
          )
        }
        title={t('venue_feat.experiences')}
      >
        {experiences?.menu_items
          .slice(0, FIRST_THREE_ITEMS)
          .map((experience: MenuItem) => (
            <SwiperSlide key={experience.id}>
              <ExperienceCard
                cardData={experience}
                experienceTitle={translate(
                  experience.name_translations,
                  language,
                )}
                onClickAction={() =>
                  openUrl(
                    buildBookingFormUrl(
                      `${CONFIG.VITE_BASE_TABLECHECK_URL}${language}/${venueSlug}/reserve/experience/${experience.id}`,
                      searchParamsObject,
                    ),
                    '_blank',
                  )
                }
              />
            </SwiperSlide>
          ))}
      </CardCarousel>
    </Wrapper>
  );
}
