import { styled } from '@linaria/react';
import { Button } from '@tablecheck/tablekit-react-css';
import { t } from 'i18next';
import { xor } from 'lodash-es';
import * as React from 'react';

import { useGetUserQuery } from '@local/auth';
import { Footer, Spinner } from '@local/components';
import type { SaveListsRequest } from '@local/types';

import { useFetchAllListsQuery } from '../../hooks/useFetchAllListsQuery';
import { CreateNewListCard } from '../CreateNewListCard/CreateNewListCard';
import { ListCard } from '../ListCard/ListCard';

const Wrapper = styled.div`
  padding: 0 var(--spacing-l4);
`;

interface Props {
  venueId: string;
  onSubmit: (listsToUpdate: SaveListsRequest) => void;
  onCreateListClick: () => void;
  onCancel: () => void;
}

export function SaveToListContent({
  venueId,
  onSubmit,
  onCreateListClick,
  onCancel,
}: Props) {
  const { data: user } = useGetUserQuery();
  const { data, status } = useFetchAllListsQuery(user);

  const [initialSelectedLists, setInitialSelectedLists] = React.useState<
    string[]
  >([]);
  const [currentSelectedLists, setCurrentSelectedLists] = React.useState<
    string[]
  >([]);
  const [listsToUpdate, setListsToUpdate] = React.useState<SaveListsRequest>({
    save: [],
    unsave: [],
  });

  const handleSelect = (id: string) => {
    const isCurrentlySelected = currentSelectedLists.includes(id);
    const isInitiallySelected = initialSelectedLists.includes(id);

    setCurrentSelectedLists((current) =>
      isCurrentlySelected
        ? current.filter((listId) => listId !== id)
        : [...current, id],
    );

    setListsToUpdate((prevState) => {
      // if this list id is already selected, unsave it only if it's not in the initial selected lists
      if (isCurrentlySelected) {
        return {
          save: prevState.save.filter((item) => item !== id),
          unsave: !isInitiallySelected
            ? prevState.unsave
            : [...prevState.unsave, id],
        };
      }
      // if this list id is not selected, save it only if it's not in the initial selected lists
      return {
        save: isInitiallySelected ? prevState.save : [...prevState.save, id],
        unsave: prevState.unsave.filter((item) => item !== id),
      };
    });
  };

  React.useEffect(() => {
    const shopIds =
      data?.lists
        ?.filter((item) => item.shop_ids.includes(venueId))
        .map((item) => item.id) ?? [];

    setInitialSelectedLists(shopIds);
    setCurrentSelectedLists(shopIds);
  }, [data, venueId]);

  return (
    <Wrapper data-testid="Save To List Modal">
      <CreateNewListCard
        onClick={() => {
          onCreateListClick();
        }}
      />
      {data && status === 'success' ? (
        <>
          {data.lists.map((list) => (
            <ListCard
              key={list.id}
              id={list.id}
              name={list.name}
              shopIds={list.shop_ids}
              imageUrl={list.image_url}
              isFavorite={list.is_favorite}
              isChecked={currentSelectedLists.includes(list.id)}
              onSelect={handleSelect}
            />
          ))}
        </>
      ) : (
        <Spinner />
      )}
      <Footer>
        <Button data-variant="ghost" type="button" onClick={() => onCancel()}>
          {t('general.cancel')}
        </Button>
        <Button
          data-testid="Save To List Btn"
          data-variant="primary"
          type="button"
          onClick={() => void onSubmit(listsToUpdate)}
          disabled={
            xor(initialSelectedLists, currentSelectedLists).length === 0
          }
        >
          {t('general.save')}
        </Button>
      </Footer>
    </Wrapper>
  );
}
