import { useSetAtom } from 'jotai';

import { useDeleteUserMemoQuery, useUpdateUserMemoQuery } from '@local/user';

import { panelStateAtom } from '../Panel/panelStore';
import { useToast } from '../Toast/useToast';

export const useEditUserMemo = (venueSlug: string) => {
  const setPanelState = useSetAtom(panelStateAtom);
  const { updateMemoToastContent, setToastContent, setIsToastOpen } =
    useToast();

  const { mutate: saveUserMemo } = useUpdateUserMemoQuery(venueSlug);
  const { mutate: deleteMemo } = useDeleteUserMemoQuery(venueSlug);

  return {
    onSubmitMemo: (memo: string) => {
      saveUserMemo(memo, {
        onSuccess: () => {
          setPanelState(null);
          setToastContent(updateMemoToastContent('save_memo'));
          setIsToastOpen(true);
        },
      });
    },

    onDeleteMemo: () => {
      deleteMemo(undefined, {
        onSuccess: () => {
          setPanelState(null);
          setToastContent(updateMemoToastContent('delete_memo'));
          setIsToastOpen(true);
        },
      });
    },
  };
};
