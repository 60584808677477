import { useMutation } from '@tanstack/react-query';

import { logger, sentryService } from '@local/services';
import type { ResetPasswordDataType } from '@local/types';

import { resetPassword } from '../authApi';

export const useResetPasswordQuery = () =>
  useMutation({
    mutationFn: (data: ResetPasswordDataType) => resetPassword(data),
    onError: (error) => {
      logger.error(error);
      sentryService.captureException({ error });
    },
  });
